import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/participants/invoice'

export default {
  // for listing in invoice one, different with listing in client tab
  listAllClientBudget (id) {
    return request(`${url}/budget/list/${id}`)
  },
  listAllClientBudgetCurrent (clientId, invId) {
    const values = {
      client_id: clientId,
      invoice_id: invId
    }

    return request(`${url}/budget/current`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listAllClientRate (id) {
    return request(`${url}/rate/list/${id}`)
  },
  listAllClientCatItems (clientId, values) {
    return request(`${url}/budget/cat-items/${clientId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
