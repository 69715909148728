import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'
import { fetchInvoices } from '../../../states/actions/invoice'
import { ExportType, Permissions } from '../../../constants'
import { auth, common, exportFile, formatter, validator } from '../../../util'
import { invoiceExportService } from '../../../services'

// UI
import { Button, Checkbox, DateTimePicker, List, Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const { confirm } = Modal
const FormItem = Form.Item

const pageSize = 20
const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const displayFormat = 'DD/MM/YYYY'

const TypeList = [
  { type: ExportType.TYPE_PRODA_PM, path: '/payment-request-pm' },
  { type: ExportType.TYPE_PRODA_STD, path: '/payment-request-std' },
  { type: ExportType.TYPE_PAYMENT, path: '/aba-remittance' }
]

const ItemsSelectErrMsg = 'No item is selected.'

const formItemLayout = {
  labelCol: { sm: 12, md: 12, lg: 12 },
  wrapperCol: { sm: 12, md: 12, lg: 12 }
}
export class InvoiceExport extends Component {
  constructor (props) {
    super(props)
    const { match } = this.props
    const { params = {} } = match
    const { type = '' } = params
    const section = TypeList.find(e => e.path === type || e.path === `/${type}`)

    this.state = {
      exportId: null,
      exportType: null,
      invoices: { list: [], total: 0 },
      listProdaPM: { list: [], total: 0 },
      listProdaSTD: { list: [], total: 0 },
      listABA: { list: [], total: 0 },
      isShowAbaModal: false,
      isShowRmtModal: false,
      isShowProdaModal: false,
      itemsModal: [],
      itemsSelect: {},
      itemsIdsSelect: [],
      itemsIdsSelectOri: [],
      itemsIdsSelectAll: false,
      itemsIdsSelectErrMsg: '',
      currentPage: 1,
      filter: {},
      filterParam: section ? section.type : ExportType.TYPE_PRODA_PM,
      loading: false,
      loadingNext: false,
      loadingSelect: false,
      searching: false,
      searchText: '',
      showJobModal: false,
      showEndDate: true,
      sort: {},
      total: 0
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location } = nextProps
    const { page, export_id, export_type } = common.getQueryStringSearchParams(location.search)

    const state = {
      ...prevState,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage,
      exportId: export_id,
      exportType: export_type
    }

    return state
  }

  componentDidMount () {
    const { exportId, exportType } = this.state
    const { match, history } = this.props
    const { params = {} } = match
    const { type = '' } = params
    const section = TypeList.find(e => e.path === type || e.path === `/${type}`)

    if (!section) {
      history.replace(`/invoices-export${TypeList[0].path}`)
    }

    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchInvoices({ currentPage, filter, filterParam, loading, searchText, sort })

    if (exportId && exportType) {
      this.fetchExportDetails()
    }
  }

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, filterParam, loading, sort } = this.state
    this.setState({ searching: true })

    // the searching method in Invoice Export is very differ with other module. do not refer here if you are using general searching
    this.fetchInvoices({ currentPage: 1, filter, filterParam, loading, searchText: value, sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  /** query = { page }  */
  redirectUrl = (query) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  render () {
    const { currentPage, filterParam, isShowAbaModal, isShowRmtModal, isShowProdaModal, itemsModal, itemsIdsSelect, itemsSelect, itemsIdsSelectAll, itemsIdsSelectErrMsg, listABA, listProdaPM, listProdaSTD, loading, loadingSelect, searching } = this.state
    const { getFieldDecorator } = this.props.form
    const invoices = filterParam === ExportType.TYPE_PRODA_PM
      ? listProdaPM
      : filterParam === ExportType.TYPE_PRODA_STD
        ? listProdaSTD
        : filterParam === ExportType.TYPE_PAYMENT
          ? listABA
          : { list: [], total: 0 }

    const columnsPayment = [
      {
        title: 'Inv No.',
        width: 2,
        render: ({ invoice_number }) => <div>{invoice_number}</div>
      },
      {
        title: 'Inv Date',
        width: 2,
        render: ({ invoice_date }) => formatter.toShortDate(invoice_date)
      },
      {
        title: 'Provider',
        width: 3,
        render: ({ provider_id, provider_name, invoice_provider_name }) => provider_id
          ? <div>{provider_name}</div>
          : invoice_provider_name
            ? <div style={{fontStyle: 'italic'}}>{invoice_provider_name}</div>
            : <div>N/A</div>
      },
      {
        title: 'Participant',
        width: 3,
        render: ({ client_name }) => <div>{client_name}</div>
      },
      {
        title: 'Svc Date',
        width: 2,
        render: ({ inv_date }) => <div>{formatter.toShortDate(inv_date)}</div>
      },
      {
        title: 'Item',
        width: 7,
        render: ({ cat_name, cat_item_name }) => <div>{cat_name} - {cat_item_name}</div>
      },
      {
        title: 'Received Payment',
        width: 5,
        render: (item) => item.rcv_history.map((e, idx) => {
          const isChecked = itemsIdsSelect.find(f => f === e.inv_item_history_id) || false
          const isLast = idx === item.rcv_history.length - 1
          return (
            <div key={`rsc${item.id}`} className={'inv-sec-row'} style={{marginBottom: !isLast ? '8px' : 0}}>
              <Col lg={16}>
                <div style={{marginRight: '15px'}}>{e.is_credit_applied ? `Credited` : `Received`} {formatter.toPrice(e.amount)} on {formatter.toShortDate(e.received_date)}</div>
              </Col>
              <Col lg={1}>
                <Checkbox
                  checked={isChecked}
                  onClick={f => this.updateModalIdsSelect(f, e.inv_item_history_id)}
                />
              </Col>
            </div>
          )
        })
      },
    ]

    const columnsProda = [
      {
        title: 'Inv No.',
        width: 6,
        render: ({ invoice_number }) => <div>{invoice_number}</div>
      },
      {
        title: 'Invoice Date Date',
        width: 4,
        render: ({ invoice_date }) => formatter.toShortDate(invoice_date)
      },
      {
        title: 'Provider',
        width: 6,
        render: ({ provider_id, provider_name, invoice_provider_name }) => provider_id
          ? <div>{provider_name}</div>
          : invoice_provider_name
            ? <div style={{fontStyle: 'italic'}}>{invoice_provider_name}</div>
            : <div>N/A</div>
      },
      {
        title: 'Participant',
        width: 6,
        render: ({ client_name }) => <div>{client_name}</div>
      },
      {
        title: 'Select',
        width: 1,
        render: (item) => {
          const isChecked = itemsIdsSelect.find(f => f === item.id) || false

          return (
            <div key={`rec${item.id}`} className={'inv-sec-row'}>
              <Checkbox
                checked={isChecked}
                onClick={f => this.updateModalIdsSelect(f, item.id)}
              />
            </div>
          )
        }
      },
    ]

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title='Redownload' />
          { this.hasAccess(Permissions.INVOICE.MGMT.LIST)
            ? <Page.Filter>
              <Row style={{marginBottom: '20px'}} gutter={8}>
                <Col lg={10}>
                  <ControlLabel>Export Date, Invoice Date, Participant, Provider, Invoice Number</ControlLabel>
                  <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
                </Col>
                <Col lg={10}>
                  <div style={{marginLeft: '12.5pt'}}>
                    <ControlLabel>Types of Redownload</ControlLabel>
                  </div>
                  <Radio.Group onChange={this.filterInvoice} value={filterParam} style={{ marginLeft: 20 }}>
                    <Radio.Button value={ExportType.TYPE_PRODA_PM}>PM Payment Request</Radio.Button>
                    <Radio.Button value={ExportType.TYPE_PRODA_STD}>STD Payment Request</Radio.Button>
                    <Radio.Button value={ExportType.TYPE_PAYMENT}>ABA / Remittance</Radio.Button>
                  </Radio.Group>
              </Col>
              </Row>
            </Page.Filter>
            : null }

          <div className='invoices'>
            <Skeleton loading={loading} active>
              { invoices && validator.isNotEmptyArray(invoices.list) && invoices.list.map((item) => {
                const {
                  id,
                  created_at: expCreatedAt,
                  invoices,
                  is_loading: isLoading,
                  is_loading_aba: isLoadingABA,
                  is_loading_proda: isLoadingProda,
                  is_loading_rmt: isLoadingRmt,
                  payment_date: paymentDate
                } = item

                return (
                  filterParam === ExportType.TYPE_PRODA_PM || filterParam === ExportType.TYPE_PRODA_STD
                  ? <div className={`list-item`} key={`rlst${id}-${formatter.toShortDate(expCreatedAt)}`}>
                    <Row>
                      <Col lg={21}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={10}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Created At</div>
                              </Col>
                              <Col lg={12}>
                                <div className='name title'>{formatter.toShortDate(expCreatedAt)}</div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: '6px' }}>
                          <Col lg={24}>
                            <Row>
                              <Col lg={2}>
                                <div className='subtitle'>Invoices</div>
                              </Col>
                              <Col lg={22}>
                                { invoices.map((e, idx) => (
                                  <Row key={`invrl-${e.invoice_number}-${id}-${idx}`} className={'invoices-row'} gutter={10}>
                                    <Col lg={4}>
                                      <div className='title'>{e.invoice_number}</div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='date'>{
                                        e.provider_id
                                        ? e.provider_name
                                        : e.invoice_provider_name
                                          ? e.invoice_provider_name
                                          : 'N/A'
                                      }</div>
                                    </Col>
                                    <Col lg={5}>
                                      <div className='date'>{e.client_name}</div>
                                    </Col>
                                    <Col lg={5}>
                                      <div className='date'>Invoiced at {formatter.toShortDate(e.invoice_date)}</div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className='item'>{e.qty} item{parseInt(e.qty) === 1 ? '' : 's'}</div>
                                    </Col>
                                  </Row>
                                ))}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3}>
                        { isLoadingProda
                          ? <div className={`list-button disable`} ><img src='/icon/button-loading.svg' alt='loading' /></div>
                          : this.hasAccess(Permissions.INVOICE.MGMT.READ)
                            ? <div className={`list-button ${isLoadingProda ? 'disable' : ''}`} onClick={() => this.triggerSelectModal(true, item, ExportType.TYPE_PRODA)}><Icon style={{fontSize: '15px', marginRight: '6px'}} type='download' /> Payment Request</div>
                            : null }
                      </Col>
                    </Row>
                  </div>
                  : filterParam === ExportType.TYPE_PAYMENT
                  ? <div className={`list-item`} key={`rlet${id}-${formatter.toShortDate(expCreatedAt)}`}>
                    <Row>
                      <Col lg={21}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={6}>
                            <Row>
                              <Col lg={8}>
                                <div className='subtitle'>Created At</div>
                              </Col>
                              <Col lg={12}>
                                <div className='name title'>{formatter.toShortDate(expCreatedAt)}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row>
                              <Col lg={10}>
                                <div className='subtitle'>Payment Date</div>
                              </Col>
                              <Col lg={12}>
                                <div className='name title'>{formatter.toShortDate(paymentDate)}</div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: '12px' }}>
                          {/* <Col lg={18}> */}
                            <Row>
                              <Col lg={2}>
                                <div className='subtitle'>Invoices</div>
                              </Col>
                              <Col lg={22}>
                                { invoices.map((e, idx) => (
                                  <Row key={`invrs-${e.invoice_number}-${id}-${idx}`} className={'invoices-row'} gutter={10}>
                                    <Col lg={4}>
                                      <div className='title'>{e.invoice_number}</div>
                                      <div className='date'>Invoiced on {formatter.toShortDate(e.invoice_date)}</div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className={`name1 ${!e.provider_id && e.invoice_provider_name ? 'italic' : ''}`}>{
                                        e.provider_id
                                        ? e.provider_name
                                        : e.invoice_provider_name
                                          ? e.invoice_provider_name
                                          : 'N/A'
                                      }</div>
                                      <div className='name2'>{e.client_name}</div>
                                    </Col>
                                    <Col lg={14}>
                                      { validator.isNotEmptyArray(e.inv_items) && e.inv_items.map((f, idx) => (
                                        <Row style={{marginBottom: e.inv_items.length - 1 === idx ? '0px' : '10px'}} gutter={10}>
                                          <Col lg={4}>
                                            <div className='date'>{formatter.toShortDate(f.inv_date)}</div>
                                          </Col>
                                          <Col lg={10}>
                                            <div className='date'>{f.cat_item_name}</div>
                                            <div className='date'>{f.cat_item_identifier}</div>
                                          </Col>
                                          <Col lg={10}>
                                            { validator.isNotEmptyArray(f.receive_history) && f.receive_history.map((p, idx) => (
                                              <div key={`invrchis${e.id}-${e.inv_item_id}-${idx}`}>{p.is_credit_applied ? `Credited` : `Received`} {formatter.toPrice(p.amount)} on {formatter.toShortDate(p.received_date)}</div>
                                            ))}
                                          </Col>
                                        </Row>
                                      ))}

                                    </Col>
                                  </Row>
                                ))}
                              </Col>
                            </Row>
                          {/* </Col> */}
                        </Row>
                      </Col>
                      <Col lg={3}>
                        { isLoadingABA
                          ? <div className={`list-button disable`} >
                            <img src='/icon/button-loading.svg' alt='loading' />
                          </div>
                          : this.hasAccess(Permissions.INVOICE.MGMT.READ)
                            ? <div
                              className={`list-button ${isLoadingABA ? 'disable' : ''}`}
                              onClick={() => this.triggerSelectModal(true, item, ExportType.TYPE_ABA)}
                            >
                              <Icon style={{fontSize: '15px', marginRight: '6px'}} type='download' /> ABA
                            </div>
                            : null }
                        { isLoadingRmt
                          ? <div className={`list-button disable`} >
                            <img src='/icon/button-loading.svg' alt='loading' />
                          </div>
                          : this.hasAccess(Permissions.INVOICE.MGMT.READ)
                            ? <div
                                className={`list-button ${isLoadingRmt ? 'disable' : ''}`}
                                onClick={() => this.triggerSelectModal(true, item, ExportType.TYPE_RMT)}
                              >
                              <Icon style={{fontSize: '15px', marginRight: '6px'}} type='download' /> Remittance
                            </div>
                            : null }
                      </Col>
                    </Row>
                  </div>
                  : null
                )
              })}
            </Skeleton>
          </div>

          { !loading
            ? <Pager
              size={pageSize}
              total={invoices.total}
              totalText={`Total ${invoices.total} records`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
            : null }

          <Modal
            width='80%'
            title={`Select and Generate ${isShowProdaModal ? 'Payment Request' : isShowAbaModal ? 'ABA' : isShowRmtModal ? 'Remittance' : 'ABA / Remittance'}`}
            visible={isShowAbaModal || isShowRmtModal || isShowProdaModal}
            onCancel={() => this.triggerSelectModal(false)}
            footer={
              [
                <Button key='close' ghost feedback={loadingSelect} onClick={() => this.triggerSelectModal(false)}>Close</Button>,
                <Button style={{backgroundColor: '#3d34eb'}} key='submit1' feedback={loadingSelect} onClick={() => this.preGetFile()}>Download Selected</Button>,
                isShowRmtModal ? <Button style={{backgroundColor: '#ebc034'}} key='submit2' feedback={loadingSelect} onClick={() => this.preGetFile(true, false)}>Email Selected</Button> : null,
                isShowRmtModal ? <Button key='submit3' feedback={loadingSelect} onClick={() => this.preGetFile(true, true)}>Download & Email Selected</Button> : null
              ]
            }
          >
            <Spin spinning={loadingSelect} blur>
              { filterParam === ExportType.TYPE_PAYMENT
                ? <Form>
                  <div className='inv-title'>Select / Update Payment Date</div>
                  <div className='inv-notice-msg'>You may select a date to set payment date on files.</div>
                  <Row>
                    <Col lg={8}>
                      <FormItem key={`pdinp-${itemsSelect.id}`} {...formItemLayout} label='Payment Date'>
                        {getFieldDecorator('payment_date', {
                          initialValue: itemsSelect.payment_date ? formatter.toMoment(itemsSelect.payment_date) : formatter.toMoment(new Date()),
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validatePaymentDate }
                          ]
                        })(
                          <DateTimePicker showTime={false} />
                        )}
                      </FormItem>
                    </Col>
                    {/* <Col lg={5}>
                      <div style={{marginTop: '6px'}}>
                        <Button key='submit4' feedback={loadingSelect} onClick={() => this.preUpdatePaymentDate()}>Update</Button>
                      </div>
                    </Col>
                    <Col lg={12}></Col> */}
                    <Col lg={17}></Col>
                  </Row>

                  <div className='inv-sec-row ' style={{marginTop: '15px'}}>
                    <div className='inv-title'>Select received amounts to export.</div>
                    <div className='inv-sec-row' style={{marginRight: '25px'}}>
                      <div style={{marginRight: '10px', fontSize: '11px'}}>Select / Deselect All</div>
                      <Checkbox
                        checked={itemsIdsSelectAll}
                        onClick={f => this.updateModalIdsSelectAll(f)}
                      />
                    </div>
                  </div>

                  { itemsIdsSelectErrMsg ? <div className='inv-err-msg'>{itemsIdsSelectErrMsg}</div> : null }
                  <div className='inv-section'>
                    <List cols={columnsPayment} rows={itemsModal} />
                  </div>
                </Form>
                : (filterParam === ExportType.TYPE_PRODA_PM || filterParam === ExportType.TYPE_PRODA_STD)
                  ? <Form>
                    <div className='inv-sec-row ' style={{marginTop: '15px'}}>
                      <div className='inv-title'>Select invoice to export.</div>
                      <div className='inv-sec-row' style={{marginRight: '25px'}}>
                        <div style={{marginRight: '10px', fontSize: '11px'}}>Select / Deselect All</div>
                        <Checkbox
                          checked={itemsIdsSelectAll}
                          onClick={f => this.updateModalIdsSelectAll(f)}
                        />
                      </div>
                    </div>

                    { itemsIdsSelectErrMsg ? <div className='inv-err-msg'>{itemsIdsSelectErrMsg}</div> : null }
                    <div className='inv-section'>
                      <List cols={columnsProda} rows={itemsModal} />
                    </div>
                  </Form>
                  : null }

            </Spin>
          </Modal>

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, filterParam, searchText, sort } = this.state
    this.redirectUrl({ page: currentPage })
    this.fetchInvoices({ currentPage, filter, filterParam, searchText, sort })
  }

  fetchInvoices = async ({ loading = true, currentPage = 1, filter = {}, filterParam, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.INVOICE.MGMT.LIST)) return

    if (window) window.scrollTo({top: 0, left: 0})

    try {
      this.setState({ currentPage, loading: true, loadingNext: currentPage === 1 ? false : true })

      if (filterParam === ExportType.TYPE_PRODA_PM) {
        filter.type = { condition: '=', value: ExportType.TYPE_PRODA_PM }
      } else if (filterParam === ExportType.TYPE_PRODA_STD) {
        filter.type = { condition: '=', value: ExportType.TYPE_PRODA_STD }
      } else if (filterParam === ExportType.TYPE_PAYMENT) {
        filter.type = { condition: '=', value: ExportType.TYPE_PAYMENT }
      }

      const r = await invoiceExportService.listExportByPage(currentPage, pageSize, filter, sort, searchText)

      this.setState({
        loading: false,
        loadingNext: false,
        listProdaPM: filterParam === ExportType.TYPE_PRODA_PM && validator.isArray(r.list) ? r : this.state.listProdaPM,
        listProdaSTD: filterParam === ExportType.TYPE_PRODA_STD && validator.isArray(r.list) ? r : this.state.listProdaSTD,
        listABA: filterParam === ExportType.TYPE_PAYMENT && validator.isArray(r.list) ? r : this.state.listABA,
        invoices: validator.isArray(r.list) ? r : { list: [], total: 0 },
        searching: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
    }
  }

  fetchExportDetails = async () => {
    if (!this.hasAccess(Permissions.INVOICE.MGMT.LIST)) return

    try {
      const { exportId, exportType, filterParam } = this.state

      if (exportType === ExportType.TYPE_ABA) {
        this.setState({ isShowAbaModal: true, loadingSelect: true })
      } else if (exportType === ExportType.TYPE_RMT) {
        this.setState({ isShowRmtModal: true, loadingSelect: true })
      } else if (exportType === ExportType.TYPE_PRODA_PM || exportType === ExportType.TYPE_PRODA_STD) {
        this.setState({ isShowProdaModal: true, loadingSelect: true })
      }

      const r = await invoiceExportService.getExportDetail(exportId, filterParam)

      if (r && r.id) {
        this.triggerSelectModal(true, r, exportType)
      }
    } catch (e) {
      this.setState({
        isShowAbaModal: false,
        isShowProdaModal: false,
        isShowRmtModal: false,
        loadingSelect: false
      })
    }
  }

  filterInvoice = (e) => {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    const tab = TypeList.find(e => e.type === filterValue)
    if (tab && tab.type) {
      this.props.history.replace(`/invoices-export${tab.path}`)
    }

    this.setState({ filterParam: filterValue, selectIds: [], selectData: [], invoices: { list: [], total: 0 } })
    this.fetchInvoices({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  triggerSelectModal = async (isModal = false, item = {}, type) => {
    const { filterParam } = this.state
    const { setFieldsValue } = this.props.form
    let selectData = []
    let selectIds = []

    if (!this.hasAccess(Permissions.INVOICE.MGMT.LIST)) return

    if (isModal === true) {
      if (filterParam === ExportType.TYPE_PAYMENT) {
        for (let i = 0; i < item.invoices.length; i++) {
          const itm = item.invoices[i]
          if (validator.isNotEmptyArray(itm.inv_items)) {
            for (let j = 0; j < itm.inv_items.length; j++) {
              const invItem = itm.inv_items[j]
              let historyData = []

              if (validator.isNotEmptyArray(invItem.receive_history)) {
                for (let k = 0; k < invItem.receive_history.length; k++) {
                  const invItemHis = invItem.receive_history[k]

                  const hisData = {
                    amount: invItemHis.amount,
                    received_date: invItemHis.received_date,
                    inv_item_history_id: invItemHis.id,
                    is_credit_applied: invItemHis.is_credit_applied
                  }

                  historyData.push(hisData)
                  selectIds.push(invItemHis.id)
                }
              }

              const data = {
                client_id: itm.client_id,
                client_name: itm.client_name,
                invoice_date: itm.invoice_date,
                invoice_number: itm.invoice_number,
                invoice_provider_name: itm.invoice_provider_name,
                provider_id: itm.provider_id,
                provider_name: itm.provider_name,
                inv_item_id: invItem.id,
                cat_item_name: invItem.cat_item_name,
                cat_name: invItem.cat_name,
                inv_date: invItem.inv_date,
                rcv_history: historyData
              }

              selectData.push(data)
            }
          }
        }

        this.setState({
          itemsSelect: { ...item, type },
          itemsModal: selectData,
          itemsIdsSelect: selectIds,
          itemsIdsSelectOri: _.cloneDeep(selectIds),
          itemsIdsSelectAll: true,
          isShowAbaModal: type === ExportType.TYPE_ABA ? true : false,
          isShowRmtModal: type === ExportType.TYPE_RMT ? true : false,
          loadingSelect: false
        })

        setFieldsValue({payment_date: formatter.toMoment(item.payment_date)})
      } else if (filterParam === ExportType.TYPE_PRODA_PM || filterParam === ExportType.TYPE_PRODA_STD) {
        for (let i = 0; i < item.invoices.length; i++) {
          const itm = item.invoices[i]

          const data = {
            client_id: itm.client_id,
            client_name: itm.client_name,
            id: itm.id,
            invoice_date: itm.invoice_date,
            invoice_number: itm.invoice_number,
            invoice_provider_name: itm.invoice_provider_name,
            provider_id: itm.provider_id,
            provider_name: itm.provider_name
          }

          selectIds.push(itm.id)
          selectData.push(data)
        }

        this.setState({
          itemsSelect: { ...item, type },
          itemsModal: selectData,
          itemsIdsSelect: selectIds,
          itemsIdsSelectOri: _.cloneDeep(selectIds),
          itemsIdsSelectAll: true,
          isShowProdaModal: true,
          loadingSelect: false
        })
      }
    } else {
      this.setState({
        itemsSelect: {},
        itemsModal: [],
        itemsIdsSelect: [],
        itemsIdsSelectOri: [],
        itemsIdsSelectAll: false,
        isShowAbaModal: false,
        isShowRmtModal: false,
        isShowProdaModal: false,
        loadingSelect: false
      })
      setFieldsValue({payment_date: null})
    }
  }

  updateModalIdsSelect = (e, id) => {
    const check = e.target.checked
    let { itemsIdsSelect, itemsIdsSelectOri } = this.state
    const r = itemsIdsSelect.findIndex(f => f === id)
    if (check) {
      if (!(r > -1)) {
        itemsIdsSelect.push(id)
      }
    } else {
      if (r > -1) {
        itemsIdsSelect.splice(r, 1)
      }
    }

    this.setState({
      itemsIdsSelect,
      itemsIdsSelectErrMsg: validator.isNotEmptyArray(itemsIdsSelect) ? '' : ItemsSelectErrMsg,
      itemsIdsSelectAll: itemsIdsSelect.length < itemsIdsSelectOri.length ? false : true
    })
  }

  updateModalIdsSelectAll = (e) => {
    const check = e.target.checked
    let { itemsIdsSelect, itemsIdsSelectOri } = this.state

    let newIdsSelect = []

    if (check) {
      newIdsSelect = _.cloneDeep(itemsIdsSelectOri)
    }
    this.setState({ itemsIdsSelect: newIdsSelect, itemsIdsSelectAll: check, itemsIdsSelectErrMsg: '' })
  }

  preGetFile = (isSendEmail, isDownload) => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { getFile2 } = this
    const { itemsIdsSelect = [] } = this.state

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (validator.isNotEmptyArray(itemsIdsSelect)) {
          confirm({
            title: title,
            content: 'Press Ok to continue, Cancel to return',
            onOk () {
              getFile2(isSendEmail, isDownload, values)
            }
          })
        } else {
          this.setState({ itemsIdsSelectErrMsg: ItemsSelectErrMsg })
        }
      }
    })
  }

  getFile2 = async (isSendEmail = false, isDownload = true, values = {}) => {
    const { itemsSelect, itemsIdsSelect = [], invoices } = this.state
    const { id, type, created_at: expCreatedAt } = itemsSelect
    const { list } = invoices
    // console.log('get file select 21', itemsIdsSelect)
    const itmIndex = list.findIndex(e => e.id === id)
    const loadingIndex = list.findIndex(e => e.is_loading === true)
    if (loadingIndex > -1) return

    if (itmIndex > -1) {
      list[itmIndex].is_loading = true
      if (type === ExportType.TYPE_PRODA ||type === ExportType.TYPE_PRODA_PM || type === ExportType.TYPE_PRODA_STD) {
        list[itmIndex].is_loading_proda = true
      } else if (type === ExportType.TYPE_ABA) {
        list[itmIndex].is_loading_aba = true
      } else if (type === ExportType.TYPE_RMT) {
        list[itmIndex].is_loading_rmt = true
      } else if (type === ExportType.TYPE_PAYMENT) {
        list[itmIndex].is_loading_aba = true
        list[itmIndex].is_loading_rmt = true
      }
      this.setState({ invoices, loadingSelect: true })
      const r = await exportFile.fetchFiles(id, type, expCreatedAt, itemsIdsSelect, isSendEmail, isDownload, values)

      if (r && r.id && isSendEmail) {
        notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
      }

      // console.log('get file select 22', r, expCreatedAt)

      setTimeout(() => {
        list[itmIndex].is_loading = false
        list[itmIndex].is_loading_proda = false
        list[itmIndex].is_loading_aba = false
        list[itmIndex].is_loading_rmt = false
        this.setState({ invoices, loadingSelect: false })
        this.triggerSelectModal(false)

        if (isDownload && isSendEmail) {
          notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
        }
      }, 3000)
    }
  }

  getFile = async (id, type, expCreatedAt, selectIds = []) => {
    const { invoices } = this.state
    const { list } = invoices

    const itmIndex = list.findIndex(e => e.id === id)
    const loadingIndex = list.findIndex(e => e.is_loading === true)
    if (loadingIndex > -1) return

    if (itmIndex > -1) {
      list[itmIndex].is_loading = true
      if (type === ExportType.TYPE_PRODA ||type === ExportType.TYPE_PRODA_PM || type === ExportType.TYPE_PRODA_STD) {
        list[itmIndex].is_loading_proda = true
      } else if (type === ExportType.TYPE_ABA) {
        list[itmIndex].is_loading_aba = true
      } else if (type === ExportType.TYPE_RMT) {
        list[itmIndex].is_loading_rmt = true
      } else if (type === ExportType.TYPE_PAYMENT) {
        list[itmIndex].is_loading_aba = true
        list[itmIndex].is_loading_rmt = true
      }
      this.setState({ invoices, loadingSelect: true })

      await exportFile.fetchFiles(id, type, expCreatedAt, selectIds)

      setTimeout(() => {
        list[itmIndex].is_loading = false
        list[itmIndex].is_loading_proda = false
        list[itmIndex].is_loading_aba = false
        list[itmIndex].is_loading_rmt = false
        this.setState({ invoices, loadingSelect: false })
        this.triggerSelectModal(false)
      }, 3000)
    }
  }

  /** update payment date */
  validatePaymentDate = (rule, value, callback) => {
    const { itemsSelect } = this.state
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Payment Date is required`))
    } else {
      if (itemsSelect && itemsSelect.id) {
        const m = moment(itemsSelect.created_at).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Payment Date cannot be earlier than file created day.`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
  }

  preUpdatePaymentDate = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { handleUpdatePaymentDate } = this
    const { itemsSelect } = this.state

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors && itemsSelect && itemsSelect.id) {
        confirm({
          title: 'Confirm to update payment date?',
          content: 'Press Ok to continue, Cancel to return.',
          onOk () {
            handleUpdatePaymentDate(values)
          }
        })
      }
    })
  }

  handleUpdatePaymentDate = async (values) => {
    const { itemsSelect, currentPage, filter, filterParam, searchText, sort  } = this.state
    this.setState({ loadingSelect: true })

    const r = await invoiceExportService.updateExport(itemsSelect.id, itemsSelect.type, values)

    if (r && r.id) {
      notify.success('Payment Date updated successfully', 'Payment Date is updated successfully.')
      this.fetchInvoices({ currentPage, filter, filterParam, searchText, sort })
    } else {
      notify.error('Unable to update Payment Date', 'Unable to generate Payment Date successfully. Please try again later.')
    }

    this.setState({ loadingSelect: false })
  }
}

const mapDispatchToProps = {
  fetchInvoices
}

const mapStateToProps = (state) => {
  return { ...state.Invoice }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoiceExport))
