import Permissions from '../permissions'

export default [
  {
    name: 'Link Requests',
    desc: 'Manage all link requests',
    icon: 'team',
    link: '/link-requests',
    enable: true,
    permission: Permissions.PARTICIPANT.INFO.LIST
  }
]
