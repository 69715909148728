export const FETCH_FUNDING_CATEGORIES = 'FETCH_FUNDING_CATEGORIES'
export const FETCHING_FUNDING_CATEGORIES = 'FETCHING_FUNDING_CATEGORIES'
export const FUNDING_CATEGORIES_FETCHED = 'FUNDING_CATEGORIES_FETCHED'

export const fetchFundingCategories = (params) => {
  return Object.assign({
    type: FETCH_FUNDING_CATEGORIES
  }, params)
}

export const fetchingFundingCategories = (loading) => {
  return {
    type: FETCHING_FUNDING_CATEGORIES,
    loading
  }
}

export const fundingCategoriesFetched = (funders) => {
  return {
    type: FUNDING_CATEGORIES_FETCHED,
    funders
  }
}
