import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_FUNDING_CATEGORIES, FETCHING_FUNDING_CATEGORIES, FUNDING_CATEGORIES_FETCHED } from '../actions/funding'
import fundingCatService from '../../services/fundingCategory'

function * fetchFundingCategories ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_FUNDING_CATEGORIES, loading })
    const fundingCategories = yield fundingCatService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: FUNDING_CATEGORIES_FETCHED, fundingCategories, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_FUNDING_CATEGORIES, loading: false })
    console.error(e)
  }
}

function * watchFunder () {
  yield takeEvery(FETCH_FUNDING_CATEGORIES, fetchFundingCategories)
}

export default watchFunder
