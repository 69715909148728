import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Button from '../Button'
import Spin from '../Spin'
import Icon from 'antd/lib/icon'
import Upload from 'antd/lib/upload'

import './styles.css'

export default class FileUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { className, file, loading = false, readOnly = false, showError = false, errorMessage = null, upload } = this.props
    upload.className = formatter.toClassName(['wd-fileupload-uploader', upload.className, readOnly ? 'disabled' : '', showError ? 'error' : ''])
    upload.disabled = readOnly || upload.disabled

    return (
      <div>
        <div className={formatter.toClassName(['wd-fileupload', className])}>
          <Upload {...upload}>
            <Spin loading={loading} blur>
              <div>
                {loading ? null : (
                  <div className='wd-fileupload-trigger'>
                    <Button className='wd-fileupload-btn' ghost>Click to {file ? 'Change' : 'Upload'}</Button>

                    {file ? <Button className='wd-fileupload-btn' ghost onClick={this.handleDownload(file)}>Click to download</Button> : null}
                  </div>
                )}

                {file ? (
                  <Icon className='wd-fileupload-icon' type='file-done' />
                ) : (
                  <Icon className='wd-fileupload-icon' type='file-add' />
                )}
              </div>
            </Spin>
          </Upload>
        </div>
        { errorMessage ? <div className='wd-fileupload-error-msg '>{errorMessage}</div> : null }
      </div>
    )
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }
}

FileUpload.propTypes = {
  className: PropTypes.string,
  img: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  upload: PropTypes.object.isRequired
}
