import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { cloneDeep, debounce, isEqual } from 'lodash'

import {
  clientInvoiceService,
  clientService,
  commService,
  commonService,
  creditClientService,
  fileService,
  invoiceService,
  providerService,
  settingFileService,
  settingGeneralService,
  settingReasonService
} from '../../../services'
import { CommType, Permissions } from '../../../constants'
import { auth, exportFile, formatter, log, validator } from '../../../util'
import { fetchingInvoices } from '../../../states/actions/invoice'

// UI
import { Button, CustomIdentifierList, Checkbox, DateTimePicker, List, Loading, Page, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'
import InvoiceActivity from '../ActivityLog'
import InvoiceFile from '../File'
import InvoiceComm from '../Comm'
import AddClientModal from '../AddClientModal'
import AddProviderModal from '../AddProviderModal'
import AddFileModal from '../AddFileModal'

import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Progress from 'antd/lib/progress'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

const { Item: FormItem } = Form
const { TextArea } = Input
const { info, confirm, warning, error } = Modal
const Option = Select.Option
const TabPane = Tabs.TabPane

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 5, path: '/comm' },
  { tabId: 4, path: '/custom-identifier' },
  { tabId: 3, path: '/files' },
  { tabId: 2, path: '/logs' }
]

const dateFormat = 'DD/MM/YYYY'
const dateFormat2 = 'YYYY-MM-DD'
const dateFormat3 = 'DD/MM/YYYY hh:mm:ss A'

const formItemLayout = {
  labelCol: { sm: 9, md: 9, lg: 9 },
  wrapperCol: { sm: 10, md: 10, lg: 10 }
}
const shortFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const DefaultInvoiceItem = {
  inv_date: undefined,
  cat_id: undefined,
  cat_item_id: undefined,
  cat_item_identifier: '',
  unit: undefined,
  inv_rate: undefined,
  max_rate: undefined,
  budget_id: '',
  cats: [],
  catItems: [],
  subtotal: null,
  received_subtotal: null,
  comment: undefined,
  is_amt_over_budget: undefined
}

const SelectClientMsg = 'Please select Participant.'
const SelectBillerMsg = 'Please select Provider.'
const MaxRateMismatchMsg = 'The invoiced rate is higher than maximum rate assigned.'
const OverBudgetMsg = 'The invoiced amount has exceed budget.'
const SBMismatchMsg = 'The service start date and end date do not fall within a same service booking.'
const InvDuplicatedMsg = 'Duplicate record found.'
const TotalAmountMismatchMsg = 'Total Invoiced Amount and Expected Invoiced Amount are mismatched.'
const RcvAmountEarlyCloseMsg = 'The received amount and amount to pay are not matched. Confirm with your received amount again.'
const RcvAmountOverPayMsg = 'Current Receive Amount has exceeded To Receive Amount'
const ItemsSelectErrMsg = 'No item is selected.'
const SBOverDaysMsg = 'This Service Booking has ended.'
const SBOver0daysMsg = 'The Service Booking for selected date has ended.'
const SBOver0daysMultiMsg = 'One of the Service Booking for selected date has ended.'
const SBOver60daysMsg = 'The Service Booking for selected date has passed 60 days of its end date.'
const SBOver60daysMultiMsg = 'One of the Service Booking for selected date has passed 60 days of its end date.'
const SBOver90daysMsg = 'The Service Booking for selected date has passed 90 days of its end date. The service date is invalid.'
const SBOver90daysMultiMsg = 'One of the Service Booking for selected date has passed 90 days of its end date. The service date is invalid.'

const AUTH_PAY_REMINDER_NOT_REQUIRED = 0
const AUTH_PAY_REMINDER_REQUIRED = 1
const AUTH_PAY_REMINDER_ALERT = 2
const AUTH_PAY_NO_EMAIL_CONFIGURED = 3
const AUTH_PAY_UPDATED_DETAILS = 4

const INV_AUTH_ACTION_SENDNOW = 'sendnow'
const INV_AUTH_ACTION_LATER = 'later'

export class InvoicePage extends Component {
  constructor (props) {
    super(props)
    const { match, location } = this.props
    const { key = undefined } = location
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      itemOri: { is_sdb_invoice: false, subtotal: 0, received_subtotal: 0 },
      item: { is_sdb_invoice: false, subtotal: 0, received_subtotal: 0 },
      invAuthModalInfo: {},
      invoiceItems: [],
      invoiceItemsOri: [],
      invoiceHistory: [],
      invoiceReceivingHistory: [],
      invSubtotalMsg: '',
      isAllowABNExempted: false,
      isEnableEmailSend: false,
      isInvNoDuplicated: false,
      commCount: 0,
      commSentCount: 0,
      clientBudget: [],
      clientCurrentBudget: {},
      clientCurrentBudgetList: [],
      clientList: [],
      clientInfo: {},
      clientMsg: '',
      clientCredit: [],
      clientCurrentCredit: [],
      clientSelectedCredit: {},
      clientCreditMaxAmt: 0.0,
      clientCurrentCreditInfo: {},
      clientCreditEditMaxAmt: 0.0,
      categoriesList: [],
      subCategoriesList: [],
      fileList: [],
      fileInfo: {},
      invoiceReasonList: [],
      providerList: [],
      providerInfo: {},
      providerMsg: '',
      rcvModalInfo: {},
      rcvModalMsg: '',
      creditModalInfo: {},
      creditModalMsg: '',
      statusList: [],
      loading: false,
      loadingCheckInvoiceNo: false,
      loadingClient: false,
      loadingCatItems: false,
      loadingRctDetail: false,
      loadingCreditDetail: false,
      loadingUpdateRct: false,
      loadingUpdateCredit: false,
      loadingUpdateComm: false,
      loadingSelectAbaRmt: false,
      shouldActive: false,
      showSave: false,
      showEdit: true,
      showInvAuthModal: false,
      showInvDeleteModal: false,
      showPrivateAlert: false,
      showClientModal: false,
      showProviderModal: false,
      showAddFileModal: false,
      showRcvAmtModal: false,
      showCreditAmtModal: false,
      showCreditEditModal: false,
      showAbaRmtModal: false,
      abaRmtModalType: '',
      abaRmtSelectList: [],
      abaRmtSelectAll: false,
      abaRmtSelectErrMsg: '',
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      pageKey: key,
    }

    this.debounceValidateInvoiceNumber = debounce(this.debounceValidateInvoiceNumber, 1000)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match

    // must check whether prev state key and locaction key are both undefined, and skip if it is
    if (prevState.pageKey === undefined && location.key === undefined) {

    } else if (prevState.pageKey !== location.key) {
      // not only check the page key but also need to check current path params. if within the same url path (navigate between tabs), do not reload the page.
      if (validator.isNumberText(params.id) && params.type !== undefined) {

      } else {
        if (window) window.location.reload()
      }
    }

    return { ...prevState, pageKey: location.key }
  }

  componentDidMount () {
    this.fetchInvStatusList()
    if (this.isEdit()) {
      this.fetchInvoice()
    } else {
      this.fetchDataList()
    }
  }

  render () {
    const { history, match } = this.props
    const {
      commCount,
      commSentCount,
      currentTab,
      clientInfo,
      clientList,
      providerInfo,
      providerList,
      item,
      loading,
      loadingClient,
      showSave,
      showEdit,
      showClientModal,
      showProviderModal,
      statusList
    } = this.state
    const invoiceId = this.getId()
    const isInfoTab = currentTab === '1'
    const isItem = item && item.id

    const isExportAvailable = item && item.id && statusList.find(e => {
      if (e.reference >= '008') {
        return item.status === e.value
      }

      return false
    })

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={
            this.isEdit()
            ? `Invoice${item.invoice_number ? ` - ${item.invoice_number}` : ''}`
            : `New Invoice`
          }>
            {/**TODO: prod handling */}
            { this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.UPDATE) && item && item.id && item.is_enable_send_inv_auth_comm === true && !loading && isInfoTab
              ? (
                <Button className='btn' key='comm' ghost type='primary' feedback={loading} onClick={() => this.showAuthorisedAlert(clientInfo, item.id)}> {'Send Inv Authorisation Email'}</Button>
              )
              : null }

            { this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.DELETE) && showSave && item && item.id && item.is_allow_edit && !loading && isInfoTab
              ? (
                <Button className='btn' key='delete' ghost type='primary' feedback={loading} onClick={() => this.handleDeleteModal(true)}> {'Delete'}</Button>
              )
              : null }

            { this.isEdit() && this.hasAccess(Permissions.INVOICE.MGMT.LIST) && !loading && isExportAvailable && validator.isNotEmptyArray(item.rcv_history_list) && isInfoTab
              ? (
                <Button className='btn' key='aba' ghost type='primary' feedback={loading} onClick={() => this.handleAbaRmtModal(true, 'aba')}> {'Get ABA'}</Button>
              )
              : null
            }

            { this.isEdit() && this.hasAccess(Permissions.INVOICE.MGMT.LIST) && !loading && isExportAvailable && validator.isNotEmptyArray(item.rcv_history_list) && isInfoTab
              ? (
                <Button className='btn' key='rmt' ghost type='primary' feedback={loading} onClick={() => this.handleAbaRmtModal(true, 'rmt')}> {'Get Remittance'}</Button>
              )
              : null
            }

            { showEdit && this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.UPDATE) && !loading && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                Edit
                </div>)
              : null }

            { ((!this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.CREATE)) ||
            (showSave && this.isEdit() && isItem)) && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
            && !loading && isInfoTab
              ? (
                <Button className='btn' key='ok' type='primary' feedback={loading} onClick={this.handleSave}> {'Save'}</Button>
                )
              : null }
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='invoice-panel'>
            <div className='invoice-panel-body'>
              <Skeleton loading={loading} active>
                <Tabs
                  defaultActiveKey={currentTab}
                  onChange={this.handleTabChange}
                >
                  <TabPane tab='Invoice Details' key='1'>
                    { this.infoTab() }
                  </TabPane>
                  { this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.READ)
                    ? <TabPane tab='Custom Identifier' key='4'>
                      <CustomIdentifierList key={`iidftab${currentTab}`} genreId={invoiceId} genre={'invoice'} history={this.props.history} permission={Permissions.INVOICE.INFO.UPDATE} />
                    </TabPane>
                    : null }
                   {/**TODO: prod handling */}
                  { this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.LIST)
                    ? <TabPane tab={<div>Communication <Badge count={commCount} /></div>} key='5'>
                      <InvoiceComm key={`icmrtab${currentTab}`} invoiceId={invoiceId} genre={'invoice'} history={this.props.history} permission={Permissions.INVOICE.INFO.UPDATE} />
                    </TabPane>
                    : null }
                  { this.isEdit() && this.hasAccess(Permissions.INVOICE.FILES.LIST)
                    ? <TabPane tab='Files' key='3'>
                      <InvoiceFile key={`fstab${currentTab}`} invoiceId={invoiceId} invoiceInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                  { this.isEdit()
                    ? <TabPane tab='Activity Log' key='2'>
                      <InvoiceActivity key={`ictab${currentTab}`} invoiceId={invoiceId} history={this.props.history} />
                    </TabPane>
                    : null }
                </Tabs>
              </Skeleton>
            </div>
          </div>

          <AddClientModal
            clientId={clientInfo ? clientInfo.id : ''}
            clientList={clientList}
            visible={showClientModal}
            onClose={() => this.startSelectClientModal(false)}
            onUpdate={(clientId) => this.handleChangeClient(clientId)}
          />
          <AddProviderModal
            providerId={providerInfo ? providerInfo.id : ''}
            providerList={providerList}
            visible={showProviderModal}
            onClose={() => this.startSelectProviderModal(false)}
            onUpdate={(provider) => this.handleProviderChange(provider)}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      clientInfo,
      clientMsg,
      clientCurrentBudget,
      clientCurrentBudgetList,
      clientCurrentCredit,
      clientSelectedCredit,
      clientCurrentCreditInfo,
      clientCreditMaxAmt,
      clientCreditEditMaxAmt,
      categoriesList,
      subCategoriesList,
      fileList,
      fileInfo,
      invoiceItems,
      invoiceHistory,
      invoiceReceivingHistory,
      invoiceReasonList,
      invSubtotalMsg,
      isAllowABNExempted,
      isEnableEmailSend,
      providerInfo,
      providerMsg,
      rcvModalInfo,
      rcvModalMsg,
      rcvModalOverpayMsg,
      creditModalInfo,
      creditModalMsg,
      loading,
      loadingCheckInvoiceNo,
      loadingClient,
      loadingCatItems,
      loadingRctDetail,
      loadingCreditDetail,
      loadingUpdateComm,
      loadingUpdateRct,
      loadingUpdateCredit,
      loadingSelectAbaRmt,
      item,
      invAuthModalInfo,
      showInvAuthModal,
      showInvDeleteModal,
      showPrivateAlert,
      showAddFileModal,
      showRcvAmtModal,
      showCreditAmtModal,
      showCreditEditModal,
      showAbaRmtModal,
      abaRmtModalType,
      abaRmtSelectList,
      abaRmtSelectAll,
      abaRmtSelectErrMsg,
      statusList
    } = this.state
    const isEdit = this.isEdit()

    const invAuthComm = item.inv_auth_comm_info || null
    const invoiceDate = isEdit ? !!item.invoice_date : getFieldValue('invoice_date')
    const invoiceNumber = isEdit ? !!item.invoice_number : getFieldValue('invoice_number')
    const isInvoiceDateSelected = invoiceDate && clientInfo && clientInfo.id
    const statusItem = statusList.find(e => e.value === item.status)
    // console.log('status item', statusList, item, statusItem)
    const statusStyle = statusItem ? { backgroundColor: `${statusItem.color}` } : {}

    const isItemDisabled = statusList.find(e => {
      if (e.reference >= '004') {
        return item.status === e.value
      }

      return false
    })

    const isClosed = statusList.find(e => {
      if (e.reference > '008') {
        return item.status === e.value
      }

      return false
    })

    const isAbleApplyCredit = statusList.find(e => {
      if (e.reference >= '001' && e.reference < '009') {
        return item.status === e.value
      }

      return false
    })

    const isToReceiveAmount = statusList.find(e => {
      if (e.reference >= '006') {
        return item.status === e.value
      }

      return false
    })

    const isToPay = statusList.find(e => {
      if (e.reference === '007' || e.reference === '008') {
        return item.status === e.value
      }

      return false
    })

    const rcvColumns = [
      {
        title: 'Received On',
        width: 5,
        render: ({ received_date }) => formatter.toShortDate(received_date)
      },
      {
        title: 'Amount',
        width: 5,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },
      {
        title: 'Rcv Notes',
        width: 6,
        render: ({ comment }) => <div dangerouslySetInnerHTML={{
          __html: `<div>${comment ? comment.replace('\n', '<br />') : ''}</div>`
        }} />
      },
      {
        title: 'Updated At',
        width: 8,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },
    ]

    const creditColumns = [
      {
        title: 'Received On',
        width: 5,
        render: ({ apply_date }) => formatter.toShortDate(apply_date)
      },
      {
        title: 'Apply Amount',
        width: 5,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },
      {
        title: 'Credit Apply Notes',
        width: 6,
        render: ({ comment }) => <div>{comment}</div>
      },
      {
        title: 'Updated At',
        width: 7,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      },
      {
        title: '',
        width: 1,
        render: (item) => isAbleApplyCredit
          ? <div style={{cursor: 'pointer'}} onClick={() => this.handleCreditAmtEditModal(true, item)}>
            <Tooltip mouseLeaveDelay={0} title='Edit Applied Credit'>
              <Icon type='form' />
            </Tooltip>
          </div>
          : null
      },
    ]

    const abaRmtColumns = [
      {
        title: 'Svc Date',
        width: 3,
        render: ({ inv_date }) => formatter.toShortDate(inv_date)
      },
      {
        title: 'Item',
        width: 9,
        render: ({ cat_name, cat_item_name }) => <div>{cat_name} - {cat_item_name}</div>
      },
      {
        title: 'Payment Date',
        width: 3,
        render: ({ payment_date }) => formatter.toShortDate(payment_date)
      },
      {
        title: 'Received Payment',
        width: 8,
        render: ({ amount, received_date, is_credit_applied }) => <div>{is_credit_applied ? `Credited` : `Received`} {formatter.toPrice(amount)} on {formatter.toShortDate(received_date)}</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, export_id }) => {
          const isChecked = abaRmtSelectList.find(f => f.id === id) || false
          return (
            <Checkbox
              checked={isChecked}
              onClick={(e) => this.updateAbaRmtModalIdsSelect(e, id, export_id)}
            />
          )
        }
      },
    ]

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_cat_id }) => {
          const mainCat = categoriesList.find(e => e.id === main_cat_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_cat_id }) => {
          const subCat = subCategoriesList.find(e => e.cat_sub_id === sub_cat_id)

          return <div>{subCat ? subCat.cat_sub_name : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, fileName }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{fileName ? `[${formatter.toStandardFileName(fileName)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Enabled',
        width: 1,
        render: ({ active }) => <div style={{ color: active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit Details'>
            <div onClick={() => this.handleAddFileModal(true, item)} style={{ marginRight: 15 }}>
              <Icon type='form' />
            </div>
          </Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete File'>
            <Popconfirm
              title={`Are you sure you want to delete ${item.label}?`}
              onConfirm={() => this.handleDeleteFile(item)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    let lastCreditBudgetId = ''
    const isRcvAmtClosed = rcvModalInfo.is_closed || parseFloat(rcvModalInfo.to_pay_subtotal) <= 0
    const isCreditClosed = creditModalInfo.is_closed || parseFloat(creditModalInfo.to_pay_subtotal) <= 0
    const isSBOverPeriod = clientCurrentBudget && clientCurrentBudget.budget_id ? formatter.toPeriodStatus(clientCurrentBudget.period_end_date).isDue : false

    return (
      <Form>
        <Loading loading={loading} blur>

          {/** Show Inv Auth Status */}
          { item && item.id && invAuthComm && invAuthComm.id
            ? <div style={{marginTop: '5px'}}>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">${!invAuthComm.is_processed_or_sent ? (invAuthComm.scheduled_at ? 'Invoice Authorisation is scheduled.' : 'Invoice Authorisation is yet to schedule.') : invAuthComm.is_failed ? 'Invoice Authorisation is unable to send.' : 'Invoice Authorisation is sent successfully.'}</span><br />${!invAuthComm.is_processed_or_sent ? (invAuthComm.scheduled_at ? `The mail is scheduled to be sent at ${formatter.toDate(invAuthComm.scheduled_at, dateFormat3)}.` : `Please go to Communication tab to schedule the email sending.`) : invAuthComm.is_failed ? `The mail is unable to be sent. Reason: ${invAuthComm.failed_reason}` : `The mail is sent successfully at ${formatter.toDate(invAuthComm.processed_or_sent_at, dateFormat3)}.`}`
                }} />
              } type={!invAuthComm.is_processed_or_sent ? 'warning' : invAuthComm.is_failed ? 'error' : 'success'} showIcon /><br />
            </div>
            : null }

          {/** header participant and biller row */}
          <Row gutter={16}>
            <Col lg={12}>
              <Panel type='custom' className='section-panel'>
                { loading
                  ? <Skeleton paragraph={{ rows: 1 }} />
                  : !isEdit
                  ? <Col>
                    { providerInfo && providerInfo.id
                      ? <div>
                        <div className='flex-space-between'>
                          <div>
                            <span className='client-name'><a href={`/providers/${providerInfo.ref_id}/info`} target='_blank'>{ providerInfo.fullname }</a></span>
                            { providerInfo.abn ? <span className='client-accref'>{ formatter.formatABN(providerInfo.abn) }</span> : null }
                          </div>
                          <span
                            style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => this.startSelectProviderModal(true)}><Icon type='edit' style={{color: '#333', fontSize: '18px'}} /></span>
                        </div>
                        <div className='flex-left detail-row'>
                          <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                          { providerInfo.unit_building ? <div>{providerInfo.unit_building}</div> : null }
                          { providerInfo.address ? <div>{providerInfo.address}</div> : null }
                        </div>
                        { providerInfo.email
                          ? <div className='flex-left detail-row'>
                            <Icon type='mail' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                            <div>{providerInfo.email}</div>
                          </div>
                          : null }
                      </div>
                      : <div>
                        <div className='flex-space-between'>
                          <div className={'client-name-alert'}>Click on Edit button to select provider</div>
                            <span
                            style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => this.startSelectProviderModal(true)}><Icon type='edit' style={{color: 'red', fontSize: '18px'}} /></span>
                        </div>
                      </div> }
                    </Col>
                  : <Col>
                    { providerInfo && providerInfo.id
                      ? <div>
                        <div className='flex-space-between'>
                          <div>
                            <span className='client-name'><a href={`/providers/${providerInfo.ref_id}/info`} target='_blank'>{ providerInfo.fullname }</a></span>
                            { providerInfo.abn ? <span className='client-accref'>{ formatter.formatABN(providerInfo.abn) }</span> : null }
                          </div>
                        </div>
                        <div className='flex-left detail-row'>
                          <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                          { providerInfo.unit_building ? <div>{providerInfo.unit_building}</div> : null }
                          { providerInfo.address ? <div>{providerInfo.address}</div> : null }
                        </div>
                        { providerInfo.email
                          ? <div className='flex-left detail-row'>
                            <Icon type='mail' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                            <div>{providerInfo.email}</div>
                          </div>
                          : null }
                      </div>
                      : <div className={'client-name-alert '}>
                          No provider info available.
                      </div> }
                    </Col> }
              </Panel>
              { providerMsg
                ? <div className='client-name-message'>{providerMsg}</div>
                : null }
            </Col>
            <Col lg={12}>
              <Panel type='custom' className='section-panel'>
                { loading || loadingClient
                  ? <Skeleton paragraph={{ rows: 1 }} />
                  : !isEdit
                    ? <Col>
                      { clientInfo && clientInfo.id
                        ? <div>
                          <div className='flex-space-between'>
                            <div>
                              <span className='client-name'><a href={`/participants/${clientInfo.ref_id}/info`} target='_blank'>{ clientInfo.first_name } { clientInfo.last_name }</a></span>
                              <span className='client-accref'>{ clientInfo.ndis_number }</span>
                            </div>
                            <span
                              style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                              onClick={() => this.startSelectClientModal(true)}><Icon type='edit' style={{color: '#333', fontSize: '18px'}} /></span>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                            { clientInfo.unit_building ? <div>{clientInfo.unit_building}</div> : null }
                            { clientInfo.address ? <div>{clientInfo.address}</div> : null }
                          </div>
                          { clientInfo.pm_is_auth_req === false
                            ? <div className='flex-left detail-row'>
                              <Icon type='dollar' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                              <div>{`Invoice Authorisation Amount: ${clientInfo.pm_auth_amount ? `${formatter.toPrice(clientInfo.pm_auth_amount)}` : 'No Limit'}`}</div>
                            </div>
                            : null }
                        </div>
                        : <div>
                          <div className='flex-space-between'>
                            <div className={'client-name-alert'}>Click on Edit button to select participant</div>
                            <span
                            style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => this.startSelectClientModal(true)}><Icon type='edit' style={{color: 'red', fontSize: '18px'}} /></span>
                          </div>

                        </div> }
                    </Col>
                    : <Col>
                      { clientInfo && clientInfo.id
                        ? <div>
                          <div className='flex-space-between'>
                            <div>
                              <span className='client-name'><a href={`/participants/${clientInfo.ref_id}/info`} target='_blank'>{ clientInfo.first_name } { clientInfo.last_name }</a></span>
                              <span className='client-accref'>{ clientInfo.ndis_number }</span>
                            </div>
                          </div>
                          <div className='flex-left detail-row'>
                            <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                            { clientInfo.unit_building ? <div>{clientInfo.unit_building}</div> : null }
                            { clientInfo.address ? <div>{clientInfo.address}</div> : null }
                          </div>
                          { clientInfo.pm_is_auth_req === false && clientInfo.pm_auth_amount > 0
                            ? <div className='flex-left detail-row'>
                              <Icon type='dollar' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '5px'}} />
                              <div>{`Invoice Authorisation Amount: ${clientInfo.pm_auth_amount ? `${formatter.toPrice(clientInfo.pm_auth_amount)}` : 'No Limit'}`}</div>
                            </div>
                            : null }
                        </div>
                        : <div className={'client-name-alert '}>
                          No participant info available.
                        </div> }
                    </Col> }
              </Panel>
              { clientMsg
                  ? <div className='client-name-message'>{clientMsg}</div>
                  : null }
            </Col>
          </Row>

          {/** Show Private Alert */}
          { showPrivateAlert && clientInfo.private_alert
            ? <div style={{marginTop: '20px'}}>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">Client: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientInfo.private_alert}`
                }} />
              } type='warning' showIcon /><br />
            </div>
            : null }

          { /** File Modal */}
          <AddFileModal
            invoiceId={'add'}
            invoiceInfo={{ invoice_number: invoiceNumber, invoice_date: invoiceDate }}
            key={`addfilemodalinv_new`}
            item={fileInfo}
            categoriesList={categoriesList}
            subCategoriesList={subCategoriesList}
            onClose={() => this.handleAddFileModal(false)}
            onSetFile={(values) => this.updateFileAdded(values)}
            visible={showAddFileModal}
          />

          {/** Select ABA/Rmt Modal */}
          <Modal
            width='80%'
            title={`Select and Generate ${abaRmtModalType === 'aba' ? 'ABA' : abaRmtModalType === 'rmt' ? 'Remittance' : 'ABA / Remittance'}`}
            visible={showAbaRmtModal}
            onCancel={() => this.handleAbaRmtModal(false)}
            footer={
              [
                <Button key='close' ghost feedback={loadingSelectAbaRmt} onClick={() => this.handleAbaRmtModal(false)}>Close</Button>,
                <Button style={{backgroundColor: '#3d34eb'}} key='submit1' feedback={loadingSelectAbaRmt} onClick={() => this.preAbaRmtGetFile()}>Download Selected</Button>,
                abaRmtModalType === 'rmt' ? <Button style={{backgroundColor: '#ebc034'}} key='submit2' feedback={loadingSelectAbaRmt} onClick={() => this.preAbaRmtGetFile(true, false)}>Email Selected</Button> : null,
                abaRmtModalType === 'rmt' ? <Button key='submit3' feedback={loadingSelectAbaRmt} onClick={() => this.preAbaRmtGetFile(true, true)}>Download & Email Selected</Button> : null
              ]
            }
          >
            <div>
              <div className='inv-sec-row '>
                <div className='inv-title'>Select received amounts to export.</div>
                <div className='inv-sec-row' style={{marginRight: '30px'}}>
                  <div style={{marginRight: '10px', fontSize: '11px'}}>Select / Deselect All</div>
                  <Checkbox
                    checked={abaRmtSelectAll}
                    onClick={(e) => this.updateAbaRmtModalIdsSelectAll(e)}
                  />
                </div>
              </div>
              { abaRmtSelectErrMsg ? <div className='inv-err-msg'>{abaRmtSelectErrMsg}</div> : null }
              <div className='inv-section'>
                <List cols={abaRmtColumns} rows={item.rcv_history_list} />
              </div>
            </div>
          </Modal>

          {/** Rcv History Modal */}
          <Modal
            key={`rcbmodal`}
            width={'60vw'}
            title='Update Received Amount'
            visible={showRcvAmtModal}
            onCancel={() => this.handleRcvAmtModal(false)}
            footer={!loadingRctDetail
              ? [
                <Button key='close' ghost disabled={loadingUpdateRct} onClick={() => this.handleRcvAmtModal(false)}>Close</Button>,
                !isRcvAmtClosed? <Button key='submit' disabled={loadingUpdateRct} onClick={() => this.preCheckRcvAmt()}>Submit</Button> : null
              ]
            : null }
          >
            <Spin spinning={loadingRctDetail || loadingUpdateRct} blur>
              { rcvModalInfo && rcvModalInfo.id
                ? <div className='invoice-rcv-amt'>

                  <Row className='row' gutter={12}>
                    <Col lg={9} style={{textAlign: 'right'}}>
                      <span className='title'>To Receive Amount:</span>
                    </Col>
                    <Col lg={9}>
                      <span className='value-amt'>{rcvModalInfo.is_closed ? 'Closed' : `$ ${rcvModalInfo.to_pay_subtotal}`}</span>
                    </Col>
                  </Row>

                  <FormItem {...formItemLayout} label='Receive Date'>
                    {getFieldDecorator('received_date', {
                      initialValue: null,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateRcvAmtDate }
                      ]
                    })(
                      <DateTimePicker showTime={false} disabled={isRcvAmtClosed} />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label='Current Receive Amount'>
                    {getFieldDecorator('rcv_amount', {
                      initialValue: 0,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: (r,v,c) => this.validateInvItemRcvAmount(r, v, c, rcvModalInfo.to_pay_subtotal) }
                      ]
                    })(
                      <Input
                        addonBefore='$'
                        onChange={(e) => this.onInvItemRcvAmountChange(e, rcvModalInfo.to_pay_subtotal)}
                        disabled={isRcvAmtClosed}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='Rcv Notes'>
                    {getFieldDecorator(`rcv_comment`, {
                      initialValue: rcvModalInfo.comment || null
                    })(
                      <TextArea
                        row={2}
                        disabled={isRcvAmtClosed}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='Going to close the Item?'>
                    {getFieldDecorator(`is_auto_closed`, {
                      initialValue: rcvModalInfo.is_auto_closed || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        disabled={isRcvAmtClosed}
                        onChange={(e) => this.onInvItemRcvClosedChange(e, rcvModalInfo.to_pay_subtotal)}
                      />
                    )}
                  </FormItem>
                  { rcvModalInfo.is_overpay
                    ? <div className='warning-msg'>{RcvAmountOverPayMsg}</div>
                    : null }
                  { rcvModalMsg
                    ? <div className='warning-msg'>{rcvModalMsg}</div>
                    : null }
                  { validator.isNotEmptyArray(rcvModalInfo.receive_history)
                    ? <div>
                      <List cols={rcvColumns} rows={rcvModalInfo.receive_history} />
                    </div>
                    : null }
                </div>
                : <div>Getting Invoice Item Detail...</div> }
            </Spin>
          </Modal>

          {/** Credit Edit Modal, unable to edit when inv status is not Authed status anymore (!isAbleApplyCredit) */}
          <Modal
            key={`csrcmodal`}
            width={'60vw'}
            title='Edit Credit Amount'
            visible={showCreditEditModal}
            onCancel={() => this.handleCreditAmtEditModal(false)}
            footer={[
              <Button key='close' ghost disabled={loadingUpdateCredit} onClick={() => this.handleCreditAmtEditModal(false)}>Close</Button>,
              !creditModalInfo.is_closed && clientCurrentCreditInfo.id ? <Button key='submit' disabled={loadingUpdateCredit} onClick={() => this.preCheckCreditEditAmt()}>Submit</Button> : null
            ]}
          >
            <Spin spinning={loadingCreditDetail || loadingUpdateCredit} blur>
              { clientCurrentCreditInfo && clientCurrentCreditInfo.id
                ? <div>
                  <FormItem {...formItemLayout} label='Apply Date'>
                    {getFieldDecorator('edit_apply_date', {
                      initialValue: clientCurrentCreditInfo.apply_date ? formatter.toMoment(clientCurrentCreditInfo.apply_date) : null,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateCreditApplyAmtDate }
                      ]
                    })(
                      <DateTimePicker disabled={!isAbleApplyCredit} showTime={false} />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label='Credit Apply Amount'
                    extra={`Max Allowed Amount: ${formatter.toPrice(clientCreditEditMaxAmt)}`}
                  >
                    {getFieldDecorator('edit_credit_amount', {
                      initialValue: clientCurrentCreditInfo.amount || 0.0,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: (r,v,c) => this.validateCreditEditAmt(r, v, c) }
                      ]
                    })(
                      <Input
                        addonBefore='$'
                        disabled={!isAbleApplyCredit}
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout} label='Credit Apply Notes'>
                    {getFieldDecorator(`edit_credit_comment`, {
                      initialValue: clientCurrentCreditInfo.comment || null
                    })(
                      <TextArea
                        row={2}
                        disabled={!isAbleApplyCredit}
                      />
                    )}
                  </FormItem>
                </div>
                : null}

            </Spin>
          </Modal>

          {/** Credit History Modal */}
          <Modal
            key={`csrhmodal`}
            width={'80vw'}
            title='Apply Credit Amount'
            visible={showCreditAmtModal}
            onCancel={() => this.handleCreditAmtModal(false)}
            footer={!loadingCreditDetail
              ? [
                <Button key='close' ghost disabled={loadingUpdateCredit} onClick={() => this.handleCreditAmtModal(false)}>Close</Button>,
                !creditModalInfo.is_closed && clientSelectedCredit.id ? <Button key='submit' disabled={loadingUpdateCredit} onClick={() => this.preCheckCreditAmt()}>Submit</Button> : null
              ]
            : null }
          >
            <Spin spinning={loadingCreditDetail || loadingUpdateCredit} blur>
              { creditModalInfo && creditModalInfo.id
                ? <div className='invoice-rcv-amt'>

                  <Row className='row' gutter={12}>
                    <Col lg={9} style={{textAlign: 'right'}}>
                      <span className='title'>To Receive Amount:</span>
                    </Col>
                    <Col lg={9}>
                      <span className='value-amt'>{isCreditClosed ? 'Closed' : `$ ${creditModalInfo.to_pay_subtotal}`}</span>
                    </Col>
                  </Row>

                  { !isCreditClosed
                    ? <div>
                      { validator.isNotEmptyArray(creditModalInfo.credits)
                        ? <FormItem
                            {...formItemLayout}
                            label='Select Credit'
                          >
                          {getFieldDecorator('credit_id', {
                            initialValue: null,
                            rules: [
                              { required: true, message: 'Please select credit entry' },
                            ]
                          })(
                            <Select
                              style={{ width: '35vw' }}
                              disabled={isCreditClosed}
                              showSearch
                              optionFilterProp='children'
                              notFoundContent='No Credit Entry available'
                              placeholder='Please select credit entry'
                              showSearch
                              filterOption={(input, option) =>
                                this.findCredits(input, option)
                              }
                              onChange={(id) => this.onCreditAmtSelect(id)}
                            >
                              {
                                creditModalInfo.credits.map((c) => {
                                  return (
                                    <Option key={c.id} value={c.id}>
                                      <div>
                                        <div>{formatter.toPrice(c.remaining_amount)} - {c.booking_number}</div>
                                        <div>{c.cat_name} ({c.report_group_name})</div>
                                      </div>
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                          )}
                        </FormItem>
                        : null }

                      <FormItem {...formItemLayout} label='Apply Date'>
                        {getFieldDecorator('apply_date', {
                          initialValue: null,
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validateCreditApplyAmtDate }
                          ]
                        })(
                          <DateTimePicker disabled={isCreditClosed} showTime={false} />
                        )}
                      </FormItem>

                      <FormItem {...formItemLayout} label='Credit Apply Amount'>
                        {getFieldDecorator('credit_amount', {
                          initialValue: 0,
                          rules: [
                            { required: true, message: ' ' },
                            { validator: (r,v,c) => this.validateCreditAmt(r, v, c) }
                          ]
                        })(
                          <Input
                            addonBefore='$'
                            disabled={isCreditClosed}
                            // onChange={(e) => this.onInvItemRcvAmountChange(e, creditModalInfo.to_pay_subtotal)}
                          />
                        )}
                      </FormItem>
                      <FormItem {...formItemLayout} label='Credit Apply Notes'>
                        {getFieldDecorator(`credit_comment`, {
                          initialValue: creditModalInfo.comment || null
                        })(
                          <TextArea
                            row={2}
                            disabled={isCreditClosed}
                          />
                        )}
                      </FormItem>
                    </div>
                    : null }

                  {/* <FormItem {...formItemLayout} label='Closed the Item?'>
                    {getFieldDecorator(`is_auto_closed`, {
                      initialValue: creditModalInfo.is_auto_closed || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        disabled={creditModalInfo.is_closed}
                        onChange={(e) => this.onInvItemRcvClosedChange(e, creditModalInfo.to_pay_subtotal)}
                      />
                    )}
                  </FormItem> */}
                  { validator.isNotEmptyArray(creditModalInfo.applied_history)
                    ? <div>
                      <List cols={creditColumns} rows={creditModalInfo.applied_history} />
                    </div>
                    : null }
                </div>
                : <div>Getting Invoice Item Detail...</div> }
            </Spin>
          </Modal>

          {/** Invoice Authorisation Modal */}
          <Modal
            key={`invathmodal`}
            width={'416px'}
            visible={showInvAuthModal}
            onCancel={(invAuthModalInfo && invAuthModalInfo.isDisabledClose) || loadingUpdateComm ? null : () => this.handleInvAuthModal(false)}
            footer={[
              invAuthModalInfo && invAuthModalInfo.isDisabledClose ? null : <Button key='close' ghost feedback={loadingUpdateComm} onClick={() => this.handleInvAuthModal(false)}>Cancel</Button>,
              <Button key='danger' style={{backgroundColor: '#ebab34'}} feedback={loadingUpdateComm} onClick={() => this.handleConfirmInvAuth(INV_AUTH_ACTION_LATER)}>Later</Button>,
              <Button key='submit' style={{backgroundColor: '#1890ff'}} feedback={loadingUpdateComm} onClick={() => this.handleConfirmInvAuth(INV_AUTH_ACTION_SENDNOW)}>Send Now</Button>
            ]}
          >
            <div>
              <span><Icon type='question-circle' style={{color: '#faad14', fontSize: '22px', marginRight: '16px'}} /><span style={{color: 'rgba(0, 0, 0, 0.85)', fontSize: '15px', lineHeight: '1.4', fontWeight: '500'}}>Invoice Authorsiation REQUIRED</span></span>
              <div style={{color: 'rgba(0, 0, 0, 0.65)', fontSize: '13px', marginTop: '8px', marginLeft: '38px'}}>{invAuthModalInfo && invAuthModalInfo.content ? invAuthModalInfo.content : null}</div>
            </div>
          </Modal>

          {/** extra provider ABN info */}
          { (this.isEdit() && item.is_invoice_required_abn) || (!this.isEdit() && providerInfo && providerInfo.id && providerInfo.is_abn_allowed_empty === true && providerInfo.abn_exempted_reason === 'N/A')
            ? <div style={{marginTop: '20px'}}>
              <Panel
                title='ABN for Invoice'
              >
                <Row>
                  <Col lg={12}>
                    <FormItem {...shortFormItemLayout} label='ABN' hasFeedback extra='Enter ABN without spacing'>
                      {getFieldDecorator('invoice_abn', {
                        initialValue: item.invoice_abn || '',
                        rules: !isAllowABNExempted
                          ? [
                            { required: true, message: ' ' },
                            { validator: this.validateABN }
                          ]
                          : undefined
                      })(
                        <Input disabled={isAllowABNExempted} />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...shortFormItemLayout} label='ATO Excluded Supply?'>
                      {getFieldDecorator('is_invoice_abn_exempted', {
                        initialValue: item.is_invoice_abn_exempted || false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          onChange={this.toggleAllowABNExempted}
                          checkedChildren='Yes'
                          unCheckedChildren='No'
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
              </div>
            : null }

          {/** invoice date / invoice number row */}
          { clientInfo && clientInfo.id
            ? <div style={{marginTop: '20px'}}>
              <Panel
                title='Invoice Detail'
                subtitle={this.isEdit()
                  ? <div className={'invoice-status'} style={statusStyle}>
                    {statusItem ? statusItem.name : formatter.capitalize(item.status_name)}
                  </div>
                  : null }
              >
                <Row gutter={16}>
                  <Col lg={12}>
                    <Row>
                      <FormItem {...shortFormItemLayout} label='Invoice Date'>
                        {getFieldDecorator('invoice_date', {
                          initialValue: isEdit && item.invoice_date ? moment(item.invoice_date) : null,
                          rules: [
                            { required: true, message: 'Please enter invoice date.' }
                          ]
                        })(
                          // <DateTimePicker onChange={this.handleInvoiceDateChange} showTime={false} disabled={isItemDisabled} />
                          <DatePicker defaultPickerValue={moment(new Date())} format={dateFormat} onChange={this.handleInvoiceDateChange} disabled={isItemDisabled} />
                        )}
                      </FormItem>
                    </Row>
                    <Row>
                      <FormItem {...shortFormItemLayout} label='Invoice Number'>
                        {getFieldDecorator('invoice_number', {
                          initialValue: isEdit && item.invoice_number ? item.invoice_number : null,
                          rules: [
                            { required: true, message: 'Please enter invoice number.' },
                            { validator: this.validateInvoiceNumberEntry }
                          ]
                        })(
                          <Input
                            onChange={(e) => this.validateInvoiceNumber(e)}
                            addonAfter={loadingCheckInvoiceNo ? <Icon type={'loading'} className='wd-loading-icon' /> : <span style={{marginRight: '13px'}}/>}
                            disabled={!!item.is_sdb_invoice}
                          />
                        )}
                      </FormItem>
                    </Row>
                    <Row>
                      <FormItem {...shortFormItemLayout} label='Expected Invoiced Amount'>
                        {getFieldDecorator('targeted_subtotal', {
                          initialValue: isEdit && item.targeted_subtotal ? item.targeted_subtotal : null,
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validateTargetedInvAmount }
                          ]
                        })(
                          <Input
                            addonBefore='$'
                            onChange={(e) => this.onTargetedInvAmountChange(e)}
                            disabled={isItemDisabled}
                          />
                        )}
                      </FormItem>
                    </Row>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...shortFormItemLayout} label='Remittance Comment'>
                      {getFieldDecorator('invoice_comment', {
                        initialValue: isEdit && item.invoice_comment ? item.invoice_comment : null
                      })(
                        <TextArea cols={2} />
                      )}
                    </FormItem>
                    <FormItem {...shortFormItemLayout} label='Private Notes'>
                      {getFieldDecorator('invoice_private_comment', {
                        initialValue: isEdit && item.invoice_private_comment ? item.invoice_private_comment : null
                      })(
                        <TextArea cols={2} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Panel>

              {/** invoice file upload section */}
              { isInvoiceDateSelected && !this.isEdit()
                ? <Panel
                    title='Files'
                    subtitle={(this.hasAccess(Permissions.INVOICE.FILES.CREATE)
                      ? <Button className='btn' key='ok' type='primary' feedback={loading} onClick={() => this.handleAddFileModal(true)}> {'Add File'}</Button>
                      : null)}
                  >
                    <List cols={fileColumns} rows={fileList} />
                </Panel>
                : null }

              {/** invoice items section */}
              { isInvoiceDateSelected
                ? <Panel title='Items'>

                  { validator.isNotEmptyArray(invoiceItems)
                    ? (validator.isNotEmptyArray(clientCurrentBudgetList)
                      ? clientCurrentBudgetList.map(e => (
                        <div className='plan-section'>
                          <div className='plan-header'><span>{`${e.booking_number} (${formatter.toShortDate(e.period_start_date)} - ${formatter.toShortDate(e.period_end_date)})`}</span>{ e.is_over_period ? <span className='plan-header-sub'>{SBOverDaysMsg}</span> : null }</div>
                          { validator.isNotEmptyArray(e.categories) && e.categories.map((c, index) => {
                            const percent = parseFloat((((c.remaining_value || 0) / (c.budget_value || 0)) * 100).toFixed(2))
                            const status = percent > 95 ? 'success' : percent < 30 ? 'exception' : ''

                            return (
                              <Row className='plan-row' key={`pcr${index}`}>
                                <Col lg={8}>
                                  { validator.isNotEmptyArray(c.cat_items)
                                    ? <div>
                                      <div className='title'>{c.cat_proda_name}</div>
                                      <div className='description'>{c.cat_items[0].cat_item_name}</div>
                                    </div>
                                    : <div>
                                      <div className='title'>{c.cat_proda_name}</div>
                                    </div>}
                                </Col>
                                <Col lg={6}>
                                  <Row><span className={`current-val ${percent < 30 ? 'exception' : ''}`}>{formatter.toPrice(c.remaining_value)}</span> / <span className='budget-val'>{formatter.toPrice(c.budget_value || 0.00)}</span></Row>
                                </Col>
                                <Col lg={8}>
                                  <Progress percent={percent} status={status} strokeColor={'#19938d'} />
                                </Col>
                              </Row>
                            )
                          })}
                        </div>
                      ))
                      : <div className='plan-section'>
                        <div className='plan-title'>{'No active budget available on invoice service dates selected.'}</div>
                      </div>
                    )
                    : null }

                  {/* { clientCurrentBudget && clientCurrentBudget.budget_id
                    ? <div className='plan-section'>
                      <div className='plan-header'><span>{`${clientCurrentBudget.booking_number} (${formatter.toShortDate(clientCurrentBudget.period_start_date)} - ${formatter.toShortDate(clientCurrentBudget.period_end_date)})`}</span>{ isSBOverPeriod ? <span className='plan-header-sub'>{SBOverDaysMsg}</span> : null }</div>
                      { validator.isNotEmptyArray(clientCurrentBudget.categories) && clientCurrentBudget.categories.map((c, index) => {
                        const percent = parseFloat((((c.remaining_value || 0) / (c.budget_value || 0)) * 100).toFixed(2))
                        const status = percent > 95 ? 'success' : percent < 30 ? 'exception' : ''
                        return (
                          <Row className='plan-row' key={`pcr${index}`}>
                            <Col lg={8}>
                              { validator.isNotEmptyArray(c.cat_items)
                                ? <div>
                                  <div className='title'>{c.cat_name}</div>
                                  <div className='description'>{c.cat_items[0].cat_item_name}</div>
                                </div>
                                : <div>
                                  <div className='title'>{c.cat_name}</div>
                                </div>}
                            </Col>
                            <Col lg={6}>
                              <Row><span className={`current-val ${percent < 30 ? 'exception' : ''}`}>{formatter.toPrice(c.remaining_value)}</span> / <span className='budget-val'>{formatter.toPrice(c.budget_value || 0.00)}</span></Row>
                            </Col>
                            <Col lg={8}>
                              <Progress percent={percent} status={status} strokeColor={'#19938d'} />
                            </Col>
                          </Row>
                        )
                      })}
                    </div>
                    : <div className='plan-section'>
                      <div className='plan-title'>{'No active budget available on invoice date selected.'}</div>
                    </div>} */}

                    { /** credit section if available */ }
                    { validator.isNotEmptyArray(clientCurrentCredit) && !isClosed
                      ? <div className='plan-section credit'>
                        <div className='plan-subheader'>Available credits and able for apply when invoice is authed.</div>
                        { clientCurrentCredit.map((e, index) => {
                          const percent = formatter.toPriceFloat((e.remaining_amount / e.amount) * 100)
                          const isNewBudget = e.budget_id !== lastCreditBudgetId
                          lastCreditBudgetId = e.budget_id
                          return (
                            <div key={`ccrc${index}`}>
                              { isNewBudget
                                ? <div className='plan-header'>{e.booking_number} ({formatter.toShortDate(e.period_start_date)} - {formatter.toShortDate(e.period_end_date)})</div>
                                : null }
                              <Row className='plan-row'>
                                {/* <Col lg={6}>
                                  <div className='title'>{e.booking_number} ({formatter.toShortDate(e.period_start_date)} - {formatter.toShortDate(e.period_end_date)})</div>
                                </Col> */}
                                <Col lg={5}>
                                  <div className='title'>{e.report_group_name}</div>
                                </Col>
                                <Col lg={7}>
                                  <div className='title'>{e.cat_name}</div>
                                </Col>
                                <Col lg={5}>
                                  <Row><span className={`current-val ${percent < 30 ? 'exception' : ''}`}>{formatter.toPrice(e.remaining_amount)}</span> / <span className='budget-val'>{formatter.toPrice(e.amount || 0.00)}</span></Row>
                                </Col>
                                <Col lg={7}>
                                </Col>
                              </Row>
                            </div>
                          )
                        })}
                      </div>
                      : null }

                    { /** add button row */ }
                    { !isItemDisabled
                      ? <div className='row-flex-end' style={{marginTop: '20px', marginBottom: '10px'}}>
                        <div className='btn' onClick={() => this.onAddInvItem()}>
                          Add Item
                        </div>
                      </div>
                      : null }

                    { /** item rows section */ }
                    { validator.isNotEmptyArray(invoiceItems) && ((!this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.CREATE) || (this.isEdit() && this.hasAccess(Permissions.INVOICE.INFO.READ))))
                      ? invoiceItems.map((itm, index) => {
                        const {
                          id: itemId,
                          cat_id: catId,
                          cat_id_mix: catIdMix,
                          cat_item_id: catItemId,
                          cats = [],
                          catItems = [],
                          comment,
                          max_rate: maxRate,
                          inv_date: invDate,
                          inv_end_date: invEndDate,
                          inv_rate: invRate,
                          received_subtotal: rcvSubtotal,
                          unit,
                          subtotal,
                          is_closed: isClosed,
                          is_delete: isDelete,
                          is_amt_over_budget: isAmtOverBudget,
                          is_sb_mismatch: isSBMismatch,
                          is_sb_period: isSBPeriod,
                          is_sb_period1: isSBPeriod1,
                          is_sb_period2: isSBPeriod2,
                          is_sb_periods: isSBPeriods,
                          is_sb_periods1: isSBPeriods1,
                          is_sb_periods2: isSBPeriods2
                        } = itm

                        const inputInvDate = isEdit ? (!!invDate || !!getFieldValue(`inv_date${index}`)) : !!getFieldValue(`inv_date${index}`)
                        const inputInvEndDate = isEdit ? (!!invEndDate || !!getFieldValue(`inv_end_date${index}`)) : !!getFieldValue(`inv_end_date${index}`)
                        const inputCatId = isEdit ? (!!catId || !!getFieldValue(`cat_id${index}`)) : !!getFieldValue(`cat_id${index}`)
                        const inputCatItemId = isEdit ? (!!catItemId || !!getFieldValue(`cat_item_id${index}`)) : !!getFieldValue(`cat_item_id${index}`)
                        const inputInvRate = parseFloat(getFieldValue(`inv_rate${index}`))
                        const inputMaxRate = parseFloat(maxRate)
                        const isAdding = !itemId
                        // console.log('invoice items', item, inputInvDate, inputCatId, inputCatItemId)
                        const isCatEnabled = inputInvDate
                        const isCatItemEnabled = inputInvDate && inputCatId
                        const isAmountRowEnabled = inputInvDate && inputCatId && inputCatItemId
                        const isInvRateMore = inputMaxRate !== null && inputMaxRate > 0 && inputMaxRate < inputInvRate

                        return (
                          <div key={`invItem${index}`} className={`invoice-item ${isDelete ? 'delete' : isAdding ? 'adding' : 'list'}`}>
                            { isDelete
                              ? <div className='row-flex-end row-inline'>
                                <div className='btn' onClick={() => this.onUndoRemoveInvItem(index)}>
                                 Undo Delete
                              </div>
                            </div>
                              : null }
                            <div className={isDelete ? 'blur' : ''}>
                              <Row gutter={12}>
                                <Col lg={1}>
                                  <div className='numbering'>
                                    { index + 1 }
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <FormItem label='Service Start Date'>
                                    {getFieldDecorator(`inv_date${index}`, {
                                      initialValue: invDate ? moment(invDate) : null,
                                      rules: [
                                        { required: true, message: ' ' },
                                        { validator: this.validateInvoiceStartDate }
                                      ]
                                    })(
                                      <DatePicker
                                        defaultPickerValue={moment(new Date())}
                                        format={dateFormat}
                                        placeholder={'Select Date'}
                                        onChange={(date) => this.onInvItemDateChange(date, index, 'inv_date')}
                                        disabled={isDelete || isItemDisabled}
                                      />
                                    )}
                                  </FormItem>
                                </Col>
                                <Col lg={4}>
                                  <FormItem label='Service End Date'>
                                    {getFieldDecorator(`inv_end_date${index}`, {
                                      initialValue: invEndDate ? moment(invEndDate) : null,
                                      rules: [
                                        { required: true, message: ' ' },
                                        { validator: this.validateInvoiceEndDate }
                                      ]
                                    })(
                                      <DatePicker
                                        defaultPickerValue={moment(new Date())}
                                        format={dateFormat}
                                        placeholder={'Select Date'}
                                        onChange={(date) => this.onInvItemDateChange(date, index, 'inv_end_date')}
                                        disabled={isDelete || isItemDisabled}
                                      />
                                    )}
                                  </FormItem>
                                </Col>
                                <Col lg={12}></Col>
                                <Col lg={2}>
                                  { this.isEdit() && isToPay
                                    ? <FormItem label='Closed?'>
                                      {getFieldDecorator(`is_closed${index}`, {
                                        initialValue: isClosed || false,
                                        valuePropName: 'checked'
                                      })(
                                        <Switch
                                          checkedChildren='Yes'
                                          unCheckedChildren='No'
                                          disabled={isClosed}
                                        />
                                      )}
                                    </FormItem>
                                    : null }
                                </Col>
                                <Col lg={1}>
                                  { !isItemDisabled
                                    ? <div className='action-buttons'>
                                      <Popconfirm
                                        title='Confirm to delete this item?'
                                        onConfirm={(e) => this.onRemoveInvItem(index)}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <Icon type='delete' style={{color: '#1d2c47', fontSize: '14px', marginBottom: '4px'}} />
                                      </Popconfirm>
                                    </div>
                                    : null }
                                </Col>
                              </Row>

                              <Row gutter={12}>
                                <Col lg={1} />
                                <Col lg={9}>
                                  {/** A hidden field to store real cat_id change in form */}
                                  <FormItem
                                    style={{display: 'none'}}
                                    label='Support Category Real'>
                                    {getFieldDecorator(`cat_id${index}`, {
                                      initialValue: catId
                                    })(<div>{catId}</div>)}
                                  </FormItem>

                                  <FormItem
                                    label='Support Category'>
                                    {getFieldDecorator(`cat_id_mix${index}`, {
                                      initialValue: catIdMix,
                                      rules: [
                                        { required: true, message: 'Please select support category' }
                                      ]
                                    })(
                                    <Select showSearch
                                      placeholder='Select Support Category'
                                      optionFilterProp='children'
                                      notFoundContent='No support categories available'
                                      filterOption={(input, option) => this.findCats(input, option)}
                                      onChange={(id, option) => this.onInvItemSelectCat(id, index, option)}
                                      disabled={!isCatEnabled || isDelete || isItemDisabled}
                                    >
                                      { cats.map((itm, idx) => {
                                          const title = `${itm.is_multiple_budget ? `(${itm.booking_number}) ` : ''}${itm.cat_identifier_text} ${itm.cat_proda_name}`
                                          const value = itm.cat_id_mix
                                          return (
                                            <Option
                                              key={`budgetcat${idx}${title}`}
                                              value={value}
                                              title={title}
                                            >
                                              {title}
                                            </Option>
                                          )
                                        }) }
                                    </Select>
                                    )}
                                  </FormItem>
                                </Col>
                                <Col lg={13}>
                                  <Spin spinning={loadingCatItems}>
                                    <FormItem label='Support Item'>
                                      {getFieldDecorator(`cat_item_id${index}`, {
                                        initialValue: catItemId,
                                        rules: [
                                          { required: true, message: 'Please select support item' }
                                        ]
                                      })(
                                      <Select showSearch
                                        placeholder='Select Support Item'
                                        optionFilterProp='children'
                                        notFoundContent='No Support Items available'
                                        filterOption={(input, option) => this.findCatItems(input, option)}
                                        onChange={(id) => this.onInvItemSelectCatItem(id, index)}
                                        disabled={!isCatItemEnabled || isDelete || isItemDisabled}
                                      >
                                        { catItems.map((itm, idx) => {
                                            return (
                                              <Option
                                                key={`budgetcatitem${idx}${catItemId}`}
                                                value={itm.cat_item_id}
                                              >
                                                <Tooltip mouseLeaveDelay={0} title={`${itm.cat_item_name} (${itm.cat_item_identifier})`}>
                                                  {`${itm.cat_item_name} (${itm.cat_item_identifier})`}
                                                </Tooltip>
                                              </Option>
                                            )
                                          }) }
                                      </Select>
                                      )}
                                    </FormItem>
                                  </Spin>
                                </Col>
                                <Col lg={1} />
                              </Row>

                              { isAmountRowEnabled
                                ? <Row>
                                  <Col lg={1} />
                                  <Col lg={3}>
                                    <FormItem style={{marginRight: '20px'}} label='Max Rate'>
                                      {getFieldDecorator(`max_rate${index}`, {
                                        initialValue: !maxRate ? 'No Limit' : formatter.toPriceFloat(maxRate)
                                      })(
                                        <Input disabled />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={3}>
                                    <FormItem style={{marginRight: '5px'}} label='Unit'>
                                      {getFieldDecorator(`unit${index}`, {
                                        initialValue: unit,
                                        rules: [
                                          { required: true, message: ' ' },
                                          { validator: this.validateUnitChange }
                                        ]
                                      })(
                                        <Input
                                          style={{marginRight: '5px'}}
                                          onChange={(e) => this.onInvItemUnitChange(e, index)}
                                          disabled={isDelete || isItemDisabled}
                                        />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={3}>
                                    <FormItem style={{marginRight: '5px'}} label='Invoiced Rate'>
                                      {getFieldDecorator(`inv_rate${index}`, {
                                        initialValue: invRate,
                                        rules: [
                                          { required: true, message: ' ' },
                                          { validator: (r, v, c) => this.validateInvoicedRateChange(r, v, c, maxRate) }
                                        ]
                                      })(
                                        <Input
                                          style={{marginRight: '5px'}}
                                          onChange={(e) => this.onInvItemInvRateChange(e, index)}
                                          disabled={isDelete || isItemDisabled}
                                        />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={3}>
                                    <FormItem style={{marginRight: '5px'}} label='Invoiced Amount'>
                                      <div className={`height-label ${isDelete ? 'delete' : ''} ${isInvRateMore ? 'error' : ''}`}>
                                        { formatter.toPrice(subtotal, '') }
                                      </div>
                                    </FormItem>
                                  </Col>
                                  {/* <Col lg={1} /> */}
                                  <Col lg={5}>
                                    <FormItem style={{marginLeft: '10px'}} label='Item Notes'>
                                      {getFieldDecorator(`comment${index}`, {
                                        initialValue: comment || null
                                      })(
                                        <TextArea row={2} />
                                      )}
                                    </FormItem>
                                  </Col>
                                  <Col lg={1} />
                                  <Col lg={3}>
                                    { !this.isEdit()
                                      ? null
                                      : <FormItem
                                          label={isAbleApplyCredit && validator.isNotEmptyArray(clientCurrentCredit) && !isToReceiveAmount ? 'Applied Credit' : 'Received Amount'}
                                        >
                                        <div className='row-flex-center' style={{marginTop: '4px'}}>
                                          <div className={`height-label validate ${isDelete ? 'delete' : ''}`}>
                                            { formatter.toPrice(rcvSubtotal, '') }
                                          </div>
                                        </div>
                                      </FormItem> }
                                  </Col>
                                  <Col lg={2}>
                                    <div>
                                    { !this.isEdit()
                                      ? null
                                      : isToReceiveAmount && isAbleApplyCredit && validator.isNotEmptyArray(clientCurrentCredit)
                                      ? <div className='action-buttons-act'>
                                        { (this.hasAccess(Permissions.INVOICE.INFO.UPDATE))
                                          ? <Tooltip mouseLeaveDelay={0} title={`Update Received Amount`}>
                                            <div className='btn-act' onClick={() => this.handleRcvAmtModal(true, itemId)}>
                                              <Icon type='to-top' style={{color: '#fff', fontSize: '16px'}} />
                                            </div>
                                          </Tooltip>
                                          : null }

                                        { (this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.CREATE) || this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.UPDATE))
                                          ? <Tooltip mouseLeaveDelay={0} title={`Apply Credit Amount`}>
                                            <div className='btn-act' onClick={() => this.handleCreditAmtModal(true, itemId)}>
                                              <Icon type='dollar' style={{color: '#fff', fontSize: '16px'}} />
                                            </div>
                                          </Tooltip>
                                          : null }
                                      </div>
                                      : isAbleApplyCredit &&
                                        validator.isNotEmptyArray(clientCurrentCredit) &&
                                        (this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.CREATE) || this.hasAccess(Permissions.INVOICE.CREDIT_APPLY.UPDATE))
                                        ? <div className='action-buttons-act'>
                                          <Tooltip mouseLeaveDelay={0} title={`Apply Credit Amount`}>
                                            <div className='btn-act' onClick={() => this.handleCreditAmtModal(true, itemId)}>
                                              <Icon type='dollar' style={{color: '#fff', fontSize: '16px'}} />
                                            </div>
                                          </Tooltip>
                                        </div>
                                        : isToReceiveAmount && this.hasAccess(Permissions.INVOICE.INFO.UPDATE)
                                          ? <div className='action-buttons-act'>
                                            <Tooltip mouseLeaveDelay={0} title={`Update Received Amount`}>
                                              <div className='btn-act' onClick={() => this.handleRcvAmtModal(true, itemId)}>
                                                <Icon type='to-top' style={{color: '#fff', fontSize: '16px'}} />
                                              </div>
                                            </Tooltip>
                                          </div>
                                          : null
                                    }
                                    </div>
                                  </Col>
                                </Row>
                                : null }
                                { isInvRateMore
                                  ? <Row>
                                    <Col lg={1} />
                                    <Col lg={15}>
                                      <div className='warning-msg-item'>{MaxRateMismatchMsg}</div>
                                    </Col>
                                    <Col lg={8} />
                                  </Row>
                                  : null  }
                                { isAmtOverBudget
                                  ? <Row>
                                    <Col lg={1} />
                                    <Col lg={15}>
                                      <div className='warning-msg-item'>{OverBudgetMsg}</div>
                                    </Col>
                                    <Col lg={8} />
                                  </Row>
                                  : null}
                                { isSBMismatch
                                  ? <Row>
                                    <Col lg={1} />
                                    <Col lg={15}>
                                      <div className='warning-msg-item'>{SBMismatchMsg}</div>
                                    </Col>
                                    <Col lg={8} />
                                  </Row>
                                  : null }
                                { isSBPeriod || isSBPeriod2 || isSBPeriod1
                                  ? <Row>
                                    <Col lg={1} />
                                    <Col lg={15}>
                                      <div className='warning-msg-item'>{isSBPeriod2 ? SBOver90daysMsg : isSBPeriod1 ? SBOver60daysMsg : isSBPeriod ? SBOver0daysMsg : ''}</div>
                                    </Col>
                                    <Col lg={8} />
                                  </Row>
                                  : null }
                                { isSBPeriods || isSBPeriods2 || isSBPeriods1
                                  ? <Row>
                                    <Col lg={1} />
                                    <Col lg={15}>
                                      <div className='warning-msg-item'>{isSBPeriods2 ? SBOver90daysMultiMsg : isSBPeriods1 ? SBOver60daysMultiMsg : isSBPeriods ? SBOver0daysMultiMsg : ''}</div>
                                    </Col>
                                    <Col lg={8} />
                                  </Row>
                                  : null }
                            </div>
                          </div>
                        )
                      })
                      : null
                    }

                    {/** total rows section */}
                    <div key={`invTotal`} className={`invoice-item total`}>
                      <Row>
                        <Col lg={7}>
                        </Col>
                        <Col lg={3}>
                          <div className='total-label'>Total Invoiced Amount</div>
                        </Col>
                        <Col lg={3}>
                          <div className={`height-label ${invSubtotalMsg ? 'error' : ''}`}>
                            { formatter.toPrice(item.subtotal, '') }
                          </div>
                        </Col>
                        <Col lg={3} />
                        <Col lg={3}>
                          { this.isEdit()
                            ? <div className='total-label' style={{marginRight: '-5px'}}>Total Received Amount</div>
                            : null }
                        </Col>
                          <Col lg={3}>
                          { this.isEdit()
                            ? <div className={`height-label validate`} style={{marginLeft: '-5px'}}>
                              { formatter.toPrice(item.received_subtotal, '') }
                            </div>
                            : null }
                        </Col>
                        <Col lg={2} />
                      </Row>
                      { invSubtotalMsg
                        ? <Row>
                          <Col lg={5}>
                          </Col>
                          <Col lg={9}>
                            <div className='warning-msg-item'>{invSubtotalMsg}</div>
                          </Col>
                          <Col lg={10} />
                        </Row>
                        : null }
                      { }
                    </div>
                  </Panel>
                : null }
            </div>
            : null }

            {/** Invoice Delete Modal */}
            <SideModal
              title={'Invoice Delete Reason'}
              showModal={showInvDeleteModal}
              onClose={() => this.handleDeleteModal(false)}
              buttons={[
                <Button key='deletesave' onClick={this.handleDelete} feedback={loading}>Proceed Delete</Button>
              ]}
            >
              { showInvDeleteModal
              ? <Form layout='vertical'>
                <FormItem label='Reason Type'>
                  {getFieldDecorator('delete_reason_type', {
                    rules: [
                      { required: true, message: 'Please select reason type' }
                    ]
                  })(
                    <Select disabled={loading} style={{ width: '100%' }}>
                      { invoiceReasonList.map((items, idx) => {
                          return <Option key={`invrsn-${idx}`} value={items.name}>{items.name}</Option>
                        }) }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Reason Notes'>
                  {getFieldDecorator('delete_reason_note', {
                    rules: [
                      { required: true, message: 'Please enter reason notes' },
                      { whitespace: true, message: `Please enter reason notes` },
                      { min: 2, message: 'Minimum 2 characters required' },
                    ]
                  })(
                    <TextArea disabled={loading} row={2} />
                  )}
                </FormItem>
              </Form>
              : null }
            </SideModal>
        </Loading>
      </Form>
    )
  }

  /** fetch data */
  fetchCommCount = async () => {
    try {
      const { item } = this.state
      const r = await commService.getCommCount('invoice', item.id)

      if (r && r.count !== undefined) {
        let data = {
          commCount: r.count || 0,
          commSentCount: r.countSent
        }
        this.setState(data)

        return data
      }

      return null
    } catch (e) {
      return null
    }
  }

  fetchDataList = async () => {
    try {
      this.setState({loadingClient: true})
      const clients = await clientService.listAllClients()
      const providers = await providerService.listAllProviders()
      const mainCats = await settingFileService.listFileCatByPage(1, 0, { active: true, module_type: 'invoice' })
      const subCats = await settingFileService.listFileCatSubByPage(1, 0, { active: true, module_type: 'invoice' })

      this.setState({
        clientList: validator.isNotEmptyArray(clients) ? clients : [],
        providerList: validator.isNotEmptyArray(providers) ? providers : [],
        categoriesList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subCategoriesList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loadingClient: false
      })
      // console.log('client list', clients, providers)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client list successfully. Please try again later.')
    }
  }

  fetchInvStatusList = async() => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'invoice-status' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    if (settings && validator.isNotEmptyArray(settings.list)) {
      this.setState({
        statusList: settings.list.filter(item => item.identifier === 'invoice-status')
      })
    }
  }

  fetchInvoice = async (isLoading = true) => {
    if (!this.hasAccess(Permissions.INVOICE.INFO.READ)) {
      return
    }

    try {
      const refId = this.getRefId()
      let id = null

      if (refId === 'add' || refId === 'new' || !refId) return

      if (isLoading) this.setState({loading: true})
      const inv = await invoiceService.getRef(refId)

      let clientInfo = {}
      let providerInfo = {}
      let invoiceItems = []
      let invoiceHistory = []
      let clientCredit = []
      let clientCurrentCredit = []
      let showPrivateAlert = false

      if (inv.id) {
        id = inv.id
        invoiceItems = inv.items || []
        clientInfo = inv.client_info || {}
        providerInfo = inv.provider_info || {}
        invoiceHistory = inv.history || []
        clientCredit = inv.credits || []
        clientCurrentCredit = inv.current_credits || []

        delete inv.items
        delete inv.client_info
        delete inv.provider_info
        delete inv.history

        let clientBudget = []
        if (inv.client_id) {
          clientBudget = await clientInvoiceService.listAllClientBudget(inv.client_id)
          clientBudget = clientBudget.filter(e => e.is_monthly_fee_main_set === !!inv.is_sdb_invoice)
        }

        if (clientInfo && clientInfo.id) {
          if (clientInfo.private_alert) {
            showPrivateAlert = true
          }
        }

        this.setState({
          loading: false,
          item: inv,
          itemOri: cloneDeep(inv),
          invoiceItems,
          invoiceItemsOri: cloneDeep(invoiceItems), // must deepclone or else ori inv items are affected when changing inv items on inputs
          clientInfo,
          clientBudget,
          providerInfo,
          invoiceHistory,
          clientCredit,
          clientCurrentCredit,
          showPrivateAlert,
          isAllowABNExempted: inv.is_invoice_abn_exempted || false,
          isEnableEmailSend: inv.is_enable_send_email || false
        }, () => {
          this.fetchCommCount()
          this.handleInvoiceDateChange(moment(inv.invoice_date))
          this.onUpdateCurrentClientBudgetList()
          this.fetchInvReasons()
        })
      } else {
        notify.error('Unable to load successfully', 'Unable to load invoice successfully. Please try again later.')
        this.setState({loading: false})
      }

    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoice successfully. Please try again later.')
    }
  }

  fetchInvReasons = async () => {
    try {
      const invoiceReasonList = await settingReasonService.listReasonItemsAll('inv-delete')
      if (validator.isNotEmptyArray(invoiceReasonList)) {
        this.setState({ invoiceReasonList })
      }
    } catch (e) {
      console.log('fetch inv reasons err', e)
    }
  }

  findCats = (input, option) => {
    const item = `${option.props.children}`
    return item.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findCatItems = (input, option) => {
    const item = `${option.props.children.props.children}`
    return item.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  startSelectClientModal = (showClientModal, cb = () => {}) => {
    this.setState({showClientModal}, cb)
  }

  startSelectProviderModal = (showProviderModal, cb = () => {}) => {
    this.setState({showProviderModal}, cb)
  }

  /** handle change client/provider/tab/inv info */
  handleChangeClient = async (clientId) => {
    this.startSelectClientModal(false, async () => {
      this.setState({
        loadingClient: true,
        clientMsg: '',
        clientCredit: [],
        clientCurrentCredit: []
      })
      const client = await clientService.get(clientId)

      let budget = []
      let credit = []
      let showPrivateAlert = false

      if (client && client.id) {
        const { item } = this.state
        if (client.private_alert) {
          showPrivateAlert = true
          this.triggerClientWarning(client)
        }

        budget = await clientInvoiceService.listAllClientBudget(clientId)
        budget = budget.filter(e => e.is_monthly_fee_main_set === !!item.is_sdb_invoice)
        credit = await creditClientService.getClientAvailableCredits(clientId)
      }

      this.setState({
        loadingClient: false,
        clientInfo: client && client.id ? client : {},
        clientBudget: validator.isNotEmptyArray(budget) ? budget : [],
        clientMsg: client && client.id ? '' : SelectClientMsg,
        clientCredit: credit,
        showPrivateAlert
      })
    })
  }

  triggerClientWarning = (clientInfo) => {
    warning({
      title: `Participant: ${clientInfo.first_name} ${clientInfo.last_name}`,
      content: (
        <div>
          { clientInfo.public_alert
            ? <div dangerouslySetInnerHTML={{ __html: clientInfo.public_alert }} />
            : null}
          { clientInfo.private_alert
            ? <div dangerouslySetInnerHTML={{ __html: clientInfo.private_alert }} />
            : null}
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  triggerSBExpireWarning = (isMulti = false) => {
    warning({
      title: `Service Booking Ended`,
      content: (
        <div>
          { isMulti ? SBOver60daysMultiMsg : SBOver60daysMsg }
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  triggerSBInvalidWarning = (isMulti = false) => {
    error({
      title: `Service Booking Ended`,
      content: (
        <div>
          { isMulti ? SBOver90daysMultiMsg : SBOver90daysMsg }
        </div>
      ),
      okText: 'OK',
      onOk() { },
    })
  }

  handleProviderChange = async (providerId) => {
    this.startSelectProviderModal(false, async () => {
      const { providerList } = this.state
      const provider = providerList.find(e => e.id === parseInt(providerId))
      this.setState({
        providerInfo: provider && provider.id ? provider : {},
        providerMsg: provider && provider.id ? '' : SelectBillerMsg
      })
    })
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})
    if (tab && tab.tabId) {
      this.props.history.replace(`/invoices/${refId}${tab.path}`)
      if (tab.tabId === 1) {
        this.fetchInvoice(false)
      }
    }
  }

  toggleAllowABNExempted = (e) => {
    const { form } = this.props
    this.setState({ isAllowABNExempted: e }, () => {
      setTimeout(() => {
        form.validateFieldsAndScroll(['is_invoice_abn_exempted'])
      }, 1000)
    })
  }

  handleInvoiceDateChange = async (date) => {
    const { clientBudget = [], invoiceItems } = this.state
    const { form } = this.props

    if (validator.isNotEmptyArray(clientBudget)) {
      this.handleUpdateCurrentBudget(date, clientBudget)
    }

    setTimeout(() => {
      let fields = []
      for (let i = 0; i < invoiceItems.length; i++) {
        fields.push(`inv_date${i}`)
        fields.push(`inv_end_date${i}`)
      }

      form.validateFieldsAndScroll(fields, (error, values) => {})
    }, 500)
  }

  handleUpdateCurrentBudget = async (date, budget) => {
    const currentBudget = budget.find(e => {
      // need to set to startOf / endOf day to make sure the range function is working and moment within period is detected correctly
      const currDate = moment.isMoment(date) ? date.clone().startOf('day') : moment(date).startOf('day')
      const planStart = moment(e.period_start_date).startOf('day')
      const planEnd = moment(e.period_end_date).endOf('day')

      if (planStart.isBefore(date) && planEnd.isAfter(date)) {
        return true
      }
      return false
    })

    if (this.isEdit()) {
      if (currentBudget && currentBudget.budget_id) {
        this.setState({
          clientCurrentBudget: currentBudget
        })
      }
    } else {
      if (currentBudget && currentBudget.budget_id) {
        let items = []
        items.push(Object.assign({}, DefaultInvoiceItem))
        this.setState({
          clientCurrentBudget: currentBudget,
          invoiceItems: items
        })
      } else {
        let items = []
        items.push(Object.assign({}, DefaultInvoiceItem))
        this.setState({
          clientCurrentBudget: {},
          invoiceItems: items
        })
      }
    }

  }

  /** handle save / update / delete */
  handleDeleteModal = (showInvDeleteModal) => {
    const { item } = this.state

    if (item.id && item.is_allow_edit) {
      this.setState({ showInvDeleteModal })
    } else {
      this.setState({ showInvDeleteModal: false })
      warning({
        title: 'Unable to Delete Invoice',
        content: 'The invoice is unable to delete anymore.'
      })
    }
  }

  handleDelete = () => {
    const { onDeleteInvoice } = this
    const { form } = this.props
    const { validateFields } = form
    const { item } = this.state

    if (item.id && item.is_allow_edit) {
      validateFields(['delete_reason_type', 'delete_reason_note'], async (errors, values) => {
        if (!errors) {
          confirm({
            title: 'Proceed to delete invoice?',
            content: (
              <div style={{color: 'rgb(238, 27, 27)'}}>
                <p>About to delete this invoice. Are you sure?</p>
                <p>This action is irreversible.</p>
              </div>
            ),
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk () {
              // eslint-disable-next-line no-lone-blocks
              onDeleteInvoice(values, item)
            },
            onCancel () {
            }
          })
        }
      })
    } else {
      warning({
        title: 'Unable to Delete Invoice',
        content: 'The invoice is unable to delete anymore.'
      })
    }
  }

  onDeleteInvoice = async (values, item) => {
    this.setState({ loading: true })
    const isSdbInvoice = !!item.is_sdb_invoice

    try {
      const r = await invoiceService.remove(item.id)

      if (r && r.id) {
        let logText = 'Invoice is deleted. '
        logText += `Delete Reason Type: ${values['delete_reason_type']}, Notes: ${values['delete_reason_note']}`
        console.log('delete invoice 2', logText)
        notify.success('Deleted successfully', 'Invoice deleted successfully.')
        log.deleteInvoice(item.id, logText)

        window.location.replace(`/invoices-list${isSdbInvoice ? '/std' : '/pm'}`)
        fetchingInvoices(true)

        this.handleDeleteModal(false)
      } else {
        notify.error('Unable to delete successfully', 'Unable to delete invoice successfully. Please try again later.')
      }
    } catch (e) {
      console.log('delete invoice error', e)
      notify.error('Unable to delete successfully', 'Unable to delete invoice successfully. Please try again later.')
    }

    this.setState({ loading: false })
  }

  handleEditButton = () => {
    this.setState({showEdit: false, showSave: true})
  }

  validateClientCredit = () => {
    const { clientCredit, clientBudget, invoiceItems } = this.state

    let newCurrentCredit = []
    for (let i = 0; i < invoiceItems.length; i++) {
      const { cats: categories = [], inv_date: invDate, cat_id: catId} = invoiceItems[i]

      if (invDate && catId) {
        const mInvDate = moment.isMoment(invDate) ? invDate.clone().startOf('day') : moment(invDate).startOf('day')
        const activeBudget = clientBudget.find(e => {
          const startDate = moment(e.period_start_date).startOf('day')
          const endDate = moment(e.period_end_date).endOf('day')
          if (startDate.isBefore(mInvDate) && endDate.isAfter(mInvDate)) {
            return true
          }
          return false
        })
        const catt = categories.find(e => e.cat_id === catId)

        if (activeBudget && catt) {
          const c = clientCredit.filter(e => e.budget_id === activeBudget.budget_id && e.report_group_id === catt.report_group_id)

          if (validator.isNotEmptyArray(c)) {
            // check repeat entry for newCurrentCredit
            for (let j = 0; j < c.length; j++) {
              const ca = c[j]
              // different with filter from client credit with report_group_id, at here need to find the repeated category id in order to correctly include all categories credits being included
              const cb = newCurrentCredit.find(e => ca.budget_id === e.budget_id && ca.cat_id === e.cat_id)
              if (!cb) {
                newCurrentCredit.push(ca)
              }
            }
          }
        }
      }
    }

    this.setState({ clientCurrentCredit: newCurrentCredit })
  }

  validateABN = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback(new Error('Please enter ABN'))
    } else if (!validator.isDigit(value) || value.length !== 11) {
      callback(new Error('ABN is invalid in format'))
    } else {
      callback()
    }
  }

  validateInvoiceNumber = (e) => {
    e.persist()
    const id = this.isEdit() ? this.getId() : null

    const value = e.target.value ? e.target.value.trim() : e.target.value
    this.debounceValidateInvoiceNumber(id, value)
  }

  validateInvoiceNumberEntry = (rule, value, callback) => {
    const { isInvNoDuplicated } = this.state
    if (isInvNoDuplicated) {
      callback(new Error(InvDuplicatedMsg))
    } else {
      callback()
    }
  }

  debounceValidateInvoiceNumber = async (id, value) => {
    const { form } = this.props
    const { providerInfo } = this.state
    const data = {
      id,
      invoice_number: value
    }

    if (providerInfo && providerInfo.id) {
      data.provider_id = providerInfo.id
    }

    if (value !== '') {
      // set the field value first and disabled all duplicated flags to temporary remove error alert
      form.setFields({ invoice_number: { value: value } })
      this.setState({ loadingCheckInvoiceNo: true, isInvNoDuplicated: false })

      const r = await commonService.checkDuplicate('invoice', data)

      if (r && r.errors) {
        // if error (duplicate detected), flag is true and set error message on input. meanwhile, the validate function will set the input as errorneous input
        this.setState({ loadingCheckInvoiceNo: false, isInvNoDuplicated: true })
        const w = window

        confirm({
          title: 'Possible Duplicated Invoice',
          content: (
            <div style={{color: 'rgb(238, 27, 27)'}}>
              <p>There is an invoice which has the same invoice number with entered value.</p>
              <p>Press "Go to" button to check the duplicated invoice or press "OK" to edit.</p>
            </div>
          ),
          okText: 'Go to Duplicated Invoice Page',
          cancelText: 'OK',
          onOk () {
            w.open(`/invoices/${r.ref_id}/info`)
          },
          onCancel () {
          }
        })

        // set the field value with errors
        form.setFields({ invoice_number: { value: value, errors: [{ message: InvDuplicatedMsg }] } })
      } else {
        this.setState({ loadingCheckInvoiceNo: false, isInvNoDuplicated: false })
      }
    }
  }

  validateTargetedInvAmount = (rule, value, callback) => {
    const { form } = this.props
    const targetedSubtotal = form.getFieldValue('targeted_subtotal')
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Expected Invoice Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Expected Invoice Amount is not number or decimal format`))
      } else {
        callback()
      }
    }
  }

  onTargetedInvAmountChange= (e) => {
    const value = e.target.value
    const { invoiceItems, item } = this.state
    if (invoiceItems.length === 0) return

    if (formatter.toPriceFloat(value) !== formatter.toPriceFloat(item.subtotal)) {
      this.setState({invSubtotalMsg: TotalAmountMismatchMsg})
    } else {
      this.setState({invSubtotalMsg: ''})
    }
  }

  handleSave = async() => {
    const { onSave } = this
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { clientInfo, providerInfo, item, invoiceItems, loading, loadingCheckInvoiceNo, statusList } = this.state

    let clientMsg = ''
    let providerMsg = ''
    let isPass = true

    if (loading || loadingCheckInvoiceNo) return

    const isItemDisabled = statusList.find(e => {
      if (e.reference >= '004') {
        return item.status === e.value
      }

      return false
    })

    const isProcessing = statusList.find(e => {
      if (e.reference === '001') {
        return item.status === e.value
      }

      return false
    })

    if (!clientInfo.id) {
      clientMsg = SelectClientMsg
      isPass = false
    }

    if (!providerInfo.id) {
      providerMsg = SelectBillerMsg
      isPass = false
    }

    const id = this.getId()

    validateFieldsAndScroll(async (errors, values) => {
      if(!errors) {
        if (!isPass) {
          this.setState({ clientMsg, providerMsg })
          return
        }

        if (isItemDisabled) {
          this.onSave(values)
        } else {
          /**
           * condition to show modal
           * 1. expected inv amt > subtotal
           * 2. any of invoice item max rate is lesser than invoice amount
           * 3. no auth amount > subtotal
           */
          let section = {
            isPrivateAlert: false,
            isPrivateAlertData: {},
            isInvRateMaxRate: false,
            isInvRateMaxRateData: [],
            isInvSubtotal: false,
            isInvSubtotalData: {},
            isAutoAuth: false,
            isAutoAuthData: {},
            isInvItemOverBudget: false,
            isInvItemOverBudgetData: []
          }
          let isTriggerConfirm = false

          if (clientInfo && clientInfo.private_alert) {
            isTriggerConfirm = true
            section.isPrivateAlert = true
            section.isPrivateAlertData = {
              client_first_name: clientInfo.first_name,
              client_last_name: clientInfo.last_name,
              private_alert: clientInfo.private_alert
            }
          }

          if (clientInfo.pm_is_auth_req === false) {
            if (parseFloat(item.subtotal) < parseFloat(clientInfo.pm_auth_amount)) {
              isTriggerConfirm = true
              section.isAutoAuth = true
              section.isAutoAuthData = {
                subtotal: formatter.toPriceFloat(item.subtotal),
                auth_amount: formatter.toPriceFloat(clientInfo.pm_auth_amount),
                client_first_name: clientInfo.first_name,
                client_last_name: clientInfo.last_name
              }
            }
          }

          if (parseFloat(values.targeted_subtotal) !== parseFloat(item.subtotal)) {
            isTriggerConfirm = true
            section.isInvSubtotal = true
            section.isInvSubtotalData = {
              subtotal: formatter.toPriceFloat(item.subtotal),
              targeted_subtotal: formatter.toPriceFloat(values.targeted_subtotal),
            }
          }

          if (validator.isNotEmptyArray(invoiceItems)) {
            for (let i = 0; i < invoiceItems.length; i++) {
              const invItem = invoiceItems[i]
              const maxRate = (invItem.max_rate === null || invItem.max_rate === undefined) ? 0 : parseFloat(invItem.max_rate)
              const invRate = values[`inv_rate${i}`]

              const { cats = [], catItems = [], subtotal } = invItem
              const catId = values[`cat_id${i}`] || invItem.cat_id
              const cat = cats.find(e => e.cat_id === catId)
              const catItemId = values[`cat_item_id${i}`] || invItem.cat_item_id
              const catItem = catItems.find(e => e.cat_item_id === catItemId)

              if (maxRate !== null && parseFloat(maxRate) > 0 && parseFloat(maxRate) < parseFloat(invRate)) {
                isTriggerConfirm = true
                section.isInvRateMaxRate = true
                section.isInvRateMaxRateData.push({
                  inv_date: values[`inv_date${i}`] || invItem.inv_date,
                  cat_name: cat ? cat.cat_name : invItem.cat_name,
                  cat_identifier: cat ? cat.cat_identifier : invItem.cat_identifier,
                  cat_item_name: catItem ? catItem.cat_item_name : invItem.cat_item_name,
                  cat_item_identifier: catItem ? catItem.cat_item_identifier : invItem.cat_item_identifier,
                  max_rate: formatter.toPriceFloat(maxRate),
                  inv_rate: formatter.toPriceFloat(invRate),
                })
              }

              const budgetOver = this.onInvItemValidateBudget(invItem, true)

              if (budgetOver) {
                isTriggerConfirm = true
                section.isInvItemOverBudget = true
                section.isInvItemOverBudgetData.push({
                  ...budgetOver,
                  cat_name: cat ? cat.cat_name : invItem.cat_name,
                  cat_identifier: cat ? cat.cat_identifier : invItem.cat_identifier,
                  cat_item_name: catItem ? catItem.cat_item_name : invItem.cat_item_name,
                  cat_item_identifier: catItem ? catItem.cat_item_identifier : invItem.cat_item_identifier,
                  inv_date: values[`inv_date${i}`] || invItem.inv_date,
                  subtotal: formatter.toPriceFloat(subtotal)
                })
              }
            }
          }

          if (isTriggerConfirm) {
            confirm({
              title: `Proceed To ${this.isEdit() ? 'Update' : 'Create'} Invoice?`,
              content: (
                <div>
                  <p>Check the following issues before proceed.</p>
                  <ul>
                    { section.isPrivateAlert
                      ? <li>
                        <p style={{color: '#D66E00', fontWeight: '700', marginBottom: '5px'}}>Participant: {section.isPrivateAlertData.client_first_name} {section.isPrivateAlertData.client_last_name}</p>
                        <p style={{color: '#D66E00'}}>{section.isPrivateAlertData.private_alert}</p>
                      </li>
                      : null }
                    { section.isInvItemOverBudget
                      ? <li>
                         <p>The following invoice item{section.isInvRateMaxRateData.length === 1 ? '' : 's'} has exceeded the budget of service booking:</p>
                         <ol>
                           { section.isInvItemOverBudgetData.map((e, idx) => (
                             <li key={`bdgts${idx}`}>
                               <p style={{color: '#ee1b1b', fontWeight: '800'}}>
                                {e.cat_item_name} ({e.cat_item_identifier}):
                               </p>
                               <p style={{color: '#ee1b1b', fontWeight: '800'}}>
                                Remaining value from budget "{e.booking_number}": {formatter.toPrice(e.remaining_value)}, Invoiced Amount: {formatter.toPrice(e.subtotal)}
                               </p>
                             </li>
                           ))}
                         </ol>
                      </li>
                      : null }
                    { section.isAutoAuth && !isProcessing
                      ? <li>
                        <p style={{color: '#D66E00'}}>This invoice will be automatically authorised due to the subtotal of invoice (${section.isAutoAuthData.subtotal}) is lower than participant <strong>{section.isAutoAuthData.client_first_name} {section.isAutoAuthData.client_last_name}</strong>'s maximum Authorisation Amount ({formatter.toPrice(section.isAutoAuthData.auth_amount)})</p>
                      </li>
                      : null }

                    { section.isInvSubtotal
                      ? <li>
                        <p style={{color: '#ee1b1b'}}>The Expected Invoiced Amount ({formatter.toPrice(section.isInvSubtotalData.targeted_subtotal)}) is different with Invoice Subtotal ({formatter.toPrice(section.isInvSubtotalData.subtotal)})</p>
                      </li>
                      : null }

                    { section.isInvRateMaxRate
                      ? <li>
                        <p>The following invoice item{section.isInvRateMaxRateData.length === 1 ? '' : 's'} has higher invoice rate than support item max rate:</p>
                        <ol>
                          { section.isInvRateMaxRateData.map((e, idx) => (
                            <li key={`mxrt${idx}`}>
                              <p>{formatter.toShortDate(e.inv_date)}</p>
                              <p style={{fontWeight: '800'}}>{e.cat_item_name} ({e.cat_item_identifier}): </p>
                              <p><span style={{color: '#ee1b1b'}}>${e.inv_rate}</span><span> (max rate: {formatter.toPrice(e.max_rate)})</span></p>
                            </li>
                          ))}
                        </ol>
                      </li>
                      : null }
                  </ul>
                  { section.isInvItemOverBudget
                    ? <p>Please resolve the issue(s) before save.</p>
                    : <p>Confirm your action to proceed.</p> }
                </div>
              ),
              okText: 'Confirm',
              cancelText: 'Cancel',
              onOk () {
                // eslint-disable-next-line no-lone-blocks
                if (!section.isInvItemOverBudget) {
                  onSave(values, section)
                }
              },
              onCancel () {
              }
            })
          } else {
            this.onSave(values)
          }
        }
      }
    })
  }

  onSave = async (values, extra) => {
    const { clientInfo, providerInfo, fileList, item, itemOri, invoiceItems, invoiceItemsOri, loading, statusList } = this.state
    const id = this.getId()

    let invoiceData = {}
    let invoiceItemData = []

    if (loading) return

    this.setState({ loading: true })

    const isItemDisabled = statusList.find(e => {
      if (e.reference >= '004') {
        return item.status === e.value
      }

      return false
    })

    if (this.isEdit()) {
      // if invoice is disabled to edit, only comment is allowed to be updated
      invoiceData = isItemDisabled
      ? {
        invoice_comment: values['invoice_comment'] === undefined ? item.invoice_comment : values['invoice_comment'] === '' ? null : values['invoice_comment'],
        invoice_private_comment: values['invoice_private_comment'] === undefined ? item.invoice_private_comment : values['invoice_private_comment'] === '' ? null : values['invoice_private_comment'],
        invoice_number: values['invoice_number'] === undefined ? item.invoice_number : values['invoice_number'] === '' ? null : values['invoice_number'],
        invoice_abn: values['invoice_abn'] === undefined ? item.invoice_abn : values['invoice_abn'] === '' ? null : values['invoice_abn'],
        is_invoice_abn_exempted: values['is_invoice_abn_exempted'] === undefined ? item.is_invoice_abn_exempted : values['is_invoice_abn_exempted'],
      }
      : {
        invoice_date: values['invoice_date'] || item.invoice_date,
        invoice_number: values['invoice_number'] || item.invoice_number,
        invoice_comment: values['invoice_comment'] === undefined ? item.invoice_comment : values['invoice_comment'] === '' ? null : values['invoice_comment'],
        invoice_private_comment: values['invoice_private_comment'] === undefined ? item.invoice_private_comment : values['invoice_private_comment'] === '' ? null : values['invoice_private_comment'],
        targeted_subtotal: values['targeted_subtotal'] === undefined ? item.targeted_subtotal : values['targeted_subtotal'],
        is_allow_targeted_override: extra && extra.isInvSubtotal === true,
        is_invoice_required_abn: values['invoice_abn'] !== null && values['invoice_abn'] !== undefined,
        invoice_abn: values['invoice_abn'] === undefined ? item.invoice_abn : values['invoice_abn'] === '' ? null : values['invoice_abn'],
        is_invoice_abn_exempted: values['is_invoice_abn_exempted'] === undefined ? item.is_invoice_abn_exempted : values['is_invoice_abn_exempted'],
      }

      for (let i = 0; i < invoiceItems.length; i++) {
        const invItem = invoiceItems[i]
        const { cats = [], catItems = [] } = invItem
        const catId = values[`cat_id${i}`] || invItem.cat_id
        const cat = cats.find(e => e.cat_id === catId)
        const catItemId = values[`cat_item_id${i}`] || invItem.cat_item_id
        const catItem = catItems.find(e => e.cat_item_id === catItemId)
        let dat = isItemDisabled
        ? {
          id: invItem.id,
          comment: values[`comment${i}`] === undefined ? invItem.comment : values[`comment${i}`] === '' ? null : values[`comment${i}`],
          is_closed: values[`is_closed${i}`] || invItem.is_closed
        }
        : {
          id: invItem.id,
          inv_date: values[`inv_date${i}`] || invItem.inv_date,
          inv_end_date: values[`inv_end_date${i}`] || invItem.inv_end_date,
          booking_number: invItem.booking_number ? invItem.booking_number : null,
          budget_id: invItem.budget_id ? invItem.budget_id : invItem.budget_id,
          cat_id: catId,
          cat_name: cat ? cat.cat_name : invItem.cat_name,
          cat_identifier: cat ? cat.cat_identifier : invItem.cat_identifier,
          cat_item_id: catItemId,
          cat_item_name: catItem ? catItem.cat_item_name : invItem.cat_item_name,
          cat_item_identifier: catItem ? catItem.cat_item_identifier : invItem.cat_item_identifier,
          rate_set_id: catItem ? catItem.rate_set_id : invItem.rate_set_id,
          max_rate: invItem.max_rate,
          unit: values[`unit${i}`] || invItem.unit,
          inv_rate: values[`inv_rate${i}`] || invItem.inv_rate,
          comment: values[`comment${i}`] === undefined ? invItem.comment : values[`comment${i}`] === '' ? null : values[`comment${i}`],
          is_delete: invItem.is_delete || false
        }
        invoiceItemData.push(dat)
      }
    } else {
      invoiceData = {
        client_id: clientInfo.id,
        provider_id: providerInfo.id,
        invoice_date: values['invoice_date'],
        invoice_number: values['invoice_number'],
        invoice_comment: values['invoice_comment'] || null,
        invoice_private_comment: values['invoice_private_comment'] || null,
        targeted_subtotal: values['targeted_subtotal'] || item.targeted_subtotal,
        is_allow_targeted_override: extra && extra.isInvSubtotal === true,
        is_invoice_required_abn: values['invoice_abn'] !== null && values['invoice_abn'] !== undefined,
        invoice_abn: values['invoice_abn'],
        is_invoice_abn_exempted: values['is_invoice_abn_exempted'] || false
      }

      for (let i = 0; i < invoiceItems.length; i++) {
        const invItem = invoiceItems[i]
        const { cats = [], catItems = [] } = invItem
        const catId = values[`cat_id${i}`]
        const cat = cats.find(e => e.cat_id === catId)
        const catItemId = values[`cat_item_id${i}`]
        const catItem = catItems.find(e => e.cat_item_id === catItemId)

        let dat = {
          inv_date: values[`inv_date${i}`],
          inv_end_date: values[`inv_end_date${i}`],
          booking_number: invItem.booking_number ? invItem.booking_number : null,
          budget_id: invItem.budget_id ? invItem.budget_id : null,
          cat_id: catId,
          cat_name: cat ? cat.cat_name : null,
          cat_identifier: cat ? cat.cat_identifier : null,
          cat_item_id: catItemId,
          cat_item_name: catItem ? catItem.cat_item_name : null,
          cat_item_identifier: catItem ? catItem.cat_item_identifier : null,
          rate_set_id: catItem ? catItem.rate_set_id : null,
          max_rate: invItem.max_rate,
          unit: values[`unit${i}`],
          inv_rate: values[`inv_rate${i}`],
          comment: values[`comment${i}`] || null
        }
        invoiceItemData.push(dat)
      }
    }

    invoiceData.items = invoiceItemData

    /**
     * client_id: 1
invoice_comment: null
invoice_date: Moment {_isAMomentObject: true, _isUTC: false, _pf: {…}, _locale: Locale, _d: Sat Mar 20 2021 22:00:00 GMT+0800 (Malaysia Time), …}
invoice_number: "333222111"
is_allow_targeted_override: undefined
items: (2) [{
    budget_id: 2
    cat_id: 1
    cat_identifier: "1"
    cat_item_id: 1
    cat_item_identifier: "01_002_0107_1_1"
    cat_item_name: "Assistance With Self-Care Activities - Standard - Weekday Night"
    cat_name: "Assistance with daily life"
    comment: null
    inv_date: Moment {_isAMomentObject: true, _isUTC: false, _pf: {…}, _locale: Locale, _d: Sat Mar 20 2021 22:00:00 GMT+0800 (Malaysia Time), …}
    inv_rate: 62.17
    max_rate: 62.17
    rate_set_id: 1
    unit: 1
  },
  {
    budget_id: 2
    cat_id: 3
    cat_identifier: "3"
    cat_item_id: 163
    cat_item_identifier: "03_040000111_0103_1_1"
    cat_item_name: "Disability-Related Health Consumables - High Cost"
    cat_name: "Consumables"
    comment: null
    inv_date: Moment {_isAMomentObject: true, _isUTC: false, _pf: {…}, _locale: Locale, _d: Fri Mar 19 2021 22:00:00 GMT+0800 (Malaysia Time), …}
    inv_rate: "15.4"
    max_rate: 0
    rate_set_id: 1
    unit: 1
  }
]
provider_id: 50
targeted_subtotal: "77.57"
     */

    let r = null
    if (this.isEdit()) {
      r = await invoiceService.save(id, invoiceData)
    } else {
      r = await invoiceService.add(invoiceData)
    }

    if (r && r.id) {
      if (!this.isEdit()) {
        // ------ add log message start --------
        let logText = `New PM Invoice: client: ${clientInfo.first_name} ${clientInfo.last_name}; provider: ${providerInfo.fullname}; invoiced date: ${formatter.toDate(invoiceData.invoice_date, dateFormat)}; invoice number: ${invoiceData.invoice_number}; expected amount: ${invoiceData.targeted_subtotal}, ${invoiceData.is_invoice_required_abn ? `Invoice ABN: ${invoiceData.invoice_abn}` : ''}\nitems:\n`

        let itemLogText = ''
        for (let i = 0; i < invoiceData.items.length; i++) {
          const itm = invoiceData.items[i]
          itemLogText = itemLogText + `${i + 1}. ${itm.cat_name} - ${itm.cat_item_name}${itm.booking_number ? ` (${itm.booking_number})` : ''}, serviced started from ${formatter.toDate(itm.inv_date, dateFormat)} and ended on ${formatter.toDate(itm.inv_end_date, dateFormat)}, max rate: ${!itm.max_rate ? 'No limit': itm.max_rate}, unit: ${itm.unit}, invoiced rate: ${itm.inv_rate}, comment: ${itm.comment}; \n`
        }

        logText += itemLogText || 'No invoice items added.'
        await log.addInvoice(r.id, logText)
        // ------ add log message end --------

        // ------ append file start -------
        if (validator.isNotEmptyArray(fileList)) {
          await this.uploadFiles(invoiceData, r)
        }
        // ------ append file end -------

        // ------ check inv authorisaton start ------
        item.id = r.id
        const isAuthorisation = await this.validateAuthorisedPay(clientInfo, item, fileList)
        // ------ check inv authorisaton end ------

        notify.success('Saved successfully', 'Invoice saved successfully.')

        const redirect = () => {
          window.location.replace(`/invoices/${r.ref_id}/info`)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }

        if (isAuthorisation > 0) {
          /**TODO: prod handling */
          // this.showAuthorisedAlertOri(clientInfo, r.id, () => {
          //   redirect()
          // })
          if (isAuthorisation === AUTH_PAY_UPDATED_DETAILS) {
            this.showAuthorisedUpdateAlert(() => {
              redirect()
            })
          } else if (isAuthorisation === AUTH_PAY_REMINDER_REQUIRED) {
            this.showAuthorisedAlert(clientInfo, r.id, () => {
              redirect()
            }, true)
          } else if (isAuthorisation === AUTH_PAY_REMINDER_ALERT) {
            this.showAuthorisedReminder(clientInfo, () => {
              redirect()
            })
          } else if (isAuthorisation === AUTH_PAY_NO_EMAIL_CONFIGURED) {
            this.showAuthorisedEmailReminder(clientInfo, () => {
              redirect()
            })
          } else {
            redirect()
          }
        } else {
          redirect()
        }

      } else {
        // ------ update log message start --------
        let logText = ''
        let itemLogText = ''
        for (let i = 0; i < invoiceData.items.length; i++) {
          const itm = invoiceData.items[i]
          const prevItm = invoiceItemsOri.find(e => e.id === itm.id) || {}

          if (itm.is_delete) {
            itemLogText += `item ${i+1}: deleted`
          } else {
            const prevItem = isItemDisabled
              ? {
                comment: prevItm.comment,
                is_closed: prevItm.is_closed || false
              }
              : {
                booking_number: prevItm.booking_number,
                category: prevItm.cat_name,
                category_item: prevItm.cat_item_name,
                category_item_identifier: prevItm.cat_item_identifier,
                comment: prevItm.comment,
                serviced_start_date: formatter.toDate(prevItm.inv_date, dateFormat),
                serviced_end_date: formatter.toDate(prevItm.inv_end_date, dateFormat),
                max_rate: !prevItm.max_rate ? 'No Limit' : prevItm.max_rate,
                unit: prevItm.unit,
                invoiced_rate: prevItm.inv_rate,
                is_closed: prevItm.is_closed || false
              }
            const currentitem = isItemDisabled
            ? {
              comment: itm.comment,
              is_closed: itm.is_closed || false
            }
            : {
              booking_number: itm.booking_number,
              category: itm.cat_name,
              category_item: itm.cat_item_name,
              category_item_identifier: itm.cat_item_identifier,
              comment: itm.comment,
              serviced_start_date: formatter.toDate(itm.inv_date, dateFormat),
              serviced_end_date: formatter.toDate(itm.inv_end_date, dateFormat),
              max_rate: !itm.max_rate ? 'No Limit' : itm.max_rate,
              unit: itm.unit,
              invoiced_rate: itm.inv_rate,
              is_closed: itm.is_closed || false
            }

            let changeText = log.generateItemChanges(
              prevItem,
              currentitem,
              [
                'booking_number',
                'category'
              ],
              [
                { key: 'comment', label: 'Item Notes' }
              ]
            )

            if (prevItem.booking_number !== currentitem.booking_number) {
              changeText += `Support Category from ${prevItem.category}${prevItem.booking_number ? ` (${prevItem.booking_number})` : ''} to ${currentitem.category}${currentitem.booking_number ? ` (${currentitem.booking_number})` : ''}`
            } else if (prevItem.category !== currentitem.category) {
              changeText += `Support Category from ${prevItem.category} to ${currentitem.category}`
            }

            if (changeText) {
              itemLogText += `item ${i+1}: ${changeText}`
            }
          }
        }

        const prevInvoice = {
          invoiced_date: formatter.toDate(itemOri.invoice_date, dateFormat),
          invoice_number: itemOri.invoice_number,
          expected_amount: itemOri.targeted_subtotal,
          invoice_comment: itemOri.invoice_comment,
          invoice_private_comment: itemOri.invoice_private_comment,
          invoice_abn: itemOri.invoice_abn,
          is_invoice_abn_exempted: itemOri.is_invoice_abn_exempted || false,
        }

        const currInvoice = {
          invoiced_date: formatter.toDate(values.invoice_date, dateFormat),
          invoice_number: values.invoice_number,
          expected_amount: values.targeted_subtotal,
          invoice_comment: values.invoice_comment,
          invoice_private_comment: values.invoice_private_comment,
          invoice_abn: values.invoice_abn,
          is_invoice_abn_exempted: values.is_invoice_abn_exempted || false
        }

        log.updateInvoice(
          r.id,
          prevInvoice,
          currInvoice,
          undefined,
          [
            { key: 'invoice_abn', label: 'Invoice ABN' },
            { key: 'is_invoice_abn_exempted', label: 'ATO Excluded Supply' },
            { key: 'invoice_comment', label: 'Remittance Comment' },
            { key: 'invoice_private_comment', label: 'Private Notes' },
          ],
          itemLogText ? `items:\n${itemLogText}` : '')

        // ------ check inv authorisaton ------
        if (!isItemDisabled) {
          /**TODO: prod handling */
          const itm = this.state.item
          const isAuthorised = await this.validateAuthorisedPay(clientInfo, itm, itm.file_list)

          if (isAuthorised > 0) {
            // this.showAuthorisedAlertOri(clientInfo)
            if (isAuthorised === AUTH_PAY_UPDATED_DETAILS) {
              this.showAuthorisedUpdateAlert()
            } else if (isAuthorised === AUTH_PAY_REMINDER_REQUIRED) {
              this.showAuthorisedAlert(clientInfo, r.id, () => {}, true)
            } else if (isAuthorised === AUTH_PAY_REMINDER_ALERT) {
              this.showAuthorisedReminder(clientInfo)
            } else if (isAuthorised === AUTH_PAY_NO_EMAIL_CONFIGURED) {
              this.showAuthorisedEmailReminder(clientInfo)
            }
          }
        }

        // ------ update log message end --------
        await this.fetchInvoice()
        notify.success('Saved successfully', 'Invoice saved successfully.')
      }
    } else {
      if (r) {
        notify.error('Unable to save successfully', formatter.toErrorMessage(r))
      } else {
        notify.error('Unable to save successfully', 'Unable to save invoice successfully. Please try again later.')
      }
    }

    this.setState({ loading: false })
    // console.log('invoice item data', invoiceData, r)
  }

  onCreateComm = async (invId, action) => {
    const body = {
      genre: 'invoice',
      genre_id: invId,
      action
    }
    const r = await commService.create(CommType.PM_COMM_TYPE_INV_AUTH, body)

    if (r && r.id) {
      this.fetchInvoice()
    }

    return r
  }

  /** start invoice items handling */
  validateInvoiceStartDate = (rule, value, callback) => {
    const { form } = this.props
    const invoiceDate = form.getFieldValue('invoice_date')

    const field = rule.field
    const index = !field
        ? null
        : field.indexOf('inv_date') > -1
        ? field.substring('inv_date'.length)
        : null
    const anotherField = `inv_end_date${index || ''}`
    const invoiceEndDate = form.getFieldValue(anotherField)

    if (value === null) {
      callback(new Error('Please select service start date'))
    } else if (invoiceEndDate === null) {
      callback(new Error('Please select service end date'))
    } else {
      const mStartDate = moment.isMoment(value) ? value.clone().startOf('date') : moment(value).startOf('date')
      const mEndDate = moment.isMoment(invoiceEndDate) ? invoiceEndDate.clone().endOf('date') : moment(invoiceEndDate).endOf('date')
      const mInvDate = moment.isMoment(invoiceDate) ? invoiceDate.clone().startOf('date') : moment(invoiceDate).startOf('date')

      if (mStartDate.isAfter(mInvDate, 'date')) {
        callback(new Error('Service Start Date must be before/on the Invoice Date'))
      } else if (mStartDate.isAfter(mEndDate, 'date')) {
        callback(new Error('Service Start Date must be before Service End Date'))
      } else {
        callback()
      }
    }

    form.validateFields([`inv_end_date${index}`])
  }

  validateInvoiceEndDate = (rule, value, callback) => {
    const { form } = this.props
    const invoiceDate = form.getFieldValue('invoice_date')

    const field = rule.field
    const index = !field
        ? null
        : field.indexOf('inv_end_date') > -1
        ? field.substring('inv_end_date'.length)
        : null
    const anotherField = `inv_date${index || ''}`
    const invoiceStartDate = form.getFieldValue(anotherField)

    if (value === null) {
      callback(new Error('Please select service end date'))
    } else if (invoiceStartDate === null) {
      callback(new Error('Please select service start date'))
    } else {
      const mEndDate = moment.isMoment(value) ? value.clone().endOf('date') : moment(value).endOf('date')
      const mStartDate = moment.isMoment(invoiceStartDate) ? invoiceStartDate.clone().startOf('date') : moment(invoiceStartDate).startOf('date')
      const mInvDate = moment.isMoment(invoiceDate) ? invoiceDate.clone().startOf('date') : moment(invoiceDate).startOf('date')

      if (mEndDate.isAfter(mInvDate, 'date')) {
        callback(new Error('Service End Date must be before/on the Invoice Date'))
      } else if (mStartDate.isAfter(mEndDate, 'date')) {
        callback(new Error('Service Start Date must be before Service End Date'))
      } else {
        callback()
      }
    }

    form.validateFields([`inv_date${index}`])
  }

  validateUnitChange = (rule, value, callback) => {
    if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error(`Unit is required`))
    } else {
      const v = validator.isNumberText(value)
      if (!v) {
        callback(new Error(`Unit is not number or decimal format`))
      } else {
        callback()
      }
    }
  }

  validateInvoicedRateChange = (rule, value, callback, maxRate) => {
    const pValue = parseFloat(value)
    if (value === null || value === undefined || value === '' || pValue === 0) {
      callback(new Error(`Invoiced Rate is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Invoiced Rate is not number or decimal format`))
      } else {
        // if (maxRate !== 0 && pValue > maxRate) {
        //   callback(new Error(MaxRateMismatchMsg))
        // } else {
        //   callback()
        // }
        callback()
      }
    }
  }

  onUpdateCurrentClientBudgetList = () => {
    const { clientBudget, invoiceItems } = this.state

    let currentList = []
    for (let i = 0; i < invoiceItems.length; i++) {
      const invItem = invoiceItems[i]

      if (invItem.inv_end_date || invItem.inv_date) {
        const date = invItem.inv_end_date
          ? (moment.isMoment(invItem.inv_end_date) ? invItem.inv_end_date.clone() : moment(invItem.inv_end_date))
          : invItem.inv_date
            ? (moment.isMoment(invItem.inv_date) ? invItem.inv_date.clone() : moment(invItem.inv_date))
            : null

        if (date) {
          const activeBudgets = clientBudget.filter(e => {
            const startDate = moment(e.period_start_date).startOf('day')
            const endDate = moment(e.period_end_date).endOf('day')
            if (startDate.isSameOrBefore(date) && endDate.isSameOrAfter(date)) {
              return true
            }
            return false
          })

          if (validator.isNotEmptyArray(activeBudgets)) {
            for (let j = 0; j < activeBudgets.length; j++) {
              const activeBudget = activeBudgets[j]

              if (activeBudget && activeBudget.budget_id && currentList.findIndex(e => activeBudget.budget_id === e.budget_id) < 0) {
                activeBudget.is_over_period = formatter.toPeriodStatus(activeBudget.period_end_date).isDue
                currentList.push(activeBudget)
              }
            }
          }
        }
      }
    }

    this.setState({ clientCurrentBudgetList: currentList })
  }

  onAddInvItem = () => {
    const { invoiceItems } = this.state
    invoiceItems.push(Object.assign({}, DefaultInvoiceItem))
    this.setState({invoiceItems})
  }

  onRemoveInvItem = async (index) => {
    const { invoiceItems } = this.state
    // const { form } = this.props
    // form.setFieldsValue({[`unit${index}`]: null, [`item_cat_item_id${index}`]: null})
    let invoiceItem = invoiceItems[index]
    if (invoiceItem.id) {
      invoiceItem.is_delete = true
    } else {
      invoiceItems.splice(index, 1)
    }

    this.setState({invoiceItems}, () => {
      this.onInvItemUpdateInvTotal()
      this.onUpdateCurrentClientBudgetList()
    })
  }

  onUndoRemoveInvItem = (index) => {
    const { invoiceItems } = this.state

    let invoiceItem = invoiceItems[index]
    invoiceItem.is_delete = false

    this.setState({invoiceItems}, () => {
      this.onInvItemUpdateInvTotal()
      this.onUpdateCurrentClientBudgetList()
    })
  }

  onInvItemDateChange = async (date, index, field) => {
    const { form } = this.props
    const { clientBudget, invoiceItems } = this.state

    const invoiceItem = invoiceItems[index]

    const invoiceDate = form.getFieldValue('invoice_date')
    let std = field === 'inv_date' ? date : form.getFieldValue(`inv_date${index}`)
    let etd = field === 'inv_end_date' ? date : form.getFieldValue(`inv_end_date${index}`)

    const resetItem = (invItem) => {
      invItem.budget_id = ''
      invItem.booking_number = null
      invItem.cats = []
      invItem.catItems = []
      invItem.cat_id = ''
      invItem.cat_id_mix = ''
      invItem.cat_item_id = ''

      invItem.max_rate = 0
      invItem.unit = 1
      invItem.inv_rate = 0
      invItem.subtotal = 0

      invItem.is_sb_period = false
      invItem.is_sb_period1 = false
      invItem.is_sb_period2 = false
      invItem.is_sb_periods1 = false
      invItem.is_sb_periods2 = false

      this.onInvItemUpdateInvTotal()
    }

    // reject if any date is empty
    if (!(std && etd)) {
      resetItem(invoiceItem)

      if (!std && etd) {
        std = moment.isMoment(etd) ? etd.clone().startOf('day') : moment(etd).startOf('day')
        form.setFieldsValue({[`inv_date${index}`]: std})
      } else if (!etd && std) {
        etd = moment.isMoment(std) ? std.clone().endOf('day') : moment(std).endOf('day')
        form.setFieldsValue({[`inv_end_date${index}`]: etd})
      } else {
        return
      }
    }

    const mInvDate = moment.isMoment(invoiceDate)
      ? invoiceDate.clone().startOf('date')
      : moment(invoiceDate).startOf('date')

    const mStartDate = (moment.isMoment(std)
      ? std.clone().startOf('date')
      : moment(std).startOf('date'))

    const mEndDate = (moment.isMoment(etd)
      ? etd.clone().endOf('date')
      : moment(etd).endOf('date'))

      // reject the date and clear cat lists if selected cat item start date / end date is invalid
    if (mStartDate.isAfter(mInvDate, 'date') ||
      mEndDate.isAfter(mInvDate, 'date') ||
      mStartDate.isAfter(mEndDate, 'date')) {
        resetItem(invoiceItem)
        return
    }

    // check start date and end date whether fall inside any of active budgets
    // and have to make sure the filtered budgets are identical when compared using start date and end date
    const activeBudgetsStart = clientBudget.filter(e => {
      const startDate = moment(e.period_start_date).startOf('day')
      const endDate = moment(e.period_end_date).endOf('day')
      if (startDate.isSameOrBefore(std, 'date') && endDate.isSameOrAfter(std, 'date')) {
        return true
      }
      return false
    })

    const activeBudgetsEnd = clientBudget.filter(e => {
      const startDate = moment(e.period_start_date).startOf('day')
      const endDate = moment(e.period_end_date).endOf('day')
      if (startDate.isSameOrBefore(etd, 'date') && endDate.isSameOrAfter(etd, 'date')) {
        return true
      }
      return false
    })

    if (activeBudgetsStart.length !== activeBudgetsEnd.length) {
      invoiceItem.is_sb_mismatch = true
      resetItem(invoiceItem)
      return
    } else {
      const startIds = activeBudgetsStart.map(e => e.budget_id)
      const endIds = activeBudgetsEnd.map(e => e.budget_id)

      if (!isEqual(startIds, endIds)) {
        invoiceItem.is_sb_mismatch = true
        resetItem(invoiceItem)
        return
      }
    }

    // const activeBudgets = clientBudget.filter(e => {
    //   const startDate = moment(e.period_start_date).startOf('day')
    //   const endDate = moment(e.period_end_date).endOf('day')
    //   if (startDate.isBefore(date) && endDate.isAfter(date)) {
    //     return true
    //   }
    //   return false
    // })

    // always use end date to detect active budget and append categories, compared with using start date (inv date) previously
    if (validator.isNotEmptyArray(activeBudgetsEnd)) {
      if (activeBudgetsEnd.length === 1) {
        const activeBudget = activeBudgetsEnd[0]
        // handle SB period status and validate expired period
        const periodStatus = formatter.toPeriodStatus(activeBudget.period_end_date)
        invoiceItem.is_sb_period = periodStatus.isDue
        invoiceItem.is_sb_period1 = periodStatus.isDuePeriod1
        invoiceItem.is_sb_period2 = periodStatus.isDuePeriod2

        if (periodStatus.isDuePeriod2) {
          this.triggerSBInvalidWarning()
        } else if (periodStatus.isDuePeriod1) {
          this.triggerSBExpireWarning()
        }

        // if due period 2 is true (SB end date is more than 90 days, stop all further actions to disallow admin to continue)
        invoiceItem.budget_id = periodStatus.isDuePeriod2 ? null : activeBudget.budget_id
        invoiceItem.booking_number = periodStatus.isDuePeriod2 ? null : activeBudget.booking_number
        invoiceItem.cats = periodStatus.isDuePeriod2 ? [] : activeBudget.categories
      } else {
        let categoriesList = []
        invoiceItem.is_sb_periods = false
        invoiceItem.is_sb_periods1 = false
        invoiceItem.is_sb_periods2 = false

        for (let i = 0; i < activeBudgetsEnd.length; i++) {
          const activeBudget = activeBudgetsEnd[i]
          const periodStatus = formatter.toPeriodStatus(activeBudget.period_end_date)

          invoiceItem.is_sb_periods = invoiceItem.is_sb_periods || periodStatus.isDue
          invoiceItem.is_sb_periods1 = invoiceItem.is_sb_periods1 || periodStatus.isDuePeriod1
          invoiceItem.is_sb_periods2 = invoiceItem.is_sb_periods2 || periodStatus.isDuePeriod2

          const categories = activeBudget.categories
          categoriesList = categoriesList.concat(categories)
        }

        if (invoiceItem.is_sb_periods2) {
          this.triggerSBInvalidWarning(true)
        } else if (invoiceItem.is_sb_periods1 ) {
          this.triggerSBExpireWarning(true)
        }

        invoiceItem.cats = categoriesList
        invoiceItem.budget_id = null
        invoiceItem.booking_number = null
      }

      invoiceItem.is_sb_mismatch = false
      invoiceItem.catItems = []
      invoiceItem.cat_id = ''
      invoiceItem.cat_id_mix = ''
      invoiceItem.cat_item_id = ''
    } else {
      invoiceItem.is_sb_period = false
      invoiceItem.is_sb_period1 = false
      invoiceItem.is_sb_period2 = false
      invoiceItem.is_sb_periods = false
      invoiceItem.is_sb_periods1 = false
      invoiceItem.is_sb_periods2 = false
      invoiceItem.is_sb_mismatch = false

      invoiceItem.budget_id = ''
      invoiceItem.booking_number = null
      invoiceItem.cats = []
      invoiceItem.catItems = []
      invoiceItem.cat_id = ''
      invoiceItem.cat_id_mix = ''
      invoiceItem.cat_item_id = ''
    }

    // reset invoice unit/rate/subtotal after select new cat
    invoiceItem.max_rate = 0
    invoiceItem.unit = 1
    invoiceItem.inv_rate = 0
    invoiceItem.subtotal = 0

    // assign value
    if (field === 'inv_date') {
      invoiceItem.inv_date = date
    } else if (field === 'inv_end_date') {
      invoiceItem.inv_end_date = date
    }

    this.onInvItemUpdateInvTotal()
    this.onInvItemValidateBudget(invoiceItem)

    // set client current budget list
    this.onUpdateCurrentClientBudgetList()

    // set form value
    form.setFieldsValue({
      [`cat_id${index}`]: undefined,
      [`cat_id_mix${index}`]: undefined,
      [`cat_item_id${index}`]: undefined,
      [`max_rate${index}`]: 0,
      [`unit${index}`]: 1,
      [`inv_rate${index}`]: 0,
      [`subtotal${index}`]: 0,
    })
  }

  onInvItemSelectCat = async (id, index, option) => {
    /**
     * due to supporting multiple SBs, the selection of categories will heavily based on budget id.
     * cat_id_mix is a set of number formed by `${cat_id}-${budget_id}` to indicate the uniqueness of the option
     * it will be available on invoice loading and category lists from api by default
     * this function required to strip the id and apply with correct cat id by searching the cat_id_mix from cat list, as well as set the correct cat_id for cat item.
     */
    const { form } = this.props
    const { clientInfo, invoiceItems } = this.state
    const invItem = index < invoiceItems.length ? invoiceItems[index] : {}
    const cat = invItem && validator.isNotEmptyArray(invItem.cats)
      ? invItem.cats.find(e => e.cat_id_mix === id)
      : null

    if (cat) {
      const date = form.getFieldValue(`inv_date${index}`)
      const d = moment.isMoment(date) ? date.format(dateFormat2) : date
      const body = {
        date: d,
        cat_id: cat.cat_id
      }

      form.setFieldsValue({
        [`cat_id${index}`]: cat.cat_id,
        [`cat_item_id${index}`]: undefined,
        [`max_rate${index}`]: 0,
        [`unit${index}`]: 1,
        [`inv_rate${index}`]: 0,
        [`subtotal${index}`]: 0,
      })

      invoiceItems[index].budget_id = cat.budget_id
      invoiceItems[index].booking_number = cat.booking_number

      this.setState({ loadingCatItems: true })
      const catItems = await clientInvoiceService.listAllClientCatItems(clientInfo.id, body)

      if (validator.isNotEmptyArray(catItems)) {
        let invoiceItem = invoiceItems[index]
        invoiceItems[index].catItems = catItems
        invoiceItems[index].cat_item_id = ''

        // reset invoice unit/rate/subtotal after select new cat
        invoiceItems[index].max_rate = 0
        invoiceItems[index].unit = 1
        invoiceItems[index].inv_rate = 0
        invoiceItems[index].subtotal = 0

        // assign value
        invoiceItems[index].cat_id = cat.cat_id

        this.onInvItemUpdateInvTotal()
        invoiceItems[index] = this.onInvItemValidateBudget(invoiceItems[index])
      }
      this.validateClientCredit()

      this.setState({ loadingCatItems: false })
    } else {

    }
  }

  onInvItemSelectCatItem = (id, index) => {
    const { invoiceItems } = this.state
    const { form } = this.props

    const invoiceItem = invoiceItems[index]
    const catItem = invoiceItem.catItems.find(e => e.cat_item_id === id)
    // console.log('cat item', invoiceItem, id, catItem)

    if (catItem) {
      const maxRate = (catItem.max_rate === null || catItem.max_rate === undefined) ? 0 : parseFloat(catItem.max_rate)
      const invRate = maxRate === 0 ? 0 : maxRate
      const subt = maxRate === 0 ? 0 : 1 * maxRate
      invoiceItem.max_rate = maxRate
      invoiceItem.unit = 1
      invoiceItem.inv_rate = invRate
      invoiceItem.subtotal = subt

      // assign value
      invoiceItems[index].cat_item_id = id

      form.setFieldsValue({
        [`max_rate${index}`]: maxRate,
        [`unit${index}`]: 1,
        [`inv_rate${index}`]: invRate,
        [`subtotal${index}`]: subt,
      })

      this.onInvItemUpdateInvTotal()
      this.onInvItemValidateBudget(invoiceItem)
    }
  }

  onInvItemUnitChange = (e, index) => {
    const value = e.target.value

    if (validator.isNumberText(value)) {
      this.onInvItemUpdateInvAmount(value, null, index)
    }

    const { invoiceItems } = this.state
    const invoiceItem = invoiceItems[index]
    this.onInvItemValidateBudget(invoiceItem)
  }

  onInvItemInvRateChange = (e, index) => {
    const value = e.target.value

    if (validator.isNumberText(value)) {
      this.onInvItemUpdateInvAmount(null, value, index)
    }

    const { invoiceItems } = this.state
    const invoiceItem = invoiceItems[index]
    this.onInvItemValidateBudget(invoiceItem)
  }

  onInvItemUpdateInvAmount = (unit = null, rate = null, index) => {
    const { form } = this.props
    const { invoiceItems, item } = this.state
    const invoiceItem = invoiceItems[index]

    const pUnit = parseFloat(unit === null ? form.getFieldValue(`unit${index}`) : unit)
    const pRate = parseFloat(rate === null ? form.getFieldValue(`inv_rate${index}`) : rate)

    const pAmount = pUnit * pRate
    invoiceItem.unit = pUnit
    invoiceItem.inv_rate = pRate
    const subtotal = formatter.toPriceFloat(pAmount)
    invoiceItem.subtotal = subtotal

    this.onInvItemUpdateInvTotal()
    this.onInvItemValidateBudget(invoiceItem)
  }

  onInvItemUpdateInvTotal = () => {
    const { form } = this.props
    const { invoiceItems = [], item } = this.state

    const targetedInvAmount = form.getFieldValue('targeted_subtotal') || item.targeted_subtotal
    let invSubtotalMsg = ''

    let subtotal = 0
    for (let i = 0; i < invoiceItems.length; i++) {
      const itm = invoiceItems[i]
      const itmSubtotal = parseFloat(itm.subtotal)

      if (!itm.is_delete) {
        subtotal += itmSubtotal
      }
    }

    const fSubtotal = formatter.toPriceFloat(subtotal)
    item.subtotal = fSubtotal

    if (invoiceItems.length > 0) {
      if (formatter.toPriceFloat(targetedInvAmount) !== fSubtotal) {
        invSubtotalMsg = TotalAmountMismatchMsg
      }
    }

    this.setState({ item, invSubtotalMsg })
  }

  onInvItemValidateBudget = (invItem, isReturnBudgetInfo = false) => {
    const { clientBudget } = this.state
    const {inv_date: invDate, cat_id: catId, cat_item_id: catItemId, subtotal } = invItem

    if (invDate && catId && catItemId) {
      const mInvDate = moment.isMoment(invDate) ? invDate.clone().startOf('day') : moment(invDate).startOf('day')
      const activeBudget = clientBudget.find(e => {
        const startDate = moment(e.period_start_date).startOf('day')
        const endDate = moment(e.period_end_date).endOf('day')
        if (startDate.isBefore(mInvDate) && endDate.isAfter(mInvDate)) {
          return true
        }
        return false
      })

      if (activeBudget) {
        const { booking_number, categories = [] } = activeBudget
        const cat = categories.find(e => {
          if (e.cat_id && e.cat_item_id) {
            return e.cat_item_id === catItemId && e.cat_id === catId
          } else if (e.cat_id) {
            return e.cat_id === catId
          } else {
            return false
          }
        })

        if (cat) {
          if (subtotal > cat.remaining_value) {
            invItem.is_amt_over_budget = true
            return isReturnBudgetInfo ? { ...cat, booking_number } : invItem
          }
        }
      }
    }

    invItem.is_amt_over_budget = false
    return isReturnBudgetInfo ? null : invItem
  }

  /** Rcv Amt Modal */

  handleRcvAmtModal = (showRcvAmtModal, itemId) => {
    this.setState({ showRcvAmtModal }, () => this.getRcvAmtInfo(showRcvAmtModal, itemId))
  }

  getRcvAmtInfo = async (showRcvAmtModal, itemId) => {
    if (showRcvAmtModal === true) {
      this.setState({ loadingRctDetail: true })

      const detail = await invoiceService.getRcvAmtInfo(itemId)

      if (detail && detail.id) {
        this.setState({ loadingRctDetail: false, rcvModalInfo: detail })
      } else {
        this.setState({ loadingRctDetail: false, rcvModalInfo: {} })
      }
    } else {
      this.setState({ rcvModalInfo: {} })
    }
  }

  validateRcvAmtDate = (rule, value, callback, invDate) => {
    const { item } = this.state
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Receive Date is required`))
    } else {
      if (item.invoice_date) {
        const m = moment(item.invoice_date).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Receive Date cannot be earlier than invoice date.`))
        }
      }

      callback()
    }
  }

  validateInvItemRcvAmount = (rule, value, callback, maxRcvAmount) => {
    const { form } = this.props
    const rcvAmount = formatter.toPriceFloat(form.getFieldValue('rcv_amount'))
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Item Receive Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Item Receive Amount is not number or decimal format`))
      } else {
        const maxVal = parseFloat(maxRcvAmount)

        // if (maxVal < parseFloat(value)) {
        //   callback(new Error(`Item Receive Amount cannot exceed To Receive Amount`))
        // } else if (parseFloat(value) === 0) {
        //   callback(new Error(`Item Receive Amount cannot be zero`))
        // } else {
        //   callback()
        // }

        if (parseFloat(value) === 0) {
          callback(new Error(`Item Receive Amount cannot be zero`))
        } else {
          callback()
        }
      }
    }
  }

  onInvItemRcvAmountChange = (e, maxRcvAmount) => {
    const { form } = this.props
    const value = formatter.toPriceFloat(e.target.value)
    let isAutoClose = this.state.rcvModalInfo.is_auto_closed
    let isOverpay = false
    if (value === maxRcvAmount) {
      isAutoClose = true
      isOverpay = false
    } else if (value > maxRcvAmount) {
      isAutoClose = true
      isOverpay = true
    } else {
      isAutoClose = false
      isOverpay = false
    }

    form.setFieldsValue({ is_auto_closed: isAutoClose })
    this.setState({
      rcvModalInfo: { ...this.state.rcvModalInfo, is_auto_closed: isAutoClose, is_overpay: isOverpay }
    })
  }

  onInvItemRcvClosedChange = (e, maxRcvAmount) => {
    const { form } = this.props
    const rcvAmount = formatter.toPriceFloat(form.getFieldValue('rcv_amount'))
    if (e === true) {
      if (parseFloat(rcvAmount) < parseFloat(maxRcvAmount)) {
        this.setState({ rcvModalMsg: RcvAmountEarlyCloseMsg })
      } else {
        this.setState({ rcvModalMsg: '' })
      }
    } else {
      this.setState({ rcvModalMsg: '' })
    }
  }

  preCheckRcvAmt = async () => {
    const { rcvModalInfo, loadingUpdateRct } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { onUpdateRcvAmt } = this

    if (loadingUpdateRct) return

    validateFieldsAndScroll(['rcv_amount', 'rcv_comment', 'is_auto_closed', 'received_date'], async (errors, values) => {
      if(!errors) {
        const rcvAmount = formatter.toPriceFloat(values.rcv_amount)
        const isClosed = values.is_auto_closed
        const toPayAmount = formatter.toPriceFloat(rcvModalInfo.to_pay_subtotal)

        if (isClosed && rcvAmount !== toPayAmount) {
          confirm({
            title: 'Proceed To Update Received Amount?',
            content: (
              <div style={{color: 'rgb(238, 27, 27)'}}>
                <p>The amount of current receiving amount is not matched with to pay amount, and you are about to close this item.</p>
                <p>Confirm your action to proceed.</p>
              </div>
            ),
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk () {
              // eslint-disable-next-line no-lone-blocks
              onUpdateRcvAmt(rcvModalInfo, values)
            },
            onCancel () {
            }
          })
        } else {
          onUpdateRcvAmt(rcvModalInfo, values)
        }
      }
    })
  }

  onUpdateRcvAmt = async (rcvModalInfo, values) => {
    const data = {
      id: rcvModalInfo.id,
      received_amount: formatter.toPriceFloat(values.rcv_amount),
      is_auto_closed: values.is_auto_closed,
      comment: values.rcv_comment,
      received_date: values.received_date
    }

    if (this.state.loadingUpdateRct) return

    this.setState({ loadingUpdateRct: true })
    const r = await invoiceService.addRcvAmt(data)

    if (r && r.id) {
      notify.success('Update Receive amount successfully', 'Receive amount is updated successfully')
      this.setState({ loadingUpdateRct: false })
      this.handleRcvAmtModal(false)
      this.fetchInvoice()
    } else {
      notify.error('Unable to update received amount', 'Unable to update received amount successfully. Please try again later.')
      this.setState({ loadingUpdateRct: false })
    }
  }

  /** Credit Amt Modal */
  handleCreditAmtModal = (showCreditAmtModal, itemId) => {
    this.setState({ showCreditAmtModal }, () => this.getCreditAmtInfo(showCreditAmtModal, itemId))
  }

  findCredits = (input, option) => {
    const cd = option.props.children.props.children
    let text = ''
    if (validator.isNotEmptyArray(cd)) {
      for (let i = 0; i < cd.length; i++) {
        let text2 = validator.isNotEmptyArray(cd[i].props.children) ? cd[i].props.children.join() : ''
        text += text2
      }

      return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return false
  }


  onCreditAmtSelect = (id) => {
    const { creditModalInfo } = this.state

    if (validator.isNotEmptyArray(creditModalInfo.credits)) {
      const credit = creditModalInfo.credits.find(e => e.id === id)

      if (credit) {
        const toPaySubtotal = formatter.toPriceFloat(creditModalInfo.to_pay_subtotal)
        const maxAmt = formatter.toPriceFloat(credit.remaining_amount)
        this.setState({
          clientSelectedCredit: credit,
          clientCreditMaxAmt: toPaySubtotal > maxAmt ? maxAmt : toPaySubtotal
        })
      }
    }
  }

  validateCreditApplyAmtDate = (rule, value, callback) => {
    const { item } = this.state
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Apply Date is required`))
    } else {
      if (item.invoice_date) {
        const m = moment(item.invoice_date).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Apply Date cannot be earlier than invoice date.`))
        }
      }

      callback()
    }
  }

  validateCreditAmt = (rule, value, callback) => {
    const { form } = this.props
    const { clientCreditMaxAmt } = this.state
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Credit Apply Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Credit Apply Amount is not number or decimal format`))
      } else {
        if (clientCreditMaxAmt < parseFloat(value)) {
          callback(new Error(`Credit Apply Amount cannot exceed To Receive Amount or Available Credit Amount`))
        } else if (parseFloat(value) === 0) {
          callback(new Error(`Credit Apply Amount cannot be zero`))
        } else {
          callback()
        }
      }
    }
  }

  getCreditAmtInfo = async (showCreditAmtModal, itemId) => {
    const { item } = this.state
    if (showCreditAmtModal === true) {
      this.setState({ loadingCreditDetail: true })

      const detail = await creditClientService.getCreditAmtInfo(itemId, item.client_id)

      if (detail && detail.id) {
        this.setState({
          loadingCreditDetail: false,
          creditModalInfo: detail,
          clientSelectedCredit: {},
          clientCreditMaxAmt: 0.0
        })
      } else {
        this.setState({ loadingCreditDetail: false, creditModalInfo: {} })
      }
    } else {
      this.setState({ creditModalInfo: {}, clientSelectedCredit: {}, clientCreditMaxAmt: 0.0 })
    }
  }

  preCheckCreditAmt = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { onUpdateCreditAmt } = this
    const { clientSelectedCredit, creditModalInfo } = this.state

    validateFieldsAndScroll(['credit_id', 'apply_date', 'credit_amount', 'credit_comment'], async (errors, values) => {
      if(!errors) {
        confirm({
          title: 'Proceed To Apply Credit Amount?',
          content: (
            <div style={{color: 'rgb(238, 27, 27)'}}>
              <p>Redeem {formatter.toPrice(values.credit_amount)} from credit {formatter.toPrice(clientSelectedCredit.remaining_amount)}.</p>
              <p>Confirm your action to proceed.</p>
            </div>
          ),
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk () {
            // eslint-disable-next-line no-lone-blocks
            onUpdateCreditAmt(creditModalInfo, clientSelectedCredit, values)
          },
          onCancel () {
          }
        })
      }
    })
  }

  onUpdateCreditAmt = async (creditModalInfo, clientSelectedCredit, values) => {
    const data = {
      credit_id: values.credit_id,
      inv_item_id: creditModalInfo.id,
      amount: values.credit_amount,
      comment: values.credit_comment,
      apply_date: values.apply_date
    }

    this.setState({ loadingUpdateCredit: true })
    const r = await creditClientService.applyCredit(data)

    if (r && r.id) {
      notify.success('Apply Credit Amount successfully', 'Credit amount is applied to invoice item successfully')
      this.setState({ loadingUpdateCredit: false })
      this.handleCreditAmtModal(false)
      this.fetchInvoice()
    } else {
      notify.error('Unable to apply credit amount', 'Unable to update received amount successfully. Please try again later.')
      this.setState({ loadingUpdateCredit: false })
    }
  }

  /** Credit Amt Edit Modal */
  handleCreditAmtEditModal = (showCreditEditModal, creditInfo = {}) => {
    this.setState({ showCreditEditModal }, () => this.getCreditAmtEditInfo(showCreditEditModal, creditInfo))
  }

  getCreditAmtEditInfo = (showCreditEditModal, creditInfo = {}) => {
    const { creditModalInfo } = this.state

    if (showCreditEditModal === true && creditInfo.id) {
      // start check maximum available amount to update the credit amount
      const creditId = creditInfo.credit_id
      const currentCreditAmount = creditInfo.amount

      const credits = creditModalInfo.credits || []
      const crd = credits.find(e => e.id === creditId)
      let maxAmt = currentCreditAmount

      if (crd && crd.id) {
        const creditRemainingAmt = parseFloat(crd.remaining_amount)
        // maximum available amount for credit side
        const creditMaxAmount = creditRemainingAmt + currentCreditAmount
         // maximum available amount for inv item side
        const itemMaxAmount = parseFloat(creditModalInfo.to_pay_subtotal) + currentCreditAmount

        maxAmt = creditMaxAmount > itemMaxAmount ? itemMaxAmount : creditMaxAmount
      }

      this.setState({
        showCreditEditModal,
        clientCurrentCreditInfo: creditInfo,
        clientCreditEditMaxAmt: maxAmt
      })
    } else {
      this.setState({
        showCreditEditModal,
        clientCurrentCreditInfo: creditInfo,
        clientCreditEditMaxAmt: 0.0
      })
    }
  }

  validateCreditEditAmt = (rule, value, callback) => {
    const { form } = this.props
    const { clientCreditEditMaxAmt } = this.state
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Credit Apply Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Credit Apply Amount is not number or decimal format`))
      } else {
        if (clientCreditEditMaxAmt < parseFloat(value)) {
          callback(new Error(`Credit Apply Amount cannot exceed Allowed Amount`))
        } else if (parseFloat(value) === 0) {
          callback(new Error(`Credit Apply Amount cannot be zero`))
        } else {
          callback()
        }
      }
    }
  }

  preCheckCreditEditAmt = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { onUpdateCreditEditAmt } = this
    const { clientCurrentCreditInfo } = this.state

    validateFieldsAndScroll(['edit_apply_date', 'edit_credit_amount', 'edit_credit_comment'], async (errors, values) => {
      if(!errors) {
        confirm({
          title: 'Proceed To Update Credit Amount?',
          content: (
            <div style={{color: 'rgb(238, 27, 27)'}}>
              <p>Update credit amount to {formatter.toPrice(values.edit_credit_amount)} (previously {formatter.toPrice(clientCurrentCreditInfo.amount)}).</p>
              <p>Confirm your action to proceed.</p>
            </div>
          ),
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk () {
            // eslint-disable-next-line no-lone-blocks
            onUpdateCreditEditAmt(clientCurrentCreditInfo, values)
          },
          onCancel () {
          }
        })
      }
    })
  }

  onUpdateCreditEditAmt = async (clientCurrentCreditInfo, values) => {
    const { showCreditAmtModal, creditModalInfo } = this.state
    const data = {
      id: clientCurrentCreditInfo.id,
      amount: values.edit_credit_amount,
      comment: values.edit_credit_comment,
      apply_date: values.edit_apply_date
    }

    this.setState({ loadingUpdateCredit: true })
    const r = await creditClientService.updateCredit(data)

    if (r && r.id) {
      notify.success('Update Credit Amount successfully', 'Credit amount is updated successfully')
      this.setState({ loadingUpdateCredit: false })
      this.handleCreditAmtEditModal(false)
      this.getCreditAmtInfo(showCreditAmtModal, creditModalInfo.id)
      this.fetchInvoice()
    } else {
      notify.error('Unable to update credit amount', 'Unable to update credit amount successfully. Please try again later.')
      this.setState({ loadingUpdateCredit: false })
    }
  }

  /** ABA/RMT Edit Modal */
  handleAbaRmtModal = (showAbaRmtModal, abaRmtModalType = '') => {
    const { item } = this.state
    let abaRmtSelectList = []
    if (showAbaRmtModal === true && item && validator.isNotEmptyArray(item.rcv_history_list)) {
      abaRmtSelectList = item.rcv_history_list.map(e => ({ export_id: e.export_id, id: e.id }))
    }

    this.setState({ showAbaRmtModal, abaRmtModalType, abaRmtSelectList, abaRmtSelectAll: showAbaRmtModal })
  }

  updateAbaRmtModalIdsSelect = (e, id, exportId) => {
    const check = e.target.checked
    let { abaRmtSelectList, item } = this.state

    if (validator.isNotEmptyArray(item.rcv_history_list)) {
      if (check) {
        const c = abaRmtSelectList.find(e => e.id === id && e.export_id === exportId)
        if (!c) {
          abaRmtSelectList.push({ id, export_id: exportId })
        }
      } else {
        const c = abaRmtSelectList.findIndex(e => e.id === id && e.export_id === exportId)
        if (c > -1) {
          abaRmtSelectList.splice(c, 1)
        }
      }

      this.setState({
        abaRmtSelectList,
        abaRmtSelectAll: item.rcv_history_list && abaRmtSelectList.length >= item.rcv_history_list.length,
        abaRmtSelectErrMsg: ''
      })
    }
  }

  updateAbaRmtModalIdsSelectAll = (e) => {
    const check = e.target.checked

    let { abaRmtSelectList, item } = this.state

    if (validator.isNotEmptyArray(item.rcv_history_list)) {
      if (check) {
        abaRmtSelectList = item.rcv_history_list.map(e => ({ export_id: e.export_id, id: e.id }))
      } else {
        abaRmtSelectList = []
      }

      this.setState({
        abaRmtSelectList,
        abaRmtSelectAll: check,
        abaRmtSelectErrMsg: ''
      })
    }
  }

  preAbaRmtGetFile = (isSendEmail, isDownload) => {
    const { getAbaRmtFile } = this
    const { abaRmtSelectList = [] } = this.state

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    if (validator.isNotEmptyArray(abaRmtSelectList)) {
      confirm({
        title: title,
        content: 'Press Ok to continue, Cancel to return',
        onOk () {
          getAbaRmtFile(isSendEmail, isDownload)
        }
      })
    } else {
      this.setState({ abaRmtSelectErrMsg: ItemsSelectErrMsg })
    }
  }

  getAbaRmtFile = async (isSendEmail = false, isDownload = true) => {
    const { item, loadingSelectAbaRmt, abaRmtModalType, abaRmtSelectList } = this.state

    if (loadingSelectAbaRmt) return

    let ids = []
    let selectIds = []

    for (let i = 0; i < abaRmtSelectList.length; i++) {
      const s = abaRmtSelectList[i]

      const a = ids.findIndex(e => e === s.export_id)
      const b = selectIds.findIndex(e => e === s.id)

      if (!(a > -1)) {
        ids.push(s.export_id)
      }

      if (!(b > -1)) {
        selectIds.push(s.id)
      }
    }

    // console.log('file 1', ids, selectIds)

    this.setState({loadingSelectAbaRmt: true})

    const r = await exportFile.fetchFilesInv(item.id, abaRmtModalType, null, ids, selectIds, isSendEmail, isDownload)

    if (r && r.id && isSendEmail) {
      notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
    }

    setTimeout(() => {
      this.setState({loadingSelectAbaRmt: false})
      this.handleAbaRmtModal(false)

      if (isDownload && isSendEmail) {
        notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
      }
    }, 4000)
  }

  /** Add new Invoice - File Upload Modal */
  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq !== undefined) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      } else {
        fileList.push(values)
      }
    } else {
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }

  handleDeleteFile = (item) => {
    const { fileList } = this.state

    if (item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  uploadFiles = async (invoiceInfo, result) => {
    const { fileList, categoriesList, subCategoriesList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.genre_id = result.id

      const cat = categoriesList.find(e => e.id === file.main_cat_id)
      const subcat = subCategoriesList.find(e => e.cat_sub_id === file.sub_cat_id)

      const r = await fileService.add(file)

      if (r && r.id) {
        log.addInvoiceFile(result.id, `New file added while invoice created for ${invoiceInfo.invoice_number} with file name "${file.fileName}" and labelled with "${file.label}". ${cat ? `Main Category as "${cat.name}", ` : ''}${subcat ? `Sub Category as "${subcat.cat_sub_name}".` : ''} ${file.issuance_date ? `Issuance Date set as "${formatter.toShortDate(file.issuance_date)}"` : ''}${file.expiry_date ? ` and Expiry Date set as "${formatter.toShortDate(file.expiry_date)}".` : '.'} File is ${file.active ? 'enabled': 'disabled'}.`)
      }
    }
  }

  /** handle Invoice Authorisation Modal */
  handleInvAuthModal = (showInvAuthModal, invAuthModalInfo = {}) => {
    this.setState({ showInvAuthModal, invAuthModalInfo })
  }

  handleConfirmInvAuth = async (action) => {
    const { invAuthModalInfo } = this.state

    if (action) {
      this.setState({ loadingUpdateComm: true })

      const r = await this.onCreateComm(invAuthModalInfo.invId, action)
      let title = null
      let content = null
      const okFunction = invAuthModalInfo && invAuthModalInfo.okFunction ? invAuthModalInfo.okFunction : () => {}

      if (action === INV_AUTH_ACTION_SENDNOW) {
        title = 'Authorisation Email in Process'
        content = <div><p>Authorisation email is being processed and will be sent to the Invoice Authorisation Email configured in participant's profile.</p></div>
      } else if (action === INV_AUTH_ACTION_LATER) {
        title = 'Authorisation Email Created'
        content = <div><p>Authorisation email is created. Please go to Comm tab to send the Invoice Authorisation email when it is ready to send.</p></div>
      }

      this.setState({ loadingUpdateComm: false })

      if (r && r.id) {
        this.handleInvAuthModal(false)
        if (title && content) {
          info({
            title: title,
            content: content,
            onOk () {
              okFunction()
            }
          })
        } else {
          notify.success('Create Comm Successfully', 'Comm is created successfully.')
        }
      } else {
        notify.error('Unable to Create Comm', 'Please try again later.')
      }
    }
  }

  /** Validate invoice client auto authorised pay */
  validateAuthorisedPay = async (clientInfo = {}, invoiceInfo = {}, fileList = []) => {
    const commCount = await this.fetchCommCount()
    // if comm count > 0, then it could be AUTH_PAY_UPDATED_DETAILS, but if comm sent count > 0 i.e. comm sent, then status become AUTH_PAY_REMINDER_NOT_REQUIRED
    let isTrigger = commCount && commCount.commCount > 0 ? (commCount.commSentCount > 0 ? AUTH_PAY_REMINDER_NOT_REQUIRED : AUTH_PAY_UPDATED_DETAILS) : AUTH_PAY_REMINDER_REQUIRED

    if (isTrigger !== AUTH_PAY_UPDATED_DETAILS && isTrigger !== AUTH_PAY_REMINDER_NOT_REQUIRED && clientInfo && clientInfo.id && invoiceInfo && invoiceInfo.id) {
      if (clientInfo.pm_is_auth_req === false) {
        if (clientInfo.pm_auth_amount) {
          const clientAmt = parseFloat(clientInfo.pm_auth_amount)
          const invAmt = parseFloat(invoiceInfo.subtotal)

          if (invAmt <= clientAmt) {
            isTrigger = AUTH_PAY_REMINDER_NOT_REQUIRED
          }
        } else {
          isTrigger = AUTH_PAY_REMINDER_NOT_REQUIRED
        }
      }

      if (isTrigger > 0) {
        if (!validator.isNotEmptyArray(fileList)) {
          isTrigger = AUTH_PAY_REMINDER_ALERT
        } else if (!(clientInfo.pm_auth_email && clientInfo.pm_auth_email_name)) {
          isTrigger = AUTH_PAY_NO_EMAIL_CONFIGURED
        }
      }
    }

    return isTrigger
  }

  showAuthorisedReminder = (clientInfo = {}, okFunction = () => {}) => {
    let content = ''
    if (clientInfo.pm_is_auth_req === false && clientInfo.pm_auth_amount) {
      content = <div><p>Invoice Authorisation is required for invoices above {formatter.toPrice(clientInfo.pm_auth_amount)} for this participant.</p><p>FILE MISSING: Please upload the original invoice</p></div>
    } else {
      content = <div><p>Invoice Authorisation is required for all invoices for this participant.</p><p>FILE MISSING: Please upload the original invoice.</p></div>
    }

    warning({
      title: `File REQUIRED; Invoice Authorisation REQUIRED`,
      content: content,
      okText: 'OK',
      onOk() {
        okFunction()
      },
    })
  }

  showAuthorisedEmailReminder = (clientInfo = {}, okFunction = () => {}) => {
    let content = ''
    if (!(clientInfo.pm_auth_email && clientInfo.pm_auth_email_name)) {
      content = `Please configure the participant's Authorisation Email details.`
    }

    warning({
      title: `NO Invoice Authorisation Email/Addressee Details`,
      content: content,
      okText: 'OK',
      onOk() {
        okFunction()
      },
    })
  }

  showAuthorisedUpdateAlert = (okFunction = () => {}) => {
    warning({
      title: `Invoice Authorisation Update Alert`,
      content: `Please check if there are any updates on Authorisation Email details and update the details before the mail is sent.`,
      okText: 'OK',
      onOk() {
        okFunction()
      },
    })
  }

  // original show authorised alert modal
  showAuthorisedAlertOri = (clientInfo = {}, invId, okFunction = () => {}) => {
    let content = ''
    if (clientInfo.pm_is_auth_req === false && clientInfo.pm_auth_amount) {
      content = `Invoice Authorisation is required for invoices above ${formatter.toPrice(clientInfo.pm_auth_amount)} for this participant. Do send invoice for approval.`
    } else {
      content = `Invoice Authorisation is required for all invoices for this participant. Do send invoice for approval.`
    }

    if (content) {
      info({
        title: 'Reminder of Invoice Authorisation',
        content: content,
        onOk () {
          okFunction()
        }
      })
    } else {
      okFunction()
    }
  }

  // updated show authorised alert modal with comm module
  showAuthorisedAlert = (clientInfo = {}, invId, okFunction = () => {}, isDisabledClose = false) => {
    const { onCreateComm } = this
    let content = ''
    if (clientInfo.pm_is_auth_req === false && clientInfo.pm_auth_amount) {
      content = `Invoice Authorisation is required for invoices above ${formatter.toPrice(clientInfo.pm_auth_amount)} for this participant. Do you want to send the email for approval now?.`
    } else {
      content = `Invoice Authorisation is required for all invoices for this participant. Do you want to send the email for approval now?`
    }

    if (content) {
      this.handleInvAuthModal(true, { content, invId, okFunction, isDisabledClose })
      // confirm({
      //   title: 'Invoice Authorisation REQUIRED',
      //   content: content,
      //   cancelText: 'Later',
      //   okText: 'Send Now',
      //   onOk () {
      //     onCreateComm(invId)
      //     info({
      //       title: 'Authorisation Email in Process',
      //       content: <div><p>Authorisation email is being processed and will be sent to the Invoice Authorisation Email configured in participant's profile.</p></div>,
      //       onOk () {
      //         okFunction()
      //       }
      //     })
      //   },
      //   onCancel () {
      //     okFunction()
      //   }
      // })
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add' && id !== 'new'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingInvoices
}

const mapStateToProps = (state) => {
  return {  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoicePage))
