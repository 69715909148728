import ClientList from './containers/Client/List'
import ClientPage from './containers/Client/Page'
import Dashboard from './containers/Dashboard'

// PCP Link Requests
import LinkRequestList from './containers/LinkRequest/List'

// Credit
import CreditList from './containers/Credit/List'
import CreditPage from './containers/Credit/Page'

// Invoice
import InvoiceList from './containers/Invoice/List'
import InvoicePage from './containers/Invoice/Page'
import InvoiceManage from './containers/Invoice/Manage'
import InvoiceExport from './containers/Invoice/Export'

// InvoiceV2
import InvoiceListV2 from './containers/InvoiceV2/List'
import InvoicePageV2 from './containers/InvoiceV2/Page'
import InvoiceManageV2 from './containers/InvoiceV2/Manage'
import InvoiceExportV2 from './containers/InvoiceV2/Export'

// Provider
import ProviderList from './containers/Provider/List'
import ProviderPage from './containers/Provider/Page'

// Report
import ReportClientBudgetEnding from './containers/Report/ClientBudgetEnding'
import ReportToReceiveInvoices from './containers/Report/ToReceiveInvoices'
import ReportCustomGenerate from './containers/Report/GenerateRequest'
import ReportRecipientSetting from './containers/Report/RecipientSetting'

// Statement
import StatementListClient from './containers/Statement/listClient'
import StatementListPeriod from './containers/Statement/listPeriod'

// Settings
import Setting from './containers/Setting'
import SettingContacts from './containers/Setting/Contacts'
import SettingCustomIdf from './containers/Setting/CustomIdentifier'
import SettingFile from './containers/Setting/File'
import SettingGST from './containers/Setting/GST'
import SettingGSTEdit from './containers/Setting/GST/RateEdit'
import SettingReason from './containers/Setting/Reasons'
import SettingReasonEdit from './containers/Setting/Reasons/Page'
import SettingImportInvoice from './containers/Setting/InvoiceImport'
import SettingProfile from './containers/Setting/Profile'
import SettingRateSet from './containers/Setting/RateSet'
import SettingRateCategoryEdit from './containers/Setting/RateSet/CategoriesEdit'
import SettingRateSetEdit from './containers/Setting/RateSet/RateSetEdit'
import SettingOther from './containers/Setting/Other'

export const routes = [
  {
    component: Dashboard,
    exact: true,
    icon: 'dashboard.svg',
    menu: true,
    name: 'Dashboard',
    path: '/'
  },
  {
    component: Setting,
    exact: true,
    icon: 'admin.svg',
    name: 'Admins',
    path: '/settings'
  },
  {
    component: ClientList,
    name: 'Participant',
    path: '/participants',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Participant',
    path: '/participants/:id',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Participant',
    path: '/participants/:id/:type',
    exact: true
  },
  {
    component: LinkRequestList,
    name: 'Link Requests',
    path: '/link-requests',
    exact: true
  },
  {
    component: CreditList,
    name: 'Credits',
    path: '/credits',
    exact: true
  },
  {
    component: CreditPage,
    name: 'Credits',
    path: '/credits/:id',
    exact: true
  },
  {
    component: CreditPage,
    name: 'Credits',
    path: '/credits/:id/:type',
    exact: true
  },
  // {
  //   component: InvoiceList,
  //   name: 'Invoices',
  //   path: '/invoices-list/:type',
  //   exact: true
  // },
  // {
  //   component: InvoiceManage,
  //   name: 'Invoices',
  //   path: '/invoices-manage',
  //   exact: true
  // },
  // {
  //   component: InvoiceExport,
  //   name: 'Invoices',
  //   path: '/invoices-export',
  //   exact: true
  // },
  // {
  //   component: InvoiceExport,
  //   name: 'Invoices',
  //   path: '/invoices-export/:type',
  //   exact: true
  // },
  // {
  //   component: InvoicePage,
  //   name: 'Invoices',
  //   path: '/invoices/:id',
  //   exact: true
  // },
  // {
  //   component: InvoicePage,
  //   name: 'Invoices',
  //   path: '/invoices/:id/:type',
  //   exact: true
  // },
  {
    component: InvoiceListV2,
    name: 'Invoices',
    path: '/invoices-list/:type',
    exact: true
  },
  // {
  //   component: InvoiceManageV2,
  //   name: 'Invoices',
  //   path: '/v2/invoices-manage',
  //   exact: true
  // },
  {
    component: InvoiceExportV2,
    name: 'Invoices',
    path: '/invoices-export',
    exact: true
  },
  {
    component: InvoiceExportV2,
    name: 'Invoices',
    path: '/invoices-export/:type',
    exact: true
  },
  {
    component: InvoicePageV2,
    name: 'Invoices',
    path: '/invoices/:id',
    exact: true
  },
  {
    component: InvoicePageV2,
    name: 'Invoices',
    path: '/invoices/:id/:type',
    exact: true
  },
  {
    component: ProviderList,
    name: 'Providers',
    path: '/providers',
    exact: true
  },
  {
    component: ProviderPage,
    name: 'Providers',
    path: '/providers/:id',
    exact: true
  },
  {
    component: ProviderPage,
    name: 'Providers',
    path: '/providers/:id/:type',
    exact: true
  },
  {
    component: ReportClientBudgetEnding,
    name: 'ReportClientBudgetEnding',
    path: '/reports/participant-sb-report',
    exact: true
  },
  {
    component: ReportToReceiveInvoices,
    name: 'ReportToReceiveInvoices',
    path: '/reports/to-receive',
    exact: true
  },
  {
    component: ReportCustomGenerate,
    name: 'ReportCustomGenerate',
    path: '/reports/custom-generate',
    exact: true
  },
  {
    component: ReportRecipientSetting,
    name: 'ReportRecipientSetting',
    path: '/reports/setting',
    exact: true
  },
  {
    component: StatementListPeriod,
    name: 'Statement',
    path: '/statement/period/:type',
    exact: true
  },
  {
    component: StatementListClient,
    name: 'Statement',
    path: '/statement/period/:type/:date',
    exact: true
  },
  {
    component: SettingContacts,
    name: 'Setting Contacts Export',
    path: '/settings/contacts',
    exact: true
  },
  {
    component: SettingCustomIdf,
    name: 'Setting Custom Identifier',
    path: '/settings/custom-identifier',
    exact: true
  },
  {
    component: SettingFile,
    name: 'Setting Files',
    path: '/settings/files',
    exact: true
  },
  {
    component: SettingGST,
    name: 'Setting GST',
    path: '/settings/gst',
    exact: true
  },
  {
    component: SettingGST,
    name: 'Setting GST',
    path: '/settings/gst/:type',
    exact: true
  },
  {
    component: SettingGSTEdit,
    name: 'Setting GST Edit',
    path: '/settings/gst/rates/:id',
    exact: true
  },
  {
    component: SettingImportInvoice,
    name: 'Setting Import Invoice',
    path: '/settings/invoice-import',
    exact: true
  },
  {
    component: SettingProfile,
    name: 'Setting Profile',
    path: '/settings/profile',
    exact: true
  },
  {
    component: SettingRateSet,
    name: 'Setting Rate Set',
    path: '/settings/rate-set',
    exact: true
  },
  {
    component: SettingRateSet,
    name: 'Setting Rate Set',
    path: '/settings/rate-set/:type',
    exact: true
  },
  {
    component: SettingRateCategoryEdit,
    name: 'Setting Rate Category Edit',
    path: '/settings/rate-set/categories/:id',
    exact: true
  },
  {
    component: SettingRateSetEdit,
    name: 'Edit Rate Set',
    path: '/settings/rate-set/edit/:id',
    exact: true
  },
  {
    component: SettingOther,
    name: 'Setting Others',
    path: '/settings/others',
    exact: true
  },
  {
    component: SettingReason,
    name: 'Setting Reason',
    path: '/settings/reasons',
    exact: true
  },
  {
    component: SettingReason,
    name: 'Setting Reason',
    path: '/settings/reasons/:type',
    exact: true
  },
  // {
  //   component: SettingReasonEdit,
  //   name: 'Setting Reason Edit',
  //   path: '/settings/reasons/:type/:id',
  //   exact: true
  // }
]
