import {
  FETCHING_INVOICES_LIST,
  INVOICES_LIST_FETCHED,
  FETCHING_INVOICES_SUMMARY,
  INVOICES_SUMMARY_FETCHED,
  INVOICES_INFO_RESET
} from '../actions/invoice-fs'

const INITIAL_STATE = {
  invoicesList: { list: [], total: 0, ts: Date.now(), is_list_item: undefined, filterSummary: undefined },
  invoicesSummary: {},
  loadingList: true,
  loadingSummary: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_INVOICES_LIST:
      return handleFetchingInvoicesList(state, action)
    case INVOICES_LIST_FETCHED:
      return handleInvoicesListFetched(state, action)
    case FETCHING_INVOICES_SUMMARY:
      return handleFetchingInvoicesSummary(state, action)
    case INVOICES_SUMMARY_FETCHED:
      return handleInvoicesSummaryFetched(state, action)
    case INVOICES_INFO_RESET:
      return handleInvoicesInfoReset(state, action)
    default:
      return state
  }
}

function handleFetchingInvoicesList (state, action) {
  const { loading } = action
  return { ...state, loadingList: loading }
}

function handleInvoicesListFetched (state, action) {
  const { invoices, loading } = action
  return { ...state, invoicesList: invoices, loadingList: loading }
}

function handleFetchingInvoicesSummary (state, action) {
  const { loading } = action
  return { ...state, loadingSummary: loading }
}

function handleInvoicesSummaryFetched (state, action) {
  const { summary, loading } = action
  return { ...state, invoicesSummary: summary, loadingSummary: loading }
}

function handleInvoicesInfoReset (state, action) {
  return { ...state, ...INITIAL_STATE }
}
