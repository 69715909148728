import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/invoice-update'

export default {
  updateStatus (type, values) {
    return request(`${url}/${type}/update`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
}
