import { ExportType } from '../constants'
import { clientService, providerService, invoiceService, invoiceExportService, statementService, settingContactsExportService } from '../services'
import formatter from './formatter'

const fileDateFormat = 'YYYYMMDD'

export default {
  async fetchFiles (id, type, createdAt = null, selectIds = [], isSendEmail = false, isDownload = true, customData = {}) {
    const date = createdAt ? formatter.toDate(createdAt, fileDateFormat) : formatter.toDate(new Date().toISOString(), fileDateFormat)

    let filename = ''
    if (type === ExportType.TYPE_PRODA) {
      filename = `PymtRequest-${date}-${Date.now()}.csv`
    } else if (type === ExportType.TYPE_PRODA_STD) {
      filename = `PymtRequest-Monthly-${date}-${Date.now()}.csv`
    } else if (type === ExportType.TYPE_PRODA_PM) {
      filename = `PymtRequest-PM-${date}-${Date.now()}.csv`
    } else if (type === ExportType.TYPE_SB_PRODA) {
      filename = `PymtRequest-Monthly-${date}-${Date.now()}.csv`
    } else if (type === ExportType.TYPE_SB_PRODA_V2) {
      filename = `PymtRequest-Monthly-${date}-${Date.now()}.csv`
    } else if (type === ExportType.TYPE_ABA) {
      filename = `ABA-${date}-${Date.now()}.aba`
    } else if (type === ExportType.TYPE_RMT) {
      filename = `Remittance-${date}-${Date.now()}.zip`
    } else if (type === ExportType.TYPE_PAYMENT) {
      filename = `Payment-${date}-${Date.now()}.zip`
    } else {
      filename = `File-${date}-${Date.now()}.csv`
    }

    const r = await invoiceExportService.getExportPost({id, periodId: (type === ExportType.TYPE_SB_PRODA || type === ExportType.TYPE_SB_PRODA_V2) ? id : null, type, selectIds, isSendEmail, isDownload, customData})
    // console.log('fetch files', r)
    return downloadFile(r, filename)
  },
  async fetchFilesInv (invId, type, createdAt = null, ids = [], selectIds = [], isSendEmail = false, isDownload = true) {
    const date = createdAt ? formatter.toDate(createdAt, fileDateFormat) : formatter.toDate(new Date().toISOString(), fileDateFormat)

    let filename = ''
    if (type === ExportType.TYPE_PRODA) {
      filename = `PymtRequest-${date}-${Date.now()}.csv`
    } else if (type === ExportType.TYPE_PRODA_STD) {
      filename = `PymtRequest-Monthly-${date}-${Date.now()}.zip`
    } else if (type === ExportType.TYPE_PRODA_PM) {
      filename = `PymtRequest-PM-${date}-${Date.now()}.zip`
    } else if (type === ExportType.TYPE_ABA) {
      filename = `ABA-${date}-${Date.now()}.zip`
    } else if (type === ExportType.TYPE_RMT) {
      filename = `Remittance-${date}-${Date.now()}.zip`
    } else if (type === ExportType.TYPE_PAYMENT) {
      filename = `Payment-${date}-${Date.now()}.zip`
    } else {
      filename = `File-${date}-${Date.now()}.csv`
    }

    const r = await invoiceExportService.getExportInvPost({inv_id: invId, type, ids, selectIds, isSendEmail, isDownload})
    // console.log('fetch files', r)
    return downloadFile(r, filename)
  },
  async fetchStatement (filter, name) {
    let filename = `Statement-${name}-${Date.now()}.pdf`
    const r = await statementService.getStatementPost(filter)
    // console.log('fetch statement', filter, r)
    return downloadFile(r, filename)
  },
  async fetchStatementMulti (filter) {
    let filename = `Statement-${Date.now()}.zip`
    const r = await statementService.getStatementPostMulti(filter)
    // console.log('fetch statement', filter, r)
    return downloadFile(r, filename)
  },
  async fetchExport (type, data) {
    const date = formatter.toDate(new Date(), fileDateFormat)
    let filename = ''
    let r = null

    if (type === 'client' && data) {
      filename = `PM-Participant-${date}-${Date.now()}.zip`
      r = await clientService.exportClient(data)
    } else if (type === 'provider') {
      filename = `PM-Provider-${date}-${Date.now()}.csv`
      r = await providerService.exportProvider()
    } else if (type === 'invoice' && data) {
      const std = formatter.toDate(data.start_date, fileDateFormat)
      const etd = formatter.toDate(data.end_date, fileDateFormat)
      filename = `PM-Invoice-${std}-${etd}-${Date.now()}.zip`
      r = await invoiceService.exportInvoicePost(data)
    } else if (type === ExportType.TYPE_CONTACTS_GENERAL_SIMPLE && data) {
      filename = `PM-Contact-General-Simple-${date}-${Date.now()}.${data.fileType}`
      r = await settingContactsExportService.exportContact(type, data.fileType)
    }

    if (r) {
      return downloadFile(r, filename)
    }
  }
}

async function downloadFile (r, filename) {
  if (r) {
    const blob = await r.blob()
    if (blob.type === 'application/json') {
      try {
        const response = await blob.text()
        return JSON.parse(response)
      } catch (e) {
        return e
      }
    } else {
      const href = window.URL.createObjectURL(blob)
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}
