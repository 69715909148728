import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/invoice-list'

export default {
  getAllStatus (type = 'all') {
    return request(`${url}/status/all/${type}`)
  },
  listInvoicesFS (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listInvoicesAndItemsFS (page, size, filter, sorting, text) {
    return request(`${url}/list-items/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listInvoicesSummary (type) {
    return request(`${url}/summary/${type}`)
  }
}
