// Please set this for deployment !!!!!
// Options [local, dev, staging, uat, prod]
const build = 'prod'
// Options [local, dev, staging, uat, prod]

const apiHostname = build === 'local' ? 'http://localhost:3200' : ''
const companyId = '5b780b639b3370063ed76788'
const googleMapApi = 'AIzaSyAjBbFpg3ZUajBzL7k6gEiurQ2XK-TyEx0'
const redirectHostname = process.env.REACT_APP_HUB_ENDPOINT

export {
  build,
  apiHostname,
  companyId,
  googleMapApi,
  redirectHostname
}
