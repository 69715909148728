import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'
import { auth } from '../../../util'

// UI
import { Page } from '../../../components'
import { Permissions } from '../../../constants'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import './styles.css'

// import Language from './Language'
// import Relationship from './Relationship'

import RateSet from './RateSet'
import Rates from './Rates'
import Categories from './Categories'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Rate Set & Categories'

const TabList = [
  { tabId: 1, path: '' },
  { tabId: 2, path: '/categories'},
  { tabId: 3, path: '/rates' }
]

export class SettingRateSet extends Component {
  constructor (props) {
    super(props)
    const { match } = this.props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1'
    }
  }

  componentDidMount () {

  }

  handleTabChange = (index) => {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})
    if (tab && tab.tabId) {
      this.props.history.replace(`/settings/rate-set${tab.path}`)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentTab, addLanguage, addRelationship, activeTab } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey={currentTab} onChange={this.handleTabChange}>
            { this.hasAccess(Permissions.SETTING.RATESETS.LIST)
              ? <TabPane tab='Rate Sets' key='1'>
                <RateSet key={`rstab${currentTab}`} />
              </TabPane>
              : null }

            { this.hasAccess(Permissions.SETTING.CATS.LIST)
              ? <TabPane tab='Categories' key='2'>
                <Categories key={`rctab${currentTab}`} />
              </TabPane>
              : null }

            { this.hasAccess(Permissions.SETTING.RATESETS.LIST)
              ? <TabPane tab='Rates' key='3'>
                <Rates key={`ratab${currentTab}`} />
              </TabPane>
              : null }
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSet))
