import { combineReducers } from 'redux'

import AdminReducer from './admin'
import AdminRoleReducer from './adminRole'
import ClientReducer from './client'
import CreditReducer from './credit'
import DashboardReducer from './dashboard'
import FunderReducer from './funder'
import FundingReducer from './funding'
import GeneralReducer from './general'
import InvoiceReducer from './invoice'
import InvoiceFSReducer from './invoice-fs'
import LinkRequestReducer from './linkRequest'
import ProviderReducer from './provider'
import ModuleReducer from './module'
import PayrollReducer from './payroll'
import StatementReducer from './statement'
import UserReducer from './user'

export default combineReducers({
  Admin: AdminReducer,
  AdminRole: AdminRoleReducer,
  Client: ClientReducer,
  Credit: CreditReducer,
  Dashboard: DashboardReducer,
  Funder: FunderReducer,
  Funding: FundingReducer,
  General: GeneralReducer,
  Invoice: InvoiceReducer,
  InvoiceFS: InvoiceFSReducer,
  LinkRequest: LinkRequestReducer,
  Provider: ProviderReducer,
  Module: ModuleReducer,
  Payroll: PayrollReducer,
  Statement: StatementReducer,
  User: UserReducer
})
