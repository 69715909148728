import { FETCHING_LINK_REQUESTS, LINK_REQUESTS_FETCHED } from '../actions/linkRequest'

const INITIAL_STATE = {
  linkRequests: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_LINK_REQUESTS:
      return handleFetchingLinkRequests(state, action)
    case LINK_REQUESTS_FETCHED:
      return handleLinkRequestsFetched(state, action)
    default:
      return state
  }
}

function handleFetchingLinkRequests(state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleLinkRequestsFetched(state, action) {
  const { linkRequests, loading } = action
  return { ...state, linkRequests, loading }
}
