import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/invoice-import'

export default {
  startImportCsv (values) {
    return request(`${url}/file-start`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
