export const FETCH_STATEMENTS = 'FETCH_STATEMENTS'
export const FETCHING_STATEMENTS = 'FETCHING_STATEMENTS'
export const STATEMENTS_FETCHED = 'STATEMENTS_FETCHED'

export const fetchStatements = (params) => {
  return Object.assign({
    type: FETCH_STATEMENTS
  }, params)
}

export const fetchingStatements = (params) => {
  return Object.assign({
    type: FETCHING_STATEMENTS
  }, params)
}

export const invoicesStatements = (statements) => {
  return {
    type: STATEMENTS_FETCHED,
    statements
  }
}
