import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchClients } from '../../../states/actions/client'
import { Permissions } from '../../../constants'
import { auth, exportFile, validator } from '../../../util'

// UI
import { Button, Checkbox, ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 40
const Option = Select.Option

export class Client extends Component {
  constructor(props) {
    super(props)
    const { clients: { list, total }, clientLimit, loading } = props
    this.state = {
      clientIds: [],
      clientLimit,
      currentPage: 1,
      filter: { active: true },
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      suburbs: [],
      total,
      isGenerating: false,
      isShowExportModal: false,
      isCheckProfile: true,
      isCheckFile: true,
      isCheckSB: true,
      isCheckMsgShow: false,
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.onSelectStatus = this.onSelectStatus.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { clients: { list, total }, clientLimit, loading } = nextProps
    const state = { ...prevState, clientLimit, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount() {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, loading, searchText, sort })
  }

  onSearchName(value) {
    const { filter, loading, sort } = this.state

    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClients({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onSelectStatus(value) {
    const { currentPage, filter, searchText, sort } = this.state

    if (value === 'active' || value === 'inactive') {
      filter.active = (value === 'active')
      filter.is_exited = false
    } else if (value === 'exited') {
      filter.active = false
      filter.is_exited = true
    }

    this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
    this.setState({ filter })
  }

  hasAccess(accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  handleExportModal(isShowExportModal) {
    this.setState({ isShowExportModal })
  }

  handleCheckboxClick(e, { isCheckProfile, isCheckFile, isCheckSB }) {
    const check = e.target.checked
    this.setState({
      isCheckProfile: isCheckProfile === undefined ? this.state.isCheckProfile : check,
      isCheckFile: isCheckFile === undefined ? this.state.isCheckFile : check,
      isCheckSB: isCheckSB === undefined ? this.state.isCheckSB : check
    }, () => {
      const { isCheckProfile, isCheckFile, isCheckSB } = this.state
      if (!(isCheckProfile || isCheckFile || isCheckSB)) {
        this.setState({ isCheckMsgShow: true })
      } else {
        this.setState({ isCheckMsgShow: false })
      }
    })
  }

  preCheckExport() {
    const { isCheckMsgShow } = this.state

    if (!isCheckMsgShow) {
      this.export()
    }
  }

  async export() {
    const { filter, isCheckFile, isCheckProfile, isCheckSB } = this.state

    const data = {
      export_file: isCheckFile,
      export_profile: isCheckProfile,
      export_sb: isCheckSB
    }

    try {
      this.setState({ isGenerating: true })
      // window.location.href = `${apiHostname}/api/export/list/client${queryString.stringify({ filter: JSON.stringify(filter) })}`
      const r = await exportFile.fetchExport('client', data)
      setTimeout(() => {
        this.setState({ isGenerating: false, isShowExportModal: false })
      }, 3000)
    } catch (e) {
      notify.error('Unable to export', 'Unable to get participant export successfully. Please try again later.')
      this.setState({ isGenerating: false, isShowExportModal: false })
    }
  }

  renderParticipant(item) {
    const { id, active, first_name: firstName, last_name: lastName, gender, suburb, acc_ref: clientId, leave_id: leaveId, leave_start: leaveStart, leave_end: leaveEnd, ndis_number: ndisNumber } = item

    return (
      <div className={`list-item ${active ? '' : 'list-item-dim'}`} key={id}>
        <Row className='list-content'>
          <Col md={6}><img alt='' src={process.env.PUBLIC_URL + '/img/' + (gender && gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /></Col>

          <Col md={18}>
            <div className='name'>{firstName} {lastName}</div>
            {ndisNumber ? <div style={{ fontSize: '8pt' }}>{ndisNumber}</div> : null}
            <div style={{ marginTop: '3px', fontSize: '8pt', color: '#888' }}>{suburb}</div>
          </Col>

        </Row>
      </div>
    )
  }

  openClientLimitModal = () => {
    const { clientLimit } = this.state
    Modal.info({
      title: 'Client Limit Reached',
      content: (
        <div>
          <div>You have reached your subscription limit of {clientLimit ? clientLimit.max_count : 0} clients.</div>

          <div>Please contact Pumpkin IT on <b>03 9118 2052</b> or <b><a href="mailto:info@pumpkinit.com.au">info@pumpkinit.com.au</a></b> to add additional clients</div>
        </div>
      )
    })
  }

  render() {
    const { clientLimit, currentPage, isGenerating, isShowExportModal, isCheckFile, isCheckProfile, isCheckSB, isCheckMsgShow, list, total, searching } = this.state
    const { loading } = this.props

    return (
      <Page.Body>
        {/* <Page.Left>
          <Page.Menu title='Home' menu={ClientMenu} />
        </Page.Left> */}
        <Page.Content nomenu>
          <Page.Header title='Participants'>
            {this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.handleExportModal(true)}>
                EXPORT
              </Button>
              : null}

            {this.hasAccess(Permissions.PARTICIPANT.INFO.CREATE) && clientLimit && clientLimit.can_add
              ? <Link to='/participants/add'>
                <div className='btn'>Add</div>
              </Link>
              : <div className='btn' onClick={this.openClientLimitModal}>Add</div>}
          </Page.Header>

          <Page.Filter>
            {this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)
              ? <Row gutter={8}>
                <Col lg={8}>
                  <ControlLabel>Name, Acc Ref ID, NDIS Number, Suburb</ControlLabel>
                  <SearchInput placeholder='Search participants' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
                </Col>
                <Col lg={8}>
                  <ControlLabel>Status</ControlLabel>
                  <Select defaultValue='active' style={{ width: 120 }} onChange={this.onSelectStatus}>
                    <Option value='active'>Active</Option>
                    <Option value='inactive'>Inactive</Option>
                    {/* <Option value='exited'>Exited</Option> */}
                  </Select>
                </Col>
              </Row>
              : null}
          </Page.Filter>

          <div className='clients'>
            <Skeleton loading={loading} active>
              {validator.isNotEmptyArray(list) && list.map((item) => {
                const {
                  id,
                  ref_id: refId,
                  active,
                  first_name: firstName,
                  last_name: lastName,
                  gender,
                  suburb,
                  acc_ref: clientId,
                  leave_id: leaveId,
                  leave_start: leaveStart,
                  leave_end: leaveEnd,
                  ndis_number: ndisNumber
                } = item

                return (
                  this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                    ? <Link to={`/participants/${refId}/info`} key={id}>
                      {this.renderParticipant(item)}
                    </Link>
                    : this.renderParticipant(item)
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} participants`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          {isShowExportModal
            ? <Modal
              width='450px'
              title='Select Participant Export Items'
              visible={isShowExportModal}
              onCancel={() => this.handleExportModal(false)}
              footer={[
                <Button key='close' ghost feedback={isGenerating} onClick={() => this.handleExportModal(false)}>Cancel</Button>,
                <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
              ]}
            >
              <Spin spinning={isGenerating} blur>
                <Form>
                  <div className='inv-title'>Please select item(s) to export:</div>

                  <span>
                    <Checkbox
                      checked={isCheckProfile}
                      onClick={f => this.handleCheckboxClick(f, { isCheckProfile: true })}
                    />
                    <span className='checkbox-text'>Participant Profile Export</span>
                  </span>

                  <span>
                    <Checkbox
                      checked={isCheckSB}
                      onClick={f => this.handleCheckboxClick(f, { isCheckSB: true })}
                    />
                    <span className='checkbox-text'>Participant SB Export</span>
                  </span>

                  <span>
                    <Checkbox
                      checked={isCheckFile}
                      onClick={f => this.handleCheckboxClick(f, { isCheckFile: true })}
                    />
                    <span className='checkbox-text'>Participant Files Export</span>
                  </span>

                  <div className='checkbox-warning-text'>{isCheckMsgShow ? `Please select at least one item.` : ''}</div>
                </Form>
              </Spin>
            </Modal>
            : null}
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, searchText, sort })
  }

  fetchClients = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)) return

    try {
      const { fetchClients } = this.props
      this.setState({ currentPage })
      fetchClients({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Client))
