import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      // {
      //   subgroup: [
      //     {
      //       title: 'Invoices',
      //       icon: 'thunderbolt',
      //       permission: [Permissions.INVOICE.INFO.CREATE, Permissions.INVOICE.INFO.LIST],
      //       menu: [
      //         {
      //           name: 'New Invoice',
      //           link: '/invoices/add',
      //           permission: Permissions.INVOICE.INFO.CREATE
      //         },
      //         {
      //           name: 'List Invoices',
      //           link: '/invoices-list/pm',
      //           permission: Permissions.INVOICE.INFO.LIST
      //         },
      //         {
      //           name: 'Manage Invoices (PM SB)',
      //           link: '/invoices-manage',
      //           permission: Permissions.INVOICE.MGMT.LIST
      //         },
      //         {
      //           name: 'Redownload - Payment Request',
      //           link: '/invoices-export/payment-request',
      //           permission: Permissions.INVOICE.MGMT.LIST
      //         },
      //         {
      //           name: 'Redownload - ABA/Remittance',
      //           link: '/invoices-export/aba-remittance',
      //           permission: Permissions.INVOICE.MGMT.LIST
      //         },
      //       ],
      //     }
      //   ]
      // },
      {
        subgroup: [
          {
            title: 'Invoices',
            icon: 'thunderbolt',
            permission: [Permissions.INVOICE.INFO.CREATE, Permissions.INVOICE.INFO.LIST],
            menu: [
              {
                name: 'New Invoice',
                link: '/invoices/add',
                permission: Permissions.INVOICE.INFO.CREATE
              },
              {
                name: 'List Invoices',
                link: '/invoices-list/pm',
                permission: Permissions.INVOICE.INFO.LIST
              },
              {
                name: 'Redownload - PM Payment Request',
                link: '/invoices-export/payment-request-pm',
                permission: Permissions.INVOICE.MGMT.LIST
              },
              {
                name: 'Redownload - STD Payment Request',
                link: '/invoices-export/payment-request-std',
                permission: Permissions.INVOICE.MGMT.LIST
              },
              {
                name: 'Redownload - ABA/Remittance',
                link: '/invoices-export/aba-remittance',
                permission: Permissions.INVOICE.MGMT.LIST
              },
            ],
          }
        ]
      },
      {
        subgroup: [
          {
            title: 'Participants',
            icon: 'usergroup-add',
            permission: [Permissions.PARTICIPANT.INFO.CREATE, Permissions.PARTICIPANT.INFO.LIST],
            menu: [
              {
                name: 'New Participant',
                link: '/participants/add',
                permission: Permissions.PARTICIPANT.INFO.CREATE
              },
              {
                name: 'List Participants',
                link: '/participants',
                permission: Permissions.PARTICIPANT.INFO.LIST
              },
              {
                name: 'List Link Requests',
                link: '/link-requests',
                permission: Permissions.PARTICIPANT.INFO.LIST
              },
            ],
          },
        ]
      },
      {
        subgroup: [
          {
            title: 'Credits',
            icon: 'dollar',
            permission: Permissions.CREDIT.INFO.CREATE || Permissions.CREDIT.INFO.LIST,
            menu: [
              {
                name: 'New Credit',
                link: '/credits/add',
                permission: Permissions.CREDIT.INFO.CREATE
              },
              {
                name: 'List Credits',
                link: '/credits',
                permission: Permissions.CREDIT.INFO.LIST
              },
            ],
          },
        ]
      },
    ]
  },
  {
    title: 'Section 2',
    groups: [
      {
        subgroup: [
          {
            title: 'Providers',
            icon: 'project',
            permission: [Permissions.PROVIDER.INFO.CREATE, Permissions.PROVIDER.INFO.LIST],
            menu: [
              {
                name: 'New Provider',
                link: '/providers/add',
                permission: Permissions.PROVIDER.INFO.CREATE
              },
              {
                name: 'List Providers',
                link: '/providers',
                permission: Permissions.PROVIDER.INFO.LIST
              },
            ],
          }
        ]
      },
      {
        title: 'Reports',
        icon: 'bar-chart',
        permission: [
          Permissions.REPORT.SCHEDULER.LIST,
          Permissions.REPORT.REPORT_SB.LIST,
          Permissions.REPORT.RECIPIENT_SETTING.LIST,
          Permissions.REPORT.REPORT_PENDING_INVOICES.LIST
        ],
        menu: [
          {
            name: 'Participant Service Booking Report',
            link: '/reports/participant-sb-report',
            permission: Permissions.REPORT.REPORT_SB.LIST
          },
          {
            name: 'Participant To Receive Invoices Report',
            link: '/reports/to-receive',
            permission: Permissions.REPORT.REPORT_PENDING_INVOICES.LIST
          },
          {
            name: 'Report Generate Request',
            link: '/reports/custom-generate',
            permission: Permissions.REPORT.SCHEDULER.LIST
          },
          {
            name: 'Report Recipient Setting',
            link: '/reports/setting',
            permission: Permissions.REPORT.RECIPIENT_SETTING.LIST
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'setting',
        permission: [
          Permissions.SETTING.CONTACTS_EXPORT.LIST,
          Permissions.SETTING.CUSTOM_IDF.LIST,
          Permissions.SETTING.FILES.LIST,
          Permissions.SETTING.PROFILE.LIST,
          Permissions.SETTING.RATESETS.LIST,
          Permissions.SETTING.REASON.LIST,
          Permissions.SETTING.INV_IMPORT.CREATE
        ],
        menu: [
          {
            name: 'Contacts',
            link: '/settings/contacts',
            permission: Permissions.SETTING.CONTACTS_EXPORT.LIST
          },
          {
            name: 'Custom Identifier',
            link: '/settings/custom-identifier',
            permission: Permissions.SETTING.CUSTOM_IDF.LIST
          },
          {
            name: 'File',
            link: '/settings/files',
            permission: Permissions.SETTING.FILES.LIST
          },
          {
            name: 'GST',
            link: '/settings/gst',
            permission: Permissions.SETTING.GST.LIST
          },
          {
            name: 'Profile',
            link: '/settings/profile',
            permission: [
              Permissions.SETTING.PACCREF.LIST,
              Permissions.SETTING.PLANGUAGE.LIST,
              Permissions.SETTING.PRELATIONSHIP.LIST
            ]
          },
          {
            name: 'Rate Set & Categories',
            link: '/settings/rate-set',
            permission: Permissions.SETTING.RATESETS.LIST
          },
          {
            name: 'Reasons',
            link: '/settings/reasons',
            permission: Permissions.SETTING.REASON.LIST
          },
          {
            name: 'Other',
            link: '/settings/others',
            permission: Permissions.SETTING.OTHERS.LIST
          },
          {
            name: 'Invoice Import',
            link: '/settings/invoice-import',
            permission: Permissions.SETTING.INV_IMPORT.CREATE
          },
        ],
      }
    ]
  }
]
