import Permissions from '../permissions'

export default [
  {
    name: 'Credits',
    icon: 'dollar',
    link: '/credits/add',
    permission: Permissions.CREDIT.INFO.LIST
  },
  {
    name: 'Invoices',
    icon: 'thunderbolt',
    link: '/invoices/all',
    permission: Permissions.INVOICE.INFO.LIST
  },
  {
    name: 'Participants',
    icon: 'usergroup-add',
    link: '/participants',
    permission: Permissions.PARTICIPANT.INFO.LIST
  },
  {
    name: 'Providers',
    icon: 'project',
    link: '/providers',
    permission: Permissions.PROVIDER.INFO.LIST
  },
  {
    name: 'Settings',
    icon: 'setting',
    link: '/settings',
    permission: Permissions.SETTING.PROFILE.LIST
  }
]
