import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchCredits } from '../../../states/actions/credit'
// import { fetchTotalPending } from '../../../states/actions'
import { CreditMenu, InvoiceStatus } from '../../../constants'
import { auth, formatter, validator } from '../../../util'
import { authService } from '../../../services'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const pageSize = 20

const displayFormat = 'DD/MM/YYYY'

export class CreditList extends Component {
  constructor (props) {
    super(props)
    // const { jobs: { list, total }, loading } = props
    this.state = {
      credits: { list: [], total: 0 },
      currentPage: 1,
      filter: {},
      list: [],
      loading: false,
      searching: false,
      searchText: '',
      sort: {},
      total: 0
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { credits: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchCredits({ currentPage, filter, loading, searchText, sort })
  }

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, loading, sort } = this.state
    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_abn: { condition: 'ilike', value: `%${words[i]}%` } },
              { booking_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { cat_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { report_group_name: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchCredits({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, searching, total } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={CreditMenu} backLink='/' countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Credits'>
            {
              this.hasAccess(Permissions.CREDIT.INFO.CREATE)
                ? <Link to='/credits/add'>
                  <div className='btn'>
                    New Credit
                  </div>
                </Link>
                : null
            }
          </Page.Header>

          { this.hasAccess(Permissions.CREDIT.INFO.LIST)
            ? <Page.Filter>
              <Row gutter={8}>
                <Col lg={8}>
                  <ControlLabel>Date, Participant, Provider, Categories</ControlLabel>
                  <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
                </Col>
              </Row>
            </Page.Filter>
            : null }

          <div className='credits'>
            <Skeleton loading={loading} active>
            {validator.isNotEmptyArray(list) && list.map((item) => {
              const {
                credit_comment: creditComment,
                client_id: clientId,
                client_ref_id: clientRefId,
                client_name: clientName,
                created_at: createdAt,
                booking_number: bookingNumber,
                id,
                ref_id: refId,
                provider_id: providerId,
                provider_ref_id: providerRefId,
                provider_name: providerName,
                amount,
                applied_amount: appliedAmount,
                cat_name: catName,
                cat_proda_name: catProdaName,
                report_group_name: rgpName,
                is_applied: isApplied,
                is_closed: isClosed
              } = item

              return (
                <div className={`list-item`} key={id}>
                  <Row>
                    <Col lg={23}>
                      <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                        <Col lg={6}>
                          <Row>
                            <Col lg={9}>
                              <div className='subtitle'>Participant</div>
                            </Col>
                            <Col lg={11}>
                              <div className='name'>
                                { this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                                  ? <a href={`/participants/${clientRefId}/info`} rel='noopener noreferrer' target='_blank'>
                                    {clientName}
                                  </a>
                                  : clientName }

                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={12}>
                              <div className='subtitle'>Booking Number</div>
                            </Col>
                            <Col lg={12}>
                              { this.hasAccess(Permissions.PARTICIPANT.SB.READ)
                                ? <div className='name'>
                                  <a href={`/participants/${clientRefId}/booking`} rel='noopener noreferrer' target='_blank'>
                                    {bookingNumber}
                                  </a>
                                </div>
                                : bookingNumber }
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={12}>
                          <Row>
                            <Col lg={4}>
                              <div className='subtitle'>Category</div>
                            </Col>
                            <Col lg={19}>
                              <div className='name'>
                                { this.hasAccess(Permissions.PARTICIPANT.SB.READ)
                                  ? <a href={`/participants/${clientRefId}/booking`} rel='noopener noreferrer' target='_blank'>
                                    <span><span style={{fontWeight: 'bold'}}>({rgpName})</span>&nbsp;&nbsp;{catProdaName}</span>
                                  </a>
                                  : <span><span style={{fontWeight: 'bold'}}>({rgpName})</span>&nbsp;&nbsp;{catProdaName}</span> }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: '6px' }}>
                        <Col lg={6}>
                          <Row>
                            <Col lg={9}>
                              <div className='header date'>Credit Date</div>
                            </Col>
                            <Col lg={11}>
                              <div className='value date'>{formatter.toDate(createdAt, displayFormat)}</div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={5}>
                          <Row>
                            <Col lg={12}>
                              <div className='header inv-amount'>Credit Amt</div>
                            </Col>
                            <Col lg={7}>
                              <div className='value inv-amount'>{formatter.toPrice(amount)}</div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={5}>
                          <Row>
                            <Col lg={12}>
                              <div className='header rcv-amount'>Balance Amt</div>
                            </Col>
                            <Col lg={7}>
                              <div className='value rcv-amount'>{formatter.toPrice(amount - appliedAmount)}</div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '6px' }}>
                        <Col>
                          <div className='detail-normal'>{ creditComment ? formatter.toShortenText(creditComment, 350) : null }</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={1} className='action-icon'>
                      { this.hasAccess(Permissions.CREDIT.INFO.READ)
                        ? <Link to={`/credits/${refId}/info`}>
                          <div style={{ color: '#D66E00' }}>
                            <Tooltip mouseLeaveDelay={0} title='Manage credit'>
                              <Icon type='form' />
                            </Tooltip>
                          </div>
                        </Link>
                        : null }

                    </Col>
                  </Row>
                </div>
              )
            })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} credits`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchCredits({ currentPage, filter, searchText, sort })
  }

  fetchCredits = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.CREDIT.INFO.LIST)) return

    try {
      const { fetchCredits } = this.props
      this.setState({ currentPage })
      fetchCredits({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load credits successfully. Please try again later.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchCredits
}

const mapStateToProps = (state) => {
  return { ...state.Credit }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditList)
