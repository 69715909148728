import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link, withRouter } from 'react-router-dom'
import { settingCancellationService, settingReasonService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Loading, Page, Pager, Panel, SideModal, List } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

import { auth, formatter, validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Cancellation'
const settingType = 'gender'

const SetList = [
  { tabId: 1, path: '/inactive', type: 'inactive', title: 'Inactive' },
  { tabId: 2, path: '/invoice-delete', type: 'inv-delete', title: 'Invoice Delete' }
]

export class SettingReasonList extends Component {
  constructor (props) {
    super(props)
    const set = SetList.find(e => e.type === props.type || e.type === `/${props.type}`)
    this.state = {
      currentPage: 1,
      filter: {},
      isItemEditable: false,
      isShowModal: false,
      list: [],
      loading: false,
      loadingForm: false,
      searchText: '',
      sort: {},
      selectedItem: {},
      set: set || SetList[0],
      type: props.type || SetList[0].type,
      total: 0,
    }
  }

  componentDidMount () {
    this.refreshList()
  }

  refreshList = () => {
    const { currentPage } = this.state
    this.fetchReasonList({ currentPage })
  }

  fetchReasonList = async ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.SETTING.REASON.LIST)) return

    const { set } = this.state
    try {
      this.setState({ currentPage, loading: true })
      filter = {
        type: { condition: '=', value: set.type }
      }

      const settings = await settingReasonService.listReasonItemByPage(currentPage, pageSize, filter)
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  addReason () {
    // this.props.history.push(`/settings/reasons/${this.state.set.type}/add`)
  }

  showModal = (item = {}) => {
    const isItemEditable = !!this.hasAccess(Permissions.SETTING.REASON.UPDATE)
    this.setState({ isShowModal: true, isItemEditable, selectedItem: item })
  }

  hideModal = () => {
    const { form } = this.props

    this.setState({ isShowModal: false, isItemEditable: false, selectedItem: {} }, () => {
      form.resetFields()
    })
  }

  async handleDelete (id) {
    const { set } = this.state
    this.setState({ loading: true })
    const res = await settingReasonService.removeReason(id)

    if (res && res.id) {
      notify.success('Deleted successfully', `${formatter.capitalize(set.title)} set deleted successfully.`)
    } else {
      notify.error('Unable to delete successfully', `Unable to delete ${set.title} successfully. Please try again later.`)
    }

    this.refreshList()
  }

  async handleDeleteItem (id) {
    const { set } = this.state
    this.setState({loading: true})
    try {
      const res = await settingReasonService.removeReasonItem(id)

      if (res && res.id) {
        notify.success('Deleted successfully', `${set.title} Reason deleted successfully`)
        this.refreshList()
      } else {
        notify.error('Unable to delete successfully', `Unable to delete ${set.title} Reason successfully. Please try again later.`)
        this.setState({loading: false})
      }
    } catch (e) {
      notify.error('Unable to delete successfully', `Unable to delete ${set.title} Reason successfully. Please try again later.`)
      this.setState({loading: false})
    }

  }

  handleSaveItem = () => {
    const { form, match } = this.props
    const { validateFields } = form
    const { selectedItem, set } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        try {
          this.setState({ loadingForm: true })
          const body = {
            name: values.name
          }

          let r = null
          if (selectedItem.id) {
            r = await settingReasonService.saveReasonItem(selectedItem.id, body)
          } else {
            body.type = set.type
            r = await settingReasonService.addReasonItem(body)
          }

          if (r && r.id) {
            notify.success('Saved successfully', `${set.title} reason saved successfully.`)
            this.refreshList()
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${set.title} reason successfully. Please try again later.`)
          }

          this.setState({ loadingForm: false })
        } catch (e) {
          this.setState({ loadingForm: false })
          notify.error('Unable to save successfully', `Unable to save ${set.title} reason successfully. Please try again later.`)
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, isShowModal, isItemEditable, list, loading, loadingForm, selectedItem, total, set, type } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Reasons',
        width: 22,
        render: ({ id, name }) => <div>{name}</div>
      },
      {
        title: '',
        width: 1,
        render: (item) => (
          this.hasAccess(Permissions.SETTING.REASON.READ) && !this.hasAccess(Permissions.SETTING.REASON.UPDATE)
          ? <div style={{cursor: 'pointer'}} onClick={() => this.showModal(item)}><Icon type='eye' /></div>
          : this.hasAccess(Permissions.SETTING.REASON.UPDATE)
            ? <div style={{cursor: 'pointer'}} onClick={() => this.showModal(item)}><Icon type='form' /></div>
            : null
        )
      },
      {
        title: '',
        width: 1,
        render: ({ id, reason_item_is_editable }) => this.hasAccess(Permissions.SETTING.REASON.DELETE) && reason_item_is_editable
        ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDeleteItem(id)}
          okText='Yes'
          cancelText='No'
          >
            <Icon type='delete' />
          </Popconfirm>
        : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <div className='header-action'>
              { this.hasAccess(Permissions.SETTING.REASON.LIST)
                ? <div className='btn btn-ghost' onClick={() => this.fetchReasonList({ currentPage: 1 })}>
                  {`Refresh`}
                </div>
                : null }
              { this.hasAccess(Permissions.SETTING.REASON.CREATE)
                ? <div className='btn' onClick={() => this.showModal()}>
                  {`Add Reason`}
                </div>
                : null }
            </div>

            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} ${set.title} Reasons`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>

          <SideModal
            title={`${set.title} Reasons`}
            showModal={isShowModal}
            onClose={this.hideModal}
            buttons={[
              <Button key='save' onClick={this.handleSaveItem} feedback={loadingForm}>Save</Button>
            ]}
          >
            <Loading loading={loadingForm} blur>
              <Form layout='vertical'>
                <FormItem label='Reason'>
                  {getFieldDecorator('name', {
                    initialValue: selectedItem.name,
                    rules: [
                      { min: 2, message: 'Reason must be between 2 and 128 characters' },
                      { max: 128, message: 'Reason must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter reason' },
                      { whitespace: true, message: 'Please enter reason' }
                    ]
                  })(
                    <Input disabled={!isItemEditable}/>
                  )}
                </FormItem>
              </Form>
            </Loading>
          </SideModal>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchCancellations({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(SettingReasonList)))
