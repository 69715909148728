import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_INVOICES_LIST,
  FETCHING_INVOICES_LIST,
  INVOICES_LIST_FETCHED,
  FETCH_INVOICES_SUMMARY,
  FETCHING_INVOICES_SUMMARY,
  INVOICES_SUMMARY_FETCHED
} from '../actions/invoice-fs'
import invoiceListService from '../../services/invoiceList'

function * fetchInvoicesList ({ loading, currentPage, pageSize, filter, sort, searchText, isListItem = false }) {
  try {
    yield put({ type: FETCHING_INVOICES_LIST, loading })
    const invoices = isListItem
      ? yield invoiceListService.listInvoicesAndItemsFS(currentPage, pageSize, filter, sort, searchText)
      : yield invoiceListService.listInvoicesFS(currentPage, pageSize, filter, sort, searchText)

    yield put({ type: INVOICES_LIST_FETCHED, invoices, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_INVOICES_LIST, loading: false })
    console.error(e)
  }
}

function * fetchInvoicesSummary ({ loading, invoiceType }) {
  try {
    yield put({ type: FETCHING_INVOICES_SUMMARY, loading })
    const summary = yield invoiceListService.listInvoicesSummary(invoiceType)
    yield put({ type: INVOICES_SUMMARY_FETCHED, summary, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_INVOICES_SUMMARY, loading: false })
    console.error(e)
  }
}

function * watchInvoice () {
  yield takeEvery(FETCH_INVOICES_LIST, fetchInvoicesList)
  yield takeEvery(FETCH_INVOICES_SUMMARY, fetchInvoicesSummary)
}

export default watchInvoice
