import { FETCHING_CREDITS, CREDITS_FETCHED } from '../actions/credit'

const INITIAL_STATE = {
  credits: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_CREDITS:
      return handleFetchingCredits(state, action)
    case CREDITS_FETCHED:
      return handleCreditsFetched(state, action)
    default:
      return state
  }
}

function handleFetchingCredits (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleCreditsFetched (state, action) {
  const { credits, loading } = action
  return { ...state, credits, loading }
}
