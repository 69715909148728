import { diff } from 'json-diff'
import { formatter } from './'
import { logService } from '../services'
import moment from 'moment'
import auth from './auth'

export default {
  /** new for pm */
  updateCustomIdf (genre, genreId, changes) {
    if (changes !== '') {
      saveLog(genre, genreId, 'update custom identifier', changes)
    }
  },
  addProvider (id, changes) {
    saveLog('provider', id, 'add', changes)
  },
  updateProvider (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('provider', id, 'update', changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  addClient (id, changes) {
    saveLog('participant', id, 'add', changes)
  },
  updateClient (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'update', changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  deleteClient (id, changes) {
    saveLog('participant', id, 'delete', changes)
  },
  addClientSB (id, changes) {
    saveLog('participant', id, 'add sb', changes)
  },
  updateClientSB (id, before, after, exclude = [], fields = [], additional) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb changes', changes)
    if (changes !== '') {
      saveLog('participant', id, 'update sb', changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  deleteClientSB (id, changes) {
    saveLog('participant', id, 'delete sb', changes)
  },
  addClientSBCat (id, changes, additional, prefix) {
    saveLog('participant', id, 'add sb cat', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
  },
  updateClientSBCat (id, before, after, exclude, additional = '', prefix, fields) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', before, after, changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'update sb cat', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  deleteClientSBCat (id, changes) {
    saveLog('participant', id, 'delete sb cat', changes)
  },
  addClientFile (id, changes) {
    saveLog('participant', id, 'file add', changes)
  },
  updateClientFile (id, before, after, exclude = [], fields = [], additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('changes', before, after, changes, changes === null, changes === '')
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeClientFile (id, changes) {
    saveLog('participant', id, 'file delete', changes)
  },
  addInvoice (id, changes) {
    saveLogAsync('invoice', id, 'add', changes)
  },
  updateInvoice (id, before, after, exclude = [], fields = [], additional = '', prefix = '', suffix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '' || suffix !== '') {
      saveLog('invoice', id, 'update', (prefix ? `${prefix} - ` : '') + changes + (additional ? `${changes ? '\n' : ''}` + additional : '') + (suffix ? `\n\n${suffix}` : ''))
    }
  },
  deleteInvoice (id, changes) {
    saveLog('invoice', id, 'delete', changes)
  },
  addInvoiceFile (id, changes) {
    saveLog('invoice', id, 'file add', changes)
  },
  updateInvoiceFile (id, before, after, exclude = [], fields = [], additional = '', prefix) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('changes', before, after, changes, changes === null, changes === '')
    if (changes !== '' || additional !== '') {
      saveLog('invoice', id, 'file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeInvoiceFile (id, changes) {
    saveLog('invoice', id, 'file delete', changes)
  },
  addInvoiceComm (id, changes) {
    saveLog('invoice', id, 'comm add', changes)
  },
  updateInvoiceComm (id, before, after, exclude = [], fields = [], additional = '', prefix) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('changes', before, after, changes, changes === null, changes === '')
    if (changes !== '' || additional !== '') {
      saveLog('invoice', id, 'comm update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  updateInvoiceCommText (id, status, changes) {
    saveLog('invoice', id, status, changes)
  },
  addCredit (id, changes) {
    saveLog('credit', id, 'add', changes)
  },
  updateCredit (id, changes) {
    saveLog('credit', id, 'update', changes)
  },
  deleteCredit (id, changes) {
    saveLog('credit', id, 'delete', changes)
  },
  updateReportRecipientSetting (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes || additional) {
      saveLog('report-recipient-setting', id, 'update', changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  updateSettingOther (id, before, after, exclude, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes) {
      saveLog('setting-other', id, 'update', changes)
    }
  },
  generateItemChanges (item, values, excludes = [], fields = []) {
    return generateChanges(item, values, excludes, fields)
  }
}

function saveLog (genre, id, action, changes) {
  const currentUser = auth.getCurrentUser()
  const { id: userId, name } = currentUser

  logService.add({ genre, genre_id: id, action, member: name, member_id: userId, changes })
}

async function saveLogAsync (genre, id, action, changes) {
  const currentUser = auth.getCurrentUser()
  const { id: userId, name } = currentUser

  await logService.add({ genre, genre_id: id, action, member: name, member_id: userId, changes })
}

function generateChanges (item, values, excludes = [], fields = []) {
  const result = diff(item, values)
  let changes = ''

  // console.log('generate changes', result, item, values)

  if (result) {
    for (const key of Object.keys(result)) {
      // console.log('generate changes 2', key)

      // result for added field: { `${key}__added`: ${value} }
      // result for updated field: { `${key}: { __old: ${oldValue}, __new: ${newValue} } }

      const value = result[key]
      const newKey = key.replace(/__added/g, '')
      const isExclude = excludes.findIndex(item => item === newKey)
      const isFound = fields.findIndex(item => item.key === newKey)
      // console.log('isExclude', key, isExclude)
      if (isExclude < 0) {
        // console.log('isValue', value, value !== null, value !== undefined, key.hasOwnProperty('__added'))
        if ((value !== null && value !== undefined) && key.indexOf('__added') > -1) {
          const label = isFound >= 0 ? fields[isFound].label : formatter.capitalize(newKey.replace(/_/g, ' '))

          changes += `${label} set to "${isDate(value) ? (showAsDateTime(newKey) ? formatDateTime(value) : formatDate(value)) : value}", `
        } else if ((value !== null && value !== undefined) && value.hasOwnProperty('__new')) {
          const label = isFound >= 0 ? fields[isFound].label : formatter.capitalize(key.replace(/_/g, ' '))

          // check if the value is date
          if ((value.__old !== null && value.__old !== undefined) && isDate(value.__old)) {
            // skip if no changes on date
            if (showAsDateTime(key)) {
              if (!moment(value.__old).isSame(moment(value.__new))) {
                changes += `${label} from "${formatDateTime(value.__old)}" to "${formatDateTime(value.__new)}", `
              }
            } else if (showAsTime(key)) {
              if (!moment(value.__old).isSame(moment(value.__new))) {
                changes += `${label} from "${formatTime(value.__old)}" to "${formatTime(value.__new)}", `
              }
            } else {
              if (!moment(value.__old).isSame(moment(value.__new), 'day')) {
                changes += `${label} from "${formatDate(value.__old)}" to "${formatDate(value.__new)}", `
              }
            }
          } else {
            if ((value.__old !== null && value.__old !== undefined) && (value.__new !== null && value.__new !== undefined)) {
              if (value.__old.toString() !== value.__new.toString()) {
                changes += `${label} from "${value.__old}" to "${value.__new}", `
              }
            } else if ((value.__old !== null && value.__old !== undefined) && (value.__new !== null && value.__new !== undefined)) {
              // changes += `${label} to "${isDate(value.__new) ? formatDate(value.__new) : value.__new}", `
              changes += `${label} to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            } else if (value.__new !== null && value.__new !== undefined) {
              changes += `${label} to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            } else if (value.__new !== null && value.__new !== undefined) {
              changes += `${label} from NULL to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            } else if (value.__old !== null && value.__old !== undefined) {
              changes += `${label} from "${isDate(value.__old) ? (showAsDateTime(key) ? formatDateTime(value.__old) : formatDate(value.__old)) : value.__old}" to NULL, `
            }
          }
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}

function generateNewSet (values, excludes = []) {
  let changes = ''
  for (const key of Object.keys(values)) {
    const value = values[key]
    const isExclude = excludes.findIndex(item => item === key)
    if (isExclude < 0) {
      if (value !== null && value !== undefined) {
        // check if the value is date
        if (isGMTDate(value)) {
          changes += `${formatter.capitalize(key.replace(/_/g, ' '))} to "${formatDate(value)}", `
        } else if (value !== false) {
          changes += `${formatter.capitalize(key.replace(/_/g, ' '))} to "${value}", `
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}
/*
function isDate (date) {
  return date ? (date.toString().indexOf('Z') === date.toString().length - 1 || date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}
 */
function isDate (date) {
  if (date && isNaN(date) && date.length >= 10) {
    const dateCheck = new RegExp(/^\d{4}-\d{2}-\d{2}$/)
    return dateCheck.exec(date.substr(0, 10)) !== null ? true : false
  }
  return false
}

function isGMTDate (date) {
  return date ? (date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}

function formatDate (value) {
  const formatted = moment(value).format('DD/MM/YYYY')

  return formatted !== 'Invalid date' ? formatted : ''
}

function formatDateTime (value) {
  const formatted = moment(value).format('DD/MM/YYYY hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsDateTime (key) {
  return key === 'job_start_date' || key === 'job_end_date'
}

function formatTime (value) {
  const formatted = moment(value).format('hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsTime (key) {
  return key === 'job_start_time' || key === 'job_end_time'
}
