import React, { Component } from 'react'
import { connect } from 'react-redux'
import { commService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'

// UI
import { Loading, List, Notification, Panel, Pager } from '../../../components'
import { CommType, CommTypeName, Permissions } from '../../../constants'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, log, validator } from '../../../util'

import './styles.css'
import AddCommModal from '../AddCommModal'

const notify = Notification

const pageSize = 20

// const formItemLayout = {
//   labelCol: { sm: 6, md: 6, lg: 6 },
//   wrapperCol: { sm: 14, md: 14, lg: 17 }
// }

// const sideBySideFormItemLayout = {
//   labelCol: { sm: 6, md: 6, lg: 12 },
//   wrapperCol: { sm: 14, md: 14, lg: 10 }
// }

const dateFormat3 = 'DD/MM/YYYY hh:mm:ss A'

// const UPDATE_ACTION_RECEIVED = 'received'
// const UPDATE_ACTION_REJECTED = 'rejected'

export class InvoiceComm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      commList: { list: [], total: 0 },
      loading: false,
      loadingList: false,
      loadingUpdate: false,
      showCommModal: false,
      modalItem: {},
      filter: {},
      filterParam: '',
      sort: {},
      searchText: ''
    }
  }

  componentDidMount () {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchComm({ currentPage, filter, searchText, sort })
  }

  componentWillReceiveProps (nextProps) {
    const { currentPage, filter, searchText, sort } = this.state
    if (nextProps.invoiceId !== this.props.invoiceId) {
      if (nextProps.invoiceId) {
        this.fetchComm({ currentPage, filter, searchText, sort }, nextProps.invoiceId)
      }
    }
  }

  render () {
    const { invoiceId, invoiceInfo = {} } = this.props
    const { currentPage, commList, loading, loadingList, loadingUpdate, modalItem, showCommModal } = this.state

    const listColumns = [
      {
        title: 'Created At',
        width: 4,
        render: ({ created_at, is_disabled }) => <div className={`${is_disabled ? 'text-disabled' : ''}`}>{formatter.toDate(created_at, dateFormat3)}</div>
      },
      {
        title: 'Event',
        width: 3,
        render: ({ comm_type, is_disabled }) => {
          const t = CommTypeName.find(e => e.type === comm_type)
          return <div className={`${is_disabled ? 'text-disabled' : ''}`}>{t ? t.name : 'Comm'}</div>
        }
      },
      {
        title: 'Subject',
        width: 4,
        render: ({ title, is_disabled }) => <div className={`${is_disabled ? 'text-disabled' : ''}`}>{title}</div>
      },
      {
        title: 'Content',
        width: 8,
        render: ({ content, is_disabled }) => <div className={`${is_disabled ? 'text-disabled' : ''}`} dangerouslySetInnerHTML={{__html: content}}></div>
      },
      {
        title: 'Scheduled?',
        width: 1,
        render: (item) => {
          const { is_disabled, is_scheduled, scheduled_at } = item

          return validator.isNotEmptyArray(is_scheduled) && is_scheduled.map((e, idx) => {
            return e
            ? <Tooltip key={`sccs${idx}`} mouseLeaveDelay={0} title={`Scheduled to send at ${formatter.toDate(scheduled_at[idx], dateFormat3)}`}>
              <div style={{ color: is_disabled ? '#ccc' : idx === 0 ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
            </Tooltip>
            : <Tooltip key={`sccsp${idx}`} mouseLeaveDelay={0} title={`To Schedule`}>
              { is_disabled
                ? <div key={`sccsn${idx}`} style={{ color: '#ccc', fontSize: '11pt', cursor: 'pointer' }}><Icon type='minus-circle' theme='filled' /></div>
                : <Popconfirm
                  title={`Ready to schedule this message?`}
                  onConfirm={() => this.handleScheduleProcess(item)}
                  okText='Yes'
                  cancelText='No'
                >
                  <div key={`sccsn${idx}`} style={{ color: idx === 0 ? '#FAAD14' : '#ccc', fontSize: '11pt', cursor: 'pointer' }}><Icon type='minus-circle' theme='filled' /></div>
                </Popconfirm>}
            </Tooltip>
          })
        }
      },
      {
        title: 'Sent?',
        width: 1,
        render: ({is_disabled, is_scheduled, is_processed_or_sent, processed_or_sent_at, is_failed, failed_at, failed_reason, scheduled_at, is_manually_closed}) => validator.isNotEmptyArray(is_processed_or_sent) && is_processed_or_sent.map((e, idx) => {
          return !e
          ? (is_scheduled[idx] !== undefined && is_scheduled[idx] === false
            ? <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
            : <Tooltip key={`pcns${idx}`} mouseLeaveDelay={0} title={`Scheduled to send at ${formatter.toDate(scheduled_at[idx], dateFormat3)}`}>
            <div style={{ color: is_disabled ? '#ccc' : idx === 0 ? '#FAAD14' : '#ccc', fontSize: '11pt' }}><Icon type='pause-circle' theme='filled' /></div>
          </Tooltip>)
          : is_scheduled[idx] !== undefined && is_scheduled[idx] === false
            ? <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
            : is_failed[idx]
              ? <Tooltip key={`pcnsp${idx}`} mouseLeaveDelay={0} title={`Sent Failed at ${formatter.toDate(failed_at[idx], dateFormat3)}, Reason: ${failed_reason[idx]}`}>
                <div style={{ color: is_disabled ? '#ccc' : idx === 0 ? '#EC7063' : '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
              </Tooltip>
              : <Tooltip key={`pcnss${idx}`} mouseLeaveDelay={0} title={`Sent at ${formatter.toDate(processed_or_sent_at[idx], dateFormat3)}${is_manually_closed[idx] === true ? ` (Cancelled)` : ''}`}>
                <div style={{ color: is_disabled || is_manually_closed[idx] === true ? '#ccc' : idx === 0 ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type={is_manually_closed[idx] === true ? 'close-circle' : 'check-circle'} theme='filled' /></div>
              </Tooltip>
        })
      },
      // {
      //   title: 'Replied?',
      //   width: 1,
      //   render: (item) => {
      //     const { is_disabled, is_scheduled, is_processed_or_sent, is_replied_required, is_failed, replied_at } = item

      //     return (
      //       validator.isNotEmptyArray(replied_at) && replied_at.map((e, idx) => {
      //         return e
      //         ? <Tooltip key={`rncs${idx}`} mouseLeaveDelay={0} title={`Replied at ${formatter.toDate(e, dateFormat3)}`}>
      //           <div style={{ color: is_disabled ? '#ccc' : idx === 0 ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      //         </Tooltip>
      //         : is_scheduled[idx] !== undefined && is_scheduled[idx] === false
      //           ? <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      //           : is_replied_required
      //             ? (idx === 0 && is_processed_or_sent[idx] !== undefined && is_processed_or_sent[idx] === true
      //               // case for index no 0 (top most & latest) and comm is_processed_or_sent = true
      //               ? <div className='action-buttons'>
      //                 <Tooltip key={`rncsp${idx}`} mouseLeaveDelay={0} title={`Update for Received/Replied`}>
      //                 { is_disabled
      //                   ? <div style={{ color: '#ccc', cursor: 'pointer', fontSize: '11pt' }}><Icon type='question-circle' theme='filled' /></div>
      //                   : <Popconfirm
      //                     title={`Update this comm with received/replied status?`}
      //                     onConfirm={() => this.handleUpdateReplyStatus(item, UPDATE_ACTION_RECEIVED)}
      //                     okText='Yes'
      //                     cancelText='No'
      //                   >
      //                     <div style={{ color: '#FAAD14', cursor: 'pointer', fontSize: '11pt' }}><Icon type='question-circle' theme='filled' /></div>
      //                   </Popconfirm> }
      //                 </Tooltip>
      //                 <Tooltip key={`rnctp${idx}`} mouseLeaveDelay={0} title={`Update for Rejected`}>
      //                 { is_disabled
      //                   ? <div style={{ color: '#ccc', cursor: 'pointer', fontSize: '11pt' }}><Icon type='question-circle' theme='filled' /></div>
      //                   : <Popconfirm
      //                     title={`Update this comm with rejected status?`}
      //                     onConfirm={() => this.handleUpdateReplyStatus(item, UPDATE_ACTION_REJECTED)}
      //                     okText='Yes'
      //                     cancelText='No'
      //                   >
      //                     <div style={{ color: '#CC0000', cursor: 'pointer', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      //                   </Popconfirm> }
      //               </Tooltip>
      //               </div>
      //               // case for index no > 0 (remaining past comm records) and show the comm status by flag is_processed_or_sent
      //               : is_processed_or_sent[idx] !== undefined
      //                 ? (
      //                   is_processed_or_sent[idx] === true
      //                   ? is_failed[idx] !== undefined
      //                     ? (
      //                       // if failed, show failed status. else show nothing
      //                       is_failed[idx] === true
      //                       ? <Tooltip key={`rncss${idx}`} mouseLeaveDelay={0} title={`Failed`}>
      //                         <div style={{ color: '#ccc', cursor: 'default', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      //                       </Tooltip>
      //                       : null
      //                     )
      //                     : null
      //                   : <Tooltip key={`rncss${idx}`} mouseLeaveDelay={0} title={`Not processed`}>
      //                     <div style={{ color: '#ccc', cursor: 'default', fontSize: '11pt' }}><Icon type='minus-circle' theme='filled' /></div>
      //                   </Tooltip>
      //                 )
      //                 : <Tooltip key={`rncss${idx}`} mouseLeaveDelay={0} title={`Unable to Update Reply`}>
      //                   <div style={{ color: '#ccc', cursor: 'default', fontSize: '11pt' }}><Icon type='question-circle' theme='filled' /></div>
      //                 </Tooltip>
      //             )
      //             // case for replied not required
      //             : <Tooltip key={`rncsb${idx}`} mouseLeaveDelay={0} title={`Reply Not Required`}>
      //               <div style={{ color: is_disabled ? '#ccc' : idx === 0 ? '#FAAD14' : '#ccc', fontSize: '11pt' }}><Icon type='minus-circle' theme='filled' /></div>
      //             </Tooltip>
      //       }
      //     )
      //   )}
      // },
      {
        title: 'Action',
        width: 1,
        render: (item) => {
          /**
           * status of process:
           * 1 - comm processed but failed to send
           * 2 - comm is scheduled but yet to process
           * 3 - comm is processed and no reply updated yet (if is_replied_required is true)
           * 4 - processed
           */
          const { comm_type, is_scheduled, is_processed_or_sent, is_manually_closed, is_closed, is_disabled, is_failed, is_replied_required, replied_at , processed_or_sent_at } = item
          const isScheduled = validator.isNotEmptyArray(is_scheduled) ? is_scheduled[0] : true
          const isEnableResend = validator.isNotEmptyArray(is_processed_or_sent) && validator.isNotEmptyArray(is_failed) && is_closed !== true // if the comm is closed, disable the resend button
            ? (
              is_processed_or_sent[0] === true && is_failed[0] === true // processed but failed
              ? 1
              : is_processed_or_sent[0] === true && is_failed[0] === false // processed and sent succesfully
                ? (!replied_at[0] && is_replied_required
                  ? 3 // if reply required but no reply yet. this may remove after approval page done
                  : 4 // processed and (no reply required or (already replied and updated))
                )
                : 2 // not yet processed i.e. is_processed_or_sent[0] === false
            )
            : 0

          const icon = isEnableResend === 1
              ? 'mail'
              : isEnableResend === 2
                ? (isScheduled ? 'clock-circle' : 'question-circle')
                : isEnableResend === 3
                  ? 'question-circle'
                  : isEnableResend === 4
                    ? 'issues-close'
                      : ''

          const tooltipText = isEnableResend === 1
              ? 'Reschedule / Resend'
              : isEnableResend === 2
                ? (isScheduled ? 'Scheduled' : 'Not Scheduled')
                : isEnableResend === 3
                ? 'Update Reply'
                  : isEnableResend === 4
                    ? 'Message Sent'
                    : ''

          return <div className='action-buttons'>
            { isEnableResend < 3 && this.hasAccess(Permissions.INVOICE.INFO.UPDATE) && !is_disabled && !is_closed && (comm_type === CommType.PM_COMM_TYPE_INV_AUTH || comm_type === CommType.PM_COMM_TYPE_INV_AUTH_CONFIRMED)
              ? <Tooltip mouseLeaveDelay={0} title='Edit Mail'>
                <div onClick={() => this.showCommModal(true, item)} style={{ marginRight: 15 }}>
                  <Icon type='form' />
                </div>
              </Tooltip>
              : null }

            { is_disabled || is_closed
                ? (
                  <div className='action-buttons'>
                    <Tooltip mouseLeaveDelay={0} title='Communication is closed/stopped'>
                      <div style={{ cursor: 'default' }}><Icon type='close-circle' /></div>
                    </Tooltip>

                    {(!is_disabled && is_manually_closed[0] && is_processed_or_sent[0]) ? (
                      <Tooltip mouseLeaveDelay={0} title='Reschedule'>
                        <div style={{ cursor: 'default' }}><Icon type='history' onClick={() => this.handleReschedule(item)} /></div>
                        </Tooltip>
                    ) : null}
                  </div>
                )
                : this.hasAccess(Permissions.INVOICE.INFO.UPDATE) && isEnableResend
                  ? (isEnableResend === 1
                    ? <Tooltip mouseLeaveDelay={0} title={tooltipText}>
                      <Popconfirm
                        title='Are you sure you want to reschedule / resend this comm?'
                        onConfirm={() => this.handleRescheduleResend(item)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <div style={{cursor: 'pointer'}}><Icon type={icon} /></div>
                      </Popconfirm>
                    </Tooltip>
                    : isEnableResend === 3
                      ? <div className='action-buttons'>
                          <Tooltip mouseLeaveDelay={0} title='Reschedule / Resend'>
                            <Popconfirm
                              title={`Are you sure you want to cancel this mail and resend?`}
                              onConfirm={() => this.handleRescheduleResend(item)}
                              okText='Yes'
                              cancelText='No'
                            >
                              <div style={{ cursor: 'pointer' }}><Icon type='mail' /></div>
                            </Popconfirm>
                          </Tooltip>
                          {/* <Tooltip mouseLeaveDelay={0} title={tooltipText}>
                            <Popconfirm
                              title={`Update this comm with reply status?`}
                              onConfirm={() => this.handleUpdateReplyStatus(item)}
                              okText='Yes'
                              cancelText='No'
                            >
                            <div style={{cursor: 'pointer'}}><Icon type={icon} /></div>
                            </Popconfirm>
                          </Tooltip> */}
                        </div>
                      : isEnableResend === 2
                        ? <div className='action-buttons'>
                          <Tooltip mouseLeaveDelay={0} title={tooltipText}>
                            <div style={{ cursor: 'default' }}><Icon type={icon} /></div>
                          </Tooltip>
                          { /** the cancel send button is only available during the comm is scheduled to send */}
                          {/* <Tooltip mouseLeaveDelay={0} title={'Cancel Sending Comm'}>
                            <Popconfirm
                              title={<div>
                                <div>Are you sure you want to cancel sending this comm?</div>
                                <div>This comm will be treated as closed if proceeded.</div>
                              </div>}
                              onConfirm={() => this.handleUpdateStop(item)}
                              okText='Yes'
                              cancelText='No'
                            >
                            <div style={{cursor: 'pointer', color: '#FF0000'}}><Icon type={'stop'} /></div>
                            </Popconfirm>
                          </Tooltip> */}
                          <Tooltip mouseLeaveDelay={0} title='Cancel Sending Comm'>
                            <div style={{ cursor: 'pointer', color: '#FF0000' }}><Icon type='stop' onClick={() => this.handleUpdateStop(item)} /></div>
                          </Tooltip>
                        </div>
                        : <Tooltip mouseLeaveDelay={0} title={tooltipText}>
                          <div style={{ cursor: 'default' }}><Icon type={icon} /></div>
                        </Tooltip>
                    )
                  : null }
          </div>
        }
      }
    ]


    return (
      <Loading loading={loading} blur>
        <Spin spinning={loadingUpdate}>
        <Panel title={'Communication'}>
          <div>
            <div className='task-list'>
              <Skeleton loading={loadingList} active>
                <List cols={listColumns} rows={commList.list} />

                <Pager
                  size={pageSize}
                  total={commList.total}
                  totalText={`Total ${commList.total} comm`}
                  current={currentPage}
                  onChange={this.changePage}
                  style={{ marginTop: '15px' }}
                />

              </Skeleton>
            </div>
          </div>
        </Panel>

        <AddCommModal
          invoiceId={invoiceId}
          invoiceInfo={invoiceInfo}
          key={`addcommmodal${modalItem && modalItem.id ? modalItem.id : '_new'}`}
          item={modalItem}
          onClose={() => this.showCommModal(false)}
          onUpdate={(isEdit, r) => this.updateCommSaved(isEdit, r)}
          visible={showCommModal}
        />
        </Spin>
      </Loading>
    )
  }

  fetchComm = async ({ currentPage = 1, filter = {}, searchText = '', sort }, iid) => {
    if (!this.hasAccess(Permissions.INVOICE.INFO.LIST)) return

    this.setState({loadingList: true})
    const invoiceId = iid || this.props.invoiceId
    if (!invoiceId) return

    filter.genre = 'invoice'
    filter.genre_id = invoiceId
    const list = await commService.listByPage(currentPage, pageSize, filter, sort, searchText)

    this.setState({
      commList: list && validator.isArray(list.list) ? list : { list: [], total: 0 },
      currentPage,
      loadingList: false
    })
  }

  showCommModal = (showCommModal, modalItem = {}, cb = () => {}) => {
    if (showCommModal) {
      modalItem.content_formatted = modalItem.content ? modalItem.content.replace(/<br \/>/g, '\n').replace(/<br\/>/g, '\n') : modalItem.content
    }

    this.setState({ showCommModal, modalItem }, () => cb())
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchComm({ currentPage, filter, searchText, sort })
  }

  updateCommSaved = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'

    this.showCommModal(false, {}, () => {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `Comm message ${action} successfully.`)
        } else {
          if (r.invalid && r.errors) {
            notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `${formatter.toErrorMessage(r.errors)}`)
          } else {
            notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} comm message successfully.`)
          }
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} comm message successfully. Please try again later`)
      }

      const { currentPage, filter, searchText, sort } = this.state
      this.fetchComm({currentPage, filter, searchText, sort})
    })
  }

  handleScheduleProcess = async (item) => {
    const { cmr_id } = item
    const { invoiceId } = this.props
    const action = 'schedule'
    const action2 = 'scheduled'

    if (validator.isNotEmptyArray(cmr_id)) {
      const data = {
        update_schedule_status: true
      }

      this.setState({loadingUpdate: true})
      const r = await commService.update(cmr_id[0], data)
      if (r && r.id) {
        notify.success(`${formatter.capitalize(action)} Successfully`, `Comm status ${action2} successfully.`)

        const t = CommTypeName.find(e => e.type === item.comm_type)
        log.updateInvoiceCommText(invoiceId, `comm update schedule${t ? ` - ${t.action}` : ''}`, 'Comm is scheduled manually.')
      } else {
        notify.error(`Unable to ${formatter.capitalize(action)} Successfully`, `Unable to ${action} comm successfully. Please try again later`)
      }

      this.setState({loadingUpdate: false})
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchComm({currentPage, filter, searchText, sort})
    } else {
      notify.error(`Unable to ${formatter.capitalize(action)} Successfully`, `Unable to ${action} comm  successfully. Please try again later`)
    }
  }

  handleRescheduleResend = async (item) => {
    const { invoiceId } = this.props
    const action = 'resend / reschedule'
    const action2 = 'resent / rescheduled'
    this.setState({loadingUpdate: true})

    const r = await commService.resend(item.id, {})
    if (r && r.id) {
      notify.success(`${formatter.capitalize(action)} Successfully`, `Comm message ${action2} successfully.`)

      const t = CommTypeName.find(e => e.type === item.comm_type)
      log.updateInvoiceCommText(invoiceId, `comm resend${t ? ` - ${t.action}` : ''}`, 'Comm is resent.')
    } else {
      notify.error(`Unable to ${formatter.capitalize(action)} Successfully`, `Unable to ${action} comm message successfully. Please try again later`)
    }

    this.setState({loadingUpdate: false})
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchComm({currentPage, filter, searchText, sort})
  }

  handleUpdateReplyStatus = async (item, updateType) => {
    const { cmr_id } = item
    const { invoiceId } = this.props
    const action = 'update'
    const action2 = 'updated'

    if (validator.isNotEmptyArray(cmr_id)) {
      const data = {
        update_reply_status: true,
        update_type: updateType
      }

      this.setState({loadingUpdate: true})
      const r = await commService.update(cmr_id[0], data)
      if (r && r.id) {
        notify.success(`${formatter.capitalize(action)} Successfully`, `Comm status ${action2} successfully.`)

        const t = CommTypeName.find(e => e.type === item.comm_type)
        log.updateInvoiceCommText(invoiceId, `comm reply update${t ? ` - ${t.action}` : ''}`,  `Comm's reply is updated manually and it is ${updateType}.`)
      } else {
        notify.error(`Unable to ${formatter.capitalize(action)} Successfully`, `Unable to ${action} comm status successfully. Please try again later`)
      }

      this.setState({loadingUpdate: false})
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchComm({currentPage, filter, searchText, sort})
    } else {
      notify.error(`Unable to ${formatter.capitalize(action)} Successfully`, `Unable to ${action} comm status successfully. Please try again later`)
    }
  }

  handleReschedule = async (item) => {
    const { cmr_id } = item

    if (validator.isNotEmptyArray(cmr_id)) {
      const data = { update_to_stop: false }

      this.setState({loadingUpdate: true})
      const r = await commService.update(cmr_id[0], data)

      if (r && r.id) {
        this.handleRescheduleResend(item)
        const t = CommTypeName.find(e => e.type === item.comm_type)
      } else {
        if (r.invalid && r.errors) {
          notify.error(`Unable to Reschedule Successfully`, `${formatter.toErrorMessage(r.errors)}`)
        } else {
          notify.error(`Unable to Reschedule Successfully`, `Unable to rescheduled successfully. Please try again later`)
        }
      }

      this.setState({loadingUpdate: false})
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchComm({currentPage, filter, searchText, sort})
    } else {
      notify.error(`Unable to Stop Successfully`, `Unable to stop comm sending successfully. Please try again later`)
    }
  }

  handleUpdateStop = async (item) => {
    const { cmr_id } = item
    const { invoiceId } = this.props

    if (validator.isNotEmptyArray(cmr_id)) {
      const data = {
        update_to_stop: true
      }

      this.setState({loadingUpdate: true})
      const r = await commService.update(cmr_id[0], data)
      if (r && r.id) {
        notify.success(`Comm Stopped Successfully`, `Comm stopped and closed successfully.`)

        const t = CommTypeName.find(e => e.type === item.comm_type)
        log.updateInvoiceCommText(invoiceId, `comm stopped`,  `Comm is stopped manually and it is closed.`)
      } else {
        if (r.invalid && r.errors) {
          notify.error(`Unable to Stop Successfully`, `${formatter.toErrorMessage(r.errors)}`)
        } else {
          notify.error(`Unable to Stop Successfully`, `Unable to stop comm sending successfully. Please try again later`)
        }
      }

      this.setState({loadingUpdate: false})
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchComm({currentPage, filter, searchText, sort})
    } else {
      notify.error(`Unable to Stop Successfully`, `Unable to stop comm sending successfully. Please try again later`)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Invoice }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoiceComm))
