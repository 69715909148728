import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_ADDED_LINK_REQUESTS, FETCH_REMOVED_LINK_REQUESTS, FETCHING_LINK_REQUESTS, LINK_REQUESTS_FETCHED
} from '../actions/linkRequest'
import linkRequestService from '../../services/linkRequest'

function* fetchAddedLinkRequests({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_LINK_REQUESTS, loading })
    const linkRequests = yield linkRequestService.listAddedByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: LINK_REQUESTS_FETCHED, linkRequests, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_LINK_REQUESTS, loading: false })
    console.error(e)
  }
}

function* fetchRemovedLinkRequests({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_LINK_REQUESTS, loading })
    const linkRequests = yield linkRequestService.listRemovedByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: LINK_REQUESTS_FETCHED, linkRequests, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_LINK_REQUESTS, loading: false })
    console.error(e)
  }
}

function* watchLinkRequest() {
  yield takeEvery(FETCH_ADDED_LINK_REQUESTS, fetchAddedLinkRequests)
  yield takeEvery(FETCH_REMOVED_LINK_REQUESTS, fetchRemovedLinkRequests)
}

export default watchLinkRequest
