import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { authService, redirectService } from '../../services'
import { auth, common, localStorage, sessionStorage, validator } from '../../util'
import { redirectHostname } from '../../config'

// UI
import { Spin } from '../../components'
import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import './styles.css'

const FormItem = Form.Item

class Redirect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isRedirect: true
    }
  }

  componentDidMount () {
    this.fetchRedirect()
  }

  fetchRedirect = async() => {
    const { location } = this.props
    let isRedirectAvailable = false
    const redirectKey = auth.getCurrentRedirectToken()

    if (location && location.search) {
      const q = common.getQueryStringSearchParams(location.search)

      if (validator.isNotEmptyObject(q) && q.key) {
        try {
          if (redirectKey && redirectKey === q.key) {
            const r1 = await redirectService.exit({ redirect_token: q.key })

            if (r1 && r1.id) {
              await localStorage.clear()
              await sessionStorage.clear()
              await localStorage.setItem('redirected', 'true')

              this.redirectToHost()
            }
          } else {
            const r = await redirectService.enter({ redirect_token: q.key })
            const { permissions, resetPassword, redirect_token, token, user, role } = r

            if (token) {
              isRedirectAvailable = true

              user.role = role
              localStorage.setItem('redirected', false)
              localStorage.setItem('rememberMe', false)
              auth.setCurrentToken(token)
              auth.setCurrentUser(user)
              auth.setPermission(permissions)
              auth.setCurrentRedirectToken(redirect_token)
              sessionStorage.removeItem('tokenExpired')
              sessionStorage.removeItem('tokenRevoked')
              sessionStorage.removeItem('unauthorized')
              this.setState({ loading: false })

              window.location.href = '/'
            }
          }
        } catch (e) {

        }
      }

      if (!isRedirectAvailable) {
        this.redirectToHost()
      }
    }
  }

  redirectToHost = () => {
    this.setState({ isRedirect: false })

    setTimeout(() => {
      window.location.href = redirectHostname
    }, 3000)
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { isRedirect } = this.state

    return (
      <div className='login'>
        <div className='login-box'>
          <Row>
            <Col xs={0} sm={0} lg={14}>
              <div className='login-left-side'>
                <img alt='' src={process.env.PUBLIC_URL + '/img/login/organizer.svg'} className='login-pic' />
              </div>
            </Col>

            <Col sm={24} lg={10} className='box'>
              <div className='center'>
                <img className='logo' alt='Pumpkin IT' src={process.env.PUBLIC_URL + '/img/pumpkinIT-long.png'} />
              </div>

              <div className='center'>
                <div className='title'>Pumpkin PM</div>
              </div>

              <div className='center'>
                { isRedirect
                  ? <div className='title-prog'>Redirecting....</div>
                  : <div className='title-err'>Unable to redirect. Will back to the Hub shortly...</div>}
              </div>
            </Col>
          </Row>
        </div>
        <div className='copyright'>
          <div className='footer'>
            Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2021-22 Pumpkin IT. All rights reserved.
          </div>
        </div>
      </div>
    )
  }

}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Auth }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Redirect))
