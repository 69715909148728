import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingReasonService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Loading, Page, Panel, SideModal } from '../../../../components'
import { Permissions } from '../../../../constants'
import Empty from 'antd/lib/empty'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

import { auth, formatter, validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form

const SetList = [
  { tabId: 1, path: '/cancellations', type: 'cancellations', title: 'Cancellation' },
  { tabId: 2, path: '/inactive', type: 'inactive', title: 'Inactive' }
]

const settingTitle = 'Cancellation Reasons'
const settingType = 'Cancellation Reasons'

export class SettingReasonsEdit extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const set = SetList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      item: {},
      itemList: { list: [] , total: 0},
      currentPage: 1,
      isShowModal: false,
      loading: false,
      loadingForm: false,
      loadingList: false,
      selectedItem: {},
      type: type,
      set: set
    }
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchReason()
    }
  }

  fetchReason = async () => {
    if (!this.hasAccess(Permissions.SETTING.REASON.READ)) return

    try {
      const id = this.getId()
      this.setState({ loading: true })
      const item = await settingReasonService.getReason(id)
      const filter = {
        reason_id: { condition: '=', value: id }
      }
      const list = await settingReasonService.listReasonItemByPage(1, pageSize, filter)

      this.setState({ item, itemList: list, currentPage: 1, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load reason successfully. Please try again later.')
    }
  }

  fetchReasonItems = async (currentPage = 1) => {
    try {
      const id = this.getId()
      const filter = {
        reason_id: { condition: '=', value: id }
      }
      this.setState({ loadingList: true })
      const list = await settingReasonService.listReasonItemByPage(currentPage, pageSize, filter)

      this.setState({ itemList: list, currentPage, loadingList: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load reasons successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { resetFields } = form
    resetFields()
    this.setState({ isShowModal: false, selectedItem: {} })
  }

  showModal = (item = {}) => {
    this.setState({ isShowModal: true, selectedItem: item })
  }

  handleSaveSet = async () => {
    const { form, history } = this.props
    const { validateFields } = form
    const { set } = this.state

    validateFields(['setName'], async (errors, values) => {
      try {
        this.setState({ loadingForm: true })
        const body = {
          name: values.setName,
          type: set.type
        }

        let r = null
        if (this.isEdit()) {
          const id = this.getId()
          r = await settingReasonService.saveReason(id, body)
        } else {
          body.code = body.name ? body.name.toLowerCase() : body.name
          r = await settingReasonService.addReason(body)
        }

        if (r && r.id) {
          notify.success('Saved successfully', `${set.title} saved successfully.`)
          if (!this.isEdit()) {
            history.replace(`/settings/reasons${set.path}/${r.id}`)
          }
        } else {
          notify.error('Unable to save successfully', `Unable to save ${set.title} successfully. Please try again later.`)
        }

        this.setState({ loadingForm: false })
      } catch (e) {
        this.setState({ loadingForm: false })
        notify.error('Unable to save successfully', `Unable to save ${set.title} successfully. Please try again later.`)
      }

    })
  }

  handleSaveItem = () => {
    const { form, match } = this.props
    const { validateFields } = form
    const { selectedItem, set } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        try {
          const id = this.getId()
          this.setState({ loadingForm: true })
          const body = {
            name: values.name
          }

          let r = null
          if (selectedItem.id) {
            r = await settingReasonService.saveReasonItem(selectedItem.id, body)
          } else {
            body.reason_id = id
            r = await settingReasonService.addReasonItem(body)
          }

          if (r && r.id) {
            notify.success('Saved successfully', `${set.title} reason saved successfully.`)
            this.fetchReasonItems()
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${set.title} reason successfully. Please try again later.`)
          }

          this.setState({ loadingForm: false })
        } catch (e) {
          this.setState({ loadingForm: false })
          notify.error('Unable to save successfully', `Unable to save ${set.title} reason successfully. Please try again later.`)
        }
      }
    })
  }

  async handleDeleteItem (id) {
    const { set } = this.state
    this.setState({loadingList: true})
    try {
      const res = await settingReasonService.removeReasonItem(id)

      if (res && res.id) {
        notify.success('Deleted successfully', `${set.title} Reason deleted successfully`)
        this.fetchReasonItems()
      } else {
        notify.error('Unable to delete successfully', `Unable to delete ${set.title} Reason successfully. Please try again later.`)
        this.setState({loadingList: false})
      }
    } catch (e) {
      notify.error('Unable to delete successfully', `Unable to delete ${set.title} Reason successfully. Please try again later.`)
      this.setState({loadingList: false})
    }

  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, item, itemList, isShowModal, loading, loadingForm, loadingList, selectedItem, set } = this.state
    const { form, history } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={item.id ? `${set.title} ${item.name ? `- ${item.name}` : ''}` : `New ${set.title} Reason`}>
            { (!this.isEdit() && this.hasAccess(Permissions.SETTING.REASON.CREATE)) || (this.isEdit() && this.hasAccess(Permissions.SETTING.REASON.UPDATE))
              ? <div className='btn' onClick={!loadingForm ? () => this.handleSaveSet() : null}>
                Save
              </div>
              : null }
            <div className='btn' onClick={() => history.goBack()}>
              Back
            </div>
          </Page.Header>

          <div className='setting-list'>

            <Skeleton loading={loading} active>
              <Loading loading={loadingForm} blur>
                <Row className='holiday-panel'>
                  <Col lg={20}>
                    <FormItem label='Type' {...formItemLayout}>
                      {getFieldDecorator('setName', {
                        initialValue: item.name || '',
                        rules: [
                          { min: 2, message: 'Name must be between 2 and 128 characters' },
                          { max: 128, message: 'Name must be between 2 and 128 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter name' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                { this.isEdit()
                  ? <Loading loading={loadingList} blur>
                    <Row>
                      <Col lg={16}>
                      </Col>
                      <Col lg={8} className='holiday-title-action'>
                        { this.hasAccess(Permissions.SETTING.REASON.CREATE)
                          ? <div className='btn' style={{ width: '120px', float: 'right' }} onClick={() => this.showModal()}>
                            Add Reason
                          </div>
                          : null }
                      </Col>
                    </Row>
                    <Row className='list-header'>
                      <Col md={20}>Reason</Col>
                    </Row>
                    { validator.isNotEmptyArray(itemList.list)
                      ? itemList.list.map((item) => {
                        const { id, name, reason_item_is_editable } = item

                        return (name
                          ? <div className='list-item' key={id}>
                            <Row className='list-content'>
                              <Col md={22}>{ name }</Col>
                              <Col md={1}>
                                { this.hasAccess(Permissions.SETTING.REASON.UPDATE)
                                  ? <div onClick={() => this.showModal(item)}><Icon type='form' /></div>
                                  : null }
                              </Col>
                              <Col md={1}>
                                { this.hasAccess(Permissions.SETTING.REASON.DELETE) && reason_item_is_editable
                                  ? <Popconfirm
                                    title='Confirm to delete this?'
                                    onConfirm={(e) => this.handleDeleteItem(id)}
                                    okText='Yes'
                                    cancelText='No'
                                  ><Icon type='delete' />
                                  </Popconfirm>
                                  : null }
                              </Col>
                            </Row>
                          </div>
                          : null
                        )})
                      : <Empty description={<span>
                          No reason yet, <a onClick={() => this.showModal()}>create one</a>?
                        </span>}
                      />
                    }
                  </Loading>
                  : null }
              </Loading>
            </Skeleton>
          </div>

        </Page.Content>

        <SideModal
          title={`${set.title} Reasons`}
          showModal={isShowModal}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSaveItem} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Loading loading={loadingForm} blur>
            <Form layout='vertical'>
              <FormItem label='Reason'>
                {getFieldDecorator('name', {
                  initialValue: selectedItem.name,
                  rules: [
                    { min: 2, message: 'Reason must be between 2 and 128 characters' },
                    { max: 128, message: 'Reason must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter reason' },
                    { whitespace: true, message: 'Please enter reason' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Form>
          </Loading>
        </SideModal>
      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingReasonsEdit))
