import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: 'Credits',
            icon: 'dollar',
            menu: [
              {
                name: 'New Credit',
                link: '/credits/add',
                permission: Permissions.CREDIT.INFO.CREATE
              },
              {
                name: 'List Credits',
                link: '/credits',
                permission: Permissions.CREDIT.INFO.LIST
              },
            ],
          }
        ]
      }
    ]
  }
]
