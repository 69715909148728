import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { apiHostname } from '../../../config'
import { fetchStatements } from '../../../states/actions/statement'
import { auth, common, formatter, queryString, validator } from '../../../util'
import { authService, statementService } from '../../../services'
import { InvoiceListType, Permissions, StatementMenu } from '../../../constants'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 40
const Option = Select.Option

const getTitle = (type) => {
  let title

  if (type === InvoiceListType.INV_LIST_PM) {
    title = 'PM SB Statements'
  } else if (type === InvoiceListType.INV_LIST_STD) {
    title = 'Std SB Statements'
  } else {
    title = 'Statement Periods'
  }

  return title
}

export class StatementListPeriod extends Component {
  constructor (props) {
    super(props)
    const { type = InvoiceListType.INV_LIST_PM } = common.getPath(props.match)
    const title = getTitle(type)
    this.state = {
      currentPage: 1,
      filter: {},
      filterType: type,
      isUpdateFilter: false,
      isInitialised: false,
      list: [],
      loading: false,
      loadingList: false,
      searching: false,
      searchText: '',
      sort: {},
      title: title,
      total: 0
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    // this.onSelectActive = this.onSelectActive.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { statements: { list, total }, loading, loadingList, location, match, fetchStatements } = nextProps

    const { type = InvoiceListType.INV_LIST_PM } = common.getPath(match)
    const { page } = common.getQueryStringSearchParams(location.search)
    let title = prevState.title

    if (prevState.filterType !== type) {
      fetchStatements({
        loading: prevState.isInitialised ? false : true,
        loadingList: prevState.isInitialised ? true : false,
        currentPage: 1,
        pageSize,
        filter: prevState.filter,
        filterType: type,
        sort: prevState.sort,
        searchText: prevState.filter.$and ? '' : prevState.searchText
      })

      title = getTitle(type)
    }

    const state = {
      ...prevState,
      list,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage,
      filter: prevState.filter,
      filterType: type,
      loading,
      loadingList,
      total,
      title
    }

    return state
  }

  componentDidMount () {
    const { currentPage, filter, filterType, loading, searchText, sort } = this.state
    this.fetchListPeriod({ isInitialised: false, currentPage, filter, filterType, loading, searchText, sort })
  }

  onSearchName (value) {
    const { currentPage, filter, filterType, loading, sort } = this.state
    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { abn: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchListPeriod({ currentPage: 1, filter, filterType, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }


  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  getColumns () {
    const { filterType } = this.state

    const columns = [
      {
        title: 'Period',
        width: 3,
        render: ({ period_fullname }) => <div>{period_fullname}</div>
      },
      {
        title: 'Total Participants',
        width: 3,
        render: ({ client_count }) => <div>{client_count}</div>
      },
      {
        title: 'Total Invoiced Amount',
        width: 3,
        render: ({ subtotal, pm_subtotal, std_subtotal }) => {
          return (
            filterType === InvoiceListType.INV_LIST_PM
            ? <div>{formatter.toPrice(pm_subtotal)}</div>
            : filterType === InvoiceListType.INV_LIST_STD
              ? <div>{formatter.toPrice(std_subtotal)}</div>
              : <div>{formatter.toPrice(subtotal)}</div>
          )
        }
      },
      {
        title: 'Total Paid Amount',
        width: 3,
        render: ({ cum_rcv_total, cum_rcv_pm_total, cum_rcv_std_total }) => {
          return (
            filterType === InvoiceListType.INV_LIST_PM
            ? <div>{formatter.toPrice(cum_rcv_pm_total)}</div>
            : filterType === InvoiceListType.INV_LIST_STD
              ? <div>{formatter.toPrice(cum_rcv_std_total)}</div>
              : <div>{formatter.toPrice(cum_rcv_total)}</div>
          )
        }
      }
    ]

    if (filterType === InvoiceListType.INV_LIST_PM || filterType === InvoiceListType.INV_LIST_STD) {
      if (filterType === InvoiceListType.INV_LIST_PM) {
        columns.push({
          title: 'Total Credit Balance',
          width: 3,
          render: ({ cum_credit_remaining_total }) => <div>{formatter.toPrice(cum_credit_remaining_total)}</div>
        })
      }

      columns.push({
        title: '',
        width: 1,
        render: ({ id, period_month }) => filterType === InvoiceListType.INV_LIST_PM || filterType === InvoiceListType.INV_LIST_STD
          ? <div className='action-buttons'>
            <Link to={`/statement/period/${filterType}/${period_month}`}><div><Icon type='eye' /></div></Link>
          </div>
          : null
      })
    }

    return columns
  }

  render () {
    const { currentPage, filterType, list, loading, loadingList, searching, total, title } = this.state

    const columns = this.getColumns()

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={StatementMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={title} />

          <Page.Filter>
            {/* <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Name, Acc Ref ID, ABN, Suburb</ControlLabel>
                <SearchInput placeholder='Search providers' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={8}>
                <ControlLabel>Active</ControlLabel>
                <Select defaultValue='true' style={{ width: 120 }} onChange={this.onSelectActive}>
                  <Option value='true'>Active</Option>
                  <Option value='false'>Inactive</Option>
                </Select>
              </Col>
            </Row> */}
          </Page.Filter>

          <Skeleton loading={loading} active>
            <Spin spinning={loadingList}>
              <List cols={columns} rows={list} />
            </Spin>
          </Skeleton>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} periods`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, filterType, searchText, sort } = this.state
    this.fetchListPeriod({ currentPage, filter, filterType, searchText, sort })
  }

  fetchListPeriod = async ({ isInitialised = true, currentPage = 1, filter = {}, filterType = InvoiceListType.INV_LIST_PM, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.STATEMENT.INFO.LIST)) return

    try {
      const { fetchStatements } = this.props
      this.setState({ currentPage, loading: isInitialised ? false : true, loadingList: isInitialised ? true : false })

      fetchStatements({
        loading: isInitialised ? false : true,
        loadingList: isInitialised ? true : false,
        currentPage,
        pageSize,
        filter: filter,
        filterType: filterType,
        sort: sort,
        searchText: filter.$and ? '' : searchText
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load period list successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchStatements
}

const mapStateToProps = (state) => {
  return { ...state.Statement }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatementListPeriod)
