import React, { Component } from 'react'
import { settingCustomIdfService } from '../../../../services'
import { CustomIdfInputType, Permissions } from '../../../../constants'
import { auth } from '../../../../util'

import { Button, Loading, SideModal } from '../../../../components'
// import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

export class AddIdfModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: {},
      isEdit: false,
      moduleItem: {},
      loading: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { item = {}, moduleItem = {} } = nextProps
    const isEdit = !!item.id
    return { ...prevState, item, isEdit, moduleItem }
  }

  handleSubmit = async () => {
    const { form, onUpdate } = this.props
    const { moduleItem, item, isEdit = false } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })

        let r = null

        if (isEdit) {
          r = await settingCustomIdfService.saveCustomIdf(item.id, values)
        } else {
          values.module_id = moduleItem.moduleId
          values.module_type = moduleItem.moduleType

          r = await settingCustomIdfService.addCustomIdf(values)
        }

        this.setState({ loading: false })

        if (onUpdate) {
          if (r && r.id) {
            resetFields()
          }
          onUpdate(isEdit, r)
        }
      }
    })
  }

  handleType (value) {
    const { form } = this.props
    const { setFieldsValue } = form

    if (value === 'toggle') {
      setFieldsValue({ 'is_include_notes': true })
    } else {
      setFieldsValue({ 'is_include_notes': false })
    }
  }

  checkIsNotEmpty = (value) => {
    return value !== null && value !== undefined
  }

  render () {
    const { visible, form, onClose } = this.props
    const { isEdit, item, loading } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit Custom Identifier' : 'Add Custom Identifier'
    const isDisabled = item.is_editable === false

    return (
      <SideModal
        key={`sideidf_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            { (isEdit && this.hasAccess(Permissions.SETTING.CUSTOM_IDF.UPDATE) || (!isEdit && this.hasAccess(Permissions.SETTING.CUSTOM_IDF.CREATE))) && !isDisabled
              ? <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
              : null }
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <FormItem label='Label'>
              {getFieldDecorator('label', {
                initialValue: item.label,
                rules: [
                  { min: 2, message: 'Label must be between 2 and 127 characters' },
                  { max: 128, message: 'Label must be between 2 and 127 characters' },
                  { required: true, message: 'Please enter label' },
                  { whitespace: true, message: 'Please enter label' },
                  { validator: this.checkLabel }
                ]
              })(
                <Input disabled={isDisabled} />
              )}
            </FormItem>

            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: this.checkIsNotEmpty(item.active) ? item.active : true,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Enable'
                  unCheckedChildren='Disable'
                  disabled={isDisabled}
                />
              )}
            </FormItem>

            <FormItem label='Input Type' style={{display: 'none'}}>
              {getFieldDecorator('input_type', {
                initialValue: item.input_type || 'toggle',
                rules: [
                  { required: true, message: 'Please select a input type' }
                ]
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder='Please select a input type'
                  onChange={(type) => this.handleType(type)}
                  disabled={isDisabled}
                >
                  {
                    CustomIdfInputType.map((types, idx) => {
                      return <Option key={`${types.value}${idx}`} value={types.value}>{types.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>

            {/* <FormItem label='Include Export'>
              {getFieldDecorator('is_include_export', {
                initialValue: this.checkIsNotEmpty(item.is_include_export) ? item.is_include_export : true,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
            </FormItem>

            <FormItem label='Include Notes?'>
              {getFieldDecorator('is_include_notes', {
                initialValue: this.checkIsNotEmpty(item.is_include_notes) ? item.is_include_notes : true,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
            </FormItem>

            <FormItem label='Allow Log'>
              {getFieldDecorator('is_log_allowed', {
                initialValue: this.checkIsNotEmpty(item.is_log_allowed) ? item.is_log_allowed : true,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
            </FormItem> */}
          </Form>
        </Loading>
      </SideModal>
    )
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

export default Form.create()(AddIdfModal)
