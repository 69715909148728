import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { authService, reportSchedulerService } from '../../../services'
import { auth, formatter, validator } from '../../../util'

// UI
import { Button, List, Page, Pager } from '../../../components'
import { FileUploadMsg, Permissions, ReportRequestType } from '../../../constants'
import { apiHostname } from '../../../config'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Upload from 'antd/lib/upload'

import './styles.css'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane
const Option = Select.Option

const displayFormat = 'DD/MM/YYYY HH:mm:ss'

const ReportType = [
  ReportRequestType.SB_COMPARE,
  ReportRequestType.INV_RCV_IMPORT
]

const formItemLayout = {
  labelCol: { sm: 3, md: 3, lg: 3 },
  wrapperCol: { sm: 12, md: 12, lg: 12 }
}

export class ReportCustomGenerate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      fileList: [],
      fileUploadedList: {},
      isSubmit: false,
      list: [],
      loading: true,
      selectedType: null,
      showModal: false,
      searchText: '',
      sort: {},
      total: 0,
      uploadErrorMsg: ''
    }
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchGenerateList({ currentPage, filter, loading, searchText, sort })
  }

  fetchGenerateList = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.REPORT.SCHEDULER.LIST)) return

    try {
      this.setState({ loading, currentPage })
      const list = await reportSchedulerService.listSchedulerReportByPage(currentPage, pageSize, filter, sort, searchText)

      if (list && validator.isArray(list.list)) {
        this.setState({ list: list.list, total: list.total, loading: false })
      } else {
        notify.error('Unable to load successfully', 'Unable to load scheduled list successfully. Please try again later.')
        this.setState({ loading: false })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load scheduled list successfully. Please try again later.')
    }
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchGenerateList({ currentPage, filter, searchText, sort })
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({fileList: newFileList, fileUploadedList: {}})
    }
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({fileUploadedList: data, uploadErrorMsg: ''})
      }
    }
  }

  fileSet = (file) => {
    if (file && (
        file.type === 'text/csv' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
      )) {
      this.setState({fileList: [file], uploadErrorMsg: ''})
      return true
    } else {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatCSV })
      return false
    }

    // return false
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  handleModal = (showModal) => {
    const { form } = this.props
    if (!showModal) form.resetFields()
    this.setState({
      showModal,
      selectedType: showModal ? this.state.selectedType : null,
      fileList: [],
      fileUploadedList: {}
    })
  }

  handleTypeChange (e) {
    this.setState({ selectedType: e })
  }


  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  submitGenerateRequest = async () => {
    const { form } = this.props
    const { fileList, fileUploadedList, selectedType } = this.state

    if (fileList.length === 0) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
    } else if (!fileUploadedList.uid) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
    } else {
      this.setState({ isSubmit: true })

      const body = {
        ...fileUploadedList,
        type: selectedType
      }

      try {
        const r = await reportSchedulerService.createScheduleReport(body)

        if (r && r.id) {
          form.resetFields()
          notify.success('Create Report Generate Request Successfully', 'Report Request is created successfully.')
          this.setState({ isSubmit: false, selectedType: null, showModal: false, fileList: [], fileUploadedList: {} })
          this.changePage(1)
        } else {
          notify.error('Unable to Create Report Request', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to create report request. Please try again later.')
          this.setState({ isSubmit: false, fileList: [], fileUploadedList: {} })
        }
      } catch (e) {
        notify.error('Unable to Create Report Request', e && e.errors ? formatter.toErrorMessage(e.errors) : 'Unable to create report request. Please try again later.')

        this.setState({ isSubmit: false, showModal: false, fileList: [], fileUploadedList: {} })
      }
    }
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { currentPage, fileList, filter, isSubmit, list, loading, searchText, selectedType, showModal, sort, total, uploadErrorMsg } = this.state
    const currentSelectedType = ReportType.find(e => e.code === selectedType)

    const columns = [
      {
        title: 'Requested At',
        width: 4,
        render: ({ created_at }) => <div>{formatter.toDate(created_at, displayFormat)}</div>
      },
      {
        title: 'Type',
        width: 5,
        render: ({ type }) => {
          const rt = ReportType.find(e => e.code === type)
          return <div>{rt ? rt.name : type}</div>
        }
      },
      {
        title: 'Status',
        width: 1,
        render: ({ status }) => <div>{formatter.capitalize(status)}</div>
      },
      {
        title: 'Completed At',
        width: 4,
        render: ({ completed_at }) => <div>{formatter.toDate(completed_at, displayFormat)}</div>
      },
      {
        title: 'Logs',
        width: 6,
        render: ({ log_message }) => <div style={{fontSize: '9px'}}>{log_message}</div>
      },
      {
        title: 'Requested By',
        width: 3,
        render: ({ member_name }) => <div>{member_name}</div>
      },
      {
        title: '',
        width: 1,
        render: ({ output_file_url }) => {
          return (
            <div className='action-buttons'>
              { this.hasAccess(Permissions.REPORT.SCHEDULER.READ) && output_file_url
                ? <div onClick={this.handleDownload(output_file_url)} style={{ cursor: 'pointer' }}>
                  <Icon type='download' />
                </div>
                : null }
            </div>
          )
        }
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Report Generate Request`}>
            { this.hasAccess(Permissions.REPORT.SCHEDULER.LIST)
              ? <Button key={`referesh`} feedback={loading} ghost onClick={() => this.fetchGenerateList({ filter, searchText, sort })}>
                REFRESH
              </Button>
              : null }

            { this.hasAccess(Permissions.REPORT.SCHEDULER.CREATE)
              ? <Button key={`create`} onClick={() => this.handleModal(true)}>
                CREATE REQUEST
              </Button>
              : null }
          </Page.Header>

          <div className='invoices'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={list} />
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} requests`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>

        { showModal
          ? <Modal
            visible={showModal}
            width={700}
            title={'Create Report Generate Request'}
            onCancel={isSubmit ? null : () => this.handleModal(false)}
            footer={[
              <Button key='close' ghost feedback={isSubmit} onClick={() => this.handleModal(false)}>Close</Button>,
              <Button key='submit' feedback={isSubmit} onClick={() => this.submitGenerateRequest()}>Submit & Request</Button>,
            ]}
          >
            <Spin spinning={isSubmit} blur>
              <Form>
                <div className='inv-title'>First, select the type of request.</div>

                <FormItem {...formItemLayout} hasFeedback>
                  {getFieldDecorator('type', {
                    initialValue: selectedType,
                    rules: [
                      { required: true, message: 'Please select request type.' }
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => this.handleTypeChange(e)}
                    >
                      { ReportType.map((type) => {
                          return <Option key={`rptype-${type.code}`} value={type.code}>{type.name}</Option>
                        }) }
                    </Select>
                  )}
                </FormItem>

                <div className='inv-title' style={{margin: '0px'}}>Then, upload the specified file for the request.</div>

                { currentSelectedType && currentSelectedType.uploadText
                  ? <div className='panel-content error'>{currentSelectedType.uploadText}</div>
                  : null }

                { currentSelectedType
                  ? <div style={{marginTop: '20px'}}>
                    <Upload
                      method={'POST'}
                      action={`${apiHostname}/private/api/report/scheduler/file`}
                      name={'file'}
                      onRemove={this.fileRemove}
                      onChange={this.fileChange}
                      beforeUpload={this.fileSet}
                      headers={{Authorization: `Bearer ${auth.getCurrentToken()}`}}
                      fileList={fileList}
                      multiple={false}
                    >
                      <Button>
                        <Icon type='upload' /> Select File
                      </Button>
                    </Upload>
                  </div>
                  : null }
                { uploadErrorMsg
                  ? <div className='panel-content error'>{uploadErrorMsg}</div>
                  : null }
              </Form>
            </Spin>
          </Modal>
          : null }
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportCustomGenerate))
