import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/invoices'
const urlItems = '/private/api/invoice-items'
const publicUrl = '/api/invoices'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getRef (id) {
    return request(`${url}/ref/${id}`)
  },
  getAuthStatus (id) {
    return request(`${url}/auth-status/${id}`)
  },
  getItems (id) {
    return request(`${urlItems}/list/${id}`)
  },
  getItemsRef (id) {
    return request(`${urlItems}/list/ref/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateStats (values) {
    return request(`${url}/status/update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateProda (values) {
    return request(`${url}/status/report/proda`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listViewByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPage (page, size, filter, sorting, sortingRaw, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), sortingRaw: sortingRaw || '', text })}`)
  },
  getRcvAmtInfo (itemId) {
    return request(`${url}/receive-amount/${itemId}`)
  },
  addRcvAmt (values) {
    return request(`${url}/receive-amount/update`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAbaInvoiceInfo (values) {
    return request(`${url}/aba/info`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateAba (values) {
    return request(`${url}/status/report/payment`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  exportInvoicePost (values) {
    return requestFile(`${url}/export/all`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  processInvAuth (values) {
    return request(`${publicUrl}/process/inv-auth`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
