import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/report/statement'

export default {
  listStatementPeriodByPage (page, size, filter, filterType, sorting, text) {
    return request(`${url}/period/list/${filterType}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listStatementClientByPage (periodId, page, size, filter, sorting, text) {
    return request(`${url}/participant/${periodId}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listStatement (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  get (id) {
    return request(`${url}/detail/${id}`)
  },
  getDate (id) {
    return request(`${url}/detail-date/${id}`)
  },
  getStatement (filter) {
    return requestFile(`${url}/list/get${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  getStatementPost (values) {
    return requestFile(`${url}/list/post`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getStatementPostMulti (values) {
    return requestFile(`${url}/list/multi/post`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
