import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { invoiceService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'

// UI
import { ControlLabel, Loading, List, Notification, Panel, Pager, SearchInput } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, validator } from '../../../util'

import './styles.css'

const notify = Notification

const pageSize = 20

export class ProviderInvoices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filterParam: 'incompleted',
      loading: false,
      loadingNext: false,
      invoices: { list: [], total: 0 },
      currentPage: 1,
      filter: {},
      searching: false,
      searchText: '',
      sort: {},
      total: 0
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    const { currentPage, filter, filterParam, searchText, sort } = this.state
    this.fetchInvoices({ currentPage, filter, filterParam, searchText, sort })
  }

  componentWillReceiveProps (nextProps) {
    const { currentPage, filter, filterParam, searchText, sort } = this.state
    if (nextProps.providerId !== this.props.providerId) {
      if (nextProps.providerId) {
        this.fetchInvoices({ currentPage, filter, filterParam, searchText, sort }, nextProps.providerId)
      }
    }
  }

  onSearchName (value) {
    const { filter, filterParam, sort } = this.state
    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              // { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_invoice_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_invoice_created_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { status: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchInvoices({ currentPage: 1, filter, filterParam, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value })
  }

  render () {
    const { form, providerId } = this.props
    const { currentPage, filterParam, loading, loadingNext, invoices, searching } = this.state

    const listColumns = [
      {
        title: 'Created Date',
        width: 3,
        render: ({ created_at }) => <span>{formatter.toShortDate(created_at)}</span>
      },
      {
        title: 'Process Date',
        width: 3,
        render: ({ processed_at }) => <span>{formatter.toShortDate(processed_at)}</span>
      },
      {
        title: 'Inv Date',
        width: 3,
        render: ({ invoice_date }) => <span>{formatter.toShortDate(invoice_date)}</span>
      },
      {
        title: 'Inv No',
        width: 3,
        render: ({ invoice_number }) => <span>{invoice_number}</span>
      },

      {
        title: 'Participant',
        width: 3,
        render: ({ client_id, client_ref_id, client_name }) => <a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_name}</a>
      },

      // {
      //   title: 'Provider',
      //   width: 3,
      //   render: ({ provider_name }) => <div>{provider_name}</div>
      // },

      {
        title: 'Subtotal',
        width: 3,
        render: ({ subtotal }) => <div>{formatter.toPrice(subtotal)}</div>
      },
      {
        title: 'Rcv Amt',
        width: 3,
        render: ({ received_subtotal }) => <div>{formatter.toPrice(received_subtotal)}</div>
      },
      {
        title: 'Item Count',
        width: 2,
        render: ({ qty }) => <div>{qty}</div>
      },
      {
        title: 'Status',
        width: 3,
        render: ({ status, status_name, status_color }) => <div className={'status'} style={{backgroundColor: status_color}}>
        {status_name ? status_name : formatter.capitalize(status)}
      </div>
      },

      {
        title: 'Action',
        width: 1,
        render: ({ id, ref_id }) => <div className='action-buttons'>
          { this.hasAccess(Permissions.PROVIDER.INVOICE.READ)
            ? <Link to={`/invoices/${ref_id}/info`}>
                <div style={{ color: '#D66E00' }}>
                <Tooltip mouseLeaveDelay={0} title='Edit'>
                  <Icon type='form' />
                </Tooltip>
              </div>
            </Link>
            : null }

        </div>
      }
    ]


    return (
      <Panel title={'Invoices'}>
        <div className='provider-inv-list'>
          { this.hasAccess(Permissions.PROVIDER.INVOICE.LIST)
            ? <Row style={{marginBottom: '20px'}} gutter={8}>
              <Col lg={8}>
                <ControlLabel>Date, Participant, Invoice Number, Status</ControlLabel>
                <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={10}>
                <div style={{marginLeft: '12.5pt'}}>
                  <ControlLabel>Invoices Completion</ControlLabel>
                </div>
                <Radio.Group onChange={this.filterInvoice} value={filterParam} style={{ marginLeft: 20 }}>
                  <Radio.Button value='incompleted'>Incompleted</Radio.Button>
                  <Radio.Button value='closed'>Closed</Radio.Button>
                  <Radio.Button value='all'>All</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            : null }

          <Skeleton loading={loading} active>
            <Loading loading={loadingNext} blur>

              <List cols={listColumns} rows={invoices.list} />

              <Pager
                size={pageSize}
                total={invoices.total}
                totalText={`Total ${invoices.total} invoices`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Loading>
          </Skeleton>
        </div>
      </Panel>
    )
  }

  changePage = (currentPage) => {
    const { filter, filterParam, searchText, sort } = this.state
    this.fetchInvoices({ currentPage, filter, searchText, sort, filterParam })
  }

  fetchInvoices = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }, pid = null) => {
    if (!this.hasAccess(Permissions.PROVIDER.INVOICE.LIST)) return

    try {
      const providerId = pid || this.props.providerId
      this.setState({ currentPage, loading: currentPage !== 1 ? false : true, loadingNext: currentPage === 1 ? false : true })

      if (!providerId) return

      if (filterParam === 'incompleted') {
        filter.status = { condition: '<>', value: 'inv-closed' }
      } else if (filterParam === 'closed') {
        filter.status = { condition: '=', value: 'inv-closed' }
      } else if (filterParam === 'all') {
        filter = {}
      }

      filter.provider_id = providerId
      const r = await invoiceService.listViewByPage(currentPage, pageSize, filter, sort, searchText)
      this.setState({
        loading: false,
        loadingNext: false,
        invoices: validator.isNotEmptyArray(r.list) ? r : { list: [], total: 0 },
        searching: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
    }
  }

  filterInvoice = (e) => {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchInvoices({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Provider }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ProviderInvoices))
