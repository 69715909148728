import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { authService, reportTypeService } from '../../../services'
import { auth, formatter, validator } from '../../../util'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import { Permissions } from '../../../constants'
import { apiHostname } from '../../../config'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Upload from 'antd/lib/upload'

import './styles.css'

const pageSize = 30
const VIEW_ENDING = 'ending'
const VIEW_ENDED = 'ended'
const VIEW_ACTIVE_NO_BUDGET = 'actnsb'

class ReportClientBudgetEnding extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      endingList: { list: [], total: 0 },
      endedList: { list: [], total: 0 },
      actnsbList: { list: [], total: 0 },
      isLoaded: false,
      loading: false,
      loadingList: false,
      view: VIEW_ENDING
    }
  }

  componentDidMount () {
    this.fetchBudgetList()
  }

  render () {
    const { currentPage, endingList, endedList, actnsbList, loading, loadingList, view } = this.state

    const endingColumns = [
      {
        title: 'Participant',
        width: 5,
        render: ({ client_ref_id, client_first_name, client_last_name }) => <a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_first_name} {client_last_name}</a>
      },
      {
        title: 'NDIS Number',
        width: 3,
        render: ({ client_ndis_number }) => <div>{client_ndis_number}</div>
      },
      {
        title: 'Booking ID',
        width: 3,
        render: ({ client_ref_id, booking_number }) => <a href={`/participants/${client_ref_id}/booking`} rel='noopener noreferrer' target='_blank'>{booking_number}</a>
      },
      {
        title: 'Service Booking Type',
        width: 4,
        render: ({ is_monthly_fee_main_set }) => <div>{is_monthly_fee_main_set ? 'Standard Booking' : 'Plan Managed'}</div>
      },
      {
        title: 'Start Date',
        width: 3,
        render: ({ period_start_date }) => <div>{formatter.toShortDate(period_start_date)}</div>
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ period_end_date }) => <div>{formatter.toShortDate(period_end_date)}</div>
      },
      {
        title: 'Remaining',
        width: 3,
        render: ({ remaining_days }) => (`${remaining_days === 0 ? `Ending TODAY` : `${remaining_days} day${remaining_days === 1 ? '' : 's'} to go`}`)
      }
    ]

    const endedColumns = [
      {
        title: 'Participant',
        width: 5,
        render: ({ client_ref_id, client_first_name, client_last_name }) => <a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_first_name} {client_last_name}</a>
      },
      {
        title: 'NDIS Number',
        width: 3,
        render: ({ client_ndis_number }) => <div>{client_ndis_number}</div>
      },
      {
        title: 'Booking ID',
        width: 3,
        render: ({ client_ref_id, booking_number }) => <a href={`/participants/${client_ref_id}/booking`} rel='noopener noreferrer' target='_blank'>{booking_number}</a>
      },
      {
        title: 'Service Booking Type',
        width: 4,
        render: ({ is_monthly_fee_main_set }) => <div>{is_monthly_fee_main_set ? 'Standard Booking' : 'Plan Managed'}</div>
      },
      {
        title: 'Start Date',
        width: 3,
        render: ({ period_start_date }) => <div>{formatter.toShortDate(period_start_date)}</div>
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ period_end_date }) => <div>{formatter.toShortDate(period_end_date)}</div>
      },
      {
        title: 'As of today',
        width: 3,
        render: ({ remaining_days }) => (`${remaining_days === 0 ? `Ended TODAY` : `${Math.abs(remaining_days)} day${remaining_days === -1 ? '' : 's'}`}`)
      }
    ]

    const actnsbColumns = [
      {
        title: 'Participant',
        width: 5,
        render: ({ client_ref_id, client_first_name, client_last_name }) => <a href={`/participants/${client_ref_id}/booking`} rel='noopener noreferrer' target='_blank'>{client_first_name} {client_last_name}</a>
      },
      {
        title: 'NDIS Number',
        width: 3,
        render: ({ client_ndis_number }) => <div>{client_ndis_number}</div>
      },
      {
        title: 'Service Booking Type',
        width: 4,
        render: ({ is_monthly_fee_main_set }) => <div>{is_monthly_fee_main_set ? 'Standard Booking' : 'Plan Managed'}</div>
      },
      {
        title: 'Last Booking ID',
        width: 3,
        render: ({ client_ref_id, booking_number }) => <a href={`/participants/${client_ref_id}/booking`} rel='noopener noreferrer' target='_blank'>{booking_number}</a>
      },
      {
        title: 'Last Start Date',
        width: 3,
        render: ({ period_start_date }) => <div>{formatter.toShortDate(period_start_date)}</div>
      },
      {
        title: 'Last End Date',
        width: 3,
        render: ({ period_end_date }) => <div>{formatter.toShortDate(period_end_date)}</div>
      },
      {
        title: 'No Active SB for',
        width: 3,
        render: ({ remaining_days }) => (`${remaining_days === 0 ? `Ended TODAY` : `${Math.abs(remaining_days)} day${remaining_days === -1 ? '' : 's'}`}`)
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>


        <Page.Content full>
          <Page.Header title={`Participant Service Booking Report`} />

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={12}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group disabled={loading || loadingList} defaultValue={view} onChange={(v) => this.onChangeView(v)}>
                  <Radio.Button value={VIEW_ENDING}> Ending Bookings </Radio.Button>
                  <Radio.Button value={VIEW_ENDED}> Ended Bookings </Radio.Button>
                  <Radio.Button value={VIEW_ACTIVE_NO_BUDGET}> Active Ppts w/o Active Bookings </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div style={{marginTop: '24px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                { view === VIEW_ENDING
                  ? <div>
                    <div className='report-title'>{`Participants with Ending Service Bookings `} <span className='report-tag'>{endingList.total}</span></div>
                    <List cols={endingColumns} rows={endingList.list} />

                    <Pager
                      size={pageSize}
                      total={endingList.total}
                      totalText={`Total ${endingList.total} booking${endingList.total === 1 ? 's' : ''}`}
                      current={currentPage}
                      onChange={(e) => this.onChangePage(e)}
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                  : view === VIEW_ENDED
                    ? <div>
                      <div className='report-title'>{`Participants with Ended Service Bookings `} <span className='report-tag'>{endedList.total}</span></div>
                      <List cols={endedColumns} rows={endedList.list} />

                      <Pager
                        size={pageSize}
                        total={endedList.total}
                        totalText={`Total ${endedList.total} booking${endingList.total === 1 ? 's' : ''}`}
                        current={currentPage}
                        onChange={(e) => this.onChangePage(e)}
                        style={{ marginTop: '15px' }}
                      />
                    </div>
                    : view === VIEW_ACTIVE_NO_BUDGET
                    ? <div>
                      <div className='report-title'>{`Active Participants without Active Bookings `} <span className='report-tag'>{actnsbList.total}</span></div>
                      <List cols={actnsbColumns} rows={actnsbList.list} />

                      <Pager
                        size={pageSize}
                        total={actnsbList.total}
                        totalText={`Total ${actnsbList.total} participant${actnsbList.total === 1 ? 's' : ''}`}
                        current={currentPage}
                        onChange={(e) => this.onChangePage(e)}
                        style={{ marginTop: '15px' }}
                      />
                    </div>
                    : null }
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  onChangeView = (e) => {
    this.setState({ view: e.target.value, isLoaded: false, loading: true, currentPage: 1 }, () => {
      this.fetchBudgetList()
    })
  }

  onChangePage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchBudgetList()
    })
  }

  fetchBudgetList = () => {
    const { currentPage, isLoaded, loading, view } = this.state
    this.fetchClientBudgetStatus({ view, currentPage, isLoaded, loading })
  }

  fetchClientBudgetStatus = async ({ currentPage, isLoaded = true, loading, view }) => {
    if (this.hasAccess(Permissions.REPORT.REPORT_SB.LIST)) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        if (view === VIEW_ENDED) {
          const list = await reportTypeService.listPageClientBudgetEndingEnded(view, currentPage, pageSize)
          this.setState({ endedList: list || [], isLoaded: true, loadingList: false, loading: false })
        } else if (view === VIEW_ENDING) {
          const list = await reportTypeService.listPageClientBudgetEndingEnded(view, currentPage, pageSize)
          this.setState({ endingList: list || [], isLoaded: true, loadingList: false, loading: false })
        } else if (view === VIEW_ACTIVE_NO_BUDGET) {
          const list = await reportTypeService.listPageActiveClientNoActiveBudget(currentPage, pageSize)
          this.setState({ actnsbList: list || [], isLoaded: true, loadingList: false, loading: false })
        } else {
          notify.error('Unable to get Partcipant Service Booking Report', 'Please try again later.')
          this.setState({ isLoaded: false, loadingList: false, loading: false })
        }
      } catch (e) {
        notify.error('Unable to get Partcipant Service Booking Report', 'Please try again later.')
      }

      if (window) window.scrollTo({ top: 0 })
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

export default ReportClientBudgetEnding
