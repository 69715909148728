import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Permissions } from '../../../../constants'
import { pmRateSetService } from '../../../../services'
import { auth, formatter } from '../../../../util'

// UI
import { Page, Pager, List } from '../../../../components'
import notify from '../../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

export class SettingRateSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      modalShow: false,
      selectedItem: {},
      list: [],
      loading: false,
      loadingForm: false,
      total: 0
    }
  }

  componentDidMount() {
    const { currentPage } = this.state
    this.fetchRates({ currentPage })
  }

  fetchRates = async ({ currentPage = 1 }) => {
    if (!this.hasAccess(Permissions.SETTING.RATESETS.LIST)) return

    try {
      this.setState({ currentPage, loading: true })
      const list = await pmRateSetService.listByPage(currentPage, pageSize)
      this.setState({ list: list.list, loading: false, total: list.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load rate sets successfully. Please try again later.')
    }
  }

  handleEdit(id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
  }

  hasAccess(accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render() {
    const { currentPage, list, loading, total } = this.state

    const columns = [
      {
        title: 'Set',
        width: 6,
        render: ({ rate_name }) => rate_name
      },
      {
        title: 'Name',
        width: 10,
        render: ({ rate_set_name }) => rate_set_name
      },
      {
        title: 'Period',
        width: 5,
        render: ({ start_date, end_date }) => `${formatter.toShortDate(start_date)} - ${formatter.toShortDate(end_date)}`
      },
      {
        title: 'Enable',
        width: 2,
        render: ({ rate_set_active: active }) => active
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          {this.hasAccess(Permissions.SETTING.RATESETS.READ)
            ? <Tooltip mouseLeaveDelay={0} title='Edit'>
              <Link to={`/settings/rate-set/edit/${item.id}`}>
                <div>
                  <Icon type='form' />
                </div>
              </Link>
            </Tooltip>
            : null}
        </div>
      },
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <Page.Header>
              {this.hasAccess(Permissions.SETTING.RATESETS.CREATE)
                ? <Link to='/settings/rate-set/edit/add'>
                  {
                    <div className='btn'>Add Rate Set</div>
                  }
                </Link>
                : null}
            </Page.Header>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} rate sets`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSet))
