import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_CREDITS, FETCHING_CREDITS, CREDITS_FETCHED } from '../actions/credit'
import creditService from '../../services/credit'

function * fetchCredits ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_CREDITS, loading })
    filter.is_delete = false
    const credits = yield creditService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: CREDITS_FETCHED, credits, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_CREDITS, loading: false })
    console.error(e)
  }
}

function * watchCredit () {
  yield takeEvery(FETCH_CREDITS, fetchCredits)
}

export default watchCredit
