import { auth, localStorage, sessionStorage, notification, validator, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/api'

export default {
  resetPassword (username) {
    try {
      const result = request(`${url}/reset-password`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  signIn (username, password, newPassword, rememberMe) {
    try {
      const { location } = window
      const { search } = location
      const result = request(`${url}/login${search}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username, password, newPassword, rememberMe })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  forceSignOut () {
    try {
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = '/login'
    } catch (e) {
      console.log('force sign out', e)
    }
  },
  signOut () {
    try {
      const token = auth.getCurrentToken()
      const user = auth.getCurrentUser()

      if (token && user && user.id) {
        const result = request(`${url}/logout`, {
          method: 'POST',
          headers,
          body: JSON.stringify({ token, user })
        })

        return result
      } else {
        this.forceSignOut()
      }
    } catch (e) {
      console.error(e)
    }
  },
  unauthorizeAction (redirectPath, duration, callback) {
    if (typeof callback === 'function') {
      callback()
    } else {
      redirectPath = typeof redirectPath === 'string' ? redirectPath : '/'
      duration = typeof duration === 'number' ? duration : 3000
      notification.show('error', 'You do not have permission to perform this action.')
      setTimeout(() => window.location.replace(redirectPath), duration)
    }
  }
}
