export default {
  PARTICIPANT: {
    INFO: {
      LIST: 'listParticipant',
      READ: 'readParticipant',
      CREATE: 'createParticipant',
      UPDATE: 'updateParticipant',
      DELETE: 'deleteParticipant'
    },
    SB: {
      LIST: 'listParticipantServiceBooking',
      READ: 'readParticipantServiceBooking',
      CREATE: 'createParticipantServiceBooking',
      UPDATE: 'updateParticipantServiceBooking',
      DELETE: 'deleteParticipantServiceBooking'
    },
    INVOICE: {
      LIST: 'listParticipantInvoices',
      READ: 'readParticipantInvoices',
      // CREATE: '',
      // UPDATE: '',
      // DELETE: ''
    },
    CREDIT: {
      LIST: 'listParticipantCredits',
      READ: 'readParticipantCredits',
      CREATE: 'createParticipantCredits',
      UPDATE: 'updateParticipantCredits',
      // DELETE: ''
    },
    STMTS: {
      LIST: 'listParticipantStatements',
      READ: 'readParticipantStatements',
      CREATE: 'createParticipantStatements',
      UPDATE: 'updateParticipantStatements',
      DELETE: 'deleteParticipantStatements'
    },
    FILES: {
      LIST: 'listParticipantFiles',
      READ: 'readParticipantFiles',
      CREATE: 'createParticipantFiles',
      UPDATE: 'updateParticipantFiles',
      DELETE: 'deleteParticipantFiles'
    }
  },
  PROVIDER: {
    INFO: {
      LIST: 'listProvider',
      READ: 'readProvider',
      CREATE: 'createProvider',
      UPDATE: 'updateProvider',
      DELETE: 'deleteProvider'
    },
    INVOICE: {
      LIST: 'listProviderInvoice',
      READ: 'readProviderInvoice',
      // CREATE: '',
      // UPDATE: '',
      // DELETE: ''
    }
  },
  STATEMENT: {
    INFO: {
      LIST: 'listStatements',
      READ: 'readStatements',
      EMAIL: 'emailStatements',
      DOWNLOAD: 'downloadStatements',
    },
  },
  INVOICE: {
    INFO: {
      LIST: 'listInvoice',
      READ: 'readInvoice',
      CREATE: 'createInvoice',
      UPDATE: 'updateInvoice',
      DELETE: 'deleteInvoice'
    },
    FILES: {
      LIST: 'listInvoiceFiles',
      READ: 'readInvoiceFiles',
      CREATE: 'createInvoiceFiles',
      UPDATE: 'updateInvoiceFiles',
      DELETE: 'deleteInvoiceFiles'
    },
    CREDIT_APPLY: {
      LIST: 'listInvoiceApplyCredit',
      READ: 'readInvoiceApplyCredit',
      CREATE: 'createInvoiceApplyCredit',
      UPDATE: 'updateInvoiceApplyCredit',
      // DELETE: ''
    },
    MGMT: {
      LIST: 'listInvoiceManagement',
      READ: 'readInvoiceManagement',
      CREATE: 'updateInvoiceManagement',
      // UPDATE: '',
      // DELETE: ''
    }
  },
  CREDIT: {
    INFO: {
      LIST: 'listCredit',
      READ: 'readCredit',
      CREATE: 'createCredit',
      UPDATE: 'updateCredit',
      DELETE: 'deleteCredit'
    }
  },
  REPORT: {
    SCHEDULER: {
      LIST: 'listReportGenerateRequest',
      READ: 'readReportGenerateRequest',
      CREATE: 'createReportGenerateRequest',
    },
    REPORT_SB: {
      LIST: 'listReportServiceBooking',
    },
    RECIPIENT_SETTING: {
      LIST: 'listReportRecipientSetting',
      UPDATE: 'updateReportRecipientSetting',
    },
    REPORT_PENDING_INVOICES: {
      LIST: 'listToReceiveInvoices',
    },
  },
  SETTING: {
    CONTACTS_EXPORT: {
      LIST: 'listSettingContacts',
      // READ: 'readSettingContacts',
      // CREATE: 'createSettingContacts',
      // UPDATE: 'updateSettingContacts',
      // DELETE: 'deleteSettingContacts'
    },
    CUSTOM_IDF: {
      LIST: 'listSettingCustomIdentifier',
      READ: 'readSettingCustomIdentifier',
      CREATE: 'createSettingCustomIdentifier',
      UPDATE: 'updateSettingCustomIdentifier',
      DELETE: 'deleteSettingCustomIdentifier'
    },
    FILES: {
      LIST: 'listSettingFiles',
      READ: 'readSettingFiles',
      CREATE: 'createSettingFiles',
      UPDATE: 'updateSettingFiles',
      DELETE: 'deleteSettingFiles'
    },
    GST: {
      LIST: 'listSettingGst',
      READ: 'readSettingGst',
      CREATE: 'createSettingGst',
      UPDATE: 'updateSettingGst',
      DELETE: 'deleteSettingGst'
    },
    PROFILE: {
      LIST: 'listSettingProfile',
      READ: 'readSettingProfile',
      CREATE: 'createSettingProfile',
      UPDATE: 'updateSettingProfile',
      DELETE: 'deleteSettingProfile'
    },
    PLANGUAGE: {
      LIST: 'listSettingProfileLanguage',
      READ: 'readSettingProfileLanguage',
      CREATE: 'createSettingProfileLanguage',
      UPDATE: 'updateSettingProfileLanguage',
      DELETE: 'deleteSettingProfileLanguage'
    },
    PRELATIONSHIP: {
      LIST: 'listSettingProfileRelationship',
      READ: 'readSettingProfileRelationship',
      CREATE: 'createSettingProfileRelationship',
      UPDATE: 'updateSettingProfileRelationship',
      DELETE: 'deleteSettingProfileRelationship'
    },
    PACCREF: {
      LIST: 'listSettingProfileAccRef',
      READ: 'readSettingProfileAccRef',
      CREATE: 'createSettingProfileAccRef',
      UPDATE: 'updateSettingProfileAccRef',
      DELETE: 'deleteSettingProfileAccRef'
    },
    REASON: {
      LIST: 'listSettingReasons',
      READ: 'readSettingReasons',
      CREATE: 'createSettingReasons',
      UPDATE: 'updateSettingReasons',
      DELETE: 'deleteSettingReasons'
    },
    RATESETS: {
      LIST: 'listSettingRateSet',
      READ: 'readSettingRateSet',
      CREATE: 'createSettingRateSet',
      UPDATE: 'updateSettingRateSet',
      DELETE: 'deleteSettingRateSet'
    },
    RATESET_IMPORT: {
      LIST: 'listSettingRateSetImport',
      READ: 'readSettingRateSetImport',
      // CREATE: 'createSettingRateSetImport',
      UPDATE: 'updateSettingRateSetImport',
      // DELETE: 'deleteSettingRateSetImport'
    },
    CATS: {
      LIST: 'listSettingCategories',
      READ: 'readSettingCategories',
      CREATE: 'createSettingCategories',
      UPDATE: 'updateSettingCategories',
      DELETE: 'deleteSettingCategories'
    },
    INV_IMPORT: {
      LIST: 'listSettingInvoiceImport',
      // READ: '',
      CREATE: 'createSettingInvoiceImport',
      // UPDATE: '',
      // DELETE: ''
    },
    OTHERS: {
      LIST: 'listSettingOther',
      UPDATE: 'updateSettingOther',
    }
  }
}
