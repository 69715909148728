export const FETCH_INVOICES = 'FETCH_INVOICES'
export const FETCHING_INVOICES = 'FETCHING_INVOICES'
export const INVOICES_FETCHED = 'INVOICES_FETCHED'

export const UPDATE_INVOICE_ABA_LIST = 'UPDATE_INVOICE_ABA_LIST'

export const fetchInvoices = (params) => {
  return Object.assign({
    type: FETCH_INVOICES
  }, params)
}

export const fetchingInvoices = (loading) => {
  return {
    type: FETCHING_INVOICES,
    loading
  }
}

export const invoicesFetched = (invoices) => {
  return {
    type: INVOICES_FETCHED,
    invoices
  }
}

export const updateInvoiceAbaList = (list = []) => {
  return {
    type: UPDATE_INVOICE_ABA_LIST,
    list
  }
}
