import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          // {
          //   title: 'Invoices',
          //   icon: 'thunderbolt',
          //   menu: [
          //     {
          //       name: 'New Invoice',
          //       link: '/invoices/add',
          //       permission: Permissions.INVOICE.INFO.CREATE
          //     },
          //     {
          //       name: 'List Invoices',
          //       link: '/invoices-list/pm',
          //       permission: Permissions.INVOICE.INFO.LIST
          //     },
          //     {
          //       name: 'Manage Invoices (PM SB)',
          //       link: '/invoices-manage',
          //       permission: Permissions.INVOICE.MGMT.LIST
          //     },
          //     {
          //       name: 'Redownload - Payment Request',
          //       link: '/invoices-export/payment-request',
          //       permission: Permissions.INVOICE.MGMT.LIST
          //     },
          //     {
          //       name: 'Redownload - ABA/Remittance',
          //       link: '/invoices-export/aba-remittance',
          //       permission: Permissions.INVOICE.MGMT.LIST
          //     },
          //   ],
          // },
          {
            title: 'Invoices',
            icon: 'thunderbolt',
            menu: [
              {
                name: 'New Invoice',
                link: '/invoices/add',
                permission: Permissions.INVOICE.INFO.CREATE
              },
              {
                name: 'List Invoices',
                link: '/invoices-list/pm',
                permission: Permissions.INVOICE.INFO.LIST
              },
              {
                name: 'Redownload - PM Payment Request',
                link: '/invoices-export/payment-request-pm',
                permission: Permissions.INVOICE.MGMT.LIST
              },
              {
                name: 'Redownload - STD Payment Request',
                link: '/invoices-export/payment-request-std',
                permission: Permissions.INVOICE.MGMT.LIST
              },
              {
                name: 'Redownload - ABA/Remittance',
                link: '/invoices-export/aba-remittance',
                permission: Permissions.INVOICE.MGMT.LIST
              },
            ],
          }
        ]
      }
    ]
  }
]
