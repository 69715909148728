import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/common'

export default {
  checkDuplicate (type = '', values) {
    return request(`${url}/check/duplicate/${type}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
