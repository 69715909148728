import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGSTRateService } from '../../../../services/setting'
import { authService } from '../../../../services'
import { auth } from '../../../../util'

// UI
import { Button, Page, Panel, SideModal, Pager, List } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

import { formatter, validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form

const settingType = 'GST Rate'

export class SettingGSTRate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      searchText: '',
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettingsGST({ currentPage })
  }

  changePage = (currentPage) => {
    this.fetchSettingsGST({ currentPage })
  }

  fetchSettingsGST = async ({ currentPage = 1 }) => {
    if (!this.hasAccess(Permissions.SETTING.GST.LIST)) return

    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingGSTRateService.listByPage(currentPage, pageSize)
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  async handleDelete (id) {
    this.setState({ loading: true })
    const res = await settingGSTRateService.remove(id)

    if (res && res.id) {
      notify.success('Deleted successfully', `${formatter.capitalize(settingType)} deleted successfully.`)
    } else {
      notify.error('Unable to delete successfully', `Unable to delete ${settingType} successfully. Please try again later.`)
    }

    this.fetchSettings({ currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Rate Name',
        width: 6,
        key: 'name'
      },
      // {
      //   title: 'Description',
      //   width: 8,
      //   key: 'description'
      // },
      {
        title: 'Start Date',
        width: 4,
        render: ({ start_date }) => <div>{formatter.toShortDate(start_date)}</div>
      },
      {
        title: 'End Date',
        width: 4,
        render: ({ end_date }) => <div>{formatter.toShortDate(end_date)}</div>
      },
      {
        title: 'P1 - GST (%)',
        width: 3,
        render: ({ p1_value }) => <div>{p1_value}</div>
      },
      {
        title: 'P2 - GST Free (%)',
        width: 3,
        render: ({ p2_value }) => <div>{p2_value}</div>
      },
      {
        title: 'P5 - GST OoS (%)',
        width: 3,
        render: ({ p5_value }) => <div>{p5_value}</div>
      },
      {
        title: 'Enable',
        width: 2,
        render: ({ active }) => <div style={{ color: active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: '',
        width: 1,
        render: (item) => this.hasAccess(Permissions.SETTING.GST.UPDATE)
          ? <Tooltip mouseLeaveDelay={0} title='Edit'>
            <Link to={`/settings/gst/rates/${item.ref_id}`}>
              <div>
                <Icon type='form' />
              </div>
            </Link>
          </Tooltip>
          : null
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => this.hasAccess(Permissions.SETTING.GST.DELETE) && is_editable
          ? <Popconfirm
            title='Confirm to delete this?'
            onConfirm={(e) => this.handleDelete(id)}
            okText='Yes'
            cancelText='No'
          ><Icon type='delete' />
          </Popconfirm>
          : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} items`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingGSTRate))
