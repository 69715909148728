/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pmCategoryService } from '../../../../services'

// UI
import { Loading, List, Page, Panel } from '../../../../components'
import { Permissions } from '../../../../constants'
import notify from '../../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'

import { SupportCatItemsUnit } from '../../../../constants'
import { auth, formatter, validator } from '../../../../util'

const { Item: FormItem } = Form
const Option = Select.Option

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 11 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

export class SettingRateCategoriesEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: { category_items: [] },
      rateSets: [],
      categoryItems: [],
      currentRateSet: {},
      currentCategoryItems: [],
      loading: false
    }
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchCategory()
    }
    // this.fetchSettings()
  }

  render () {
    const { form, history } = this.props
    const { getFieldDecorator } = form
    const { categoryItems, currentRateSet, currentCategoryItems, item, loading, rateSets } = this.state

    const itemColumns = [
      {
        title: 'Name',
        width: 6,
        render: ({ cat_item_name }) => cat_item_name
      },
      {
        title: 'Support Item Number',
        width: 3,
        render: ({ cat_item_identifier }) => cat_item_identifier
      },
      {
        title: 'Unit',
        width: 2,
        render: ({ unit }) => {
          const f = SupportCatItemsUnit.find(e => e.value === unit)
          if (f) {
            return f.label
          } else {
            return formatter.capitalize(unit, false)
          }
        }
      },
      {
        title: 'Quote?',
        width: 2,
        render: ({ is_quote }) => is_quote
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Price Limit?',
        width: 2,
        render: ({ is_price_limit }) => is_price_limit
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Non-face-to-face?',
        width: 2,
        render: ({ is_nf2f }) => is_nf2f
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Provider Travel?',
        width: 2,
        render: ({ is_provider_travel }) => is_provider_travel
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Short Notice Cancellations?',
        width: 2,
        render: ({ is_short_notice_cancel }) => is_short_notice_cancel
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'NDIA Requested Reports?',
        width: 2,
        render: ({ is_ndia_requested_reports }) => is_ndia_requested_reports
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Irregular SIL Supports?',
        width: 2,
        render: ({ is_irregular_sil }) => is_irregular_sil
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      // {
      //   title: 'Active',
      //   width: 2,
      //   render: ({ active }) => active
      //   ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      //   : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      // },
    ]

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Skeleton loading={loading} active>
            <Page.Header title={loading
                ? <div className='client-panel-header-skeleton' style={{ width: 200 }} />
                : item && item.name
                  ? `${item.name}`
                  : 'Support Budget'}
                >
              <div className='btn' onClick={history.goBack}>Back</div>
            </Page.Header>

            { validator.isNotEmptyArray(rateSets)
              ? <Panel title='Support Items'>
                <Row>
                  <Col lg={12}>
                    <FormItem {...formItemLayout} label='Rate Set'>
                      {getFieldDecorator('rate_set', {
                        initialValue: currentRateSet.rate_set_id || ''
                      })(
                        <Select placeholder='Select Rate Set' onChange={this.handleChangeRateSet}>
                          {
                            rateSets.map((set) => (
                              <Option key={set.rate_set_id} value={set.rate_set_id}>{`${set.rate_name} - ${set.rate_set_name}    `}</Option>
                            ))
                          }
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Loading loading={loading} blur>
                  <List cols={itemColumns} rows={currentCategoryItems} />
                </Loading>
              </Panel>
              : null }

          </Skeleton>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchCategory = async () => {
    if (!this.hasAccess(Permissions.SETTING.CATS.READ)) return

    try {
      const id = this.getId()

      this.setState({ loading: true })

      const item = await pmCategoryService.get(id)

      if (item && item.id) {
        const categoryItems = item.category_items || []
        const rateSets = item.rates || []
        let currentRateSet = {}

        if (validator.isNotEmptyArray(rateSets)) {
          currentRateSet = rateSets[0]
        }

        this.setState({
          item,
          loading: false,
          currentRateSet,
          rateSets,
          categoryItems
        }, () => {
          this.updateCurrentCategories()
        })
      } else {
        this.setState({ loading: false })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load support budget successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleChangeRateSet = (value) => {
    const { rateSets } = this.state

    const rs = rateSets.find(e => e.rate_set_id === value)

    if (rs && rs.rate_set_id) {
      this.setState({ currentRateSet: rs }, () => {
        this.updateCurrentCategories()
      })
    }
  }

  updateCurrentCategories = () => {
    const { currentRateSet, categoryItems } = this.state

    if (currentRateSet && currentRateSet.rate_set_id) {
      const currentCats = categoryItems.filter(e => e.rate_set_id === currentRateSet.rate_set_id)

      if (validator.isArray(currentCats)) {
        this.setState({ currentCategoryItems: currentCats })
      }
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  getPath = () => {
    const { match } = this.props
    const { params } = match
    const { path = '' } = params
    return path
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Funding }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateCategoriesEdit))
