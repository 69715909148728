import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clientBudgetService, clientBudgetItemService, pmCategoryService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'

// UI
import { Loading, List, Notification, Panel } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, log, validator } from '../../../util'

import './styles.css'
import AddBudgetModal from '../AddBudgetModal'
import AddBudgetItemModal from '../AddBudgetItemModal'

const { confirm, warning } = Modal
const notify = Notification

const contentStyle = { fontSize: '11.5px' }
const contentInactiveStyle = { color: '#cfcfcf' }
const iconButtonStyle = {
  marginRight: 15,
  cursor: 'pointer',
  fontSize: '15px'
}

export class ClientBudget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // budgetList: [],
      // budgetSelectList: [],
      clientBudget: [],
      categories: [],
      loading: false,
      loadingList: false,
      showAddBudgetModal: false,
      showAddBudgetItemModal: false,
      modalItemBudget: {},
      modalItemBudgetItem: {}
    }
  }

  componentDidMount () {
    this.fetchBudget()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchBudget(nextProps.clientId)
      }
    }
  }

  render () {
    const { form, clientId, clientInfo = {} } = this.props
    const { getFieldDecorator } = form
    const { categories, clientBudget, loading, loadingList, modalItemBudget, modalItemBudgetItem, showAddBudgetModal, showAddBudgetItemModal } = this.state

    const listColumns = (additional = null) => ([
      {
        titleRender: () => (additional
            ? <span>Support Budget {additional}</span>
            : <span>Support Budget</span>),
        width: 5,
        render: ({ active, cat_proda_name }) => <span style={active === false ? {...contentStyle, ...contentInactiveStyle} : contentStyle}>{cat_proda_name}</span>
      },
      {
        title: 'Item Number',
        width: 9,
        render: ({ active, cat_item_name, cat_item_identifier }) => <div style={active === false ? {...contentStyle, ...contentInactiveStyle} : contentStyle}><div>{cat_item_name}</div><div>{cat_item_identifier}</div></div>
      },
      {
        title: 'Claim/Utilised Amount',
        width: 3,
        render: ({ active, previous_value }) => <div style={active === false ? {...contentStyle, ...contentInactiveStyle} : contentStyle}>{formatter.toPrice(previous_value)}</div>
      },
      {
        title: 'Allocated Amount',
        width: 3,
        render: ({ active, budget_value }) => <div style={active === false ? {...contentStyle, ...contentInactiveStyle} : contentStyle}>{formatter.toPrice(budget_value)}</div>
      },
      {
        title: '(Forecast) / Remaining Budget',
        width: 6,
        render: ({ active, remaining_forecast_value, remaining_value }) => <span style={active === false ? {...contentStyle, ...contentInactiveStyle} : contentStyle}><span style={{fontStyle: 'italic'}}>({formatter.toPrice(remaining_forecast_value)})</span>&nbsp;{formatter.toPrice(remaining_value)}</span>
      },
      // {
      //   title: 'Forecasted Remaining Budget',
      //   width: 3,
      //   render: ({ active, remaining_forecast_value }) => <div style={active === false ? {...contentStyle, ...contentInactiveStyle} : contentStyle}>{formatter.toPrice(remaining_forecast_value)}</div>
      // },
      {
        title: 'Action',
        width: 1,
        render: (item, index, additional) => {
          const isMainSet = additional && !!additional.is_monthly_fee_main_set

          return (!isMainSet
            ? <div className='action-buttons'>
              { this.hasAccess(Permissions.PARTICIPANT.SB.UPDATE)
              ? <Tooltip mouseLeaveDelay={0} title='Edit'>
                <div onClick={() => this.showAddBudgetItemModal(true, item, {})} style={{ marginRight: 15 }}>
                  <Icon type='form' />
                </div>
              </Tooltip>
              : null }
              { this.hasAccess(Permissions.PARTICIPANT.SB.DELETE)
                ? <Tooltip mouseLeaveDelay={0} title='Remove Item'>
                  <Popconfirm
                    title='Confirm to remove this item number?'
                    onConfirm={() => this.removeBudgetItem(item)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
                  </Popconfirm>
                </Tooltip>
                : null }
              </div>
            : null)
        }
      }
    ])


    return (
      <Loading loading={loading} blur>
        <Panel title={'Service Booking'}
          subtitle={<div className={`btn btn-alert`}
          onClick={() => this.hasAccess(Permissions.PARTICIPANT.SB.CREATE)
            ? this.showAddBudgetModal(true, { is_monthly_fee_main_set: true, is_unable_update_period: false })
            : null
          }>
            Add
          </div>}
        >
          <div className='budget-list'>
            <Skeleton loading={loadingList} active>
              { clientBudget && validator.isNotEmptyArray(clientBudget)
                ? clientBudget.map(m => {
                  const { subset = [], booking_number, is_monthly_fee_main_set,
                    period_start_date, period_end_date, is_expand,
                    budget_total, remaining_total, forecast_remaining_total, monthly_fee_acq, items = []
                  } = m

                  const periodStatus = m && m.id ? formatter.toPeriodStatus(period_end_date) : {}
                  const isDisabled = periodStatus && periodStatus.isDuePeriod2

                  const sbItem = (c) => {
                    const { id, booking_number, is_deletable = false, is_monthly_fee_main_set, main_set_id,
                      period_start_date, period_end_date, is_expand,
                      budget_total, remaining_total, forecast_remaining_total, monthly_fee_acq, items = []
                    } = c

                    const isMainSet = !!is_monthly_fee_main_set
                    const isNoMainSetAlert = !isMainSet
                    const title1 = `${isMainSet ? 'Standard Booking' : 'PM Booking'}`
                    const title2 = `${booking_number} (${formatter.toShortDate(period_start_date)} - ${formatter.toShortDate(period_end_date)})`
                    const titleType = `${isMainSet ? 'Standard Booking' : 'PM Booking'}`

                    const deleteButtonText = isMainSet ? 'Delete Standard Booking' : 'Delete PM Booking'
                    const editButtonText = isMainSet ? 'Edit Standard Booking' : 'Edit PM Booking'
                    const createButtonText = isMainSet ? '' : 'Add Standard Booking'

                    return (
                      <div className={`list-item ${isNoMainSetAlert ? 'list-item-alert' : ''}`} key={`bkmn_${m.id}`}>
                        <div className={isMainSet ? `std-highlight ${m.active ? '' : 'inactive'}` : 'pm-highlight-single'}>
                          <Row>
                            <Col xs={12} sm={12} lg={12} xl={12} >
                              <div>
                                <div>
                                  <span className={`title-sub ${m.active ? '' : 'disabled'}`}>{title1}</span>
                                </div>
                                <div>
                                  <span className={`title ${m.active ? '' : 'disabled'}`}>{title2}<span style={{cursor: 'pointer'}} onClick={() => this.handleBudgetHideShow(m)}><Icon type={is_expand ? 'up-circle' : 'down-circle'} style={{fontSize: '12pt', marginLeft: '20pt', color: '#444'}} /></span></span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} lg={12} xl={12}>
                              <div className='btn-row'>
                                { this.hasAccess(Permissions.PARTICIPANT.SB.CREATE) && createButtonText
                                  ? <Tooltip mouseLeaveDelay={0} title={createButtonText}>
                                    <div
                                      onClick={() => this.showAddBudgetModal(true, {
                                        is_monthly_fee_main_set: true,
                                        sub_set_id: id,
                                        period_start_date: period_start_date,
                                        period_end_date: period_end_date,
                                        is_unable_update_period: true,
                                        subset
                                      })}
                                      style={{ ...iconButtonStyle, color: isNoMainSetAlert ? 'red' : undefined }}
                                    >
                                      <Icon type='plus-circle' theme='filled' />
                                    </div>
                                  </Tooltip>
                                  : null }
                                { this.hasAccess(Permissions.PARTICIPANT.SB.UPDATE)
                                  ? <Tooltip mouseLeaveDelay={0} title={editButtonText}>
                                    <div
                                      onClick={() => this.showAddBudgetModal(true, {...m, is_unable_update_period: !m.active})}
                                      style={iconButtonStyle}
                                    >
                                      <Icon type='form' />
                                    </div>
                                  </Tooltip>
                                  : null }
                                { this.hasAccess(Permissions.PARTICIPANT.SB.DELETE) && is_deletable
                                  ? <Tooltip mouseLeaveDelay={0} title={deleteButtonText}>
                                    <div
                                      onClick={() => this.handleDelete(m, titleType)}
                                      style={iconButtonStyle}
                                    >
                                      <Icon type='delete' />
                                    </div>
                                  </Tooltip>
                                  : null }
                              </div>
                            </Col>
                          </Row>

                          { isNoMainSetAlert
                            ? <div className='error-msg'>No Standard Booking available. Please add a Standard Booking for this Plan Management Booking.</div>
                            : null }

                          <Row style={{margin: '20px 30px -16px'}}>
                            <Col lg={6}>
                              <div className={`total-amount ${m.active ? '' : 'inactive'}`}>Total Allocated: {formatter.toPrice(m.budget_total)}</div>
                            </Col>
                            <Col lg={6}>
                              <div className={`total-amount ${m.active ? '' : 'inactive'}`}>Total Remaining: {formatter.toPrice(m.remaining_total)}</div>
                            </Col>
                            <Col lg={6}>
                              <div className={`total-amount ${m.active ? '' : 'inactive'}`}>Forecasted Remaining: {formatter.toPrice(m.forecast_remaining_total)}</div>
                              {/* <div className='total-amount'>Monthly Booking Fee: {formatter.toPrice(m.monthly_fee_acq)}</div> */}
                            </Col>
                            <Col lg={6}>
                            </Col>
                          </Row>

                          { is_expand
                            ? <div className='row-section-sb'>
                              { m.items && validator.isArray(m.items)
                                ? <List
                                  noBackground
                                  cols={listColumns(this.hasAccess(Permissions.PARTICIPANT.SB.CREATE) && !isMainSet && !isDisabled
                                    ? <Tooltip mouseLeaveDelay={0} title={'Add Category'}>
                                      <span
                                        onClick={() => this.showAddBudgetItemModal(true, {booking_number: m.booking_number}, m)}
                                        style={ iconButtonStyle }
                                      >
                                        <Icon type='plus-circle' theme='filled' />
                                      </span>
                                    </Tooltip>
                                    : null)}
                                  rows={m.items}
                                  additional={m}
                                />
                                : <div>No support categories and items added to the booking.</div>}
                            </div>
                            : <div className='row-section-sb' /> }
                        </div>

                        <div className={isMainSet ? 'pm-highlight' : validator.isNotEmptyArray(subset) ? 'pm-highlight-single' : ''}>
                          {/** if it is main set and having subset, generate a higher padding for Plan Managed Bookings. else, minimize the padding as possible */}
                          <div style={{padding: isMainSet ? '15px 0px 0px 0px' : '0px 0px 0px 0px'}}>
                            <Row style={validator.isNotEmptyArray(subset) ? {paddingBottom: '10px'} : {}}>
                              { isMainSet
                                ? <span className='title-section'>Plan Managed Bookings <span>{ this.hasAccess(Permissions.PARTICIPANT.SB.CREATE) && isMainSet && !isDisabled
                                  ? <Tooltip mouseLeaveDelay={0} title={'Add New PM Booking'}>
                                      <span
                                        onClick={() => this.showAddBudgetModal(true, {
                                          is_monthly_fee_main_set: false,
                                          main_set_id: id,
                                          period_start_date: period_start_date,
                                          period_end_date: period_end_date,
                                          subset,
                                          main_period_start_date: period_start_date,
                                          main_period_end_date: period_end_date
                                        })}
                                        style={ iconButtonStyle }
                                      >
                                        <Icon type='plus-circle' theme='filled' />
                                      </span>
                                    </Tooltip>
                                  : null }
                                  </span>
                                </span>
                                : null }
                            </Row>

                            { validator.isNotEmptyArray(subset)
                              ? subset.map(s => {
                                const { id, active, booking_number, is_deletable = false, is_monthly_fee_main_set, main_set_id,
                                  period_start_date, period_end_date, is_expand,
                                  budget_total, remaining_total, forecast_remaining_total, monthly_fee_acq, items = []
                                } = s

                                const isMainSetSub = !!is_monthly_fee_main_set
                                const title1 = `PM Booking`
                                const title2 = `${booking_number} (${formatter.toShortDate(period_start_date)} - ${formatter.toShortDate(period_end_date)})`

                                return (
                                  <div className={`list-item-child`} key={`bkmns_${m.id}`}>
                                    <Row>
                                      <Col xs={12} sm={12} lg={12} xl={12} >
                                        <div>
                                          <div>
                                            <span className={`title ${active ? '' : 'disabled'}`}>{title2}<span style={{cursor: 'pointer'}} onClick={() => this.handleBudgetHideShow(s)}><Icon type={is_expand ? 'up-circle' : 'down-circle'} style={{fontSize: '12pt', marginLeft: '20pt', color: '#444'}} /></span></span>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs={12} sm={12} lg={12} xl={12}>
                                        <div className='btn-row'>
                                          { this.hasAccess(Permissions.PARTICIPANT.SB.UPDATE)
                                            ? <Tooltip mouseLeaveDelay={0} title={'Edit PM Booking'}>
                                              <div
                                                onClick={() => this.showAddBudgetModal(true, {
                                                  ...s,
                                                  subset,
                                                  main_period_start_date: m.period_start_date,
                                                  main_period_end_date: m.period_end_date,
                                                  is_unable_update_period: !active
                                                })}
                                                style={iconButtonStyle}
                                              >
                                                <Icon type='form' />
                                              </div>
                                            </Tooltip>
                                            : null }
                                          { this.hasAccess(Permissions.PARTICIPANT.SB.DELETE) && is_deletable
                                            ? <Tooltip mouseLeaveDelay={0} title={'Delete PM booking'}>
                                              <div
                                                onClick={() => this.handleDelete(s, 'PM Booking')}
                                                style={iconButtonStyle}
                                              >
                                                <Icon type='delete' />
                                              </div>
                                            </Tooltip>
                                            : null }
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row style={{margin: `20px 30px ${is_expand ? '-16px' : '10px'}`}}>
                                      <Col lg={6}>
                                        <div className={`total-amount ${active ? '' : 'inactive'}`}>Total Allocated: {formatter.toPrice(budget_total)}</div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className={`total-amount ${active ? '' : 'inactive'}`}>Total Remaining: {formatter.toPrice(remaining_total)}</div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className={`total-amount ${active ? '' : 'inactive'}`}>Forecasted Remaining: {formatter.toPrice(forecast_remaining_total)}</div>
                                        {/* <div className='total-amount'>Monthly Booking Fee: {formatter.toPrice(monthly_fee_acq)}</div> */}
                                      </Col>
                                      <Col lg={6}>
                                      </Col>
                                    </Row>

                                    { is_expand
                                      ? <div className='row-section-sb'>
                                        { items && validator.isArray(items)
                                          ? <List
                                            noBackground
                                            cols={listColumns(this.hasAccess(Permissions.PARTICIPANT.SB.CREATE) && !isMainSetSub && !isDisabled
                                            ? <Tooltip mouseLeaveDelay={0} title={'Add Category'}>
                                              <span
                                                onClick={() => this.showAddBudgetItemModal(true, {booking_number: m.booking_number}, s)}
                                                style={ iconButtonStyle }
                                              >
                                                <Icon type='plus-circle' theme='filled' />
                                              </span>
                                            </Tooltip>
                                            : null)}
                                            rows={items}
                                          />
                                          : <div>No support categories and items added to the booking.</div>}
                                      </div>
                                      : null }
                                  </div>
                                )
                              })
                              : null }
                          </div>
                        </div>
                      </div>
                    )
                  }

                  return sbItem(m)
                })
                : null }

            </Skeleton>
          </div>
        </Panel>
        <AddBudgetModal
          clientId={clientId}
          clientInfo={clientInfo}
          key={`addbudgetItem${modalItemBudget && modalItemBudget.id ? modalItemBudget.id : '_new'}`}
          item={modalItemBudget}
          onClose={() => this.showAddBudgetModal(false)}
          onUpdate={(isEdit, r) => this.updateBudgetSaved(isEdit, r)}
          visible={showAddBudgetModal}
        />
        <AddBudgetItemModal
          clientId={clientId}
          clientInfo={clientInfo}
          categories={categories}
          key={`addbudgetCatItem${modalItemBudgetItem && modalItemBudgetItem.id ? modalItemBudgetItem.id : '_new'}`}
          item={modalItemBudgetItem}
          parentItem={modalItemBudget}
          onClose={() => this.showAddBudgetItemModal(false)}
          onUpdate={(isEdit, r) => this.updateBudgetItemSaved(isEdit, r)}
          visible={showAddBudgetItemModal}
        />
      </Loading>
    )
  }

  fetchBudget = async (cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.SB.LIST)) return

    const clientId = cid || this.props.clientId
    this.setState({loadingList: true})

    if (!clientId) return
    const bls = await clientBudgetService.listClientBudget(clientId)
    const cats = await pmCategoryService.listAvailableCats()

    this.setState({
      clientBudget: bls && validator.isArray(bls) ? bls : [],
      categories: validator.isArray(cats) ? cats : [],
      loadingList: false
    })
  }

  handleBudgetHideShow (item) {
    // const { clientBudget } = this.state

    // const findIndex = clientBudget.findIndex(e => e.id === item.id)
    // if (findIndex > -1) {
    //   item.is_expand = !item.is_expand
    //   clientBudget.splice(findIndex, 1, item)
    //   this.setState(clientBudget)
    // }
    item.is_expand = !item.is_expand
    this.setState({})
  }

  showAddBudgetModal = (showAddBudgetModal, modalItemBudget = {}, cb = () => {}) => {
    const { clientBudget } = this.state
    if (showAddBudgetModal === true) {
      let budgetPeriods = clientBudget.map(e => ({
        id: e.id,
        active: e.active,
        period_start_date: e.period_start_date,
        period_end_date: e.period_end_date,
        is_monthly_fee_main_set: e.is_monthly_fee_main_set,
        main_set_id: e.main_set_id,
        booking_number: e.booking_number
      }))
      if (modalItemBudget.id) {
        budgetPeriods = budgetPeriods.filter(e => e.id !== modalItemBudget.id)
      }
      modalItemBudget.budget_periods = budgetPeriods
    }

    this.setState({ showAddBudgetModal, modalItemBudget: modalItemBudget || {} }, () => cb())
  }

  showAddBudgetItemModal = (showAddBudgetItemModal, modalItemBudgetItem = {}, modalItemBudget = {}, cb = () => {}) => {
    const { clientBudget } = this.state
    if (modalItemBudgetItem && modalItemBudgetItem.id) {
      modalItemBudget = clientBudget.find(e => e.id === modalItemBudgetItem.budget_id) || {}
    }

    this.setState({
      showAddBudgetItemModal,
      modalItemBudget: modalItemBudget || {},
      modalItemBudgetItem: modalItemBudgetItem || {}
    }, () => cb())
  }

  updateBudgetSaved = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'

    this.showAddBudgetModal(false, {},  () => {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `Participant Budget ${action} successfully.`)
        } else {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} participant budget successfully.`)
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} participant budget successfully. Please try again later.`)
      }

      this.fetchBudget()
    })
  }

  updateBudgetItemSaved = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'

    try {
      if (r && r.id) {
        notify.success(`${formatter.capitalize(action)} Successfully`, `Item Number ${action} successfully.`)
        this.showAddBudgetItemModal(false)

        this.fetchBudget()
      } else {
        if (r.invalid && r.errors) {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `${formatter.toErrorMessage(r.errors)}`)
        } else {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} item number successfully.`)
        }
      }
    } catch (e) {
      notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} item number successfully. Please try again later`)
    }

    // this.showAddBudgetItemModal(false, {}, {}, () => {
    //   try {
    //     if (r && r.id) {
    //       notify.success(`${formatter.capitalize(action)} Successfully`, `Item Number ${action} successfully.`)
    //     } else {
    //       if (r.invalid && r.errors) {
    //         notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `${formatter.toErrorMessage(r.errors)}`)
    //       } else {
    //         notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} item number successfully.`)
    //       }
    //     }
    //   } catch (e) {
    //     notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} item number successfully. Please try again later`)
    //   }

    //   this.fetchBudget()
    // })
  }

  removeBudgetItem = async(item) => {
    const { clientId } = this.props
    this.setState({ loadingList: true })
    const r = await clientBudgetItemService.remove(item.id)
    await this.fetchBudget()
    this.setState({ loadingList: false })

    if (r && r.id) {
      const logText = `PM Booking Item under booking number "${item.booking_number}": "${item.cat_name}${item.cat_item_name ? ` - ${item.cat_item_name} (${item.cat_item_identifier})` : ''}" is deleted.`
      log.deleteClientSBCat(clientId, logText)

      notify.success(`Delete Successfully`, `Item Number deleted successfully.`)
    } else {
      notify.error(`Unable to Delete Successfully`, `Unable to delete item number successfully.`)
    }
  }

  handleDelete = (item, title = 'Service Booking') => {
    const { onDeleteBudget } = this
    if (item.id && item.is_deletable) {
      confirm({
        title: `Proceed to delete ${title}?`,
        content: (
          <div style={{color: 'rgb(238, 27, 27)'}}>
            <p>About to delete this {title}. Are you sure?</p>
            <p>This action is irreversible.</p>
          </div>
        ),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk () {
          onDeleteBudget(item, title)
        },
        onCancel () {
        }
      })
    } else {
      warning({
        title: `Unable to Delete ${title}`,
        content: `This ${title} is unable to delete. Please make sure there are no invoices/credits and subsets under this ${title}.`
      })
    }
  }

  onDeleteBudget = async (item, title) => {
    const { clientId } = this.props
    this.setState({ loading: true })

    try {
      const r = await clientBudgetService.remove(item.id)

      if (r && r.id) {
        const logText = `${title} with booking number "${item.booking_number}" is deleted.`
        log.deleteClientSB(clientId, logText)

        notify.success('Deleted successfully', `${title} deleted successfully.`)
        this.fetchBudget()
      } else {
        notify.error('Unable to delete successfully', `Unable to delete ${title} successfully. Please try again later.`)
      }
    } catch (e) {
      notify.error('Unable to delete successfully', `Unable to delete ${title} successfully. Please try again later.`)
    }

    this.setState({ loading: false })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientBudget))
