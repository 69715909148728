export const FETCH_CREDITS = 'FETCH_CREDITS'
export const FETCHING_CREDITS = 'FETCHING_CREDITS'
export const CREDITS_FETCHED = 'CREDITS_FETCHED'

export const fetchCredits = (params) => {
  return Object.assign({
    type: FETCH_CREDITS
  }, params)
}

export const fetchingCredits = (loading) => {
  return {
    type: FETCHING_CREDITS,
    loading
  }
}

export const creditsFetched = (credits) => {
  return {
    type: CREDITS_FETCHED,
    credits
  }
}
