import { FETCHING_FUNDING_CATEGORIES, FUNDING_CATEGORIES_FETCHED } from '../actions/funding'

const INITIAL_STATE = {
  fundingCategories: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_FUNDING_CATEGORIES:
      return handleFetchingFundingCats(state, action)
    case FUNDING_CATEGORIES_FETCHED:
      return handleFundingCatsFetched(state, action)
    default:
      return state
  }
}

function handleFetchingFundingCats (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleFundingCatsFetched (state, action) {
  const { fundingCategories, loading } = action
  return { ...state, fundingCategories, loading }
}
