import React, { Component } from 'react'
import Moment from 'moment-timezone'
import { clientBudgetService } from '../../../services'
import { formatter, log, validator } from '../../../util'

import { Button, Loading, SideModal } from '../../../components'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'

import './styles.css'

const { Item: FormItem } = Form
const { confirm } = Modal
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
// const dbFormat = 'YYYY-MM-DD HH:mm:ss'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const SBOver0daysMsg = 'This Service Booking has ended.'
const SBOver60daysMsg = 'This Service Booking has passed 60 days of its end date.'
const SBOver90daysMsg = 'This Service Booking has passed 90 days of its end date. Unable to edit the details.'

export class AddBudgetModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      budgetPeriods: [],
      clientId: '',
      clientInfo: {},
      item: {},
      periodStatus: {},
      isEdit: false,
      loading: false,
      spinLoading: false,
      firstDate: null,
      lastDate: null,
      isEnableAmountInput: null
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clientId = '', clientInfo = {}, item = {} } = nextProps
    const isEdit = !!item.id
    const periodStatus = item && item.id ? formatter.toPeriodStatus(item.period_end_date) : {}

    let firstDate = null
    let lastDate = null

    /**
     * when open up budget modal, two scenarios available
     * 1. Main Set (standard booking)
     * - is_monthly_fee_main_set = true
     * - comes with subset id (for added PM Booking)
     * - with or without subset (PM booking list)
     * - is_unable_update_period = true if subset is available
     * - first date and last date would be following the date range of subset
     *
     * 2. Sub Set (PM booking)
     * - is_monthly_fee_main_set = false
     * - comes with mainset id (or without mainset id if no main set available)
     * - no subset
     * - is_unable_update_period = false
     * - first date and last date would be following the main set period (main_period_start_date / main_period_end_date)
     */

    if (item.is_monthly_fee_main_set) {
      if (validator.isNotEmptyArray(item.subset)) {
        for (let i = 0; i < item.subset.length; i++) {
          const s = item.subset[i]

          if (firstDate && s.period_start_date && new Date(s.period_start_date).getTime() < firstDate) {
            firstDate = s.period_start_date
          } else if (!firstDate) {
            firstDate = s.period_start_date
          }

          if (lastDate && s.period_end_date && new Date(s.period_end_date).getTime() < lastDate) {
            lastDate = s.period_end_date
          } else if (!lastDate) {
            lastDate = s.period_end_date
          }
        }
      }
    } else {
      firstDate = item.main_period_start_date
      lastDate = item.main_period_end_date
    }

    const isEnableAmountInput = prevState.isEnableAmountInput === null ? !!item.is_enable_previous_amount : prevState.isEnableAmountInput

    return { ...prevState, budgetPeriods: validator.isArray(item.budget_periods) ? item.budget_periods : [], clientId, clientInfo, item, isEdit, periodStatus, firstDate, lastDate, isEnableAmountInput }
  }

  handleSubmit = () => {
    const { clientId, clientInfo, form, onUpdate } = this.props
    const { item, isEdit = false } = this.state
    const { validateFieldsAndScroll } = form
    const { onSubmit } = this

    const isMainSet = !!item.is_monthly_fee_main_set
    const title = isMainSet
      ? 'STANDARD Booking'
      : 'PM Booking'

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        let section = {
          isDifferentSBNumber: false,
          isDifferentSBNumberData: {}
        }
        let isTriggerConfirm = false

        if (isEdit) {
          if (isMainSet) {
            const prevBookingNumber = item.booking_number ? item.booking_number.trim() : item.booking_number
            const currBookingNumber = values.booking_number ? values.booking_number.trim() : values.booking_number

            if (prevBookingNumber !== currBookingNumber) {
              isTriggerConfirm = true

              section.isDifferentSBNumber = true
              section.isDifferentSBNumberData = {
                prevBookingNumber,
                currBookingNumber
              }
            }
          }

          if (isTriggerConfirm) {
            confirm({
              title: `Proceed To Update ${title} ?`,
              content: (
                <div>
                  <p>Check the following issues before proceed.</p>
                  <ul>
                    { section.isDifferentSBNumber
                        ? <li>
                          <p style={{color: '#ee1b1b'}}>{`The STANDARD Service Booking Number (${section.isDifferentSBNumberData.currBookingNumber}) is different with previous Booking Number (${section.isDifferentSBNumberData.prevBookingNumber})`} </p>
                          <ul>
                            <li>
                              <p>This will affect all generated Standard Booking Invoices created so far. You may need to review on all Standard Invoices, Statements, and Payment Request csv attached with this STANDARD Booking.</p>
                            </li>
                          </ul>
                        </li>
                        : null }
                  </ul>
                  <p>Confirm your action to proceed.</p>
                </div>
              ),
              okText: 'Confirm',
              cancelText: 'Cancel',
              onOk () {
                // eslint-disable-next-line no-lone-blocks
                onSubmit(values)
              },
              onCancel () {
              }
            })
          } else {
            onSubmit(values)
          }
        } else {
          onSubmit(values)
        }
      }
    })
  }

  onSubmit = async (values) => {
    const { clientId, clientInfo, form, onUpdate } = this.props
    const { item, isEdit = false } = this.state
    const { resetFields } = form

    // set the prev end of date and reset the value of period_end_date back to unadjusted value to avoid update log come out with unwanted log details.
    const prevEndOfDate = Moment.isMoment(values.period_end_date) ? values.period_end_date.clone() : values.period_end_date

    values.client_id = clientId
    values.period_start_date = Moment.isMoment(values.period_start_date) ? values.period_start_date.startOf('day') : values.period_start_date
    values.period_end_date = Moment.isMoment(values.period_end_date) ? values.period_end_date.startOf('day') : values.period_end_date

    if (values.is_setup_cost_req === true) {
      values.setup_cost_reason = null
    }

    if (values.is_first_month_fee === true) {
      values.first_month_fee_reason = null
    }

    if (values.is_enable_previous_amount !== true) {
      values.sb_previous_value = 0
    }

    // console.log('add modal values', values, values.period_start_date.format(dbFormat), values.period_end_date.format(dbFormat))

    this.setState({ loading: true })

    let r = null
    if (isEdit) {
      r = await clientBudgetService.save(item.id, values)
    } else {
      values.is_monthly_fee_main_set = item.is_monthly_fee_main_set
      values.main_set_id = item.main_set_id || null
      values.sub_set_id = item.sub_set_id || null
      r = await clientBudgetService.add(values)
    }

    this.setState({ loading: false })

    if (r && r.id) {
      const isMainSet = item.is_monthly_fee_main_set
      // reset the period_end_date to before rectified one
      values.period_end_date = prevEndOfDate
      values.sb_budget_value = values.sb_budget_value ? formatter.toPriceFloat(values.sb_budget_value) : values.sb_budget_value
      values.sb_previous_value = values.sb_previous_value ? formatter.toPriceFloat(values.sb_previous_value) : values.sb_previous_value

      resetFields()

      if (isEdit) {
        log.updateClientSB(
          clientId,
          item,
          values,
          ['is_monthly_fee_main_set', 'is_enable_previous_amount', 'main_set_id', 'sub_set_id'],
          [
            { key: 'sb_budget_value', label: 'Allocated Amount' },
            { key: 'sb_previous_value', label: 'Claimed/Utilised Amount' },
          ],
          `(${values.booking_number})`
        )
      } else {
        const startDate = formatter.toShortDate(values.period_start_date)
        const endDate = formatter.toShortDate(values.period_end_date)
        const booking = isMainSet ? 'Standard Booking' : 'PM Booking'
        const amount = isMainSet ? `, along with Allocated Amount of ${values.sb_budget_value} and Claimed/Utilised Amount of "${values.sb_previous_value}"` :''

        let logText = `New ${booking} for ${clientInfo.first_name} ${clientInfo.last_name} with Booking Number "${values.booking_number}" starting from "${startDate}" to "${endDate}"${amount}.`

        if (isMainSet) {
          logText += ` Is Setup Cost Required set to ${values.is_setup_cost_req}`
          if (values.is_setup_cost_req === false) {
            logText += `, Reason: ${values.setup_cost_reason}.`
          } else {
            logText += '. '
          }

          logText += ` Is Monthly Fee Invoiced for first day set to ${values.is_first_month_fee}`
          if (values.is_first_month_fee === false) {
            logText += `, Reason: ${values.first_month_fee_reason}.`
          } else {
            logText += '. '
          }
        }

        log.addClientSB(clientId, logText)
      }
    }

    if (onUpdate) {
      onUpdate(isEdit, r)
    }
  }

  validateDate = (rule, value, callback) => {
    const { form } = this.props
    const { item, firstDate, lastDate, budgetPeriods } = this.state
    const isMainSet = !!item.is_monthly_fee_main_set

    const sd1 = rule.field === 'period_start_date' ? value.clone() : form.getFieldValue('period_start_date')
    const ed1 = rule.field === 'period_end_date' ? value.clone() : form.getFieldValue('period_end_date')
    const sf1 = firstDate ? (Moment.isMoment(firstDate) ? firstDate.clone().startOf('day') : Moment(firstDate)) : null
    const ef1 = lastDate ?(Moment.isMoment(lastDate) ? lastDate.clone() : Moment(lastDate)) : null

    if (sd1) {
      if (value < sd1) {
        callback(new Error('End date is before start date.'))
      }
    }

    if (sf1 && ef1) {
      /**
       * 1. if the Service Booking is Main Set (Standard Booking), the period should be out of the subset.
       * e.g. if subset periods earliest date is d1 and latest date is d2, the period of main set should be <= d1 & >= d2
       *
       * 2. if the Service Booking is Sub Set (PM Booking), the period should be within the subset.
       * e.g. if subset periods earliest date is d1 and latest date is d2, the period of sub set should be >= d1 & <= d2
       */
      if (isMainSet) {
        if (sd1.isAfter(sf1, 'day') && sd1.isBefore(ef1, 'day')) {
          callback(new Error('Start date should be outside of PM Booking periods within.'))
        }

        if (ed1.isAfter(sf1, 'day') && ed1.isBefore(ef1, 'day')) {
          callback(new Error('End date should be outside of PM Booking periods within.'))
        }
      } else {
        if (!(sd1.isSameOrAfter(sf1, 'day') && sd1.isSameOrBefore(ef1, 'day'))) {
          callback(new Error('Start date should be inside the period of standard booking.'))
        }

        if (!(ed1.isSameOrAfter(sf1, 'day') && ed1.isSameOrBefore(ef1, 'day'))) {
          callback(new Error('End date should be inside the period of standard booking.'))
        }
      }
    }

    const overlapping = this.isDateOverlapping(value)
    if (overlapping.result) {
      callback(new Error(`Date is overlapping with ${overlapping.text}`))
    }

    callback()
  }

  validateAmount = (rule, value, callback) => {
    const { form } = this.props
    const field = rule && rule.field ? rule.field : null
    const amtText = field === 'sb_original_value' ? 'Allocated Amount' : field === 'sb_budget_value' ? 'Allocated Budget' : field === 'sb_previous_value' ? 'Claimed/Utilised Amount' : ''
    const formValues = form.getFieldsValue(['sb_original_value', 'sb_previous_value', 'sb_budget_value'])

    if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error(`Please enter ${amtText}`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${amtText} value must be valid`))
    } else {
      callback()
    }
  }

  isDateOverlapping (value) {
    const { item, budgetPeriods } = this.state
    const isUnableUpdatePeriod = !!item.is_unable_update_period
    const isMainSet = !!item.is_monthly_fee_main_set
    const isSubsetWithMain = !!item.main_set_id

    let r = { result: null }

    /**
     * only check date overlapping when
     * 1. it is standard booking (main set = true) and it is able to update period (i.e. for the case that add Standard booking on top of PM Booking, ignore)
     * 2. it is pm booking (main set = false) and it does not have parent standard booking (isSubsetWithMain = false)
     */
    if ((isMainSet && !isUnableUpdatePeriod) || (!isMainSet && !isSubsetWithMain)) {
      if (validator.isNotEmptyArray(budgetPeriods)) {
        for (let i = 0; i < budgetPeriods.length; i++) {
          const p = budgetPeriods[i]

          if (p.id && p.active) {
            const isBetween = Moment(value).isBetween(p.period_start_date, p.period_end_date, '[]')

            if (isBetween) {
              const extraText = `${p.is_monthly_fee_main_set ? `Standard Booking` : `PM Booking`} (${p.booking_number})`
              return {
                result: true,
                period: `${Moment(p.period_start_date).format('DD/MM/YYYY')} - ${Moment(p.period_end_date).format('DD/MM/YYYY')}`,
                text: extraText
              }
            }
          }
        }
      }
    }

    r.result = false
    return r
  }

  togglePreviousAmount = (value) => {
    this.setState({ isEnableAmountInput : value })
  }

  render () {
    const { visible, clientId, form, onClose } = this.props
    const { isEdit, isEnableAmountInput, item, loading, periodStatus, spinLoading } = this.state
    const { getFieldDecorator, getFieldValue, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }
    const isDisabled = periodStatus && periodStatus.isDuePeriod2
    const isUnableUpdatePeriod = !!item.is_unable_update_period

    const title = item.is_monthly_fee_main_set
      ? (isEdit ? 'Edit STANDARD Booking' : 'Add STANDARD Booking')
      : (isEdit ? 'Edit PM Booking' : 'Add PM Booking')

    const isSetupCostReq = !isEdit ? getFieldValue('is_setup_cost_req') === false : null
    const isFirstMonthFeeReq = !isEdit ? getFieldValue('is_first_month_fee') === false : null
    const isMainSetCreate = (item.is_monthly_fee_main_set && !isEdit)

    return (
      <SideModal
        key={`sidebudget_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          resetFields()
          onClose()
        }}
        buttons={!isMainSetCreate
          ? [ <Loading loading={loading} blur key='btnr'>
            {!isDisabled
              ? <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
              : null }
          </Loading>]
          : null }
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              { periodStatus && periodStatus.isDue
                ? <div className='warning-msg'>{periodStatus.isDuePeriod2 ? SBOver90daysMsg : periodStatus.isDuePeriod1 ? SBOver60daysMsg : SBOver0daysMsg}</div>
                : null }

              <FormItem label='Active'>
                {getFieldDecorator('active', {
                  initialValue: isEdit ? item.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    disabled={isDisabled}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>

              <FormItem label='Booking Number' hasFeedback>
                {getFieldDecorator('booking_number', {
                  initialValue: item.booking_number || '',
                  rules: [
                    { required: true, message: 'Please enter booking number' },
                    { whitespace: true, message: 'Please enter booking number' }
                  ]
                })(
                  <Input disabled={isDisabled} />
                )}
              </FormItem>

              <FormItem label='Start Date'>
                {getFieldDecorator('period_start_date', {
                  initialValue: item.period_start_date ? formatter.toMoment(item.period_start_date) : null,
                  rules: [
                    { required: true, message: 'Please enter start date' },
                    { validator: this.validateDate }
                  ]
                })(
                  <DatePicker defaultPickerValue={Moment(new Date())} disabled={isDisabled || isUnableUpdatePeriod} format={dateFormat} />
                )}
              </FormItem>

              <FormItem label='End Date'>
                {getFieldDecorator('period_end_date', {
                  initialValue: item.period_end_date ? formatter.toMoment(item.period_end_date) : null,
                  rules: [
                    { required: true, message: 'Please enter end date' },
                    { validator: this.validateDate }
                  ]
                })(
                  <DatePicker defaultPickerValue={Moment(new Date())} disabled={isDisabled || isUnableUpdatePeriod} format={dateFormat} />
                )}
              </FormItem>

              { item.is_monthly_fee_main_set
                ? <div>
                  <FormItem label='Allocated Amount (PM - Monthly Fee)'>
                    {getFieldDecorator('sb_budget_value', {
                      initialValue: item.sb_budget_value || 0.0,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateAmount }
                      ]
                    })(
                      <Input addonBefore={'$'} disabled={isDisabled || isUnableUpdatePeriod} />
                    )}
                  </FormItem>

                  <FormItem label="Is Allocated Amount different from Plan's Allocation?">
                    {getFieldDecorator('is_enable_previous_amount', {
                      initialValue: item.is_enable_previous_amount || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        onChange={this.togglePreviousAmount}
                        disabled={isDisabled || isUnableUpdatePeriod}
                      />
                    )}
                  </FormItem>

                  { isEnableAmountInput
                    ? <FormItem label='Claimed/Utilised Amount'>
                      {getFieldDecorator('sb_previous_value', {
                        initialValue: item.sb_previous_value || 0.0,
                        rules: [
                          { required: true, message: ' ' },
                          { validator: this.validateAmount }
                        ]
                      })(
                        <Input
                          addonBefore={'$'}
                          disabled={isDisabled || isUnableUpdatePeriod}
                        />
                      )}
                    </FormItem>
                    : null }
                </div>
                : null }

              { !isEdit && item.is_monthly_fee_main_set
                ? <div>
                  <FormItem label='Also Add Setup Cost into booking?'>
                    {getFieldDecorator('is_setup_cost_req', {
                      initialValue: item.is_setup_cost_req === undefined ? true : false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        disabled={isDisabled}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        disabled={isDisabled || isUnableUpdatePeriod}
                      />
                    )}
                  </FormItem>

                  { isSetupCostReq
                    ? <FormItem label='No Setup Cost Reason'>
                      {getFieldDecorator('setup_cost_reason', {
                        rules: [
                          { required: true,  message: 'Please enter No Setup Cost Reason' },
                          { whitespace: true, message: 'Please enter No Setup Cost Reason' }
                        ]
                      })(
                        <TextArea
                          rows={3}
                          disabled={isDisabled || isUnableUpdatePeriod}
                        />
                      )}
                    </FormItem>
                    : null }

                  <FormItem label='Create first invoice for Monthly Fee immediately?'>
                    {getFieldDecorator('is_first_month_fee', {
                      initialValue: item.is_first_month_fee === undefined ? true : false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        disabled={isDisabled}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        disabled={isDisabled || isUnableUpdatePeriod}
                      />
                    )}
                  </FormItem>

                  { isFirstMonthFeeReq
                    ? <FormItem label='No First Day Monthly Fee Inv Reason'>
                      {getFieldDecorator('first_month_fee_reason', {
                        rules: [
                          { required: true,  message: 'Please enter No First Day Monthly Fee Inv Reason' },
                          { whitespace: true, message: 'Please enter No First Day Monthly Fee Inv Reason' }
                        ]
                      })(
                        <TextArea
                          rows={3}
                          disabled={isDisabled || isUnableUpdatePeriod}
                        />
                      )}
                    </FormItem>
                    : null }
                </div>
                : null }

              {/* <FormItem label='Monthly Financial Admin. Fee'>
                {getFieldDecorator('monthly_fee_acq', {
                  initialValue: item.monthly_fee_acq || 0.0,
                  rules: [
                    { required: true, message: 'Please enter monthly financial admin fee.' },
                    { validator: this.validateAmount }
                  ]
                })(
                  <Input addonBefore={'$'} disabled={isDisabled} />
                )}
              </FormItem>

              <FormItem label='Monthly Financial Admin. SB No.'>
                {getFieldDecorator('monthly_fee_booking_number', {
                  initialValue: item.monthly_fee_booking_number || null,
                  rules: [
                    { whitespace: true, message: 'Please enter monthly financial admin SB no.' }
                  ]
                })(
                  <Input disabled={isDisabled} />
                )}
              </FormItem> */}

              { isMainSetCreate
                ? (!isDisabled
                  ? <div className='scroll-footer'>
                    <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
                  </div>
                  : null )
                : null }
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddBudgetModal)
