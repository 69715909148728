import { FETCHING_STATEMENTS, STATEMENTS_FETCHED } from '../actions/statement'

const INITIAL_STATE = {
  statements: { list: [], total: 0 },
  abaList: [],
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_STATEMENTS:
      return handleFetchingStatements(state, action)
    case STATEMENTS_FETCHED:
      return handleStatementsFetched(state, action)
    default:
      return state
  }
}

function handleFetchingStatements (state, action) {
  const { loading, loadingList } = action
  return { ...state, loading, loadingList, statements: { list: state.statements.list, total: loading ? 0 : state.statements.total } }
}

function handleStatementsFetched (state, action) {
  const { statements, loading, loadingList } = action
  return { ...state, statements, loading, loadingList }
}
