module.exports = {
  SB_COMPARE: {
    code: 'sb-compare',
    name: 'Service Booking Validation',
    uploadText: 'Please upload NDIS service booking extract from PRODA site.'
  },
  INV_RCV_IMPORT: {
    code: 'inv-rcv-import',
    name: 'Invoice Receive Amount Import',
    uploadText: 'Please upload PRODA amount receive summary csv from PRODA site.'
  }
}
