import React, { Component } from 'react'
import moment from 'moment-timezone'
import { fileService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'
import { FileUploadMsg } from '../../../constants'
import { cloneDeep } from 'lodash'

import { Button, FileUpload, Loading, SideModal } from '../../../components'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import { apiHostname } from '../../../config'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

const dateFormat = 'DD/MM/YYYY'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const checkIsNewInvoice = (props) => {
  const { invoiceId } = props
  return invoiceId === '' || invoiceId === 'new' || invoiceId === 'add'
}

export class AddFileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoiceId: '',
      invoiceInfo: {},
      currentSubCatList: [],
      currentSubCat: {},
      fileList: [],
      fileUploadedList: {},
      item: {},
      itemPrev: {},
      isEdit: false,
      isNewInvoice: false,
      loading: false,
      spinLoading: false,
      visible: false,
      uploadErrorMsg: ''
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { invoiceId = '', invoiceInfo = {}, item = {}, subCategoriesList = [], visible } = nextProps
    const isNewInvoice = checkIsNewInvoice(nextProps)
    const isEdit = isNewInvoice ? (item.seq !== undefined && item.seq > -1) : !!item.id
    const itemPrev = cloneDeep(item)

    if (visible === true && prevState.visible === false) {
      // for edit invoice file when add invoice, the sub categories must be enabled to select, so the currentSubCatList must be available to select when opened up the modal
      const list = isEdit ? subCategoriesList.filter(e => e.id === item.main_cat_id) : []

      return { ...prevState, invoiceId, invoiceInfo, item, itemPrev, isEdit, isNewInvoice, visible, currentSubCatList: list, currentSubCat: {} }
    } else {
      return { ...prevState, invoiceId, invoiceInfo, item, itemPrev, isEdit, isNewInvoice, visible }
    }
  }

  findCatItems = (input, option) => {
    const item = `${option.props.children}`
    return item.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleSubmit = () => {
    const { invoiceId, invoiceInfo, form, onUpdate, categoriesList = [], subCategoriesList = [], onSetFile = () => {} } = this.props
    const { currentSubCat, item, itemPrev, isEdit = false, isNewInvoice = false, fileUploadedList, uploadErrorMsg } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (!isEdit && !fileUploadedList.uid) {
          this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
          return
        } else if (uploadErrorMsg) {
          return
        } else {
          const adminUser = await auth.getCurrentUser() || {}

          if (fileUploadedList.uid) {
            values.fileName = fileUploadedList.fileName
            values.filePath = fileUploadedList.filePath
            values.fileUrl = fileUploadedList.fileUrl
          } else if (isNewInvoice && isEdit && item.fileUrl) {
            // append previous saved file parameters unless new file is changed (i.e. new uid is available)
            values.fileName = item.fileName
            values.filePath = item.filePath
            values.fileUrl = item.fileUrl
          }

          // console.log('add modal values', values)

          this.setState({ loading: true })

          let r = null
          if (isNewInvoice) {
            values.genre = 'invoice'
            values.created_by_id = adminUser.id || ''
            values.created_by_name = adminUser.name || ''
            values.is_attach_mail_comm = currentSubCat && currentSubCat.id ? currentSubCat.cat_sub_is_attach_mail_comm : undefined // only required when save (fileSet) under new invoice. for file tab, the is_attach_mail_comm flag will be detected during inv loading

            if (!isEdit) {
              values.seq = Math.random()
            } else {
              values.seq = item.seq
            }

            onSetFile(values)
            resetFields()
            this.fileClear()
          } else {
            if (isEdit) {
              r = await fileService.save(item.id, values)
            } else {
              values.genre = 'invoice'
              values.genre_id = invoiceId
              values.created_by_id = adminUser.id || ''
              values.created_by_name = adminUser.name || ''

              r = await fileService.add(values)
            }

            this.setState({ loading: false })
            if (onUpdate) {
              if (r && r.id) {
                this.fileClear()
                resetFields()

                const cat = values.main_cat_id ? categoriesList.find(e => e.id === values.main_cat_id) : null
                const subcat = values.sub_cat_id ? subCategoriesList.find(e => e.cat_sub_id === values.sub_cat_id) : null

                if (isEdit) {
                  const prevCat = itemPrev.main_cat_id ? categoriesList.find(e => e.id === itemPrev.main_cat_id) : null
                  const prevSubCat = itemPrev.sub_cat_id ? subCategoriesList.find(e => e.cat_sub_id === itemPrev.sub_cat_id) : null

                  const prevItem = {
                    file_name: itemPrev.name,
                    label: itemPrev.label,
                    issuance_date: formatter.toShortDate(itemPrev.issuance_date),
                    expiry_date: itemPrev.expiry_date ? formatter.toShortDate(itemPrev.expiry_date) : null,
                    main_category: prevCat ? prevCat.name : '',
                    sub_category: prevSubCat ? prevSubCat.cat_sub_name : '',
                    active: itemPrev.active
                  }

                  const currItem = {
                    file_name: values.fileName || item.name,
                    label: values.label,
                    issuance_date: formatter.toShortDate(values.issuance_date),
                    expiry_date: values.expiry_date ? formatter.toShortDate(values.expiry_date) : null,
                    main_category: cat ? cat.name : '',
                    sub_category: subcat ? subcat.cat_sub_name : '',
                    active: values.active
                  }

                  log.updateInvoiceFile(invoiceId, prevItem, currItem, undefined, undefined, undefined, `${currItem.file_name}`)
                } else {
                  log.addInvoiceFile(invoiceId, `New file added for ${invoiceInfo.invoice_number} with file name "${values.fileName}" and labelled with "${values.label}". ${cat ? `Main Category as "${cat.name}", ` : ''}${subcat ? `Sub Category as "${subcat.cat_sub_name}".` : ''} ${values.issuance_date ? `Issuance Date set as "${formatter.toShortDate(values.issuance_date)}"` : ''}${values.expiry_date ? ` and Expiry Date set as "${formatter.toShortDate(values.expiry_date)}".` : '.'} File is ${values.active ? 'enabled': 'disabled'}.`)
                }
              }
              onUpdate(isEdit, r)
            }
          }

          this.setState({ loading: false })
        }
      } else if (!fileUploadedList.uid) {
        this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
      }
    })
  }

  handleMainCatChange = (id) => {
    const { form, subCategoriesList } = this.props
    const { setFieldsValue } = form
    const list = subCategoriesList.filter(e => e.id === id)
    this.setState({ currentSubCatList: list, currentSubCat: {} })
    setFieldsValue({ sub_cat_id: undefined })
  }

  handleSubCatChange = (id) => {
    const { currentSubCatList } = this.state
    const cat = currentSubCatList.find(e => e.cat_sub_id === id)

    if (cat) {
      this.setState({ currentSubCat: cat })
    }
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ spinLoading: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCat } = this.state

    if (value) {
      if (currentSubCat.is_expiry && currentSubCat.expiry_value) {
        const expiryMonth = parseInt(currentSubCat.expiry_value)
        let expDate = formatter.toMomentClone(value)
        const expiryDate = expDate.add(expiryMonth, currentSubCat.expiry_unit || 'month')
        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ spinLoading: false })
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({fileList: newFileList, fileUploadedList: {}})
    }
  }

  fileClear = () => {
    this.setState({fileList: [], fileUploadedList: {}, uploadErrorMsg: ''})
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({fileUploadedList: data, uploadErrorMsg: ''})
      }
    }
  }

  fileSet = (file) => {
    if (file && validator.isValidUploadType(file.type)) {
      this.setState({ fileList: [file], uploadErrorMsg: '' })
      return true
    } else {
      this.setState({ fileList: [], fileUploadedList: {}, uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatDOC })
      return false
    }
  }

  render () {
    const { visible, categoriesList, subCategoriesList, invoiceId, form, onClose } = this.props
    const { currentSubCatList, fileList, isEdit, isNewInvoice, invoiceInfo, item, loading, spinLoading, currentSubCat, uploadErrorMsg } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit Invoice File' : 'Add Invoice File'
    // when it is under new invoice or add file at file tab, Main/Sub cats are editable. else, it is not
    const isMainSubNotEditable = isNewInvoice ? false : isEdit

    return (
      <SideModal
        key={`sidebudget_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          this.fileClear()
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem label='Main Category'>
                {getFieldDecorator('main_cat_id', {
                  initialValue: item.main_cat_id || '',
                  rules: [
                    { required: true, message: 'Please select main category' }
                  ]
                })(
                  <Select
                    placeholder='Please select main category'
                    showSearch
                    optionFilterProp='children'
                    onChange={(mainId) => this.handleMainCatChange(mainId)}
                    filterOption={(input, option) => this.findCatItems(input, option)}
                    disabled={isMainSubNotEditable}>
                    {
                      categoriesList.map((main) => (
                        <Option key={main.name} value={main.id}>{main.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Sub Category'>
                {getFieldDecorator('sub_cat_id', {
                  initialValue: item.sub_cat_id || '',
                  rules: [
                    { required: true, message: 'Please select sub category' }
                  ]
                })(
                  <Select
                    placeholder='Please select sub category'
                    showSearch
                    optionFilterProp='children'
                    onChange={(subId) => this.handleSubCatChange(subId)}
                    filterOption={(input, option) => this.findCatItems(input, option)}
                    disabled={currentSubCatList.length < 1 || isMainSubNotEditable}>
                    {isEdit
                      ? subCategoriesList.map((sub) => (
                        <Option key={sub.cat_sub_name} value={sub.cat_sub_id}>{sub.cat_sub_name}</Option>
                      ))
                      : currentSubCatList.map((sub) => (
                        <Option key={sub.cat_sub_name} value={sub.cat_sub_id}>{sub.cat_sub_name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Issuance Date'>
                {getFieldDecorator('issuance_date', {
                  rules: [
                    { required: true, message: 'Please enter issuance date' }
                  ],
                  initialValue: item.issuance_date ? formatter.toMoment(item.issuance_date) : formatter.toMoment(invoiceInfo.invoice_date)
                })(
                  <DatePicker defaultPickerValue={moment(new Date())} onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} />
                )}
              </FormItem>

              {currentSubCat.is_expiry || item.expiry_date
                ? <FormItem label='Expiry Date'>
                  {getFieldDecorator('expiry_date', item.expiry_date ? {
                    rules: [
                      { required: true, message: 'Please enter expiry date' }
                    ],
                    initialValue: item.expiry_date ? formatter.toMoment(item.expiry_date) : null
                  } : {
                    rules: [
                      { required: true, message: 'Please enter expiry_date' }
                    ]
                  })(
                    <DatePicker defaultPickerValue={moment(new Date())} format={dateFormat} />
                  )}
                </FormItem>
                : null }

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: item.label || invoiceInfo.invoice_number,
                    rules: [
                      { required: true, message: 'Please enter label' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof item.active === 'boolean' ? item.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FileUpload
                  file={item.url || item.fileUrl}
                  fileName={item.name || item.fileName}
                  loading={spinLoading}
                  readOnly={false}
                  showError={!!uploadErrorMsg}
                  errorMessage={uploadErrorMsg}
                  upload={{
                    method: 'POST',
                    action: `${apiHostname}/private/api/files/upload`,
                    data: {invoiceId: invoiceId},
                    name: 'file',
                    onRemove: this.fileRemove,
                    onChange: this.fileChange,
                    beforeUpload: this.fileSet,
                    headers: {Authorization: `Bearer ${auth.getCurrentToken()}` },
                    fileList: fileList,
                    multiple: false
                  }}
                />
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddFileModal)
