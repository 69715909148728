export default {
  TYPE_PRODA: 'proda',
  TYPE_PRODA_STD: 'proda-std',
  TYPE_PRODA_PM: 'proda-pm',
  TYPE_PAYMENT: 'payment',
  TYPE_ABA: 'aba',
  TYPE_RMT: 'rmt',
  TYPE_SB_PRODA: 'sb-proda',
  TYPE_SB_PRODA_V2: 'sb-proda-v2',
  TYPE_STMT: 'statement',
  TYPE_CONTACTS_GENERAL_SIMPLE: 'general-simple'
}
