import React, { Component } from 'react'
import moment from 'moment-timezone'
import { invoiceUpdateService } from '../../../services'
import { formatter } from '../../../util'

import { Button, Loading } from '../../../components'
import { InvoiceUpdateType } from '../../../constants'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'

import notify from '../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form

const dateFormat = 'DD/MM/YYYY'

export class StatusUpdateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actionType: null,
      invoiceList: [],
      listType: null,
      loading: false,
      loadingUpdate: false,
      totalRcvAmt: 0,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { invoiceList = [], listType, actionType = null, visible } = nextProps

    if (visible === true && prevState.visible === false) {
      let subtotal = 0
      for (let i = 0; i < invoiceList.length; i++) {
        const inv = invoiceList[i]
        subtotal += inv.received_subtotal
      }

      return { ...prevState, invoiceList, listType, actionType, visible, totalRcvAmt: subtotal }
    } else if (visible === false && prevState.visible === true) {
      return { ...prevState, invoiceList: [], listType: null, actionType: null, visible, totalRcvAmt: 0 }
    } else {
      return prevState
    }
  }

  handleSubmit = async () => {
    const { form, onUpdate } = this.props
    const { actionType, invoiceList, listType, loading, loadingUpdate } = this.state

    if (loading || loadingUpdate) return

    this.setState({ loadingUpdate: true })

    const type = actionType === InvoiceUpdateType.INV_UPDATE_ABA_RMT
      ? 'topay'
      : actionType === InvoiceUpdateType.INV_UPDATE_AUTHORISED
        ? 'authorise'
        : actionType === InvoiceUpdateType.INV_UPDATE_DELETE
          ? 'delete'
          : actionType === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST
            ? 'claim'
            : actionType === InvoiceUpdateType.INV_UPDATE_REJECTED
              ? 'rejected'
              : actionType === InvoiceUpdateType.INV_UPDATE_RCV_PYMT
                ? 'rcvpymt'
                : ''

    if (type) {
      try {
        const paymentDate = form.getFieldValue('payment_date')

        const invoices = []
        for (let i = 0; i < invoiceList.length; i++) {
          const inv = invoiceList[i]

          const dt = actionType === InvoiceUpdateType.INV_UPDATE_RCV_PYMT
            ? {
              id: inv.id,
              item_id: inv.item_id,
              update_received_subtotal: inv.update_received_subtotal,
              update_received_notes: inv.update_received_notes,
              update_received_date: inv.update_received_date,
              update_is_closed: inv.update_is_closed
            }
            : { id: inv.id }
          invoices.push(dt)
        }

        const r = await invoiceUpdateService.updateStatus(type, { invoices, list_type: listType, payment_date: paymentDate })

        if (r && r.count !== undefined) {
          this.closeModal()

          if (onUpdate) {
            await onUpdate(r.invoices, r.file)
          }

          if (r.error) {
            notify.warning('Some status are unable to be updated', formatter.toErrorMessage(r.error))
          } else {
            notify.warning('Status updated', `Invoice(s) selected are updated successfully.`)
          }
        } else {
          console.log('res', r)
          notify.error('Unable to update the status', 'Please try again later.')
        }
      } catch (e) {
        console.log('re', e)
        notify.error('Unable to update the status', 'Some error occrured. Please try again later.')
      }
    } else {
      notify.error('Unable to update the status', 'No allowed action performed.')
    }

    this.setState({ loadingUpdate: false })
  }

  getModalContent = () => {
    const { actionType, invoiceList, totalRcvAmt } = this.state
    const { getFieldDecorator } = this.props.form
    let title = ''
    let content = ''

    const isSingleInv = invoiceList.length === 1
    if (actionType === InvoiceUpdateType.INV_UPDATE_AUTHORISED) {
      title = <div className='sct-title'>
        Authorise Invoice{isSingleInv ? '' : 's'}?
      </div>

      content = <div className='sct-content'>
        Do you want to authorise the following invoice{isSingleInv ? '' : 's'}?
        <ul>
          { invoiceList.map(e => (<li><div>
              <div>{e.invoice_number} ({formatter.toPrice(e.subtotal)}) - processed at {formatter.toShortDate(e.processed_at)}</div>
              <div>for <b>{e.client_name}</b></div>
              { e.provider_name ? <div>with <i>{e.provider_name}</i></div> : null }
            </div>
          </li>)) }
        </ul>
      </div>
    } else if (actionType === InvoiceUpdateType.INV_UPDATE_REJECTED) {
      title = <div className='sct-title'>
        Reject Invoice{isSingleInv ? '' : 's'}?
      </div>

      content = <div className='sct-content'>
        Do you want to reject the following invoice{isSingleInv ? '' : 's'}?
        <ul>
          { invoiceList.map(e => (<li><div>
              <div>{e.invoice_number} ({formatter.toPrice(e.subtotal)}) - processed at {formatter.toShortDate(e.processed_at)}</div>
              <div>for <b>{e.client_name}</b></div>
              { e.provider_name ? <div>with <i>{e.provider_name}</i></div> : null }
            </div>
          </li>)) }
        </ul>
      </div>
    } else if (actionType === InvoiceUpdateType.INV_UPDATE_DELETE) {
      title = <div className='sct-title'>
        Delete Invoice{isSingleInv ? '' : 's'}?
      </div>

      content = <div className='sct-content'>
        <p>Do you want to delete the following invoice{isSingleInv ? '' : 's'}?</p>
        <p><b color='#ff0000'>This action is IRREVERSIBLE!</b></p>
        <ul>
          { invoiceList.map(e => (<li><div>
              <div>{e.invoice_number} ({formatter.toPrice(e.subtotal)}) - processed at {formatter.toShortDate(e.processed_at)}</div>
              <div>for <b>{e.client_name}</b></div>
              { e.provider_name ? <div>with <i>{e.provider_name}</i></div> : null }
            </div>
          </li>)) }
        </ul>
      </div>
    } else if (actionType === InvoiceUpdateType.INV_UPDATE_PYMT_REQUEST) {
      title = <div className='sct-title'>
        Generate Payment Request?
      </div>

      content = <div className='sct-content'>
        Do you want to generate payment request for the following invoice{isSingleInv ? '' : 's'}?
        <ul>
          { invoiceList.map(e => (<li><div>
              <div>{e.invoice_number} ({formatter.toPrice(e.subtotal)}) - processed at {formatter.toShortDate(e.processed_at)}</div>
              <div>for <b>{e.client_name}</b></div>
              { e.provider_name ? <div>with <i>{e.provider_name}</i></div> : null }
            </div>
          </li>)) }
        </ul>
      </div>
    } else if (actionType === InvoiceUpdateType.INV_UPDATE_ABA_RMT) {
      title = <div className='sct-title'>
        Generate ABA / RMT?
      </div>

      content = <div className='sct-content'>
        <p style={{color: 'red'}}><b>To Pay Amount: {formatter.toPrice(totalRcvAmt)}</b></p>
        <p>Select Payment Date for ABA:</p>
        <FormItem
          style={{width: '160px'}}
        >
          {getFieldDecorator(`payment_date`, {
            initialValue: moment(new Date())
          })(
            <DatePicker
              defaultPickerValue={moment(new Date())}
              format={dateFormat}
              placeholder={''}
            />
          )}
        </FormItem>
        <p>Do you want to generate ABA / RMT for the following invoice{isSingleInv ? '' : 's'}?</p>
        <ul>
          { invoiceList.map(e => (<li><div>
              <div>{e.invoice_number} <b>(Received {formatter.toPrice(e.received_subtotal)})</b> - processed at {formatter.toShortDate(e.processed_at)}</div>
              <div>for <b>{e.client_name}</b></div>
              { e.provider_name ? <div>with <i>{e.provider_name}</i></div> : null }
            </div>
          </li>)) }
        </ul>
      </div>
    } else if (actionType === InvoiceUpdateType.INV_UPDATE_RCV_PYMT) {
      // the title and content for rcv payment is completely diff with others
      title = <div className='sct-title'>
        Update Received Payment?
      </div>

      content = <div className='sct-content'>
      Do you want to update payment for the following invoice{isSingleInv ? '' : 's'}?
      <ul>
        { invoiceList.map(e => {
          const isShowRcvAmtNotFull = !e.update_received_subtotal && formatter.toPriceFloat(e.item_subtotal) !== formatter.toPriceFloat(e.item_rcv_subtotal)

          return (
            <li>
              <div>{e.invoice_number} - {e.item_seq} - {e.item_cat_item_name} {e.item_cat_item_identifier ? `(${e.item_cat_item_identifier})` : ''}:</div>
              <ul>
                { e.update_received_subtotal ? <li><b>rcv'd amount: {formatter.toPrice(e.update_received_subtotal)}</b></li> : null }
                { e.update_received_date ? <li><b><i>rcv'd date: {formatter.toShortDate(e.update_received_date)}</i></b></li> : null }
                { e.update_received_notes ? <li>rcv'd notes: {e.update_received_notes}</li> : null }
                { e.update_is_closed
                  ? <li>
                    <b>Is Closed?: {formatter.toYesNo(e.update_is_closed)}</b>
                    { isShowRcvAmtNotFull
                      ? <div style={{color: 'red'}}>
                        <div>Expected Rcv'd Amount: {formatter.toPrice(e.item_subtotal)}</div>
                        <div>Actual Rcv'd Amount: {formatter.toPrice(e.item_rcv_subtotal)}</div>
                      </div>
                      : null }
                  </li>
                  : null }
              </ul>
            </li>
          )
        }) }
      </ul>
      </div>
    }

    return { title, content }
  }


  closeModal = () => {
    const { onClose, form } = this.props
    const { resetFields } = form

    resetFields()
    onClose()
  }

  render () {
    const { key, visible, form, onClose } = this.props
    const { actionType, invoiceList, loading, loadingUpdate } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const { title, content } = this.getModalContent(actionType, invoiceList)
    const isLoading = loading || loadingUpdate

    return (
      <Modal
        width='600px'
        key={`invstatsupdate_${key}`}
        visible={visible}
        title={title}
        onCancel={isLoading ? () => {} : () => { this.closeModal() }}
        footer={[
          <Loading loading={loading} blur>
            <Button
              key='close'
              ghost
              feedback={isLoading}
              onClick={isLoading ? () => {} : () => this.closeModal()}
            >
              Close
            </Button>
            <Button
              style={{backgroundColor: '#3d34eb', color: '#FFF'}}
              key='confirm'
              ghost
              feedback={isLoading}
              onClick={isLoading ? () => {} : () => this.handleSubmit()}
            >
              Confirm
            </Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            { content }
          </Form>
        </Loading>
      </Modal>
    )
  }
}

export default Form.create()(StatusUpdateModal)
