import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { authService, pmRateSetService, pmCategoryService } from '../../../../services'
import { auth } from '../../../../util'

// UI
import { Button, Page, Pager, Panel, SideModal, List } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Rate Set'

export class SettingRateCategories extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      // filter: {},
      // list: [],
      // modal: {
      //   item: { link: {} },
      //   show: false
      // },
      // modalShow: false,
      // searchText: '',
      // selectedItem: { _id: null },
      // sort: {},
      // total: 0
      list: [],
      loading: false,
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchCategories({ currentPage })
  }

  fetchCategories = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.SETTING.CATS.LIST)) return

    try {
      this.setState({ currentPage, loading: true })
      const list = await pmCategoryService.listByPage(currentPage, pageSize, { active: true })
      this.setState({ list: list.list, loading: false, total: list.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  hideModal = () => {
    // const { form, onComplete } = this.props
    // const { modal } = this.state
    // const { resetFields } = form
    // resetFields()
    // modal.item = { link: {} }
    // modal.show = false
    // this.setState({ modal, selectedItem: {} })
    // onComplete()
  }

  showModal = () => {
    // const { modal } = this.state
    // modal.show = true
    // this.setState({ modal })
  }

  handleSave = () => {
    // const { form } = this.props
    // const { validateFields } = form
    // const { selectedItem } = this.state

    // validateFields(async (errors, values) => {
    //   if (!errors) {
    //     const { id } = selectedItem
    //     const { modal } = this.state
    //     const { item } = modal
    //     values.identifier = settingType
    //     values.value = values.name.toLowerCase()
    //     this.setState({ loadingForm: true })

    //     try {
    //       let response

    //       if (id) {
    //         response = await settingGeneralService.save(id, values)
    //       } else {
    //         values.image = item.image
    //         response = await settingGeneralService.add(values)
    //       }

    //       this.setState({ loadingForm: false })

    //       if (response.id) {
    //         const { id } = response
    //         modal.item = { ...item, ...values, id }

    //         this.setState({ modal })
    //         notify.success('Saved successfully', `${settingTitle} saved successfully.`)
    //         this.fetchSettings({ currentPage: 1 })
    //         this.hideModal()
    //       } else {
    //         notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
    //       }
    //     } catch (e) {
    //       notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
    //       this.setState({ loadingForm: false })
    //     }
    //   }
    // })
  }

  handleEdit (id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
    this.showModal()
  }

  async handleDelete (id) {
    // const res = await settingGeneralService.remove(id)

    // if (res) {
    //   message.success('Deleted successfully', `${settingType} deleted successfully`)
    //   this.fetchSettings({ currentPage: 1 })
    // }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Categories',
        width: 16,
        key: 'name'
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => active
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },

      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess(Permissions.SETTING.CATS.READ)
        ? <Link to={`/settings/rate-set/categories/${id}`}>
          <div style={{ cursor: 'pointer' }}>
            <Icon type='form' />
          </div>
        </Link>
        : null
      },
      // {
      //   title: '',
      //   width: 1,
      //   render: ({ id }) => this.hasAccess('deleteRelationship') ? <Popconfirm
      //     title='Confirm to delete this?'
      //     onConfirm={(e) => this.handleDelete(id)}
      //     okText='Yes'
      //     cancelText='No'
      //   ><Icon type='delete' />
      //   </Popconfirm> : null
      // }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} categories`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchCategories({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateCategories))
