import React, { Component } from 'react'
import { formatter } from '../../../util'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

export class AddProviderModal extends Component {
  state = {
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history, onUpdate } = this.props
        const providerId = values.provider_id
        onUpdate && onUpdate(providerId)
      }
    })
  }

  findProviders = (input, option) => {
    const provider = `${option.props.children[0] ? option.props.children[0].trim() : option.props.children[0]}`
    const abn = `${option.props.children[1].props.children ? option.props.children[1].props.children.trim() : option.props.children[1].props.children}`
    const abnFormatted = abn ? formatter.toABNFormat(abn) : abn
    const pvd = `${provider || ''} ${abn || ''} ${abnFormatted || ''}`

    return pvd.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  render () {
    const { form, visible, onClose, providerId, providerList = [] } = this.props
    const { getFieldDecorator } = form
// console.log('provider list', providerList)
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return (
      <Modal visible={visible}
        width={700}
        title={providerId ? 'Update Provider' : 'Select Provider'}
        onCancel={onClose}
        footer={[
          <Button key='ok' type='primary' onClick={this.handleSubmit}>Select</Button>
        ]}
      >
        <div>
          <Form>
            <FormItem {...formItemLayout} label='Provider' hasFeedback>

              {getFieldDecorator('provider_id', providerId ? {
                initialValue: parseInt(providerId),
                rules: [
                  { required: true, message: 'Please select a provider' }
                ]
              } : {
                rules: [
                  { required: true, message: 'Please select a provider' }
                ]
              })(
                <Select showSearch
                  style={{ width: '100%'}}
                  placeholder='Provider'
                  optionFilterProp='children'
                  notFoundContent='Not found'
                  filterOption={(input, option) => this.findProviders(input, option)}
                  // onChange={(e, c) => this.onSelectClient(e, c)}
                >
                  {
                    providerList.map((pvd, idx) => {
                      return <Option key={`provider${idx}`} value={pvd.id}>
                        {pvd.fullname}
                        <div className='clientId'>{pvd.abn}</div>
                      </Option>

                    })
                  }

                </Select>
              )}
            </FormItem>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AddProviderModal)
