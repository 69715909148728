import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { reportTypeService } from '../../../services'
import { auth, formatter, validator } from '../../../util'

// UI
import {List, Page, Pager } from '../../../components'
import { Permissions } from '../../../constants'

import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'
import './styles.css'

const pageSize = 20

export class ReportToReceiveInvoices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      loading: true,
      searchText: '',
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchList({ currentPage, filter, loading, searchText, sort })
  }

  fetchList = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.REPORT.REPORT_PENDING_INVOICES.LIST)) return

    try {
      this.setState({ loading, currentPage })
      const list = await reportTypeService.listToReceiveInvoicesReportByPage(currentPage, pageSize, filter, sort, searchText)

      if (list && validator.isArray(list.list)) {
        this.setState({ list: list.list, total: list.total, loading: false })
      } else {
        notify.error('Unable to load successfully', 'Unable to load report successfully. Please try again later.')
        this.setState({ loading: false })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load report successfully. Please try again later.')
    }
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchList({ currentPage, filter, searchText, sort })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }


  render () {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { currentPage, list, loading, total } = this.state

    const columns = [
      {
        title: 'JID',
        render: ({ id_numbering , ref_id }) => { return <div><a href={`/invoices/${ref_id}/info`} rel='noopener noreferrer' target='_blank'>{formatter.capitalize(id_numbering, false)}</a></div> }
      },
      {
        title: 'Claimed Date',
        render: ({ status_date }) => <div>{formatter.toShortDate(status_date)} ({formatter.toDayCount(status_date)})</div>
      },
      {
        title: 'Inv Date',
        render: ({ invoice_date }) => { return <div>{formatter.toShortDate(invoice_date)}</div> }
      },
      {
        title: 'Inv No',
        render: ({ invoice_number }) => { return <div>{invoice_number}</div> }
      },
      {
        title: 'Participant',
        render: ({ client_ref_id, client_name }) => { return <div><a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_name}</a></div> }
      },
      {
        title: 'Provider',
        render: ({ provider_ref_id, provider_name}) => { return <div><a href={`/providers/${provider_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{provider_name}</a></div> }
      },
      {
        title: 'Item',
        render: ({ qty }) => { return <div>{qty}</div> }
      },
      {
        title: 'Inv Total',
        render: ({ subtotal }) => { return <div>{formatter.toPrice(subtotal)}</div> }
      },
      {
        title: 'Rcv Total',
        render: ({ received_subtotal }) => { return <div>{formatter.toPrice(received_subtotal)}</div> }
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Participant To Receive Invoices Report'>
          </Page.Header>

          <div className='invoices'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={list} />
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} invoices`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportToReceiveInvoices))
