import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { ConfigProvider } from 'antd'
import en_GB from 'antd/lib/locale-provider/en_GB'
import moment from 'moment-timezone'

import App from './containers/App'
import ForgotPassword from './containers/ForgotPassword'
import Login from './containers/Login'
import RedirectModule from './containers/Redirect'
import ProcessModule from './containers/Process'
// import Print from './containers/Print'
// import ReportEmail from './containers/Report/Email'
import reducers from './states/reducers'
import root from './states/sagas'
import auth from './util/auth'

import './index.css'

moment.tz.setDefault('Australia/Melbourne')
// moment.locale('en-gb', { week: { dow: 1 } })
// moment.updateLocale('en', { week: { dow: 1 } })

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(root)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/login' component={Login} />
        <Route path='/redirect' component={RedirectModule} />
        {/* <Route path='/process' render={() => <ProcessModule />} /> */}
        <Route path='/process' component={ProcessModule} />
        <Route path='/' render={() => (isLoggedIn()? <ConfigProvider locale={en_GB}><App /></ConfigProvider> : <Redirect to='/login' />)} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

function isLoggedIn () {
  return auth.isSignedIn()
}
