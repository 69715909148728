import React, { Component } from 'react'
import { settingFileService } from '../../../../services'

import { Button, Loading, SideModal } from '../../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

export class AddFileCatSubModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      item: {},
      isExpiryChecked: false,
      isEdit: false,
      loading: false,
      spinLoading: false,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { item = {}, categories = [], visible: nextVisible } = nextProps
    const isEdit = !!item.id

    if (prevState.visible === false && nextVisible && nextVisible === true) {
      const isExpiryChecked = !!item.is_expiry
      return { ...prevState, item, isEdit, categories, visible: nextVisible, isExpiryChecked }
    } else {
      return { ...prevState, item, isEdit, categories, visible: nextVisible }
    }
  }

  handleSubmit = async () => {
    const { form, onUpdate } = this.props
    const { item, isEdit = false } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })

        let r = null
        values.expiry_value_unit = 'month'

        if (isEdit) {
          r = await settingFileService.saveFileCatSub(item.cat_sub_id, values)
        } else {
          r = await settingFileService.addFileCatSub(values)
        }

        this.setState({ loading: false })

        if (onUpdate) {
          if (r && r.id) {
            resetFields()
          }
          onUpdate(isEdit, r)
        }
      }
    })
  }

  handleExpiryChange = (value) => {
    this.setState({ isExpiryChecked: value }, () => {
      if (!value) {
        const { form } = this.props
        const { setFieldsValue } = form

        setTimeout(() => {
          setFieldsValue({ expiry_value: undefined })
        }, 200)
      }
    })
  }

  render () {
    const { visible, form, onClose, moduleItem } = this.props
    const { categories, isEdit, isExpiryChecked, item, loading, spinLoading } = this.state
    const { getFieldDecorator, resetFields } = form
    const title = isEdit ? 'Edit File Sub Category' : 'Add File Sub Category'
    const moduleType = moduleItem ? moduleItem.moduleType : null

    return (
      <SideModal
        key={`sidecatsub_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem layout='vertical' label='Category'>
                {getFieldDecorator('file_cat_id', {
                  initialValue: item.file_cat_id || '',
                  rules: [
                    { required: true, message: 'Please select a category' }
                  ]
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Please select a category'
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      categories.map((category, idx) => {
                        return <Option key={category.id} value={category.id}>{category.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
              <FormItem label='Sub-Category'>
                {getFieldDecorator('name', {
                  initialValue: item.cat_sub_name,
                  rules: [
                    { required: true, message: 'Please enter sub-category' },
                    { whitespace: true, message: 'Please enter sub-category' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              <FormItem label='Mandatory'>
                {getFieldDecorator('is_mandatory', {
                  initialValue: item.is_mandatory || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes' unCheckedChildren='No'
                  />
                )}
              </FormItem>
              <FormItem label='Has Expiry'>
                {getFieldDecorator('is_expiry', {
                  initialValue: item.is_expiry || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={(value) => this.handleExpiryChange(value)}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
                {getFieldDecorator('expiry_value', {
                  initialValue: item.expiry_value || null
                })(
                  <Input style={{ width: '30%', marginLeft: 20 }} disabled={!isExpiryChecked} suffix='months' />
                )}
              </FormItem>
              { moduleType === 'invoice'
                ? <FormItem label='Attach for Inv Auth?'>
                  {getFieldDecorator('is_attach_mail_comm', {
                    initialValue: item.cat_sub_is_attach_mail_comm || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
                : null }
              <FormItem label=''>
                {getFieldDecorator('active', {
                  initialValue: item.cat_sub_active === false ? false : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Enable'
                    unCheckedChildren='Disable'
                  />
                )}
              </FormItem>
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddFileCatSubModal)
