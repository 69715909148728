import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pcp-users/client-availabilities'

export default {
  listAddedByPage(page, size, filter, sorting, text) {
    return request(`${url}/list/added/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listRemovedByPage(page, size, filter, sorting, text) {
    return request(`${url}/list/removed/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  approveLink(id, values) {
    return request(`${url}/approve/${id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  rejectLink(id, values) {
    return request(`${url}/reject/${id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  linkClient(values) {
    return request(`${url}/link`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  unlinkClient(values) {
    return request(`${url}/unlink`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  verifyOtp(values) {
    return request(`${url}/verify-otp`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
