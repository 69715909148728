import { put, takeEvery } from 'redux-saga/effects'
import { FETCHING_STATEMENTS, FETCH_STATEMENTS, STATEMENTS_FETCHED } from '../actions/statement'
import statementService from '../../services/statement'

function * fetchStatements ({ loading, loadingList, currentPage, pageSize, filter, filterType, sort, searchText }) {
  try {
    yield put({ type: FETCHING_STATEMENTS, loading, loadingList })
    const statements = yield statementService.listStatementPeriodByPage(currentPage, pageSize, filter, filterType, sort, searchText)
    yield put({ type: STATEMENTS_FETCHED, statements, loading: false, loadingList: false })
  } catch (e) {
    yield put({ type: FETCHING_STATEMENTS, loading: false, loadingList: false })
    console.error(e)
  }
}

function * watchStatements () {
  yield takeEvery(FETCH_STATEMENTS, fetchStatements)
}

export default watchStatements
