import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchingCredits, setRefreshActivityLog } from '../../../states/actions'
import { auth, common, formatter, log, validator } from '../../../util'
import _ from 'lodash'
import { clientInvoiceService, clientService, providerService, pmCategoryService, creditService } from '../../../services'

// UI
import { Button, List, Loading, Page, Panel } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'

import './styles.css'
import CreditActivity from '../ActivityLog'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'

const { Item: FormItem } = Form
const { TextArea } = Input
const { confirm, warning } = Modal
const Option = Select.Option
const TabPane = Tabs.TabPane

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/logs' }
]

const SBOver0daysMsg = 'The selected Service Booking has ended.'
const SBOver60daysMsg = 'The selected Service Booking has passed 60 days of its end date.'
const SBOver90daysMsg = 'The selected Service Booking has passed 90 days of its end date. Unable to add Credit under this SB.'

const formItemShortLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 10, md: 10, lg: 12 }
}

export class CreditPage extends Component {
  constructor (props) {
    super(props)
    const { match, location } = this.props
    const { key = undefined } = location
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      item: {},
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      clientList: [],
      clientInfo: {},
      catList: [],
      currentCatList: [],
      catInfo: {},
      clientBudgetList: [],
      clientBudgetInfo: {},
      clientBudgetMsg: '',
      catItemList: [],
      catItemInfo: {},
      defaultClientRefId: null,
      providerList: [],
      providerInfo: {},
      loading: false,
      loadingSave: false,
      loadingData: false,
      maxAmount: null,
      comment: null,
      showSave: false,
      showEdit: true,
      pageKey: key,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { match, location } = nextProps
    const { params = {} } = match
    const { cid = null } = common.getQueryStringSearchParams(location.search)

    // must check whether prev state key and locaction key are both undefined, and skip if it is
    if (prevState.pageKey === undefined && location.key === undefined) {

    } else if (prevState.pageKey !== location.key) {
      // not only check the page key but also need to check current path params. if within the same url path (navigate between tabs), do not reload the page.
      if (validator.isNumberText(params.id) && params.type !== undefined) {

      } else {
        if (window) window.location.reload()
      }
    }

    return { ...prevState, defaultClientRefId: cid ? parseInt(cid) : null, pageKey: location.key }
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchCredit()
    } else {
      this.fetchDetails()
    }
  }

  render () {
    const { history, match } = this.props
    const { item, clientInfo, currentTab, loading, loadingSave, showSave, showEdit } = this.state
    const creditId = this.getId()
    const isInfoTab = currentTab === '1'
    const isItem = item && item.id

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={
            this.isEdit()
              ? `Credit ${clientInfo.id? ` - ${clientInfo.first_name} ${clientInfo.last_name}` : ''}`
              : `New Credit`
          }>
            { this.isEdit() && this.hasAccess(Permissions.CREDIT.INFO.DELETE) && showSave && !loading && isInfoTab && isItem
              ? (
                <Button className='btn' key='delete' ghost type='primary' feedback={loading} onClick={this.preHandleDelete}> {'Delete'}</Button>
              )
              : null }

            { showEdit && this.isEdit() && this.hasAccess(Permissions.CREDIT.INFO.UPDATE) && !loading && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                Edit
                </div>)
              : null }

            { ((!this.isEdit() && this.hasAccess(Permissions.CREDIT.INFO.CREATE)) ||
              (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.CREDIT.INFO.UPDATE))) &&
              !loading && isInfoTab
              ? (
                <Button className='btn' key='ok' type='primary' feedback={loading} onClick={this.preHandleSave}> {'Save'}</Button>
                )
              : null }
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='credit-panel'>
            <div className='invoice-panel-body'>
              <Skeleton loading={loading} active>
                <Spin spinning={loadingSave}>
                  <Tabs
                    defaultActiveKey={currentTab}
                    onChange={this.handleTabChange}
                  >
                    <TabPane tab='Credit Details' key='1'>
                      { this.infoTab() }
                    </TabPane>
                    { this.isEdit() && this.hasAccess(Permissions.CREDIT.INFO.READ)
                      ? <TabPane tab='Activity Log' key='2'>
                        <CreditActivity key={`acttab${currentTab}`} creditId={creditId} history={this.props.history} />
                      </TabPane>
                      : null }
                  </Tabs>
                </Spin>
              </Skeleton>
            </div>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      clientList,
      clientInfo,
      providerList,
      providerInfo,
      clientBudgetList,
      clientBudgetInfo,
      clientBudgetMsg,
      currentCatList,
      catInfo,
      catItemList,
      maxAmount,
      comment,
      item,
      loading,
      loadingData
    } = this.state
    const isEdit = this.isEdit()
    const isApplied = item && item.id && item.is_applied

    const creditColumns = [
      {
        title: 'Received On',
        width: 5,
        render: ({ apply_date }) => formatter.toShortDate(apply_date)
      },
      {
        title: 'Apply Amount',
        width: 5,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },
      {
        title: 'Credit Notes',
        width: 4,
        render: ({ comment }) => <div>{comment}</div>
      },
      {
        title: 'Invoice',
        width: 5,
        render: ({ inv_id, inv_ref_id, invoice_number }) => (
          this.hasAccess('readInvoice')
          ? <div>
            <a href={`/invoices/${inv_ref_id}/info`} rel='noopener noreferrer' target='_blank'>
              {invoice_number}
            </a>
          </div>
          : <div>{invoice_number}</div>

        )
      },
      {
        title: 'Updated At',
        width: 5,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      }
    ]

    return (
      <Form>
        <Loading loading={loading} blur>
          <Spin spinning={loadingData}>
            <Panel title='Details'>
              <FormItem
                {...formItemShortLayout}
                label='Participant'>
                {getFieldDecorator(`client_id`, {
                  initialValue: clientInfo.id || null,
                  rules: [
                    { required: true, message: 'Please select participant' }
                  ]
                })(
                <Select
                  showSearch
                  placeholder='Select Participant'
                  optionFilterProp='children'
                  notFoundContent='No participants available'
                  filterOption={(input, option) => this.findClient(input, option)}
                  onChange={(id) => this.onSelectClient(id, undefined)}
                  disabled={isEdit}
                >
                  { clientList.map((itm, idx) => {
                      return (
                        <Option
                          key={`cts${idx}`}
                          value={itm.id}
                        >
                          <div>
                            <div>{itm.first_name} {itm.last_name} - {itm.ndis_number}</div>
                          </div>
                        </Option>
                      )
                    }) }
                </Select>
                )}
              </FormItem>

              { clientInfo && clientInfo.id
                ? <FormItem
                  {...formItemShortLayout}
                  label='Service Booking'>
                  {getFieldDecorator(`budget_id`, {
                    initialValue: clientBudgetInfo.budget_id || null,
                    rules: [
                      { required: true, message: 'Please select SB' }
                    ]
                  })(
                  <Select
                    showSearch
                    placeholder='Select SB'
                    optionFilterProp='children'
                    notFoundContent='No service bookings available'
                    filterOption={(input, option) => this.findClient(input, option)}
                    onChange={(id) => this.onSelectBudget(id)}
                    disabled={isEdit}
                  >
                    { clientBudgetList.map((itm, idx) => {
                        return (
                          <Option
                            key={`pts${idx}`}
                            value={itm.budget_id}
                          >
                            {itm.booking_number} ({formatter.toShortDate(itm.period_start_date)} - {formatter.toShortDate(itm.period_end_date)})
                          </Option>
                        )
                      }) }
                  </Select>
                  )}
                </FormItem>
                : null }

              { clientBudgetMsg
                ? <Row>
                  <Col sm={6} md={6} lg={4}>
                  </Col>
                  <Col sm={10} md={10} lg={12}>
                    <div className='warning-msg'>
                      { clientBudgetMsg }
                    </div>
                  </Col>
                </Row>
                : null }

              { currentCatList && currentCatList.length > 0
                ? <div>
                  {/** A hidden field to store real report_group_id change in form */}
                  <FormItem
                    style={{display: 'none'}}
                    label='Support Budget Group ID'>
                    {getFieldDecorator(`cat_report_group_id`, {
                      initialValue: catInfo.report_group_id || null
                    })(<div>{catInfo.report_group_id}</div>)}
                  </FormItem>

                  <FormItem
                    {...formItemShortLayout}
                    label='Support Budget'>
                    {getFieldDecorator(`cat_id`, {
                      initialValue: catInfo.id || null,
                      rules: [
                        { required: true, message: 'Please select support budget' }
                      ]
                    })(
                      <Select
                        showSearch
                        disabled={isApplied}
                        placeholder='Select support budget'
                        optionFilterProp='children'
                        notFoundContent='No support budget available'
                        filterOption={(input, option) => this.findClient(input, option)}
                        onChange={(id) => this.onSelectCat(id)}
                      >
                        { currentCatList.map((itm, idx) => {
                            return (
                              <Option
                                key={`catitm${idx}`}
                                value={itm.id}
                              >
                                {itm.report_group_name} - {itm.proda_name || itm.cat_proda_name}
                              </Option>
                            )
                          }) }
                      </Select>
                    )}
                  </FormItem>
                </div>
                : null }

              { catInfo && catInfo.id
                ? <div>
                  <FormItem
                    {...formItemShortLayout}
                    label='Credit Amount'
                    >
                    {getFieldDecorator(`amount`, {
                      initialValue: maxAmount,
                      rules: [
                        { required: true, message: ' ' },
                        { validator: this.validateCreditAmount }
                      ]
                    })(
                      <Input
                        addonBefore='$'
                        disabled={isApplied}
                        onChange={(e) => this.updateInputValue(e, 'maxAmount')}
                        style={{marginRight: '5px'}}
                      />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemShortLayout}
                    label='Credit Notes'
                  >
                    {getFieldDecorator('credit_comment', {
                      initialValue: comment || null
                    })(
                      <TextArea
                        cols={2}
                        onChange={(e) => this.updateInputValue(e, 'comment')}
                      />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemShortLayout}
                    label='Provider'>
                    {getFieldDecorator(`provider_id`, {
                      initialValue: providerInfo.id || null
                    })(
                    <Select
                      showSearch
                      placeholder='Select Provider'
                      optionFilterProp='children'
                      notFoundContent='No providers available'
                      filterOption={(input, option) => this.findProvider(input, option)}
                      onChange={(id) => this.onSelectProvider(id)}
                    >
                      { providerList.map((itm, idx) => {
                          return (
                            <Option
                              key={`pts${idx}`}
                              value={itm.id}
                            >
                              {itm.fullname}
                            </Option>
                          )
                        }) }
                    </Select>
                    )}
                  </FormItem>
                </div>
                : null }
            </Panel>

            { isEdit
              ? <Panel title='Application History'>
                { validator.isNotEmptyArray(item.applied_history)
                  ? <List cols={creditColumns} rows={item.applied_history} />
                  : <div class='client-name'>No History Available.</div> }

              </Panel>
              : null }
          </Spin>
        </Loading>
      </Form>
    )
  }

  onSelectClient = (id = null, refId = null) => {
    const { form } = this.props
    const { setFieldsValue, validateFieldsAndScroll } = form
    const { clientList } = this.state
    const c = clientList.find(e => id ? e.id === id : refId ? e.ref_id === refId : false)

    if (c && c.id) {
      this.setState({
        clientInfo: c,
        clientBudgetList: [],
        clientBudgetInfo: {},
        clientBudgetMsg: null,
        currentCatList: [],
        maxAmount: null
      }, () => {
        if (c && c.id) {
          setFieldsValue({['budget_id']: undefined})
          this.fetchBudgets()
        }
      })
    } else {

    }
  }

  onSelectBudget = (id) => {
    const { form } = this.props
    const { setFieldsValue } = form
    const { clientBudgetList, catList } = this.state
    const p = clientBudgetList.find(e => e.budget_id === id)

    if (p) {
      const periodStatus = formatter.toPeriodStatus(p.period_end_date)

      this.setState({
        clientBudgetInfo: p,
        currentCatList: [],
        catInfo: {},
        clientBudgetMsg: !periodStatus.isDue ? '' : periodStatus.isDuePeriod2 ? SBOver90daysMsg : periodStatus.isDuePeriod1 ? SBOver60daysMsg : SBOver0daysMsg
      }, async () => {
        if (periodStatus.isDuePeriod2) {
          this.setState({ currentCatList: [] })
        } else {
          const currentCatList = catList.filter(e => {
            return validator.isNotEmptyArray(p.categories) && !!p.categories.find(f => f.cat_id === e.id)
          })
          // console.log('on select budget 3', currentCatList)
          this.setState({ currentCatList })
        }
      })
    }
  }

  onSelectCat = async (id) => {
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentCatList, catList } = this.state
    const p = currentCatList.find(e => e.id === id)

    if (p) {
      this.setState({
        catInfo: p,
        maxAmount: null
      }, () => {
        setFieldsValue({cat_report_group_id: p.report_group_id})
      })
    }
  }

  onSelectProvider = (id) => {
    const { providerList } = this.state
    const p = providerList.find(e => e.id === id)
    if (p) {
      this.setState({ providerInfo: p})
    }
  }


  findClient = (input, option) => {
    const clientArr = option.props.children.props.children.props.children
    const fstname = clientArr[0] ? clientArr[0].trim() : clientArr[0]
    const lstname = clientArr[2] ? clientArr[2].trim() : clientArr[2]
    const ndis = clientArr[4] ? clientArr[4].trim() : clientArr[4]

    const client = `${fstname} ${lstname} ${ndis}`

    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findProvider = (input, option) => {
    const pvd = `${option.props.children}`
    return pvd.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  fetchCredit = async () => {
    if (!this.hasAccess(Permissions.CREDIT.INFO.READ)) return

    try {
      const refId = this.getRefId()
      let id = null
      this.setState({loading: true})

      const credit = await creditService.getRef(refId)
      const providers = await providerService.listAllProviders()

      if (credit && credit.id) {
        id = credit.id
        const clientInfo = credit.clientInfo ? Object.assign({}, credit.clientInfo) : {}
        const providerInfo = credit.providerInfo ? Object.assign({}, credit.providerInfo) : {}
        const catInfo = credit.catInfo ? Object.assign({}, credit.catInfo) : {}
        const currentCatList = credit.currentCatList || []
        const clientBudgetInfo = credit.clientBudgetInfo ? Object.assign({}, credit.clientBudgetInfo) : {}

        // delete credit.clientInfo
        // delete credit.providerInfo
        // delete credit.catInfo
        // delete credit.currentCatList
        // delete credit.clientBudgetInfo

        this.setState({
          item: credit,
          clientList: clientInfo && clientInfo.id ? [clientInfo] : [],
          providerList: validator.isNotEmptyArray(providers) ? providers : [],
          clientBudgetList: clientBudgetInfo && clientBudgetInfo ? [clientBudgetInfo] : [],
          catList: _.cloneDeep(currentCatList),
          clientInfo,
          providerInfo,
          catInfo,
          currentCatList,
          clientBudgetInfo,
          maxAmount: credit.amount,
          comment: credit.credit_comment || null,
          loading: false
        })
      } else {
        notify.error('Unable to load successfully', 'Unable to load credit details successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load credit details successfully. Please try again later.')
    }
  }

  fetchDetails = async () => {
    const { location } = this.props

    try {
      this.setState({loading: true})
      const clients = await clientService.listAllClients()
      const providers = await providerService.listAllProviders()
      const cats = await pmCategoryService.listAvailableCats()
      // console.log('details 1', clients, cats)

      this.setState({
        clientList: validator.isNotEmptyArray(clients) ? clients : [],
        providerList: validator.isNotEmptyArray(providers) ? providers : [],
        catList: validator.isNotEmptyArray(cats) ? cats : [],
        loading: false
      }, () => {
        const { defaultClientRefId } = this.state
        if (defaultClientRefId) {
          this.onSelectClient(undefined, defaultClientRefId)
        }
      })
      // console.log('client list', clients, providers)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load client list successfully. Please try again later.')
    }
  }

  fetchBudgets = async () => {
    const { clientInfo } = this.state

    if (clientInfo && clientInfo.id) {
      this.setState({ loadingData: true })
      const r = await clientInvoiceService.listAllClientBudget(clientInfo.id)

      // console.log('budget list', r)

      if (validator.isNotEmptyArray(r)) {
        const clientBudgetList = r.filter(e => e.is_monthly_fee_main_set === false)
        this.setState({ clientBudgetList, loadingData: false })
      } else {
        this.setState({ loadingData: false })
      }
    }
  }

  updateInputValue = (e, key) => {
    this.setState({ [key]: e.target.value })
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})
    if (tab && tab.tabId) {
      this.props.history.replace(`/credits/${refId}${tab.path}`)
    }
  }

  handleEditButton = () => {
    this.setState({showEdit: false, showSave: true})
  }

  preHandleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { handleSave } = this

    if (!this.isEdit()) {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          confirm({
            title: <div><b>Is the credit money with you?</b></div>,
            content: (<div>
              Create a Credit <b>ONLY IF YOU HAVE</b> the overclaimed money / Provider has returned the overpayment.
            </div>),
            okText: 'OK',
            cancelText: 'Cancel',
            onOk () {
              handleSave()
            },
            onCancel () {
            }
          })
        }
      })
    } else {
      handleSave()
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = ''

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingCredits, history } = this.props
        const { item, loading, loadingData, loadingSave, clientInfo, providerInfo, clientBudgetInfo, catInfo, maxAmount, comment } = this.state

        if (loading || loadingSave || loadingData) {
          return
        }

        this.setState({ loadingSave: true })
        values.amount = formatter.toBigNumber(values.amount).toFixed(2)

        try {
          if (this.isEdit()) {
            const r = await creditService.save(item.id, values)
            this.setState({ item: { ...item, ...values } })
            // console.log('response', response)

            if (r && r.id) {
              const { id } = r

              let logInfo = ''

              if (item.provider_id !== providerInfo.id) {
                if (item.providerInfo && item.providerInfo.id) {
                  logInfo += `Provider change from ${item.providerInfo.fullname} to ${providerInfo.fullname}`
                } else {
                  const prevProvider = item.providerInfo && item.providerInfo.id ? item.providerInfo.fullname : null
                  const currProvider = providerInfo && providerInfo.id ? providerInfo.fullname : null

                  if (prevProvider || currProvider) {
                    logInfo += `Provider change from ${prevProvider || ""} to ${currProvider || ""}`
                  }
                }
              }

              if (item.cat_id !== catInfo.id) {
                logInfo += `${logInfo === '' ? '' : `, `}Support Budget change from ${item.catInfo.name} to ${catInfo.name}`
              }

              if (parseFloat(maxAmount) !== parseFloat(item.amount)) {
                logInfo += `${logInfo === '' ? '' : `, `}Credit Amount change from ${formatter.toPrice(item.amount)} to ${formatter.toPrice(maxAmount)}`
              }

              if (comment !== item.credit_comment) {
                logInfo += `${logInfo === '' ? '' : `, `}Comment change from "${item.credit_comment}" to "${comment}"`
              }

              if (logInfo) {
                log.updateCredit(id, logInfo)
              }

              notify.success('Saved successfully', 'Credit saved successfully.')
              fetchingCredits(true)
            }
          } else {
            const r = await creditService.add(values)

            if (r && r.id) {
              const { id, ref_id } = r

              log.addCredit(id, `New credit for ${clientInfo.first_name} ${clientInfo.last_name}'s Service Booking "${clientBudgetInfo.booking_number}" with Support Budget "${catInfo.name}" (${catInfo.report_group_name}), ${providerInfo.id ? `with select of provider "${providerInfo.fullname}", ` : ''}credited amount of ${formatter.toPrice(maxAmount)}${comment ? `along with comment "${comment}".` : '.'}`)
              notify.success('Saved successfully', 'Credit added successfully.')

              window.location.replace(`/credits/${ref_id}/info`)
              fetchingCredits(true)
            }
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save credit successfully. Please try again later.')
        }

        this.setState({ loadingSave: false })
      }
    })
  }

  preHandleDelete = async () => {
    const { item } = this.state
    const { handleDelete } = this

    if (item.is_applied || item.is_closed) {
      warning({
        title: 'Unable to delete this credit',
        content: 'Some credit amounts have been applied to the invoices.',
      })
    } else {
      confirm({
        title: 'Are you sure you want to delete this credit?',
        content: 'Press Ok to continue, Cancel to return',
        async onOk () {
          typeof handleDelete === 'function' && handleDelete(item.id)
        }
      })
    }
  }

  handleDelete = async (id) => {
    const { loading, loadingData, loadingSave } = this.state

    if (loading || loadingSave || loadingData) {
      return
    }

    this.setState({ loadingSave: true })
    try {
      const r = await creditService.remove(id)

      if (r && r.id) {
        notify.success('Deleted successfully', 'Credit deleted successfully.')
        log.updateCredit(id, 'Credit is deleted')

        window.location.replace(`/credits`)
        fetchingCredits(true)
      } else {
        notify.error('Unable to delete successfully', 'Unable to delete credit successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to delete successfully', 'Unable to delete credit successfully. Please try again later.')
    }

    this.setState({ loadingSave: false })
  }

  validateCreditAmount = (rule, value, callback) => {
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Credit Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)

      if (!v) {
        callback(new Error(`Credit Amount is not number or decimal format`))
      } else {
        callback()
      }
    }
  }


  hasAccess = (accessLevel) => {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add' && id !== 'new'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  getPath = () => {
    const { match } = this.props
    const { params } = match
    const { path = '' } = params
    return path
  }
}

const mapDispatchToProps = {
  fetchingCredits,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Credit }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CreditPage))
