import { FETCHING_INVOICES, INVOICES_FETCHED, UPDATE_INVOICE_ABA_LIST } from '../actions/invoice'

const INITIAL_STATE = {
  invoices: { list: [], total: 0 },
  abaList: [],
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_INVOICES:
      return handleFetchingInvoices(state, action)
    case INVOICES_FETCHED:
      return handleInvoicesFetched(state, action)
    case UPDATE_INVOICE_ABA_LIST:
      return { ...state, abaList: action.list }
    default:
      return state
  }
}

function handleFetchingInvoices (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleInvoicesFetched (state, action) {
  const { invoices, loading } = action
  return { ...state, invoices, loading }
}
