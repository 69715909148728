import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'
import { auth } from '../../../util'

// UI
import { Page } from '../../../components'
import { Permissions } from '../../../constants'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import './styles.css'

import GSTItem from './Item'
import GSTRate from './Rate'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'GST'

const TabList = [
  { tabId: 1, path: '' },
  { tabId: 2, path: '/rates' }
]

export class SettingGST extends Component {
  constructor (props) {
    super(props)
    const { match } = this.props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)

    this.state = {
      addGSTItem: false,
      addGSTRate: false,
      currentPage: 1,
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1'
    }
  }

  componentDidMount () {

  }

  addGSTItem = () => {
    this.setState({ addGSTItem: true })
  }

  addGSTRate = () => {
    this.setState({ addGSTRate: true })
  }

  handleTabChange = (index) => {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})
    if (tab && tab.tabId) {
      this.props.history.replace(`/settings/gst${tab.path}`)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { addGSTItem, addGSTRate, currentTab } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <div>
            <div className='header-action'>
              { this.hasAccess(Permissions.SETTING.GST.CREATE)
                ? <Link to='/settings/gst/rates/add'>
                  <div className='btn'>
                    Add GST Rate
                  </div>
                </Link>
                : null }
            </div>
            <GSTRate onAdd={addGSTRate} onComplete={() => this.setState({ addGSTRate: false })} />
          </div>

          {/* <Tabs defaultActiveKey='1' activeKey={currentTab} onChange={this.handleTabChange}>
            { this.hasAccess(Permissions.SETTING.GST.LIST)
              ? <TabPane tab='GST Item' key='1'>
                <div className='header-action'>
                  { this.hasAccess(Permissions.SETTING.GST.CREATE)
                    ? <div className='btn' onClick={() => this.addGSTItem()}>
                      Add GST Item
                    </div>
                    : null }
                </div>
                <GSTItem onAdd={addGSTItem} onComplete={() => this.setState({ addGSTItem: false })} />
              </TabPane>
              : null }

            { this.hasAccess(Permissions.SETTING.GST.LIST)
              ? <TabPane tab='GST Rates' key='2'>
                <div className='header-action'>
                  { this.hasAccess(Permissions.SETTING.GST.CREATE)
                    ? <Link to='/settings/gst/rates/add'>
                      <div className='btn'>
                        Add GST Rate
                      </div>
                    </Link>
                    : null }
                </div>
                <GSTRate onAdd={addGSTRate} onComplete={() => this.setState({ addGSTRate: false })} />
              </TabPane>
              : null }
          </Tabs> */}

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingGST))
