import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Permissions } from '../../../constants'
import SettingMenu from '../../../constants/menu/setting'
import { settingOtherService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'

// UI
import { Page, Panel } from '../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'
import notify from '../../../components/Notification'

const { Item: FormItem } = Form

const settingTitle = 'Settings'
const settingType = 'Public Holiday'

export class SettingOther extends Component {
  constructor (props) {
    super(props)
    this.state = {
      settings: {},
      loading: false,
      loadingForm: false
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.list()
      this.setState({ settings: settings && settings.id ? settings : {}, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  validateCount = (rule, value, callback) => {
    if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error(`Please enter Day count`))
    } else if (!validator.isIntegerText(value)) {
      callback(new Error(`Day count value must be valid`))
    } else {
      callback()
    }
  }

  validateDay = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback(new Error('Please enter a Day count'))
    } else if (!validator.isIntegerText(value)) {
      callback(new Error(`Day count value must be valid`))
    } else if (formatter.toBigNumber(value).isLessThanOrEqualTo(0)) {
      callback(new Error('Day count must be 1 or more'))
    } else {
      callback()
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { settings } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        this.setState({ loadingForm: true })

        try {
          const data = {
            pm_inv_auth_day: values.pm_inv_auth_day,
            pm_sb_ending_day_count: values.pm_sb_ending_day_count,
            pm_to_receive_period: values.pm_to_receive_period
          }

          const r = await settingOtherService.save(data)

          if (r && r.id) {
            log.updateSettingOther(
              r.id,
              settings,
              values,
              ['client_acc_ref', 'provider_acc_ref', 'job_acc_ref', 'job_item_acc_ref'],
              [
                { key: 'pm_inv_auth_day', label: 'Inv Auth Grace Period' },
                { key: 'pm_sb_ending_day_count', label: 'SB Ending Day Count' },
                { key: 'pm_to_receive_period', label: 'To Receive Invoices Report Period' }
              ]
            )
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          }

          this.setState({ loadingForm: false })
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { loading, loadingForm, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Other Settings'>
            { this.hasAccess(Permissions.SETTING.OTHERS.UPDATE)
              ? <div className='btn' onClick={() => this.handleSave()}>
                Save
              </div>
              : null }
          </Page.Header>

          <div className='setting-list'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingForm}>
                <Form>
                  <Panel title='Accounting Ref'>
                    <Row>
                      <Col lg={12}>
                        <FormItem label='Participant' {...formItemLayout}>
                          {getFieldDecorator('client_acc_ref', {
                            initialValue: `${settings.client_prefix} ${settings.client_acc_ref}`,
                            rules: [
                              { required: true, message: 'Please enter participant acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={12}>
                        <FormItem label='Provider' {...formItemLayout}>
                          {getFieldDecorator('provider_acc_ref', {
                            initialValue: `${settings.provider_prefix} ${settings.provider_acc_ref}`,
                            rules: [
                              { required: true, message: 'Please enter provider acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem label='Invoice' {...formItemLayout}>
                          {getFieldDecorator('job_acc_ref', {
                            initialValue: `${settings.job_prefix} ${settings.job_acc_ref_id}`,
                            rules: [
                              { required: true, message: 'Please enter invoice acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={12}>
                        <FormItem label='Invoice Item' {...formItemLayout}>
                          {getFieldDecorator('job_item_acc_ref', {
                            initialValue: `${settings.job_item_prefix} ${settings.job_item_acc_ref_id}`,
                            rules: [
                              { required: true, message: 'Please enter invoice item acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel title='Invoice Auth'>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          label='Inv Auth Grace Period'
                          extra='The maximum day count to process Authorisation Comm Reply. The invoice will be automatically authorised if the authorisation comm sent date is beyond of the grace period day count.'
                          {...formItemLayout}
                        >
                          {getFieldDecorator('pm_inv_auth_day', {
                            initialValue: settings.pm_inv_auth_day,
                            rules: [
                              { validator: this.validateCount }
                            ]
                          })(
                            <Input suffix='day(s)' />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel title='Service Booking'>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          label='SB Ending Day Count'
                          extra='The day count to trigger alert before any Service Booking ends. This setting will be reflected on Participant Service Booking Report and Weekly Reminder for Expiring / Expired SBs.'
                          {...formItemLayout}
                        >
                          {getFieldDecorator('pm_sb_ending_day_count', {
                            initialValue: settings.pm_sb_ending_day_count,
                            rules: [
                              { validator: this.validateCount }
                            ]
                          })(
                            <Input suffix='day(s)' />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel title='Reports'>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          label='To Receive Report Period'
                          extra='The minimum number of days invoice is left pending in "To Receive" before it will be included in the To Receive Invoices Report.'
                          {...formItemLayout}
                        >
                          {getFieldDecorator('pm_to_receive_period', {
                            initialValue: settings.pm_to_receive_period,
                            rules: [
                              { validator: this.validateDay }
                            ]
                          })(
                            <Input suffix='day(s)' />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                </Form>
              </Spin>
            </Skeleton>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingOther))
