export const FETCH_INVOICES_LIST = 'FETCH_INVOICES_LIST'
export const FETCHING_INVOICES_LIST = 'FETCHING_INVOICES_LIST'
export const INVOICES_LIST_FETCHED = 'INVOICES_LIST_FETCHED'

export const FETCH_INVOICES_SUMMARY = 'FETCH_INVOICES_SUMMARY'
export const FETCHING_INVOICES_SUMMARY = 'FETCHING_INVOICES_SUMMARY'
export const INVOICES_SUMMARY_FETCHED = 'INVOICES_SUMMARY_FETCHED'

export const INVOICES_INFO_RESET = 'INVOICES_INFO_RESET'

export const fetchInvoicesList = (params) => {
  return Object.assign({
    type: FETCH_INVOICES_LIST
  }, params)
}

export const fetchingInvoicesList = (loading) => {
  return {
    type: FETCHING_INVOICES_LIST,
    loading
  }
}

export const invoicesListFetched = (params) => {
  return Object.assign({
    type: INVOICES_LIST_FETCHED
  }, params)
}

export const fetchInvoicesSummary = (params) => {
  return Object.assign({
    type: FETCH_INVOICES_SUMMARY
  }, params)
}

export const fetchingInvoicesSummary = (loading) => {
  return {
    type: FETCHING_INVOICES_SUMMARY,
    loading
  }
}

export const invoicesSummaryFetched = (summary) => {
  return {
    type: INVOICES_SUMMARY_FETCHED,
    summary
  }
}

export const invoicesInfoReset = (params) => {
  return Object.assign({
    type: INVOICES_INFO_RESET
  }, params)
}
