import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/credits-client'

export default {
  getClientAllCredits (id) {
    return request(`${url}/list/all/${id}`)
  },
  getClientAvailableCredits (id) {
    return request(`${url}/list/available/${id}`)
  },
  getCreditAmtInfo (id, clientId) {
    return request(`${url}/inv-credit-amount/${id}/${clientId}`)
  },
  getCreditAmtInfoForAdd (values) {
    return request(`${url}/inv-credit-amount-add`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  applyCredit (values) {
    return request(`${url}/credit/apply`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateCredit (values) {
    return request(`${url}/credit/update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
