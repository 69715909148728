import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { auth, formatter } from '../../util'

import Icon from 'antd/lib/icon'

import './styles.css'

export class Body extends Component {
  render () {
    const { children, className } = this.props

    return [
      <div className={formatter.toClassName(['wd-body', className])}>
        { children }
      </div>,
      <div className='wd-footer'>Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2021-22 Pumpkin IT. All rights reserved.</div>
    ]
  }
}

Body.propTypes = {
  className: PropTypes.string
}

export class Left extends Component {
  render () {
    const { children, className } = this.props

    return (
      <div className={formatter.toClassName(['wd-left', className])}>
        { children }
      </div>
    )
  }
}

export class Content extends Component {
  render () {
    const { children, className, full, nomenu } = this.props

    return (
      <div className={formatter.toClassName(['wd-content', full ? 'wd-content-no-right' : nomenu ? 'wd-content-full' : '', className])}>
        { children }
      </div>
    )
  }
}

Content.propTypes = {
  className: PropTypes.string
}

export class Right extends Component {
  render () {
    const { children, className } = this.props

    return (
      <div className={formatter.toClassName(['wd-right', className])}>
        { children }
      </div>
    )
  }
}

export class Menu extends Component {
  render () {
    const { className, location, menu = [], title, top, backLink, countData, panel = null } = this.props
    const backUrl = backLink || location.pathname.substr(0, location.pathname.lastIndexOf('/'))

    return (
      <div className={formatter.toClassName(['wd-panel-box', className])}>
        {top ? (
          <div className='title'>{title || 'No title'}</div>
        ) : (
          <Link to={backUrl}><div className='title back'><Icon type='left' /> {title || 'No title'}</div></Link>
        )}

        {
          menu.map((m, index) => {
            const showMenu = this.showMenu(m.permission)

            return showMenu ? (
              <div key={`mibm-${index}`} >
                { m.link
                  ? <NavLink
                    to={m.link}
                    className={isActive => `item ${isActive ? 'item-selected' : ''}`}
                  >
                    <div className='item'>
                      {!m.icon.includes('svg') ? (
                        <Icon type={m.icon.includes('svg') ? 'check' : m.icon} style={{ color: '#0a90ce', width: '20px', height: '18px' }} />
                      ) : (
                        <img src={'/icon' + m.icon} style={{ width: 16, marginTop: -3 }} />
                      )}
                      {m.name || 'no name'}
                    </div>
                  </NavLink>
                  : <div className='item item-selected'>
                    {!m.icon.includes('svg') ? (
                      <Icon type={m.icon.includes('svg') ? 'check' : m.icon} style={{ color: '#0a90ce', width: '20px', height: '18px' }} />
                    ) : (
                      <img src={'/icon' + m.icon} style={{ width: 16, marginTop: -3 }} />
                    )}
                    {m.name || 'no name'}
                  </div> }

                {
                  m.submenu ? (
                    m.submenu.map((sm, i) => {
                      const showMenu = this.showMenu(sm.permission)
                      const isSimilar = location.pathname && sm.similar ? location.pathname.indexOf(sm.similar) > -1 : false

                      return showMenu ? (
                        <NavLink
                          exact
                          to={sm.link}
                          key={`subm-${index}-${i}`}
                          className={isActive => `sub-item ${isActive || isSimilar ? 'sub-item-selected' : ''}`}
                        >
                          <div>
                            {sm.name || 'no name'} {sm.count && countData ? <div className='count'>{countData[sm.count]}</div> : null}
                          </div>
                        </NavLink>
                      ) : null
                    })
                  ) : null
                }
              </div>
            ) : null
          })
        }

        {
          panel || null
        }
      </div>
    )
  }

  showMenu = (permission) => {
    if (permission) {
      return auth.hasAccess(permission)
    }

    return true
  }
}

export class Panel extends Component {
  render () {
    const { children, className, title } = this.props

    return (
      <div className={formatter.toClassName(['wd-panel-box', className])}>
        <div className='title'>{title || 'No title'}</div>

        <div className='content'>
          { children || 'No content available'}
        </div>
      </div>
    )
  }
}

export class Filter extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-filter', className])}>
        { this.props.children }
      </div>
    )
  }
}

Filter.propTypes = {
  className: PropTypes.string
}

export class Header extends Component {
  render () {
    const { children, className, icon, title, total, leaveFlag } = this.props

    return (
      <div className={formatter.toClassName(['wd-header', className])}>
        <div className='title'><h2>{ icon || null } <span>{title || ''}</span> {leaveFlag || null}</h2>{ total ? <div className='tag'>{total}</div> : null }</div>
        {children ? <div className='header-action'>{children}</div> : null}
      </div>
    )
  }
}

Header.propTypes = {
  className: PropTypes.string
}

export class Tab extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-tab', className])}>
        { this.props.children }
      </div>
    )
  }
}

Tab.propTypes = {
  className: PropTypes.string
}

export class Table extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-section', className])}>
        { this.props.children }
      </div>
    )
  }
}

Table.propTypes = {
  className: PropTypes.string
}

export default { Body, Content, Filter, Header, Left, Menu: withRouter(Menu), Panel, Right, Tab, Table }
