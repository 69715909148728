import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pmRateService, pmRateSetService } from '../../../../services'
import { setRefreshActivityLog } from '../../../../states/actions'
import moment from 'moment-timezone'

// UI
import { Button, ControlLabel, Loading, Page, SearchInput } from '../../../../components'
import { FileUploadMsg, Permissions } from '../../../../constants'
import notify from '../../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Table from 'antd/lib/table'
import Upload from 'antd/lib/upload'
import { SupportCatItemsUnit } from '../../../../constants'
import { auth, formatter, validator } from '../../../../util'
import { apiHostname } from '../../../../config'

import DatePicker from 'antd/lib/date-picker'
// import Subset from './subset'
import './styles.css'

const { Item: FormItem } = Form

const Option = Select.Option

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 7 },
  wrapperCol: { sm: 14, md: 14, lg: 16 }
}

const formItemDateLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 18, md: 18, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}
export class SettingRateSetEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      allRates: [],
      allRateSets: [],
      filteredCats: [],
      rateSet: { name: '', active: false, categories: [], sets: [] },
      loading: false,
      loadingForm: false,
      loadingImport: false,

      categoryItems: [],
      currentCategoryItems: [],
      showAlertModal: false,
      showSave: false,
      showEdit: true,
      shouldValidate: false,
      searching: false,

      fileList: [],
      fileUploadedList: {},
      isUploadFile: false,
      uploadErrorMsg: ''
    }
  }

  componentDidMount () {
    this.fetchRateSet()
  }

  render () {
    const { catId, form, history } = this.props
    const { getFieldDecorator } = form
    const { allRates, allRateSets, categoryItems, currentCategoryItems, rateSet, loading, loadingForm, loadingImport, modalItem, showAlertModal, showSave, showEdit, shouldValidate, searching } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? `Add New Rate Set${rateSet.rate_name ? `under ${rateSet.rate_name}` : ''}` : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : `${rateSet.rate_name} - ${rateSet.name}`}>
            {
              showEdit && this.isEdit() && this.hasAccess(Permissions.SETTING.RATESETS.UPDATE)
                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              ((!this.isEdit() && this.hasAccess(Permissions.SETTING.RATESETS.CREATE)) ||
                (showSave && this.isEdit() && this.hasAccess(Permissions.SETTING.RATESETS.UPDATE)))
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  { loadingForm ? <div><Icon type="loading" /> &nbsp;Saving...</div> : 'Save' }
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>
          { this.renderForm() }

          { !loading && this.isEdit() && validator.isNotEmptyArray(rateSet.categories)
            ? <Page.Filter>
              <Row gutter={8}>
                <Col lg={8}>
                  <ControlLabel>Support Budget, Support Items, Support Item Number</ControlLabel>
                  <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} disabled={loading || loadingForm} />
                </Col>
              </Row>
            </Page.Filter>
            : null }

          { this.renderCategories() }
        </Page.Content>
      </Page.Body>
    )
  }

  renderCategories = () => {
    const { allRates, fileList, fileUploadedList, filteredCats, isUploadFile, loadingForm, loadingImport, loading, rateSet, showAlertModal, uploadErrorMsg } = this.state

    const columns = [
      {
        title: 'Support Budget',
        width: 200,
        dataIndex: 'cat_name',
        key: 'cat_name',
        fixed: 'left',
      },
      {
        title: 'Support Item',
        width: 300,
        dataIndex: 'cat_item_name',
        key: 'cat_item_name',
        fixed: 'left',
      },
      {
        title: 'Support Item Number',
        width: 180,
        dataIndex: 'cat_item_identifier',
        key: 'cat_item_identifier',
        fixed: 'left',
      },
      // {
      //   title: <div>Price Limit:<br />NT-SA<br />TWS-WA<br />(MMM 1-5)</div>,
      //   dataIndex: 'value_d0',
      //   key: 'd0'
      // },
      // {
      //   title: <div>Price Limit:<br />ACT-NSW<br />QLD-VIC<br />(MMM 1-5)</div>,
      //   dataIndex: 'value_d1',
      //   key: 'd1'
      // },
      // {
      //   title: <div>Price Limit:<br />National<br />Non-Remote<br />(MMM 1-5)</div>,
      //   dataIndex: 'value_d2',
      //   key: 'd2'
      // },
      // {
      //   title: <div>Price Limit:<br />National<br />Remote<br />(MMM 6)</div>,
      //   dataIndex: 'value_d3',
      //   key: 'd3'
      // },
      // {
      //   title: <div>Price Limit:<br />National<br />Very Remote<br />(MMM 7)</div>,
      //   dataIndex: 'value_d4',
      //   key: 'd4'
      // },
      {
        title: <div>ACT</div>,
        dataIndex: 'value_d0',
        key: 'd0',
        render: (d0) => <div>{d0 ? formatter.toPriceValue(d0) : ''}</div>
      },
      {
        title: <div>NSW</div>,
        dataIndex: 'value_d1',
        key: 'd1',
        render: (d1) => <div>{d1 ? formatter.toPriceValue(d1) : ''}</div>
      },
      {
        title: <div>NT</div>,
        dataIndex: 'value_d2',
        key: 'd2',
        render: (d2) => <div>{d2 ? formatter.toPriceValue(d2) : ''}</div>
      },
      {
        title: <div>QLD</div>,
        dataIndex: 'value_d3',
        key: 'd3',
        render: (d3) => <div>{d3 ? formatter.toPriceValue(d3) : ''}</div>
      },
      {
        title: <div>SA</div>,
        dataIndex: 'value_d4',
        key: 'd4',
        render: (d4) => <div>{d4 ? formatter.toPriceValue(d4) : ''}</div>
      },
      {
        title: <div>TAS</div>,
        dataIndex: 'value_d5',
        key: 'd5',
        render: (d5) => <div>{d5 ? formatter.toPriceValue(d5) : ''}</div>
      },
      {
        title: <div>VIC</div>,
        dataIndex: 'value_d6',
        key: 'd6',
        render: (d6) => <div>{d6 ? formatter.toPriceValue(d6) : ''}</div>
      },
      {
        title: <div>WA</div>,
        dataIndex: 'value_d7',
        key: 'd7',
        render: (d7) => <div>{d7 ? formatter.toPriceValue(d7) : ''}</div>
      },
      {
        title: <div>Remote</div>,
        dataIndex: 'value_d20',
        key: 'd20',
        render: (d20) => <div>{d20 ? formatter.toPriceValue(d20) : ''}</div>
      },
      {
        title: <div>Very<br/>Remote</div>,
        dataIndex: 'value_d30',
        key: 'd30',
        render: (d30) => <div>{d30 ? formatter.toPriceValue(d30) : ''}</div>
      },
      {
        title: <div>Unit</div>,
        dataIndex: 'unit',
        key: 'unit',
        render: (unit) => {
          const f = SupportCatItemsUnit.find(e => e.value === unit)
          if (f) {
            return f.label
          } else {
            return formatter.capitalize(unit, false)
          }
        }
      },
      {
        title: <div>Quote?</div>,
        dataIndex: 'is_quote',
        key: 'is_quote',
        render: (is_quote) => is_quote
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: <div>Price<br />Limit?</div>,
        dataIndex: 'is_price_limit',
        key: 'is_price_limit',
        render: (is_price_limit) => is_price_limit
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: <div>Non-Face-<br/>To-Face?</div>,
        dataIndex: 'is_nf2f',
        key: 'is_nf2f',
        render: (is_nf2f) => is_nf2f
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: <div>Provider<br/>Travel?</div>,
        dataIndex: 'is_provider_travel',
        key: 'is_provider_travel',
        render: (is_provider_travel) => is_provider_travel
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: <div>Short Notice<br />Cancellations?</div>,
        dataIndex: 'is_short_notice_cancel',
        key: 'is_short_notice_cancel',
        render: (is_short_notice_cancel) => is_short_notice_cancel
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: <div>NDIA<br />Requested<br />Reports?</div>,
        dataIndex: 'is_ndia_requested_reports',
        key: 'is_ndia_requested_reports',
        render: (is_ndia_requested_reports) => is_ndia_requested_reports
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: <div>Irregular<br />SIL<br />Supports?</div>,
        dataIndex: 'is_irregular_sil',
        key: 'is_irregular_sil',
        render: (is_irregular_sil) => is_irregular_sil
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
    ]

    return (
      rateSet && validator.isNotEmptyArray(rateSet.categories)
      ? <div style={{marginTop: '20px'}}>
        <Loading loading={loadingForm} blur>
          <Table
            rowKey={'sidtable'}
            columns={columns}
            dataSource={filteredCats}
            pagination={{defaultPageSize: 30}}
            scroll={{ x: true, scrollToFirstRowOnChange: true }}
          />
        </Loading>
      </div>
      : null
    )
  }

  renderForm = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { allRates, fileList, fileUploadedList, isUploadFile, loadingForm, loadingImport, loading, rateSet, showAlertModal, uploadErrorMsg } = this.state

    const isRateSelected = getFieldValue('rate_id')
    const id = this.getId()
    return (
      <div>
        <Form>
        <Loading loading={loadingForm} blur>
          <Skeleton loading={loading} active>

          <div className='billing-rate-info'>

            <Row style={{ marginTop: '20px' }}>
              <Col lg={10}>
                <FormItem label='Set' {...formItemLayout}>
                  {getFieldDecorator('rate_id', {
                    initialValue: rateSet.rate_id || '',
                    rules: [
                      { required: true, message: 'Please select Set' }
                    ]
                  })(
                    <Select
                      disabled={this.isEdit()}
                      showSearch
                      onChange={this.handleRateSetChange}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        allRates.map((set, index) => {
                          return <Option key={index} value={set.id}>{set.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
                <FormItem label='Set Name' {...formItemLayout}>
                  {getFieldDecorator('name', {
                    initialValue: rateSet.name || '',
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter name' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input disabled={!isRateSelected} />
                  )}
                </FormItem>
              </Col>
              <Col lg={2}>
                <FormItem label='' {...formItemLayout}>
                  {getFieldDecorator('active', {
                    initialValue: rateSet.active || false,
                    valuePropName: 'checked',
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                      disabled={!isRateSelected}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={6}>
                <FormItem label='Start Date' {...formItemDateLayout}>
                  {getFieldDecorator('start_date', {
                    initialValue: rateSet.start_date ? formatter.toMoment(rateSet.start_date) : null,
                    rules: [
                      { required: true, message: 'Please select start date' },
                      { validator: this.handleDateChange }
                    ]
                  })(
                    <DatePicker
                      defaultPickerValue={moment(new Date())}
                      format={'DD/MM/YYYY'}
                      disabled={!isRateSelected}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={6}>
                <FormItem label='End Date' {...formItemDateLayout}>
                  {getFieldDecorator('end_date', {
                    initialValue: rateSet.end_date ? formatter.toMoment(rateSet.end_date) : null,
                    rules: [
                      { required: true, message: 'Please select end date' },
                      { validator: this.handleDateChange }
                    ]
                  })(
                    <DatePicker
                      defaultPickerValue={moment(new Date())}
                      format={'DD/MM/YYYY'}
                      disabled={!isRateSelected}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>


          {/* { this.isEdit() && !validator.isNotEmptyArray(rateSet.categories) */}
          { /** remove comment when import is required */}
          { this.isEdit() && this.hasAccess(Permissions.SETTING.RATESET_IMPORT.UPDATE)
            ? <div>
              <div className='billing-rate-info'>
                <div className='row'>
                  <div className='billing-rate-row-title'>Import rate set csv to configure the latest set of categories and rates under this rate set.</div>
                  <Button onClick={this.showAlertModal} feedback={loadingForm || loadingImport}>Import</Button>
                </div>
              </div>
            </div>
            : null }
          </Skeleton>

          <Modal visible={showAlertModal}
            width={700}
            title='Import Rate Sets and Categories'
            onOk={this.handleAlertSubmit}
            onCancel={isUploadFile ? null : this.hideAlertModal}
            footer={[
              <Button ghost key='cancel' type='primary' onClick={this.hideAlertModal}>Cancel</Button>,
              <Button key='ok' ghost={!fileUploadedList.uid} feedback={isUploadFile} type='primary' onClick={this.handleAlertSubmit}>Start Import</Button>
            ]}
          >
            <div>Upload the latest rate set file in csv/Excel format and wait for the updates for a while.</div>
            <div style={{color: 'red'}}>Be reminded that this action cannot be undone!</div>

            <div style={{marginTop: '20px'}}>
              <Upload
                method={'POST'}
                action={`${apiHostname}/private/api/pm-rate-set/file/${id}`}
                data= {{rateId: id}}
                name={'file'}
                onRemove={this.fileRemove}
                onChange={this.fileChange}
                beforeUpload={this.fileSet}
                headers={{Authorization: `Bearer ${auth.getCurrentToken()}` }}
                fileList={fileList}
                multiple={false}
              >
                <Button>
                  <Icon type="upload" /> Select File
                </Button>
              </Upload>
            </div>

            { uploadErrorMsg ? <div style={{color: 'red', fontWeight: 'bold', marginTop: '5px'}}>{uploadErrorMsg}</div> : null }
          </Modal>
        </Loading>
      </Form>


      </div>

    )
  }

  onSearchName = (value = '') => {
    this.setState({ searching: true })

    const { rateSet } = this.state

    const cats = validator.isNotEmptyArray(rateSet.categories) ? rateSet.categories.slice() : []
    let filteredCats = []

    value = value ? value.trim() : ''

    if (value) {
      let words = []
      const value2 = this.replacer(value)
      if (value.indexOf(' ') >= 0) {
        words = value.split(' ')
      } else {
        words = [value]
      }

      filteredCats = cats.filter(e => {
        let status = false

        if (validator.isNotEmptyArray(words)) {
          const w1 = value.toLowerCase()

          if (e &&
            ((e.cat_item_identifier && this.replacer(e.cat_item_identifier).indexOf(w1) > -1) ||
             (e.cat_item_name && this.replacer(e.cat_item_name).indexOf(w1) > -1) ||
             (e.cat_name && this.replacer(e.cat_name).indexOf(w1) > -1)
            )) {
            status = true
          }

          if (status === false) {
            for (let i = 0; i < words.length; i++) {
              const w2 = words[i].toLowerCase()

              if (e &&
                ((e.cat_item_identifier && this.replacer(e.cat_item_identifier).indexOf(w2) > -1) ||
                 (e.cat_item_name && this.replacer(e.cat_item_name).indexOf(w2) > -1) ||
                 (e.cat_name && this.replacer(e.cat_name).indexOf(w2) > -1)
                )) {
                status = true
                break
              }
            }
          }
        } else {
          const w = value2

          if (e &&
            ((e.cat_item_identifier && this.replacer(e.cat_item_identifier).indexOf(w) > -1) ||
             (e.cat_item_name && this.replacer(e.cat_item_name).indexOf(w) > -1) ||
             (e.cat_name && this.replacer(e.cat_name).indexOf(w) > -1)
            )) {
            status = true
          }
        }

        return status
      })

      if (validator.isNotEmptyArray(words)) {
        // sort to put value all matched records on top
        filteredCats.sort((a, b) => {
          const c1 = (a.cat_item_identifier && this.replacer(a.cat_item_identifier).indexOf(value2) > -1) ||
                      (a.cat_item_name && this.replacer(a.cat_item_name).indexOf(value2) > -1) ||
                      (a.cat_name && this.replacer(a.cat_name).indexOf(value2) > -1)

          const c2 = (b.cat_item_identifier && this.replacer(b.cat_item_identifier).indexOf(value2) > -1) ||
                      (b.cat_item_name && this.replacer(b.cat_item_name).indexOf(value2) > -1) ||
                      (b.cat_name && this.replacer(b.cat_name).indexOf(value2) > -1)

          if (c1 && c2) return 0
          if (c1 && !c2) return -1
          if (!c1 && c2) return 1
        })
      }
    } else {
      if (value.length > 0) {
        filteredCats = []
      } else {
        filteredCats = cats
      }
    }


    this.setState({ searching: false, filteredCats })
  }

  replacer = (value) => {
    let str = value.toLowerCase()

    if (str) {
      str = str.trim()

      // Replace special characters
      str = str.replace(/[•·\-\\\/\<\>\?\[\]\"\']/g, '')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')
    }

    return str || ''
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({fileList: newFileList, fileUploadedList: {}})
    }
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({fileUploadedList: data, uploadErrorMsg: ''})
      }
    }
  }

  fileSet = (file) => {
    if (file && (
        file.type === 'text/csv' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
        // file.type === 'application/vnd.ms-excel.sheet.macroEnabled.12' ||
        // file.type === 'application/vnd.ms-excel.template.macroEnabled.12' ||
        // file.type === 'application/vnd.ms-excel.addin.macroEnabled.12' ||
        // file.type === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
      )) {
      this.setState({fileList: [file], uploadErrorMsg: ''})
      return true
    } else {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatCSV })
      return false
    }

    // return false
  }

  fetchRateSet = async () => {
    this.setState({ loading: true })

    const id = this.getId()

    if (id === 'add') {
      if (!this.hasAccess(Permissions.SETTING.RATESETS.CREATE)) return

      await this.fetchAllRates()
      this.setState({ loading: false })
    } else {
      if (!this.hasAccess(Permissions.SETTING.RATESETS.READ)) return

      const item = await pmRateSetService.get(id)
      if (item && item.id) {
        this.setState({
          loading: false,
          rateSet: item,
          filteredCats: validator.isNotEmptyArray(item.categories) ? item.categories.slice() : []
        }, async () => {
          await this.fetchAllRates()
          await this.fetchAllRateSets()
        })
      }
    }
  }

  fetchAllRates = async () => {
    const { item = {} } = this.state

    const id = this.getId()
    let filter = {}
    filter.active = {
      $and: [
        { condition: '=', value: true }
      ]
    }

    const rates = await pmRateService.listByPage(1, 0, filter)
    const defaultRateId = rates && validator.isNotEmptyArray(rates.list) ? rates.list[0].id : ''

    this.setState({
      allRates: rates && validator.isNotEmptyArray(rates.list) ? rates.list : [],
      // item: item.id ? item : defaultRateId? { rate_id: defaultRateId } : {}
    })
  }

  fetchAllRateSets = async (rateId = null) => {
    const { rateSet } = this.state
    const { form } = this.props
    rateId = rateId ? rateId : rateSet.rate_id
    const id = this.getId()

    const allRateSets = await pmRateSetService.getAllActiveRateSets(rateId, id)

    if (allRateSets && validator.isNotEmptyArray(allRateSets)) {
      this.setState({allRateSets})
    }
  }

  handleDateChange = (rule, value, callback) => {
    callback()
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleImport = async () => {
    this.setState({ showAlertModal: true })
  }

  showAlertModal = () => {
    this.setState({ showAlertModal: true })
  }

  hideAlertModal = () => {
    this.setState({ showAlertModal: false })
  }

  handleAlertSubmit = async () => {
    const { fileList, fileUploadedList } = this.state
    const id = this.getId()
    if (fileList.length === 0) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
    } else if (!fileUploadedList.uid) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
    } else {
      this.setState({ isUploadFile: true })

      try {
        const r = await pmRateSetService.startImportCsv(id, fileUploadedList)

        if (r && r.id) {
          notify.success('Import Rates Successfully', 'Rates are imported successfully.')
          this.setState({ isUploadFile: false, showAlertModal: false, fileList: [], fileUploadedList: {} })
          window.location.reload()
        } else {
          notify.error('Unable to Import Rates', formatter.toErrorMessage(r.errors) || 'Unable to import rates. Please try again later.')
          this.setState({ isUploadFile: false, fileList: [], fileUploadedList: {} })
        }
      } catch (e) {
        notify.error('Unable to Import Rates', 'Unable to import rates. Please try again later.')

        this.setState({ isUploadFile: false, showAlertModal: false, fileList: [], fileUploadedList: {} })
      }

    }
  }

  handleDelete = () => {
    // const { rateSet } = this.state

    // for (let i=0; i < rateSet.sets.length; i++) {
    //   const rateset = rateSet.sets[i]
    //   if (rateset.id === id) {
    //     rateSet.sets.splice(i, 1)
    //     break
    //   }
    // }

    // this.setState({ rateSet })
  }

  handleRateSetChange = (e) => {
    if (e) {
      this.fetchAllRateSets(e)
    }
  }

  handlePreSaveCheck = () => {
    this.setState({ shouldValidate: true, hasError: false })

    setTimeout(() => {
      this.handleSave()
    }, 1100)
  }

  handleSave = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldsValue, validateFields, validateFieldsAndScroll } = form
    const { loadingImport, loadingForm, rateSet } = this.state
    const rateSetId = this.getId()

    if (loadingForm || loadingImport) return

    validateFields(async(errors, values) => {
      if (!errors) {
        try {
          let r
          this.setState({ loadingForm: true })
          if (this.isEdit()) {
            r = await pmRateSetService.save(rateSetId, values)
          } else {
            // rateSet.funding_cat_id = parseInt(catId)
            r = await pmRateSetService.add(values)
          }
          this.setState({ loadingForm: false })

          if (r.id) {
            notify.success('Saved successfully', `Rate set saved successfully.`)

            if (!this.isEdit()) {
              window.location.replace(`/settings/rate-set/edit/${r.id}`)
            }
          } else {
            notify.error('Unable to save successfully', `Unable to save rate set successfully. Please try again later.`)
            console.log('save err 2', r)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save rate set successfully. Please try again later.`)
          console.log('save err 1', e)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  removeItem = async(item) => {

  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getCatId = () => {
    const { match } = this.props
    const { params } = match
    const { cid } = params
    return cid
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSetEdit))
