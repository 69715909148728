import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGSTItemService } from '../../../../services/setting'
import { authService } from '../../../../services'
import { auth } from '../../../../util'

// UI
import { Button, Page, Panel, SideModal, Pager, List } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

import { formatter, validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form

const settingType = 'GST Item'

export class SettingGSTItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: {},
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettingsGST({ currentPage })
  }

  changePage = (currentPage) => {
    this.fetchSettingsGST({ currentPage })
  }

  fetchSettingsGST = async ({ currentPage = 1 }) => {
    if (!this.hasAccess(Permissions.SETTING.GST.LIST)) return

    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingGSTItemService.listByPage(currentPage, pageSize)
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form, onComplete } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = {}
    modal.show = false
    this.setState({ modal, selectedItem: {} })
    onComplete()
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal

        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingGSTItemService.save(id, values)
          } else {
            response = await settingGSTItemService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response && response.id) {
            notify.success('Saved successfully', `${settingType} saved successfully.`)
            this.fetchSettingsGST({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (item) {
    const { id, name, description, is_editable, is_value_zero, active } = item
    this.setState({ selectedItem: { id, name, description, is_editable, is_value_zero, active } })
    this.showModal()
  }

  async handleDelete (id) {
    this.setState({ loading: true })
    const res = await settingGSTItemService.remove(id)

    if (res && res.id) {
      notify.success('Deleted successfully', `${settingType} deleted successfully.`)
    } else {
      notify.error('Unable to delete successfully', `Unable to delete ${settingType} successfully. Please try again later.`)
    }

    this.fetchSettingsGST({ currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const isDisabled = selectedItem && selectedItem.is_editable === false

    const columns = [
      {
        title: 'Name',
        width: 6,
        key: 'name'
      },
      {
        title: 'Description',
        width: 12,
        key: 'description'
      },
      {
        title: 'Is Zero Value?',
        width: 4,
        render: ({ is_value_zero }) => <div style={{ color: is_value_zero ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Enable',
        width: 4,
        render: ({ active }) => <div style={{ color: active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: '',
        width: 1,
        render: (item) => this.hasAccess(Permissions.SETTING.GST.UPDATE)
          ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(item)}>
            <Icon type='form' />
          </div>
          : null
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => this.hasAccess(Permissions.SETTING.GST.DELETE) && is_editable
          ? <Popconfirm
            title='Confirm to delete this?'
            onConfirm={(e) => this.handleDelete(id)}
            okText='Yes'
            cancelText='No'
          ><Icon type='delete' />
          </Popconfirm>
          : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} items`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title='GST Item'
          showModal={modal.show || this.props.onAdd}
          onClose={this.hideModal}
          buttons={[
            isDisabled
            ? null
            : <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Name'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Name must be between 2 and 128 characters' },
                  { max: 128, message: 'Name must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter name' },
                  { whitespace: true, message: 'Please enter name' }
                ]
              })(
                <Input disabled={loadingForm || isDisabled} />
              )}
            </FormItem>
            <FormItem label='Description'>
              {getFieldDecorator('description', {
                initialValue: selectedItem.description,
                rules: [
                  { whitespace: true, message: 'Please enter description' }
                ]
              })(
                <Input disabled={loadingForm || isDisabled} />
              )}
            </FormItem>
            <FormItem label='Is Zero Value' extra={'If the GST item is zero value or null value (e.g. not applicable), enable the toggle. Else, disable the toggle and enter the relevant value of percentage in GST rate info.'}>
              {getFieldDecorator('is_value_zero', {
                initialValue: selectedItem.is_value_zero !== undefined ? selectedItem.is_value_zero : false,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={loadingForm || isDisabled}
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                />
              )}
            </FormItem>
            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: selectedItem.active !== undefined ? selectedItem.active : true,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={loadingForm || isDisabled}
                  checkedChildren='Enable'
                  unCheckedChildren='Disable'
                />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </div>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingGSTItem))
