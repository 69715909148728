import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/report/type'

export default {
  listClientBudgetEndingEnded () {
    return request(`${url}/client-budget-ending/list`)
  },
  listActiveClientNoActiveBudget  () {
    return request(`${url}/client-active-no-sb/list`)
  },
  listPageClientBudgetEndingEnded (type, page, size, filter, sorting, text) {
    return request(`${url}/client-budget-ending/type/${type}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listPageActiveClientNoActiveBudget (page, size, filter, sorting, text) {
    return request(`${url}/client-active-no-sb/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listToReceiveInvoicesReportByPage (page, size, filter, sorting, text) {
    return request(`${url}/to-receive/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
}
