import { all, fork } from 'redux-saga/effects'

import watchClient from './client'
import watchCredit from './credit'
import watchFunder from './funder'
import watchFunding from './funding'
import watchInvoice from './invoice'
import watchInvoiceFS from './invoice-fs'
import watchProvider from './provider'
import watchDashboard from './dashboard'
import watchLinkRequest from './linkRequest'
import watchPayroll from './payroll'
import watchStatements from './statement'
import watchUser from './user'

export default function * root () {
  yield all([
    fork(watchClient),
    fork(watchCredit),
    fork(watchDashboard),
    fork(watchFunder),
    fork(watchInvoice),
    fork(watchInvoiceFS),
    fork(watchFunding),
    fork(watchLinkRequest),
    fork(watchPayroll),
    fork(watchProvider),
    fork(watchStatements),
    fork(watchUser)
  ])
}
