import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { pmRateService } from '../../../../services'
import { authService } from '../../../../services'
import { auth } from '../../../../util'

// UI
import { Button, Page, Pager, Panel, SideModal, List } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Rates'

export class SettingRates extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      // filter: {},
      // list: [],
      // modal: {
      //   item: { link: {} },
      //   show: false
      // },
      // modalShow: false,
      // searchText: '',
      // selectedItem: { _id: null },
      // sort: {},
      // total: 0
      modalShow: false,
      selectedItem: {},
      list: [],
      loading: false,
      loadingForm: false,
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchRates({ currentPage })
  }

  fetchRates = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.SETTING.RATESETS.LIST)) return

    try {
      this.setState({ currentPage, loading: true })
      const list = await pmRateService.listByPage(currentPage, pageSize)
      this.setState({ list: list.list, loading: false, total: list.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load rates successfully. Please try again later.')
    }
  }

  hideModal = () => {
    // const { form, onComplete } = this.props
    // const { modal } = this.state
    // const { resetFields } = form
    // resetFields()
    // modal.item = { link: {} }
    // modal.show = false
    // this.setState({ modal, selectedItem: {} })
    // onComplete()
    this.setState({modalShow: false})
  }

  showModal = () => {
    // const { modal } = this.state
    // modal.show = true
    // this.setState({ modal })
    this.setState({modalShow: true})
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await pmRateService.save(id, values)
          } else {
            response = await pmRateService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            this.setState({ selectedItem: {} })
            notify.success('Saved successfully', `Rate saved successfully.`)
            this.fetchRates({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save rate successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save rate successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, name, active) {
    this.setState({ selectedItem: { id, name, active } })
    this.showModal()
  }

  async handleDelete (id) {
    // const res = await settingGeneralService.remove(id)

    // if (res) {
    //   message.success('Deleted successfully', `${settingType} deleted successfully`)
    //   this.fetchSettings({ currentPage: 1 })
    // }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, modalShow, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Rate',
        width: 16,
        key: 'name'
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div> }
        </div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, name, active }) => this.hasAccess(Permissions.SETTING.RATESETS.UPDATE)
          ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(id, name, active)}><Icon type='form' /></div>
          : null
      },
      // {
      //   title: '',
      //   width: 1,
      //   render: ({ id }) => this.hasAccess('deleteRelationship') ? <Popconfirm
      //     title='Confirm to delete this?'
      //     onConfirm={(e) => this.handleDelete(id)}
      //     okText='Yes'
      //     cancelText='No'
      //   ><Icon type='delete' />
      //   </Popconfirm> : null
      // }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Page.Header>
            { !loading && this.hasAccess(Permissions.SETTING.RATESETS.CREATE)
              ? <div className='btn' onClick={this.showModal}>Add Rate</div>
              : null }
          </Page.Header>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} Rates`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title={settingTitle}
          showModal={modalShow}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Rate Name'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Rate name must be between 2 and 128 characters' },
                  { max: 128, message: 'Rate name must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter rate name' },
                  { whitespace: true, message: 'Please enter rate name' }
                ]
              })(
                <Input disabled={loadingForm} />
              )}
            </FormItem>
            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: selectedItem.active || true,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Enable'
                  unCheckedChildren='Disable'
                  defaultChecked
                  disabled={loadingForm}
                />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchRates({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRates))
