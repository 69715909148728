import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/comm'

export default {
  getCommCount (type, typeId) {
    return request(`${url}/count/${type}/${typeId}`)
  },
  create (type = '', values) {
    return request(`${url}/create/${type}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  resend (id, values) {
    return request(`${url}/resend/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  save (id, values) {
    return request(`${url}/update/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  update (id, values) {
    return request(`${url}/update-record/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
}
