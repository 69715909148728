import React, { Component } from 'react'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

export class AddClientModal extends Component {
  state = {
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history, onUpdate } = this.props
        const clientId = values.client_id

        onUpdate && onUpdate(clientId)
      }
    })
  }

  findClients = (input, option) => {
    // console.log('client options', option)
    const clientArr = option.props.children.props.children[0].props.children
    const clientNdis = option.props.children.props.children[1].props.children
    const fstname = clientArr[0] ? clientArr[0].trim() : clientArr[0]
    const lstname = clientArr[2] ? clientArr[2].trim() : clientArr[2]
    const ndis = clientNdis ? clientNdis.trim() : clientNdis
    const client = `${fstname} ${lstname} ${ndis}`

    return client.toLowerCase().indexOf(input.toLowerCase()) > -1
  }

  render () {
    const { form, visible, onClose, clientId, clientList = [] } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return (
      <Modal visible={visible}
        width={700}
        title={clientId ? 'Update Participant' : 'Select Participant'}
        onCancel={onClose}
        footer={[
          <Button key='ok' type='primary' onClick={this.handleSubmit}>Select</Button>
        ]}
      >
        <div>
          <Form>
            <FormItem {...formItemLayout} label='Participant' hasFeedback>
              {getFieldDecorator('client_id', clientId ? {
                initialValue: parseInt(clientId),
                rules: [
                  { required: true, message: 'Please select a client' }
                ]
              } : {
                rules: [
                  { required: true, message: 'Please select a client' }
                ]
              })(
                <Select showSearch
                  style={{ width: '100%'}}
                  placeholder='Participant'
                  optionFilterProp='children'
                  notFoundContent='Not found'
                  filterOption={(input, option) => this.findClients(input, option)}
                  // onChange={(e, c) => this.onSelectClient(e, c)}
                >
                  {
                    clientList.map((client, idx) => {
                      return <Option key={`client${idx}`} value={client.id}>
                        <div>
                          <div>{client.first_name} {client.last_name}</div>
                          <div className='clientId'>{client.ndis_number}</div>
                        </div>
                      </Option>

                    })
                  }

                </Select>
              )}
            </FormItem>
          </Form>
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AddClientModal)
