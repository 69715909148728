export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: 'Payroll',
            icon: 'dollar',
            menu: [
            ],
            permission: 'listPayrolls'
          },
          {
            title: 'Reports',
            icon: 'snippets',
            menu: [
              {
                name: 'Participants\' Balance Job Hours',
                link: '/reports/client-balance-hours',
                permission: 'readClientJobBalanceHourReport'
              },
              {
                name: 'Participants\' Due Report',
                link: '/reports/client-due',
                permission: 'readClientDueReport'
              },
              {
                name: 'Participants\' Plan Expiry',
                link: '/reports/client-plan-expiry',
                permission: 'readClientFundReport'
              },
              {
                name: 'Payroll',
                link: '/payrolls',
                permission: 'listPayrolls'
              },
              {
                name: 'User Action',
                link: '/reports/action',
                permission: 'readActionReport'
              }
            ],
            permission: 'listReports'
          }
        ],
        permission: 'listEmployees'
      }
    ]
  }
]
