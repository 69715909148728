import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService, invoiceImportService } from '../../../services'

// UI
import { Button, Page } from '../../../components'
import { FileUploadMsg, Permissions } from '../../../constants'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Upload from 'antd/lib/upload'
import { apiHostname } from '../../../config'
import { auth, formatter, log, uploader, validator } from '../../../util'
import './styles.css'

class SettingInvoiceImport extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isCompleteLoading: false,
      isFileLoaded: false,
      isUploadFile: false,
      errorMessage: '',
      fileList: [],
      fileUploadedList: {},
      uploadErrorMsg: ''
    }
  }

  componentDidMount () {

  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  handleAlertSubmit = async () => {
    const { fileList, fileUploadedList } = this.state
    if (fileList.length === 0) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
    } else if (!fileUploadedList.uid) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
    } else {
      this.setState({ isUploadFile: true })
      try {
        const r = await invoiceImportService.startImportCsv(fileUploadedList)

        if (r && r.valid) {
          notify.success('Import Invoices Successfully', 'Invoices are imported successfully.')
          this.setState({ isUploadFile: false, showAlertModal: false, fileList: [], fileUploadedList: {} })
          window.location.reload()
        } else {
          notify.error('Unable to Import Invoices', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to import invoices. Please try again later.')
          this.setState({ isUploadFile: false, fileList: [], fileUploadedList: {} })
        }
      } catch (e) {
        notify.error('Unable to Import Invoices', 'Unable to import invoices. Please try again later.')

        this.setState({ isUploadFile: false, showAlertModal: false, fileList: [], fileUploadedList: {} })
      }

    }
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({fileList: newFileList, fileUploadedList: {}})
    }
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({fileUploadedList: data, uploadErrorMsg: ''})
      }
    }
  }

  fileSet = (file) => {
    if (file && (
        file.type === 'text/csv' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
      )) {
      this.setState({fileList: [file], uploadErrorMsg: ''})
      return true
    } else {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatCSV })
      return false
    }

    // return false
  }

  render () {
    const { isFileLoaded, isCompleteLoading, isUploadFile, errorMessage, fileList, fileUploadedList, uploadErrorMsg } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={'Invoice Import'} />

          <div className='panel'>
            { errorMessage
              ? <div className='panel-content error'>{errorMessage}</div>
              : isUploadFile
                ? <div className='panel-content'>Importing...</div>
                : <div>
                  <div className='panel-content error'>Pleas make sure your csv import file matches the required format, and the data of participants/provider/categories/service booking are created and available in Pumpkin PM.</div>
                  <div className='panel-content error'>The invoices will be unable to import if failed to achieve the requirements above.</div>
                  <div className='panel-content'>Press on Import button to select appropriate import file and start import invoices.</div>

                  { this.hasAccess(Permissions.SETTING.INV_IMPORT.CREATE)
                    ? <div style={{marginTop: '20px'}}>
                      <Upload
                        method={'POST'}
                        action={`${apiHostname}/private/api/invoice-import/file`}
                        name={'file'}
                        onRemove={this.fileRemove}
                        onChange={this.fileChange}
                        beforeUpload={this.fileSet}
                        headers={{Authorization: `Bearer ${auth.getCurrentToken()}`}}
                        fileList={fileList}
                        multiple={false}
                      >
                        <Button>
                          <Icon type='upload' /> Select File
                        </Button>
                      </Upload>
                    </div>
                    : null }

                  { uploadErrorMsg ? <div style={{color: 'red', fontWeight: 'bold', marginTop: '5px'}}>{uploadErrorMsg}</div> : null }

                  { this.hasAccess(Permissions.SETTING.INV_IMPORT.CREATE)
                    ? <div className='panel-row center' style={{marginTop: '8px'}} onClick={() => this.handleAlertSubmit()}>
                      <Button ghost={!fileUploadedList.uid} feedback={isUploadFile} type='primary'>Start Import</Button>
                    </div>
                    : null }
                </div>
            }

          </div>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingInvoiceImport))
