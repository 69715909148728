import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/settings/reasons'
const urlItems = `${url}/items`

export default {
  getReason (id) {
    return request(`${url}/${id}`)
  },
  addReason (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveReason (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeReason (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  listReasonByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getReasonItem (id) {
    return request(`${urlItems}/${id}`)
  },
  addReasonItem (values) {
    return request(urlItems, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveReasonItem (id, values) {
    return request(`${urlItems}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeReasonItem (id) {
    return request(`${urlItems}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  listReasonItemByPage (page, size, filter, sorting, text) {
    return request(`${urlItems}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listReasonItemsAll (type) {
    return request(`${urlItems}/list/all/${type}`)
  }
}
