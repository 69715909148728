import React, { Component } from 'react'
import { connect } from 'react-redux'
import { statementService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'

// UI
import { Loading, List, Pager } from '../../../components'
import { Permissions } from '../../../constants'
import { auth, exportFile, formatter, validator } from '../../../util'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const TabPane = Tabs.TabPane
const pageSize = 20


export class ClientStatement extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      reportList: [],
      loading: false,
      clientId: '',
      currentTab: '1',
    }
  }

  componentDidMount () {
    this.fetch()
  }

  componentWillReceiveProps (nextProps) {
    const { currentPage } = this.state
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchStatementList(currentPage, nextProps.clientId)
      }
    }
  }

  render () {
    const { currentTab } = this.state

    return (
      <div>
        <Tabs defaultActiveKey={currentTab} type="card" onChange={this.changeTab} style={{ marginBottom: 32 }}>
          <TabPane tab="PM Statements" key='1'>
            { this.renderBody('PM Statements') }
          </TabPane>
          <TabPane tab="Standard Booking Statements" key='2'>
            { this.renderBody('Standard Booking Statements') }
          </TabPane>
        </Tabs>
      </div>
    )
  }

  renderBody = (title) => {
    const { currentPage, loading, reportList, total } = this.state

    const reportColumns = [
      {
        title: 'Period',
        width: 3,
        render: ({ period_fullname }) => <div>{period_fullname}</div>
      },
      {
        title: 'Booking Number',
        width: 4,
        render: ({ booking_number }) => <div>{booking_number}</div>
      },
      {
        title: 'SB Period',
        width: 5,
        render: ({ period_start_date, period_end_date }) => <div>{formatter.toShortDate(period_start_date)} - {formatter.toShortDate(period_end_date)}</div>
      },
      {
        title: 'Opening Bal',
        width: 3,
        render: ({ total_last_month_value, cum_rcv_total }) => <div>{formatter.toPrice(parseFloat(total_last_month_value) + parseFloat(cum_rcv_total))}</div>
      },
      {
        title: 'Monthly Spend',
        width: 3,
        render: ({ cum_rcv_total }) => <div>{formatter.toPrice(cum_rcv_total)}</div>
      },
      {
        title: 'Closing Bal',
        width: 3,
        render: ({ total_last_month_value }) => <div>{formatter.toPrice(total_last_month_value)}</div>
      },
      {
        title: '',
        width: 2,
        render: (item) => {
          const isEmail = !!item.client_statement_email

          return (<div className='action-buttons'>
            { this.hasAccess(Permissions.STATEMENT.INFO.DOWNLOAD)
              ? <Tooltip mouseLeaveDelay={0} title='Download'>
                <div style={{cursor: 'pointer'}} onClick={() => this.getStatement(item, true, false)}>
                  <Icon type='download' />
                </div>
              </Tooltip>
              : null }
            { this.hasAccess(Permissions.STATEMENT.INFO.EMAIL)
              ? <Tooltip mouseLeaveDelay={0} title={isEmail ? 'Send Email' : 'No Email Recipient for Statement'}>
                { isEmail
                  ? <div style={{cursor: 'pointer'}} onClick={() => this.getStatement(item, false, true)}>
                    <Icon type='mail' />
                  </div>
                  : <div style={{cursor: 'default'}}>
                    <Icon type='mail' style={{color: '#ccc'}} />
                  </div> }
              </Tooltip>
              : null }
          </div>)
        }
      },
    ]

    return (
      <Loading loading={loading} blur>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={reportColumns} rows={reportList} />

          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} statements`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  changeTab = (index) => {
    this.setState({
      currentPage: 1,
      total: 0,
      reportList: [],
      loading: false,
      clientId: '',
      currentTab: index,
    }, () => {
      this.fetch()
    })
  }

  fetch = () => {
    const { currentPage } = this.state
    this.fetchStatementList(currentPage)
  }

  fetchStatementList = async (startPage = null, cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.STMTS.LIST)) return

    this.setState({ loading: true })
    const { currentPage, currentTab } = this.state
    const filter = {}

    const clientId = cid || this.props.clientId
    const page = startPage || currentPage

    if (!clientId) return

    filter.budget_client_id = { condition: '=', value: clientId }

    if (currentTab === '1') {
      filter.is_monthly_fee_main_set = { condition: '=', value: false }
    } else if (currentTab === '2') {
      filter.is_monthly_fee_main_set = { condition: '=', value: true }
    } else {
      delete filter.is_monthly_fee_main_set
    }

    const { list: reportList, total } = await statementService.listStatement(page, pageSize, filter)
    this.setState({ reportList, total, loading: false, currentPage: page, filter })
  }

  changePage = async (currentPage) => {
    this.fetchStatementList(currentPage)
  }

  getStatement = async (item, isDownload = true, isSendEmail = false) => {
    const { clientId, clientInfo } = this.props
    const clientName = clientInfo.first_name + clientInfo.last_name
    const name = item.period_name.replace(/ /g, '')
    const filter = {
      id: item.id,
      booking_number: item.booking_number,
      budget_id: item.budget_id,
      budget_client_id: clientId,
      is_download: isDownload,
      is_send_email: isSendEmail
    }
    const filename = `${clientName}${name}`
    const r = await exportFile.fetchStatement(filter, filename)

    if (r && r.id && isSendEmail) {
      notify.success('Email Sent Successfully', 'Statement has been sent out by email successfully.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientStatement))
