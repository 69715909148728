import React, { Component } from 'react'
import { commService } from '../../../services'
import { log } from '../../../util'
import { cloneDeep } from 'lodash'

import { Button, Loading, SideModal } from '../../../components'
import { CommTypeName } from '../../../constants'
// import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Spin from 'antd/lib/spin'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input

export class AddCommModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoiceId: '',
      invoiceInfo: {},
      item: {},
      itemPrev: {},
      isEdit: false,
      loading: false,
      spinLoading: false,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { invoiceId = '', invoiceInfo = {}, item = {}, visible } = nextProps
    const isEdit = !!item.id
    const itemPrev = cloneDeep(item)

    if (visible === true && prevState.visible === false) {
      return { ...prevState, invoiceId, invoiceInfo, item, itemPrev, isEdit, visible }
    } else {
      return { ...prevState, invoiceId, invoiceInfo, item, itemPrev, isEdit, visible }
    }
  }

  isNewInvoice = () => {
    const { invoiceId } = this.props
    return invoiceId === '' || invoiceId === 'new' || invoiceId === 'add'
  }

  handleSubmit = () => {
    const { invoiceId, invoiceInfo, form, onUpdate } = this.props
    const { item, itemPrev, isEdit = false, loading } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const that = this
    const isNewInvoice = this.isNewInvoice()

    if (loading) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        try {
          this.setState({ loading: true })

          values.title = values.title ? values.title.replace(/\n/g, ' ') : values.title
          values.content = values.content_formatted ? values.content_formatted.replace(/\n/g, '<br />') : values.content_formatted
          delete values.content_formatted

          let r = null

          if (isEdit) {
            r = await commService.save(item.id, values)
          } else {
            // disabled self added comm api handling until further requests incoming
          }

          if (r && r.id) {
            resetFields()

            if (isEdit) {
              const prevItem = {
                title: itemPrev.title,
                content: itemPrev.content
              }

              const currItem = {
                title: values.title || '',
                content: values.content || ''
              }

              const commType = CommTypeName.find(e => e.type === item.comm_type)
              const prefix = commType ? `${commType.name}` : undefined

              log.updateInvoiceComm(
                invoiceId,
                prevItem,
                currItem,
                undefined,
                [
                  { key: 'title', label: 'Subject' },
                  { key: 'content', label: 'Body' }
                ],
                undefined,
                prefix
              )
            } else {
              // disabled self added comm api handling until further requests incoming
            }
          }

          if (onUpdate) {
            onUpdate(isEdit, r)
          }
        } catch (e) {
          onUpdate(isEdit, e)
        }
        this.setState({ loading: false })
      }
    })
  }

  render () {
    const { visible, invoiceId, form, onClose } = this.props
    const { isEdit, item, loading, spinLoading } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit Comm' : 'Add Comm'

    return (
      <SideModal
        key={`invcommms_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem label='Subject'>
                {getFieldDecorator('title', {
                  initialValue: item.title,
                  rules: [
                    { required: true, message: 'Please enter subject' },
                    { whitespace: true, message: 'Please enter subject' }
                  ]
                })(
                  <TextArea rows={2} />
                )}
              </FormItem>

              <FormItem
                label='Body'
                // extra={'Enter <br/> for new line and <b></b> (tag must be closing) for bolded text'}
              >
                {getFieldDecorator('content_formatted', {
                  initialValue: item.content_formatted,
                  rules: [
                    { required: true, message: 'Please enter body' },
                    { whitespace: true, message: 'Please enter body' }
                  ]
                })(
                  <TextArea rows={8} />
                )}
              </FormItem>
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddCommModal)
