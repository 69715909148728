import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { invoiceService, settingGeneralService } from '../../../services'
import { setRefreshActivityLog, updateInvoiceAbaList } from '../../../states/actions'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

// UI
import { ExportType, InvoiceFilterType, Permissions } from '../../../constants'
import { auth, common, exportFile, formatter, validator } from '../../../util'
import notify from '../../../components/Notification'
import { Button, ControlLabel, DateTimePicker, Loading, List, Page, Pager, SearchInput } from '../../../components'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const FormItem = Form.Item
const { confirm } = Modal

const formItemLayout = {
  labelCol: { sm: 3, md: 3, lg: 3 },
  wrapperCol: { sm: 12, md: 12, lg: 12 }
}

const getFilterParams = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        filter.$and.push({
          $or: [
            { id_numbering: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
            { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { provider_abn: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_invoice_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_invoice_created_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_last_received_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_payment_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
            { invoice_provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { status: { condition: 'ilike', value: `%${words[i]}%` } }
          ]
        })
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

export class InvoiceManage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      invoices: { list: [], total: 0 },
      invoiceABAList: [],
      currentPage: 1,
      filter: {},
      filterParam: InvoiceFilterType.FILTER_INCOMPLETED,
      isLoaded: false,
      list: [],
      loading: false,
      loadingList: false,
      loadingUpdate: false,
      loadingAbaDetail: false,
      loadingUpdateAba: false,
      searching: false,
      searchText: '',
      isShowUpdateButton: false,
      isShowGenerateProda: false,
      isShowGenerateABA: false,
      isShowABAModal: false,
      statusList: [],
      sort: {},
      sortRaw: '',
      total: 0,
      updateItems: [],
      updateProdaItems: [],
      updateABAItems: [],
      updateABARcvItems: []
    }

    this.onSearchName = debounce(this.onSearchName, 500)
    this.updateProdaBox = this.updateProdaBox.bind(this)
    this.updateABABox = this.updateABABox.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location } = nextProps
    const { page, q, status } = common.getQueryStringSearchParams(location.search)
    const filter = getFilterParams(prevState.filter, q)

    const state = {
      ...prevState,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage,
      filter,
      filterParam: status || InvoiceFilterType.FILTER_INCOMPLETED,
      searchText: q || '',
    }

    return state
  }

  componentDidMount () {
    this.fetchInvStatusList()
    this.refetchInvoices()
  }

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, filterParam, loading, sort, sortRaw } = this.state
    this.setState({ searching: true })

    const newFilter = getFilterParams(filter, value)

    this.redirectUrl({ page: 1, q: value, status: filterParam })
    this.fetchInvoices({ currentPage: 1, filter: newFilter, filterParam, loading, searchText: value, sort, sortRaw })
    this.setState({ searchText: value, currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  /** query = { page, q, status } */
  redirectUrl = (query) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { currentPage, filterParam, invoiceABAList, list, loading, loadingList, loadingUpdate, loadingAbaDetail, loadingUpdateAba, searching, searchText, isShowUpdateButton, isShowGenerateProda, isShowGenerateABA, isShowABAModal, total, updateItems } = this.state
    let lastInvId = ''

    const columns = [
      {
        title: 'Process Date',
        width: 2,
        render: ({ created_at }) => formatter.toShortDate(created_at)
      },
      {
        title: 'Inv Date',
        width: 2,
        render: ({ invoice_date }) => formatter.toShortDate(invoice_date)
      },

      {
        title: 'Inv No.',
        width: 2,
        render: ({ invoice_number }) => <div>{invoice_number}</div>
      },
      {
        title: 'Participant',
        width: 2,
        render: ({ client_id, client_ref_id, client_name }) => <div><a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_name}</a></div>
      },
      {
        title: 'Provider',
        width: 2,
        render: ({ provider_id, provider_ref_id, provider_name }) => <div><a href={`/providers/${provider_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{provider_name}</a></div>
      },

      {
        title: 'Inv Amt',
        width: 1,
        render: ({ subtotal }) => <div>{formatter.toPrice(subtotal)}</div>
      },

      {
        title: 'Rcv Amt',
        width: 1,
        render: ({ received_subtotal }) => <div>{formatter.toPrice(received_subtotal)}</div>
      },
      {
        title: 'Item Count',
        width: 1,
        render: ({ qty }) => <div>{qty}</div>
      },
      {
        title: 'Processing?',
        width: 1,
        render: (item) => item.status_id_001 || item.status_reference > item.ref_001
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Pending Auth?',
        width: 1,
        render: (item, index) => {
          // console.log('item', item.is_update, item.status === 'client-pending-auth')
          return (
            item.status_reference >= item.ref_002
            ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}>
              <Icon type='check-circle' theme='filled' />
            </div>
            : item.status_reference < item.ref_001
              ? <div style={{ color: '#ccc', fontSize: '11pt' }}>
                <Icon type='close-circle' theme='filled' />
              </div>
              : item.is_update && item.status === 'client-pending-auth'
                ? <div style={{ color: '#eb9234', fontSize: '11pt', cursor: 'pointer' }} onClick={(e) => this.updateInvStatus(item.ref_002, item, index)}>
                  <Icon type='check-circle' theme='filled' />
                </div>
                : <div style={{ color: '#ffd79e', fontSize: '11pt', cursor: 'pointer' }} onClick={(e) => this.updateInvStatus(item.ref_002, item, index)}>
                  <Icon type='close-circle' theme='filled' />
                </div>
          )
        },
        key: 'status',
        onSort: (key, order) => this.handleSortStatus({[key]: order}, '001')
      },
      {
        title: 'Auth Rejected?',
        width: 1,
        render: (item, index) => {
          // console.log('item', item.is_update, item.status === 'client-rejected')
          return (
            item.status_reference === item.ref_003
            ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}>
              <Icon type='check-circle' theme='filled' />
            </div>
            : item.status_reference >= item.ref_004
              ? null
              : item.status_reference < item.ref_002
              ? <div style={{ color: '#ccc', fontSize: '11pt' }}>
                <Icon type='close-circle' theme='filled' />
              </div>
              : item.is_update && item.status === 'client-rejected'
                ? <div style={{ color: '#eb9234', fontSize: '11pt', cursor: 'pointer' }} onClick={(e) => this.updateInvStatus(item.ref_003, item, index)}>
                  <Icon type='check-circle' theme='filled' />
                </div>
                : <div style={{ color: '#ffd79e', fontSize: '11pt', cursor: 'pointer' }} onClick={(e) => this.updateInvStatus(item.ref_003, item, index)}>
                  <Icon type='close-circle' theme='filled' />
                </div>
          )
        },
        key: 'status',
        onSort: (key, order) => this.handleSortStatus({[key]: order}, '002')
      },
      {
        title: 'Auth Authorised?',
        width: 1,
        render: (item, index) => {
          // console.log('item', item.is_update, item.status === 'client-authed')
          return (
            item.status_reference >= item.ref_004
            ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}>
              <Icon type='check-circle' theme='filled' />
            </div>
            : item.status_reference < item.ref_002
              ? <div style={{ color: '#ccc', fontSize: '11pt' }}>
                <Icon type='close-circle' theme='filled' />
              </div>
              : item.is_update && item.status === 'client-authed'
                ? <div style={{ color: '#eb9234', fontSize: '11pt', cursor: 'pointer' }} onClick={(e) => this.updateInvStatus(item.ref_004, item, index)}>
                  <Icon type='check-circle' theme='filled' />
                </div>
                : <div style={{ color: '#ffd79e', fontSize: '11pt', cursor: 'pointer' }} onClick={(e) => this.updateInvStatus(item.ref_004, item, index)}>
                  <Icon type='close-circle' theme='filled' />
                </div>
          )
        },
        key: 'status',
        onSort: (key, order) => this.handleSortStatus({[key]: order}, ['002', '003'])
      },
      {
        title: 'Claim Submited?',
        width: 1,
        render: (item) => item.status === 'pymt-req-submit' || item.status_reference >= item.ref_006
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>,
        key: 'status',
        onSort: (key, order) => this.handleSortStatus({[key]: order}, '004')
      },
      {
        title: 'To Pay?',
        width: 1,
        render: (item) => item.status === 'pymt-to-pay' || item.status_reference >= item.ref_007
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>,
        key: 'status',
        onSort: (key, order) => this.handleSortStatus({[key]: order}, '006')
      },
      {
        title: 'Closed?',
        width: 1,
        render: (item) => item.status === 'inv-closed'
        ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
        : item.status === 'pymt-partial-paid'
        ? <div style={{ color: '#eb9234', fontSize: '11pt' }}><Icon type='minus-circle' theme='filled' /></div>
        : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>,
        key: 'status',
        onSort: (key, order) => this.handleSortStatus({[key]: order}, ['007', '008'])
      },
      {
        title: 'NDIS Pymt Req',
        width: 1,
        render: (item, index) => <label key={`item${item.id}-${item.ref_id}-pymtreq`} class='check-container'>
          <input
            key={`item${item.id}-${item.ref_id}-pymtreq-ipt`}
            type="checkbox"
            defaultChecked={item.is_proda_checked || item.status_reference >= item.ref_006}
            // checked={item.is_proda_checked || item.status_reference >= item.ref_006}
            disabled={item.is_proda_generated || !(item.status_reference >= item.ref_004 && item.status_reference < item.ref_006)}
            onClick={e => this.updateProdaBox(e, index)}
          />
          <span className="checkmark"></span>
        </label>
      },
      {
        title: 'ABA & Rmt',
        width: 1,
        render: (item, index) => <label key={`item${item.id}-${item.ref_id}-abarmt`} class='check-container'>
          <input
            key={`item${item.id}-${item.ref_id}-abarmt-ipt`}
            type="checkbox"
            defaultChecked={item.status_reference >= item.ref_009}
            disabled={item.is_aba_generated || !(item.status_reference >= item.ref_007 && item.status_reference < item.ref_009)}
            onClick={e => this.updateABABox(e, index)}
          />
          <span className="checkmark"></span>
        </label>
      },
      {
        title: '',
        width: 1,
        render: ({ id, ref_id }) => <div>
          { this.hasAccess(Permissions.INVOICE.INFO.READ)
            ? <Link to={`/invoices/${ref_id}/info`}>
              <div style={{ color: '#D66E00' }}>
                <Tooltip mouseLeaveDelay={0} title='Manage invoice'>
                  <Icon type='form' />
                </Tooltip>
              </div>
            </Link>
            : null }
        </div>
      }
    ]

    const hisColumns = [
      {
        title: 'Receive Date',
        width: 5,
        render: ({ created_at }) => formatter.toShortDate(created_at)
      },
      {
        title: 'Receive Amount',
        width: 7,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },
      {
        title: 'Credit Applied?',
        width: 3,
        render: ({ is_credit_applied }) => is_credit_applied
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Select',
        width: 1,
        render: (item, index) => <label class='check-container'>
          <input
            type="checkbox"
            defaultChecked={item.is_aba_select}
            onClick={e => this.updateABASelectBox(e, item)}
          />
          <span className="checkmark"></span>
        </label>
      },
    ]

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title='Manage Invoices (PM SB)'>
            { <div style={{height: '30px'}} /> }
            {
              this.hasAccess(Permissions.INVOICE.MGMT.CREATE) && isShowUpdateButton
                ? <div className='btn' onClick={this.preCheckUpdateStatus}>
                  Update Status
                </div>
                : null
            }
            {
              this.hasAccess(Permissions.INVOICE.MGMT.CREATE) && isShowGenerateProda
                ? <div className='btn' onClick={this.preCheckUpdateProda}>
                  Generate Pymt Req
                </div>
                : null
            }

            {
              this.hasAccess(Permissions.INVOICE.MGMT.CREATE) && isShowGenerateABA
                ? <div className='btn' onClick={this.preCheckUpdateAba}>
                  Generate ABA/Remittance
                </div>
                : null
            }
          </Page.Header>

          <Loading loading={loadingUpdate} blur>
            { this.hasAccess(Permissions.INVOICE.MGMT.LIST)
              ? <div className='task-list'>
                <Row gutter={12}>
                  <Col lg={10}>
                    <ControlLabel>Date, Participant, Provider, Invoice Number, Status</ControlLabel>
                    <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} value={searchText} isSearching={searching} />
                  </Col>
                  <Col lg={10}>
                    <div style={{marginLeft: '12.5pt'}}>
                      <ControlLabel>Invoices Completion</ControlLabel>
                    </div>
                    <Radio.Group onChange={this.filterInvoice} value={filterParam} style={{ marginLeft: 20 }}>
                      <Radio.Button value={InvoiceFilterType.FILTER_INCOMPLETED}>Incompleted</Radio.Button>
                      <Radio.Button value={InvoiceFilterType.FILTER_CLOSED}>Closed</Radio.Button>
                      <Radio.Button value={InvoiceFilterType.FILTER_ALL}>All</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>

              </div>
              : null }

            <Spin spinning={loadingList} blur>
              <Skeleton loading={loading} active>
                <List cols={columns} rows={list} wide />
              </Skeleton>

              { !loading
                ? <Pager
                  current={currentPage}
                  size={pageSize}
                  total={total}
                  totalText={`Total ${total} invoices`}
                  onChange={(e) => this.changePage(e)}
                  style={{ marginTop: '15px' }}
                />
                : null }

              <Modal
                width='80%'
                title='Generate ABA / Remittance'
                visible={isShowABAModal}
                onCancel={() => this.handleAbaModal(false)}
                footer={!loadingAbaDetail
                  ? [
                    <Button key='close' ghost disabled={loadingUpdateAba} onClick={() => this.handleAbaModal(false)}>Close</Button>,
                    <Button key='submit' disabled={loadingUpdateAba} onClick={() => this.preCheckGenerateAba()}>Submit</Button>
                  ]
                : null }
              >
                <Spin spinning={loadingAbaDetail || loadingUpdateAba} blur>
                  { loadingAbaDetail
                    ? <div>Getting Invoice Item Detail...</div>
                    : <Form>
                      <div className='inv-title'>First, select Payment Date for ABA</div>
                      <FormItem {...formItemLayout} label='ABA Payment Date'>
                        {getFieldDecorator('payment_date', {
                          initialValue: formatter.toMoment(new Date()),
                          rules: [
                            { required: true, message: ' ' },
                            { validator: this.validateAbaDate }
                          ]
                        })(
                          <DateTimePicker showTime={false} />
                        )}
                      </FormItem>
                      <div className='inv-title'>Then, select received amounts to include in ABA / Remittance</div>
                      <div className='inv-section'>
                      { invoiceABAList.map((e, index) => {
                        let header = null
                        if (e.inv_id !== lastInvId) {
                          header = (
                            <div>
                              <div className='inv-header'>{ e.invoice_number } - { formatter.toShortDate(e.invoice_date) } ({e.client_name})</div>
                              <div className='inv-header-sub'>{ e.cat_item_name } ({e.cat_item_identifier})</div>
                            </div>
                          )
                        } else {
                          header = (
                            <div>
                              <div className='inv-header-sub'>{ e.cat_item_name } ({e.cat_item_identifier})</div>
                            </div>
                          )
                        }

                        lastInvId = e.inv_id
                        // console.log('invoice aba', e)
                        return (
                          <div>
                            { header }
                            <List cols={hisColumns} rows={e.receive_history} />
                          </div>
                        )
                      })}
                      </div>
                    </Form>}
                </Spin>
              </Modal>
            </Spin>
          </Loading>

        </Page.Content>
      </Page.Body>
    )
  }

  refetchInvoices = () => {
    const { currentPage, isLoaded, filter, filterParam, searchText, sort, sortRaw } = this.state
    this.fetchInvoices({ currentPage, isLoaded, filter, filterParam, searchText, sort, sortRaw })
  }

  handleSortStatus (sort, statusType) {
    const { statusList, searchText, filterParam } = this.state

    /**
     * statusType could be in array mode or text mode
     * when in array mode, the status need to have "or" condition to make sure multiple status are enlisted
     * when in text mode, only single status value is sorted
     */
    if (validator.isNotEmptyArray(statusType)) {
      let s = []
      let statusText = ''

      for (let i = 0; i < statusType.length; i++) {
        const sp = statusList.find(e => e.reference === statusType[i])

        if (sp && sp.id) {
          s.push(sp)
          statusText += `${statusText ? ' or ': ''}status = '${sp.value}'`
        }
      }

      let rawText = ''
      let newSort = Object.assign({}, sort)

      for (let v in newSort) {
        if (newSort[v] === 0) {
          newSort = {}
        } else {
          const sort = newSort[v] === 1 ? 'asc' : 'desc'
          const seq1 = newSort[v] === 1 ? 1 : 2
          const seq2 = newSort[v] === -1 ? 1 : 2
          rawText = `case when ${statusText} then ${seq1} else ${seq2} end, status_reference ${sort}`
        }
      }

      if (!validator.isNotEmptyObject(newSort)) {
        newSort = {}
        rawText = ''
      }

      this.redirectUrl({ page: 1, q: searchText, status: filterParam })
      this.setState({ currentPage: 1, sort: newSort, sortRaw: rawText }, () => {
        this.refetchInvoices()
      })
    } else if (statusType) {
      const s = statusList.find(e => e.reference === statusType)

      if (s && s.id) {
        let rawText = ''
        let newSort = Object.assign({}, sort)
        for (let v in newSort) {
          if (newSort[v] === 0) {
            newSort = {}
          } else {
            const seq1 = newSort[v] === 1 ? 1 : 2
            const seq2 = newSort[v] === -1 ? 1 : 2
            rawText = `case when status = '${s.value}' then ${seq1} else ${seq2} end, created_at desc, invoice_date desc, invoice_number desc`
          }
        }

        if (!validator.isNotEmptyObject(newSort)) {
          newSort = {}
          rawText = ''
        }

        this.redirectUrl({ page: 1, q: searchText, status: filterParam })
        this.setState({ currentPage: 1, sort: newSort, sortRaw: rawText }, () => {
          this.refetchInvoices()
        })
      }
    }
  }

  changePage = (currentPage) => {
    const { filter, filterParam, isLoaded, searchText, sort, sortRaw } = this.state
    this.redirectUrl({ page: currentPage, q: searchText, status: filterParam })
    this.fetchInvoices({ currentPage, filter, filterParam, isLoaded, searchText, sort, sortRaw })
  }

  fetchInvoices = async ({ currentPage = 1, filter = {}, sort = {}, sortRaw = '', isLoaded = true, searchText, filterParam }) => {
    if (!this.hasAccess(Permissions.INVOICE.MGMT.LIST)) return

    try {
      if (isLoaded) {
        this.setState({ loadingList: true, currentPage })
      } else {
        this.setState({ loading: true, currentPage })
      }

      if (filterParam === InvoiceFilterType.FILTER_INCOMPLETED) {
        filter.status = { condition: '<>', value: 'inv-closed' }
      } else if (filterParam === InvoiceFilterType.FILTER_CLOSED) {
        filter.status = { condition: '=', value: 'inv-closed' }
      } else if (filterParam === InvoiceFilterType.FILTER_ALL) {
        filter.status = {}
      }

      const r = await invoiceService.listByPage(currentPage, pageSize, filter, sort, sortRaw, filter.$and ? '' : searchText)
      if (r && r.list) {
        this.setState({ list: r.list, total: r.total, isLoaded: true, loading: false, loadingList: false, loadingUpdate: false, searching: false })
      } else {
        notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
        this.setState({ loading: false, loadingList: false, loadingUpdate: false, searching: false })
      }

      window.scrollTo(0, 0)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
    }
  }

  fetchInvStatusList = async() => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'invoice-status' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    if (settings && validator.isNotEmptyArray(settings.list)) {
      this.setState({
        statusList: settings.list.filter(item => item.identifier === 'invoice-status')
      })
    }
  }

  filterInvoice = (e) => {
    const { filter, loading, searchText, sort, sortRaw } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.redirectUrl({ page: 1, q: searchText, status: filterValue })
    this.fetchInvoices({ currentPage: 1, filter, filterParam: filterValue, isLoaded: false, loading, searchText, sort, sortRaw })
  }

  /** update status section */

  // validate aba payment date
  validateAbaDate = (rule, value, callback) => {
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`ABA Payment Date is required`))
    } else {
      const m = moment(new Date()).startOf('day')
      const v = moment.isMoment(value) ? value : moment(value)

      if (v.isBefore(m)) {
        callback(new Error(`ABA Payment Date cannot be earlier than today.`))
      } else {
        callback()
      }
    }
  }

  // update inv status by state
  updateInvStatus = (e, item, index) => {
    const { list, statusList, updateItems } = this.state

    if (!this.hasAccess(Permissions.INVOICE.MGMT.CREATE)) return

    // update on list item
    const itm = list[index]
    itm.prev_status = itm.prev_status ? itm.prev_status : itm.status
    itm.prev_status_reference = itm.prev_status_reference ? itm.prev_status_reference : itm.status_reference
    itm.is_update = true

    // const statIdx = statusList.findIndex(f => f.reference === e)
    // const prevStatIdx = statusList.findIndex(f => f.reference === itm.prev_status_reference)
    // const stat = statIdx > -1 ? statusList[statIdx] : {}
    // const prevStat = prevStatIdx > -1 ? statusList[prevStatIdx] : {}

    const stat = statusList.find(f => f.reference === e) || {}
    const prevStat =  statusList.find(f => f.reference === itm.prev_status_reference) || {}
    // console.log('update inv stats 2', itm.status, stat.value)

    if (itm.status === stat.value) {
      itm.status = prevStat.value
      itm.status_id = prevStat.id
    } else if (itm.status === prevStat.value) {
      itm.status = stat.value
      itm.status_id = stat.id
    } else {
      itm.status = stat.value
      itm.status_id = stat.id
    }

    // console.log('update inv stats 2', itm.status, itm.status_id)

    list.splice(index, 1, itm)

    // update on update list item
    const upIdx = updateItems.findIndex(e => e.id === itm.id)
    const upData = {
      id: itm.id,
      prev_status: itm.prev_status,
      status: itm.status,
      status_id: itm.status_id,
      invoice_number: itm.invoice_number,
      invoice_date: itm.invoice_date,
      string_invoice_date: itm.string_invoice_date,
      client_name: itm.client_name,
      provider_name: itm.provider_name,
      has_update: (itm.status !== itm.prev_status)
    }
    if (upIdx > -1) {
      updateItems.splice(upIdx, 1, upData)
    } else {
      updateItems.push(upData)
    }

    const isShowUpdateButton = updateItems.slice().filter(e => e.has_update === true).length > 0

    this.setState({ list, isShowUpdateButton, updateItems })
  }

  // precheck inv status
  preCheckUpdateStatus = () => {
    const { updateItems, statusList } = this.state
    const { handleSave } = this

    const finalItems = updateItems.filter(e => e.has_update === true)
    const hasUpdate = finalItems.length > 0

    if (!hasUpdate) {
      notify.error('Unable to update invoice status', 'No invoice is going to be updated.')
    } else {
      confirm({
        title: 'Proceed To Invoice Status Update?',
        content: (
          <div>
            <p>The following invoice{finalItems.length === 1 ? ' is' : 's are'} about to update the status:</p>
            <ul>
              { finalItems.map(itm => {
                const prevStats = statusList.find(e => e.value === itm.prev_status)
                const curStats = statusList.find(e => e.value === itm.status)

                return (
                  <li>
                    <div style={{ fontSize: '13px' }}>
                      <strong>{itm.invoice_number} ({itm.string_invoice_date})</strong><br />
                      <span><span style={{color: '#f6ad32'}}>{prevStats.name}</span> => <span style={{color: '#4fbc85'}}>{curStats.name}</span></span>

                      {/** TODO: check on reference code */}
                      { curStats && curStats.reference === '004'
                        ? <div style={{color: '#EC7063', fontWeight: '600', fontSize: '10px', lineHeight: '10px'}}>
                          * Once the invoice is updated, all items under invoice are unable to remove or modify anymore.
                        </div>
                        : null }
                    </div>
                  </li>
                )
              })}
            </ul>


          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks
          handleSave(finalItems)
        },
        onCancel () {
        }
      })
    }
  }

  handleSave = async (finalItems) => {
    try {
      let data = []
      for (let i = 0; i < finalItems.length; i++) {
        const itm = finalItems[i]
        data.push({
          id: itm.id,
          status: itm.status,
          status_id: itm.status_id
        })
      }

      this.setState({ loadingUpdate: true })
      // console.log('save 0', data)
      const r = await invoiceService.updateStats(data)

      // console.log('save 1', r)

      if (r && r.count) {
        notify.success('Update Invoice Status successfully', 'Invoice Status updated successfully.')
        this.setState({ updateItems: [], isShowUpdateButton: false })
      } else {
        notify.error('Some issues happened during update', formatter.toErrorMessage(r.errors))
      }
    } catch (e) {
      notify.error('Unable to update status successfully', 'Unable to update invoice status successfully. Please try again later.')
    }

    this.refetchInvoices()
  }

  /** select proda section */
  updateProdaBox = (e, index) => {
    const { list, updateProdaItems } = this.state

    const itm = list[index]
    itm.is_update_proda = e.target.checked

    list.splice(index, 1, itm)

    // update on update list item
    const upIdx = updateProdaItems.findIndex(e => e.id === itm.id)
    const upData = {
      id: itm.id,
      is_update_proda: itm.is_update_proda,
      invoice_number: itm.invoice_number,
      invoice_date: itm.invoice_date,
      string_invoice_date: itm.string_invoice_date,
      client_name: itm.client_name,
      provider_name: itm.provider_name
    }
    if (upIdx > -1) {
      updateProdaItems.splice(upIdx, 1, upData)
    } else {
      updateProdaItems.push(upData)
    }

    const isShowGenerateProda = updateProdaItems.slice().filter(e => e.is_update_proda === true).length > 0

    this.setState({ list, isShowGenerateProda, updateProdaItems })
  }

  preCheckUpdateProda = () => {
    const { updateProdaItems, statusList } = this.state
    const { handleUpdateProda } = this

    const finalItems = updateProdaItems.filter(e => e.is_update_proda === true)
    const hasUpdate = finalItems.length > 0

    if (!hasUpdate) {
      notify.error('Unable to generate Payment Request', 'No invoice is selected.')
    } else {
      confirm({
        title: 'Proceed To generate Payment Request?',
        content: (
          <div>
            <p>The following invoice{finalItems.length === 1 ? ' is' : 's are'} about to be enlisted for Payment Request:</p>
            <ul>
              { finalItems.map(itm => {
                return (
                  <li>
                    <div style={{ fontSize: '13px' }}>
                      <strong>{itm.invoice_number} ({itm.string_invoice_date})</strong><br />
                    </div>
                  </li>
                )
              })}
            </ul>


          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks
          handleUpdateProda(finalItems)
        },
        onCancel () {
        }
      })
    }
  }

  handleUpdateProda = async (finalItems) => {
    try {
      let data = []
      for (let i = 0; i < finalItems.length; i++) {
        const itm = finalItems[i]
        data.push({
          id: itm.id,
          is_update_proda: itm.is_update_proda
        })
      }

      this.setState({ loadingUpdate: true })
      const r = await invoiceService.updateProda(data)

      if (r && r.count) {
        notify.success('Generate PRODA file successfully', 'PRODA file is generated successfully.')
        this.setState({ updateProdaItems: [], isShowGenerateProda: false })

        if (r.file && r.file.id) {
          await exportFile.fetchFiles(r.file.id, r.file.type, r.file.created_at)
        }
      } else {
        notify.error('Some issues happened during generate file', formatter.toErrorMessage(r.errors))
      }
    } catch (e) {
      console.log('save error', e)
      notify.error('Unable to generate file successfully', 'Unable to generate file successfully. Please try again later.')
    }

    this.refetchInvoices()
  }

   /** select ABA section */
   updateABABox = (e, index) => {
    const { list, updateABAItems } = this.state

    const itm = list[index]
    itm.is_update_aba = e.target.checked

    list.splice(index, 1, itm)

    // update on update list item
    const upIdx = updateABAItems.findIndex(e => e.id === itm.id)
    const upData = {
      id: itm.id,
      is_update_aba: itm.is_update_aba,
      invoice_number: itm.invoice_number,
      invoice_date: itm.invoice_date,
      string_invoice_date: itm.string_invoice_date,
      client_name: itm.client_name,
      provider_name: itm.provider_name
    }
    if (upIdx > -1) {
      updateABAItems.splice(upIdx, 1, upData)
    } else {
      updateABAItems.push(upData)
    }

    const isShowGenerateABA = updateABAItems.slice().filter(e => e.is_update_aba === true).length > 0

    this.setState({ list, isShowGenerateABA, updateABAItems })
  }

  preCheckUpdateAba = () => {
    const { updateABAItems, statusList } = this.state
    const { navigateToAbaPage } = this

    const finalItems = updateABAItems.filter(e => e.is_update_aba === true)
    const hasUpdate = updateABAItems.length > 0

    if (!hasUpdate) {
      notify.error('Unable to generate ABA/Remittance', 'No invoice is selected.')
    } else {
      this.handleAbaModal(true, finalItems)
      // confirm({
      //   title: 'Proceed To generate ABA/Remittance?',
      //   content: (
      //     <div>
      //       <p>The following invoice{finalItems.length === 1 ? ' is' : 's are'} about to be enlisted for ABA/Remittance:</p>
      //       <ul>
      //         { finalItems.map(itm => {
      //           return (
      //             <li>
      //               <div style={{ fontSize: '13px' }}>
      //                 <strong>{itm.invoice_number} ({itm.string_invoice_date})</strong><br />
      //               </div>
      //             </li>
      //           )
      //         })}
      //       </ul>


      //     </div>
      //   ),
      //   okText: 'OK',
      //   cancelText: 'Cancel',
      //   onOk () {
      //     // eslint-disable-next-line no-lone-blocks
      //     navigateToAbaPage(finalItems)
      //   },
      //   onCancel () {
      //   }
      // })
    }
  }

  handleAbaModal = (isShowABAModal, finalItems) => {
    this.setState({ isShowABAModal }, () => {
      this.getAbaInfo(isShowABAModal, finalItems)
    })
  }

  /** generate ABA modal */

  getAbaInfo = async (isShowABAModal, finalItems) => {
    this.setState({ loadingAbaDetail: true })
    let ids = []
    // console.log('get aba info 0', finalItems)

    if (isShowABAModal) {
      for (let i = 0; i < finalItems.length; i++) {
        const itm = finalItems[i]
        ids.push(itm.id)
      }

      const r = await invoiceService.getAbaInvoiceInfo({ ids })
      if (r && validator.isNotEmptyArray(r)) {
        let updateABARcvItems = []

        for (let i = 0; i < r.length; i++) {
          const history = r[i].receive_history
          const upData = history.filter(e => e.is_aba_select === true)
          const upData2 = upData.map(e => ({id: e.id, inv_item_id: e.inv_item_id, is_aba_select: e.is_aba_select}))
          updateABARcvItems = updateABARcvItems.concat(upData2)
        }
        this.setState({ invoiceABAList: r, loadingAbaDetail: false, updateABARcvItems })
      } else {
        this.setState({ loadingAbaDetail: false })
      }
    } else {
      this.setState({ loadingAbaDetail: false, invoiceABAList: [], updateABARcvItems: [] })
    }
  }

  updateABASelectBox = (e, item) => {
    const { updateABARcvItems } = this.state

    // update on update list item
    const upIdx = updateABARcvItems.findIndex(e => e.id === item.id)
    const upData = {
      id: item.id,
      inv_item_id: item.inv_item_id,
      is_aba_select: e.target.checked
    }
    if (upIdx > -1) {
      updateABARcvItems.splice(upIdx, 1, upData)
    } else {
      updateABARcvItems.push(upData)
    }
    this.setState({ updateABARcvItems })
  }

  preCheckGenerateAba = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { updateABARcvItems, statusList } = this.state
    const { handleABASave } = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        let hasUpdate = false
        for (let i = 0; i < updateABARcvItems.length; i++) {
          const itm = updateABARcvItems[i]
          if (itm.is_aba_select === true) {
            hasUpdate = true
            updateABARcvItems[i].has_aba_select = true
          } else {
            updateABARcvItems[i].has_aba_select = false
          }
        }

        const finalItems = updateABARcvItems.filter(e => e.has_aba_select === true)

        if (!hasUpdate) {
          notify.error('Unable to generate ABA/Remittance', 'No item is selected.')
        } else {
          confirm({
            title: 'Proceed To generate ABA/Remittance?',
            content: (
              <div>
                <p>{updateABARcvItems.length} transaction{updateABARcvItems.length === 1 ? ' is': 's are'} selected.</p>
                <p>Confirm?</p>
              </div>
            ),
            okText: 'OK',
            cancelText: 'Cancel',
            onOk () {
              handleABASave(finalItems, values)
            },
            onCancel () {
            }
          })
        }
      }
    })

  }

  handleABASave = async (finalItems, values) => {
    try {
      let data = []
      for (let i = 0; i < finalItems.length; i++) {
        const itm = finalItems[i]
        data.push({
          id: itm.id,
          inv_item_id: itm.inv_item_id,
          is_aba_select: itm.is_aba_select
        })
      }

      const body = {
        data,
        payment_date: values.payment_date
      }

      this.setState({ loadingUpdateAba: true })
      const r = await invoiceService.updateAba(body)

      if (r && r.count) {
        notify.success('Generate ABA/Remittance files successfully', 'ABA/Remittance files are generated successfully.')
        this.setState({ updateABARcvItems: [], isShowABAModal: false, loadingUpdateAba: false })

        if (r.file && r.file.id) {
          if (r.file.ref_id) {
            this.preCheckNavigateToExportPage(r.file)
          }

          await exportFile.fetchFiles(r.file.id, ExportType.TYPE_ABA, r.file.created_at)
        }
      } else {
        notify.error('Some issues happened during generate file', formatter.toErrorMessage(r.errors))
        this.setState({ loadingUpdateAba: false })
      }
    } catch (e) {
      console.log('save error', e)
      notify.error('Unable to generate file successfully', 'Unable to generate file successfully. Please try again later.')
      this.setState({ loadingUpdateAba: false })
    }

    this.refetchInvoices()
  }

  preCheckNavigateToExportPage = (file) => {
    const { navigateToExportPage } = this

    confirm({
      title: 'Do you want to send Remittance Advice(s) now?',
      content: (
        <div>
          <p>Navigate to Invoice Export Page and send Remittance Advice(s).</p>
          <p>Confirm?</p>
        </div>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk () {
        navigateToExportPage(file)
      },
      onCancel () {
      }
    })
  }

  navigateToExportPage = (file) => {
    window.open(`/invoices-export/aba-remittance?export_id=${file.ref_id}&export_type=rmt`)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog,
  updateInvoiceAbaList
}

const mapStateToProps = (state) => {
  return { ...state.Invoice }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoiceManage))

