import React, { Component } from 'react'
import { connect } from 'react-redux'
import { creditClientService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'

// UI
import { Loading, List, Panel } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, validator } from '../../../util'

import './styles.css'

export class ClientCredit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientCredit: [],
      loading: false,
      loadingList: false
    }
  }

  componentDidMount () {
    this.fetchCredit()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchCredit(nextProps.clientId)
      }
    }
  }

  render () {
    const { clientRefId } = this.props
    const { clientCredit, loading, loadingList } = this.state

    const listColumns = [
      {
        title: 'Entered At',
        width: 5,
        render: ({ created_at }) => <span>{formatter.toShortDate(created_at)}</span>
      },
      {
        title: 'Support Budget',
        width: 8,
        render: ({ cat_name }) => <span>{cat_name}</span>
      },
      {
        title: 'Credited',
        width: 5,
        render: ({ amount }) => <div>{formatter.toPrice(amount)}</div>
      },

      {
        title: 'Remaining',
        width: 5,
        render: ({ remaining_amount }) => <div>{formatter.toPrice(remaining_amount)}</div>
      },

      {
        title: '',
        width: 1,
        render: (item) => <div className='action-buttons'>
          {console.log('acts', item)}
          { this.hasAccess(Permissions.PARTICIPANT.CREDIT.UPDATE)
            ? <Tooltip mouseLeaveDelay={0} title='Edit'>
              <a href={`/credits/${item.ref_id}/info`} rel='noopener noreferrer' target='_blank'>
                <Icon type='form' />
              </a>
            </Tooltip>
            : null }
        </div>
      }
    ]


    return (
      <Loading loading={loading} blur>
        <Panel title={'Credits'}
          subtitle={ this.hasAccess(Permissions.PARTICIPANT.CREDIT.CREATE)
            ? <div className='btn'>
            <a href={`/credits/add?cid=${clientRefId}`} rel='noopener noreferrer' target='_blank'>
              Add Credit
            </a>
            </div>
            : null
          }
        >
          <div className='client-credit-list'>
            <Skeleton loading={loadingList} active>
              { clientCredit && validator.isNotEmptyArray(clientCredit)
                ? clientCredit.map (m => {
                  const title = `${m.booking_number} (${formatter.toShortDate(m.period_start_date)} - ${formatter.toShortDate(m.period_end_date)})`
                  return <div className='list-item'>
                    <Row>
                      <Col xs={8} sm={10} lg={12} xl={12} >
                        <span className={`title`}>{title} <span className='expand-button' onClick={() => this.handleBudgetHideShow(m)}><Icon type={m.is_expand ? 'up-circle' : 'down-circle'} style={{fontSize: '12pt', marginLeft: '20pt', color: '#444'}} /></span></span>
                      </Col>
                      <Col xs={16} sm={14} lg={12} xl={12} />
                    </Row>
                    <Row>
                    <div>
                      { validator.isNotEmptyArray(m.items)
                        ? m.items.map((mm, index) => (
                          <div key={`acng${index}`}>
                            <Row style={{margin: '20px 30px'}}>
                              <Col lg={7}>
                                <div className='total-title'>{mm.report_group_name}</div>
                              </Col>
                              <Col lg={7}>
                                <div className='total-amount'>Total Credited: {formatter.toPrice(mm.total_amount)}</div>
                              </Col>
                              <Col lg={7}>
                                <div className='total-amount'>Total Remaining: {formatter.toPrice(mm.total_remaining_amount)}</div>
                              </Col>
                              <Col lg={3} />
                            </Row>
                            { m.is_expand
                              ? <Row style={{margin: '10px 30px 10px'}}>
                                <List cols={listColumns} rows={mm.items} />
                              </Row>
                              : null }
                          </div>
                        ))
                        : null }
                      </div>
                    </Row>
                    {/* <Row style={{margin: '20px 30px -16px'}}>
                      <Col lg={7}>
                        <div className='total-amount'>Total Allocated: {formatter.toPrice(m.budget_total)}</div>
                      </Col>
                      <Col lg={7}>
                        <div className='total-amount'>Total Remaining: {formatter.toPrice(m.remaining_total)}</div>
                      </Col>
                      <Col lg={10}>
                      </Col>
                    </Row> */}
                    {/* { m.is_expand
                      ? <div className='row-section'>
                        { m.items && validator.isNotEmptyArray(m.items)
                          ? <List cols={listColumns} rows={m.items} />
                          : <div>No support categories and items added to the booking.</div>}
                      </div>
                      : null } */}

                  </div>
                })
                : null }

            </Skeleton>
          </div>
        </Panel>
      </Loading>
    )
  }

  fetchCredit = async (cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.CREDIT.LIST)) return

    const clientId = cid || this.props.clientId
    this.setState({loadingList: true})

    if (!clientId) return

    const credits = await creditClientService.getClientAllCredits(clientId)

    this.setState({
      loadingList: false,
      clientCredit: validator.isNotEmptyArray(credits) ? credits : []
    })
  }

  handleBudgetHideShow (item) {
    const { clientCredit } = this.state
    const findIndex = clientCredit.findIndex(e => e.budget_id === item.budget_id)
    if (findIndex > -1) {
      item.is_expand = !item.is_expand
      clientCredit.splice(findIndex, 1, item)
      this.setState(clientCredit)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientCredit))
