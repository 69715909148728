export const FETCH_ADDED_LINK_REQUESTS = 'FETCH_ADDED_LINK_REQUESTS'
export const FETCH_REMOVED_LINK_REQUESTS = 'FETCH_REMOVED_LINK_REQUESTS'
export const FETCHING_LINK_REQUESTS = 'FETCHING_LINK_REQUESTS'
export const LINK_REQUESTS_FETCHED = 'LINK_REQUESTS_FETCHED'

export const fetchAddedLinkRequests = (params) => {
  return Object.assign({
    type: FETCH_ADDED_LINK_REQUESTS
  }, params)
}

export const fetchRemovedLinkRequests = (params) => {
  return Object.assign({
    type: FETCH_REMOVED_LINK_REQUESTS
  }, params)
}

export const fetchingLinkRequests = (loading) => {
  return {
    type: FETCHING_LINK_REQUESTS,
    loading
  }
}

export const clientsFetched = (clients) => {
  return {
    type: LINK_REQUESTS_FETCHED,
    clients
  }
}
