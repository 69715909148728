import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/settings/main-log'

export default {
  getLatest () {
    return request(`${url}/latest`)
  }
}
