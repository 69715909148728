import React, { Component } from 'react'
import { connect } from 'react-redux'
import { settingGSTItemService, settingGSTRateService } from '../../../../services'
import { setRefreshActivityLog } from '../../../../states/actions'
import moment from 'moment-timezone'

// UI
import { Loading, Panel, Page } from '../../../../components'
import { Permissions } from '../../../../constants'
import notify from '../../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import { auth, formatter, validator } from '../../../../util'

import DatePicker from 'antd/lib/date-picker'
import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 7 },
  wrapperCol: { sm: 14, md: 14, lg: 16 }
}

export class SettingGSTRateEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      detailItems: [],
      item: { values: [] },
      loading: false,
      loadingDetails: false,
      loadingForm: false,
      showEdit: true,
      showSave: false
    }
  }

  componentDidMount () {
    this.fetchDetailItems()
    if (this.isEdit()) {
      this.fetchGSTRateSet()
    }
  }

  render () {
    const { history } = this.props
    const { item, loading, loadingForm, showEdit, showSave } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit()
            ? `Add New GST Set`
            : loading
              ? <div className='client-panel-header-skeleton' style={{ width: '100%' }} />
              : `GST Set - ${item.name}`}
          >
            { showEdit && this.isEdit() && this.hasAccess(Permissions.SETTING.GST.UPDATE)
                ? <div className='btn' onClick={this.handleEditButton}>
                  Edit
                </div>
                : null }

            { ((!this.isEdit() && this.hasAccess(Permissions.SETTING.GST.CREATE)) ||
                (showSave && this.isEdit() && this.hasAccess(Permissions.SETTING.GST.UPDATE)))
                ? <div className='btn' onClick={this.handleSave}>
                  { loadingForm ? <div><Icon type="loading" /> &nbsp;Saving...</div> : 'Save' }
                </div>
                : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          { this.renderForm() }
        </Page.Content>
      </Page.Body>
    )
  }

  fetchDetailItems = async () => {
    this.setState({ loadingDetails: true })

    const r = await settingGSTItemService.getAll()

    if (validator.isArray(r)) {
      this.setState({ detailItems: r, loadingDetails: false })
    } else {
      this.setState({ loadingDetails: false })
    }
  }

  fetchGSTRateSet = async () => {
    this.setState({ loading: true })

    const id = this.getId()

    if (!this.hasAccess(Permissions.SETTING.GST.READ)) return

    const item = await settingGSTRateService.getRef(id)

    if (item && item.id) {
      this.setState({
        item,
        loading: false
      })
    }
  }

  renderForm = () => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { detailItems, item, loading, loadingDetails, loadingForm } = this.state

    const isRateSelected = getFieldValue('rate_id')
    const id = this.getId()

    return (
      <div>
        <Form>
          <Loading loading={loadingForm} blur>
            <Skeleton loading={loading} active>
              <Panel title='Details'>
                <Row gutter={16}>
                  <Col lg={10}>
                    <FormItem label='Set Name' {...formItemLayout}>
                      {getFieldDecorator('name', {
                        initialValue: item.name || '',
                        rules: [
                          { min: 2, message: 'Name must be between 2 and 128 characters' },
                          { max: 128, message: 'Name must be between 2 and 128 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter name' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>

                    <FormItem label='Description' {...formItemLayout}>
                      {getFieldDecorator('description', {
                        initialValue: item.description || '',
                        rules: [
                          { whitespace: true, message: 'Please enter description' }
                        ]
                      })(
                        <TextArea rows={2}/>
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={2}>
                    <FormItem label='' {...formItemLayout}>
                      {getFieldDecorator('active', {
                        initialValue: item.active || false,
                        valuePropName: 'checked',
                      })(
                        <Switch
                          checkedChildren='Enable'
                          unCheckedChildren='Disable'
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col lg={1} />
                  <Col lg={6}>
                    <FormItem label='Start Date' {...formItemLayout}>
                      {getFieldDecorator('start_date', {
                        initialValue: item.start_date ? formatter.toMoment(item.start_date) : null,
                        rules: [
                          { required: true, message: ' ' },
                          { validator: this.validateDate }
                        ]
                      })(
                        <DatePicker
                          defaultPickerValue={moment(new Date())}
                          format={dateFormat}
                          placeholder={'Select Date'}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={6}>
                    <FormItem label='End Date' {...formItemLayout}>
                      {getFieldDecorator('end_date', {
                        initialValue: item.end_date ? formatter.toMoment(item.end_date) : null,
                        rules: [
                          { required: true, message: ' ' },
                          { validator: this.validateDate }
                        ]
                      })(
                        <DatePicker
                          defaultPickerValue={moment(new Date())}
                          format={dateFormat}
                          placeholder={'Select Date'}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={10} />
                </Row>
              </Panel>

              <Panel title='GST Values'>
                { loadingDetails
                  ? <div className='set-title'>Getting GST Items...</div>
                  : validator.isNotEmptyArray(detailItems)
                  ? detailItems.map(e => {
                    const { id, is_editable, is_value_zero, name } = e
                    const idf = `_${id}`
                    const valueData = item.values && validator.isNotEmptyArray(item.values)
                      ? item.values.find(e => e.item_id === id) || {}
                      : {}

                    return (
                      <Row gutter={8}>
                        <Col lg={4}>
                          <div className='set-input-text'>
                            { name }
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className='set-input'>
                            <FormItem
                              style={{textAlign: 'right'}}
                            >
                              {getFieldDecorator(`value${idf}`, {
                                initialValue: (!item || !item.id) && is_value_zero ? 0 : valueData.value,
                                rules: [
                                  { validator: (r, v, c) => this.validateValues(r, v, c) }
                                ]
                              })(
                                <Input disabled={!is_editable && is_value_zero} addonAfter={'%'} />
                              )}
                            </FormItem>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className='set-input hidden'>
                            <FormItem
                              style={{textAlign: 'right'}}
                            >
                              {getFieldDecorator(`id${idf}`, {
                                initialValue: valueData ? valueData.id : null
                              })(
                                <Input disabled={true} />
                              )}
                            </FormItem>
                          </div>
                        </Col>
                      </Row>
                    )
                  })
                  : <div className='set-title'>Please add at least one GST Item.</div>}
              </Panel>
            </Skeleton>
          </Loading>
        </Form>
      </div>
    )
  }

  validateDate = (rule, value, callback) => {
    const { form } = this.props
    const field = rule.field

    const startDate = field === 'start_date' ? value : form.getFieldValue('start_date')
    const endDate = field === 'end_date' ? value : form.getFieldValue('end_date')
    const anotherField = field === 'start_date'
      ? 'end_date'
      : field === 'end_date'
        ? 'start_date'
        : ''
    const title = field === 'start_date'
      ? 'start date'
      : field === 'end_date'
        ? 'end date'
        : 'date'

    if (value === null) {
      callback(new Error(`Please select set ${title}`))
    } else {
      const mStartDate = moment.isMoment(startDate) ? startDate.clone().startOf('date') : moment(startDate).startOf('date')
      const mEndDate = moment.isMoment(endDate) ? endDate.clone().endOf('date') : moment(endDate).endOf('date')

      if (mStartDate.isAfter(mEndDate, 'date')) {
        callback(new Error('Service Start Date must be before Service End Date'))
      } else {
        callback()
      }
    }

    if (anotherField) {
      form.validateFields([`${anotherField}`])
    }
  }

  validateValues = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback(new Error(`Please enter value`))
    } else {
      const v = validator.isDecimal(value)
      if (!v) {
        callback(new Error(`Value is not number or decimal format`))
      } else {
        value = parseFloat(value)

        if (value < 0 || value > 100) {
          callback(new Error(`Value must be within 0 - 100`))
        } else {
          callback()
        }
      }
    }
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { loadingDetails, loadingForm, item, detailItems } = this.state

    if (loadingForm || loadingDetails) return

    validateFields(async(errors, values) => {
      if (!errors) {
        try {
          let r
          this.setState({ loadingForm: true })

          const itemValues = []

          for (let i = 0; i < detailItems.length; i++) {
            const dc = detailItems[i]
            const idf = `_${dc.id}`

            const v = {
              id: values[`id${idf}`] || undefined,
              value: values[`value${idf}`],
              item_id: dc.id
            }

            itemValues.push(v)
          }

          const body = {
            name: values.name,
            description: values.description,
            active: values.active,
            start_date: values.start_date,
            end_date: values.end_date,
            values: itemValues
          }

          if (this.isEdit() && item && item.id > 0) {
            r = await settingGSTRateService.save(item.id, body)
          } else {
            r = await settingGSTRateService.add(body)
          }

          this.setState({ loadingForm: false })

          if (r && r.id) {
            notify.success('Saved successfully', `GST Rate Set saved successfully.`)

            if (!this.isEdit()) {
              window.location.replace(`/settings/gst/rates/${r.ref_id}`)
            }
          } else {
            notify.error('Unable to save successfully', `Unable to save GST Rate Set successfully. Please try again later.`)
            console.log('save err 2', r)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save GST Rate Set successfully. Please try again later.`)
          console.log('save err 1', e)
        }

        this.setState({ loadingForm: false })
      }
    })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getCatId = () => {
    const { match } = this.props
    const { params } = match
    const { cid } = params
    return cid
  }

  getId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingGSTRateEdit))
