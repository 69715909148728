import { put, takeEvery } from 'redux-saga/effects'
import { FETCHING_INVOICES, FETCH_INVOICES, INVOICES_FETCHED } from '../actions/invoice'
import invoiceService from '../../services/invoice'

function * fetchInvoices ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_INVOICES, loading })
    const invoices = yield invoiceService.listViewByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: INVOICES_FETCHED, invoices, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_INVOICES, loading: false })
    console.error(e)
  }
}

function * watchInvoice () {
  yield takeEvery(FETCH_INVOICES, fetchInvoices)
}

export default watchInvoice
