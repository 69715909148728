import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Panel extends Component {
  render () {
    const { children, className, color, subtitle, title, type } = this.props

    return (
      <div className={formatter.toClassName(['wd-panel', className])} style={type === 'custom' ? {} : { border: type === 'warn' ? '1px solid rgba(255,0,0,0.4)' : '0' }}>
        <div className='wd-panel-title' style={{ display: title ? '' : 'none' }}>
          { title }

          <div style={{float: 'right'}}>{subtitle}</div>
        </div>

        <div className='wd-panel-body'>
          { children }
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.node,
  title: PropTypes.node
}
